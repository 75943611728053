import axios from 'axios';

import { logSentryEndpointError } from '../../shared/utilities';

export const getUserMetadata = (actions, payload) => axios({
  method: 'get',
  url: `${ process.env.REACT_APP_API_URL }/api/v1/users/metadata`,
  headers: {
    'auth-token': payload || localStorage.getItem('token'),
    // 'Access-Control-Allow-Origin': '*',
    // 'Content-Type': 'application/json'
  }
})
.then(response => {
  actions.storeGetUserMetadata(response.data[0]);
  return response.data[0];
})
.catch(error => {
  logSentryEndpointError(error);
});