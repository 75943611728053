import axios from 'axios';

import { logSentryEndpointError } from '../../shared/utilities';

export const getLines = (actions, payload, state, storeState) => axios({
  method: 'get',
  url: `${ process.env.REACT_APP_API_URL }/api/admin/v1/lines`,
  params: {
    page: payload.page ? payload.page : state.page,
    pageSize: state.pageSize,
    ...(storeState.global.clientSelected !== 'all' && !storeState.global.clientsDisabled && { clientId: storeState.global.clientSelected }),
    ...(!!payload.clientId && { clientId: payload.clientId }),
    textSearch: payload.textSearch === undefined ? state.textSearch : payload.textSearch,
  },
  headers: {
    'auth-token': localStorage.getItem('token'),
    // 'Access-Control-Allow-Origin': '*',
    // 'Content-Type': 'application/json'
  }
})
.then(response => {
  actions.setLoading(false);
  actions.storeGetLines({ data: response.data, page: payload.page || state.page, textSearch: payload.textSearch === undefined ? state.textSearch : payload.textSearch });
})
.catch(error => {
  actions.setLoading(false);
  actions.setError(error.response);
  logSentryEndpointError(error);
});


export const getLine = (actions, payload) => axios({
  method: 'get',
  url: `${ process.env.REACT_APP_API_URL }/api/admin/v1/lines/${ payload }`,
  headers: {
    'auth-token': localStorage.getItem('token'),
    // 'Access-Control-Allow-Origin': '*',
    // 'Content-Type': 'application/json'
  }
})
.then(response => {
  actions.setLoading(false);
  actions.storeGetLine(response.data[0]);
})
.catch(error => {
  actions.setLoading(false);
  actions.setError(error.response);
  logSentryEndpointError(error);
});

