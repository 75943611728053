import React from 'react';

import PropTypes from 'prop-types';

import clsx from 'clsx';

import { useStoreActions, useStore } from 'easy-peasy';

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';

import * as Yup from 'yup';

import { Formik, Field } from 'formik';
import { TextField } from 'formik-material-ui';

import { handleEndpointErrors, SNACKBAR_TIME } from '../../shared/utilities';

import CustomFormikForm from '../CustomFormikForm';
import FormikCheckbox from '../FormikCheckbox';

import { useTranslation } from 'react-multi-lang';

const useStyles = makeStyles(theme => ({
  vehicleActive: {
    right: theme.spacing(3),
  },
}));

const AddVehicle = (props) => {
  const { onGetVehicles, onAddVehicleModalClose } = props;
  const classes = useStyles();

  const store = useStore();

  const setSnackbar = useStoreActions(actions => actions.global.setSnackbar);

  const addVehicle = useStoreActions(actions => actions.vehicles.addVehicle);

  const t = useTranslation();

  return (
    <React.Fragment>
      <DialogTitle>{ t('vehicles.table.actionAdd') }</DialogTitle>
      <Formik
        initialValues={{ active: true, identifier: '', licensePlate: '', make: '', model: '', year: '', km: '', capacity: '', costPerKm: '' }}
        validationSchema={ Yup.object({
          identifier: Yup.number()
            .typeError(t('global.errors.number', { field: t('vehicles.table.identifier') }))
            .required(t('global.errors.required', { field: t('vehicles.table.identifier') })),
          licensePlate: Yup.string()
            .max(64, t('global.errors.max', { field: t('vehicles.licensePlate'), length: 64 }))
            .required(t('global.errors.required', { field: t('vehicles.licensePlate') })),
          make: Yup.string()
            .max(50, t('global.errors.max', { field: t('vehicles.make'), length: 50 }))
            .required(t('global.errors.required', { field: t('vehicles.make') })),
          model: Yup.string()
            .max(50, t('global.errors.max', { field: t('vehicles.model'), length: 50 }))
            .required(t('global.errors.required', { field: t('vehicles.model') })),
          year: Yup.number()
            .typeError(t('global.errors.number', { field: t('vehicles.year') }))
            .required(t('global.errors.required', { field: t('vehicles.year') })),
          km: Yup.number()
            .typeError(t('global.errors.number', { field: t('vehicles.km') }))
            .required(t('global.errors.required', { field: t('vehicles.km') })),
          capacity: Yup.number()
            .typeError(t('global.errors.number', { field: t('vehicles.capacity') }))
            .required(t('global.errors.required', { field: t('vehicles.capacity') })),
          costPerKm: Yup.number()
            .typeError(t('global.errors.number', { field: t('vehicles.costPerKm') }))
        }) }
        onSubmit={(values, { setSubmitting }) => {
          const data = {
            active: values.active,
            identifier: +values.identifier,
            licensePlate: values.licensePlate,
            make: values.make,
            model: values.model,
            year: +values.year,
            km: +values.km,
            capacity: +values.capacity,
            costPerKm: +values.costPerKm
          };
          addVehicle(data).then(() => {
            const vehiclesState = store.getState().vehicles;
            if (!vehiclesState.loading && !vehiclesState.error) {
              setSubmitting(false);
              onGetVehicles();
              setSnackbar({ show: true, autoHideDuration: SNACKBAR_TIME.SUCCESS, severity: 'success', message: t('global.success.saved') });
              onAddVehicleModalClose();
            } else {
              handleEndpointErrors(vehiclesState, props, setSnackbar, t);
            }
          });
        }}
      >
        {({ submitForm, isSubmitting, values }) => (
          <CustomFormikForm>
            <DialogContent>
              <Field
                component={ FormikCheckbox }
                className={clsx( 'formik-checkbox', classes.vehicleActive )}
                name="active"
                label={ t('vehicles.active') }
                disabled={ isSubmitting }
              />
              <Grid container spacing={ 3 }>
                <Grid item xs={ 6 } md={ 4 }>
                  <Field
                    component={ TextField }
                    name="identifier"
                    type="text"
                    label={ t('vehicles.table.identifier') }
                    variant="outlined"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={ 6 } md={ 4 }>
                  <Field
                    component={ TextField }
                    name="licensePlate"
                    type="text"
                    label={ t('vehicles.licensePlate') }
                    variant="outlined"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={ 6 } md={ 4 }>
                  <Field
                    component={ TextField }
                    name="make"
                    type="text"
                    label={ t('vehicles.make') }
                    variant="outlined"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={ 6 } md={ 4 }>
                  <Field
                    component={ TextField }
                    name="model"
                    type="text"
                    label={ t('vehicles.model') }
                    variant="outlined"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={ 6 } md={ 4 }>
                  <Field
                    component={ TextField }
                    name="year"
                    type="text"
                    label={ t('vehicles.year') }
                    variant="outlined"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={ 6 } md={ 4 }>
                  <Field
                    component={ TextField }
                    name="km"
                    type="text"
                    label={ t('vehicles.km') }
                    variant="outlined"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={ 6 } md={ 4 }>
                  <Field
                    component={ TextField }
                    name="capacity"
                    type="text"
                    label={ t('vehicles.capacity') }
                    variant="outlined"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={ 6 } md={ 4 }>
                  <Field
                    component={ TextField }
                    name="costPerKm"
                    type="text"
                    label={ t('vehicles.costPerKm') }
                    variant="outlined"
                    fullWidth
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={ onAddVehicleModalClose } color="primary">{ t('global.modal.btnClose') }</Button>
              <Button disabled={ isSubmitting } onClick={ submitForm } color="primary">{ t('global.modal.btnAdd') }</Button>
            </DialogActions>
          </CustomFormikForm>
        )}
      </Formik>
    </React.Fragment>
  );
}

AddVehicle.propTypes = {
  onGetVehicles: PropTypes.func.isRequired,
  onAddVehicleModalClose: PropTypes.func.isRequired,
};

export default AddVehicle;
