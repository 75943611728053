import { action, thunk } from 'easy-peasy';

import { getClients, getClient } from '../../services/clients/get';
import addClient from '../../services/clients/post';
import updateClient from '../../services/clients/put';
import deleteClient from '../../services/clients/delete';

const clientsModel = {
  clients: [],
  client: [],
  clientsUpdated: false,
  loading: false,
  error: '',
  page: 1,
  pageSize: localStorage.getItem('clientsPageSize') ? localStorage.getItem('clientsPageSize') : 50,
  textSearch: '',
  getClients: thunk(async (actions, payload = {}, { getState }) => {
    actions.setLoading(payload.loading);
    actions.setError('');
    return await getClients(actions, payload, getState());
  }),
  getClient: thunk(async (actions, payload) => {
    actions.setLoading(true);
    actions.setError('');
    return await getClient(actions, payload);
  }),
  addClient: thunk(async (actions, payload) => {
    actions.setError('');
    return await addClient(actions, payload);
  }),
  updateClient: thunk(async (actions, payload) => {
    actions.setError('');
    return await updateClient(actions, payload);
  }),
  deleteClient: thunk(async (actions, payload) => {
    actions.setError('');
    return await deleteClient(actions, payload);
  }),
  storeGetClients: action((state, payload) => {
    state.clients = payload.data;
    state.page = payload.page ? payload.page : 1;
    state.textSearch = payload.textSearch ? payload.textSearch : '';
  }),
  storeGetClient: action((state, payload) => {
    state.client = payload;
  }),
  setLoading: action((state, payload) => {
    state.loading = payload;
  }),
  setError: action((state, payload) => {
    state.error = payload;
  }),
  setTextSearch: action((state, payload) => {
    state.textSearch = payload;
  }),
  setClientsUpdated: action((state, payload) => {
    state.clientsUpdated = payload;
  }),
}

export default clientsModel;
