import React from 'react';
import { withRouter } from 'react-router-dom';

import PropTypes from 'prop-types';

import clsx from 'clsx';

import { useStoreState, useStoreActions, useStore } from 'easy-peasy';

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';

import * as Yup from 'yup';

import { Formik, Field } from 'formik';
import { TextField } from 'formik-material-ui';

import { handleEndpointErrors, renderPlaceOption, SNACKBAR_TIME } from '../../shared/utilities';
import { useDebounce } from '../../shared/hooks/useDebounce';

import LoadingCard from '../LoadingCard';
import CustomFormikForm from '../CustomFormikForm';
import FormikCheckbox from '../FormikCheckbox';
import FormikAutocomplete from '../FormikAutocomplete';

import { useTranslation } from 'react-multi-lang';

const useStyles = makeStyles(theme => ({
  routeActive: {
    right: theme.spacing(3),
  },
}));

const AddRoute = (props) => {
  const { onAddRouteModalClose } = props;
  const classes = useStyles();

  const store = useStore();

  const routeDirections = useStoreState(state => state.global.routeDirections);
  const routeTypes = useStoreState(state => state.global.routeTypes);
  const clients = useStoreState(state => state.global.clients);
  const clientSelected = useStoreState(state => state.global.clientSelected);
  const setSnackbar = useStoreActions(actions => actions.global.setSnackbar);

  const addRoute = useStoreActions(actions => actions.routes.addRoute);
  const addRouteClient = useStoreActions(actions => actions.routes.addRouteClient);

  const getAdminPlaces = useStoreActions(actions => actions.places.getAdminPlaces);
  const adminPlaces = useStoreState(state => state.places.adminPlaces);
  const adminPlacesLoading = useStoreState(state => state.places.loading);

  const getOperationalIntervals = useStoreActions(actions => actions.operationalIntervals.getOperationalIntervals);
  const operationalIntervals = useStoreState(state => state.operationalIntervals.operationalIntervals);
  const operationalIntervalsLoading = useStoreState(state => state.operationalIntervals.loading);

  const [placeName, setPlaceName] = React.useState('');
  const debouncedPlaceName = useDebounce(placeName, 500);

  const onGetAdminPlaces = (payload) => {
    getAdminPlaces(payload).then(() => {
      const placesState = store.getState().places;
      if (!placesState.loading && !placesState.error) {
        // console.log(placesState);
      } else {
        handleEndpointErrors(placesState, props, setSnackbar, t);
      }
    });
  }

  React.useEffect(() => {
    onGetAdminPlaces({ textSearch: debouncedPlaceName });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedPlaceName]);

  React.useEffect(() => {
    getOperationalIntervals().then(() => {
      const operationalIntervalsState = store.getState().operationalIntervals;
      if (!operationalIntervalsState.loading && !operationalIntervalsState.error) {
        // console.log(operationalIntervalsState);
      } else {
        handleEndpointErrors(operationalIntervalsState, props, setSnackbar, t);
      }
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const t = useTranslation();

  return (
    <React.Fragment>
      <DialogTitle>{ t('routes.table.actionAdd') }</DialogTitle>
      <Formik
        initialValues={{ active: true, name: '', direction: '', type: '', origin: '', destination: '', interval: '', client: clientSelected !== 'all' ? clients.find(client => client.id === +clientSelected) : '', km: '', eta: '', order: '', costPerKm: '', passengerTripCost: '' }}
        validationSchema={ Yup.object({
          name: Yup.string()
            .max(50, t('global.errors.max', { field: t('routes.name'), length: 50 }))
            .required(t('global.errors.required', { field: t('routes.name') })),
          direction: Yup.string()
            .required(t('global.errors.required', { field: t('routes.direction.label') })),
          type: Yup.string()
            .required(t('global.errors.required', { field: t('routes.type.label') })),
          origin: Yup.string()
            .required(t('global.errors.required', { field: t('routes.origin') })),
          destination: Yup.string()
            .required(t('global.errors.required', { field: t('routes.destination') })),
          interval: Yup.string()
            .required(t('global.errors.required', { field: t('routes.interval') })),
          client: Yup.string()
            .required(t('global.errors.required', { field: t('routes.client') })),
          km: Yup.number()
            .typeError(t('global.errors.number', { field: t('routes.km') }))
            .required(t('global.errors.required', { field: t('routes.km') })),
          eta: Yup.number()
            .typeError(t('global.errors.number', { field: t('routes.eta') })),
          order: Yup.number()
            .typeError(t('global.errors.number', { field: t('routes.order') })),
          costPerKm: Yup.number()
            .typeError(t('global.errors.number', { field: t('routes.costPerKm') })),
          passengerTripCost: Yup.number()
            .typeError(t('global.errors.number', { field: t('routes.passengerTripCost') }))
        }) }
        onSubmit={(values, { setSubmitting }) => {
          const data = {
            active: values.active,
            name: values.name,
            direction: values.direction,
            routeTypeId: +values.type.id,
            originId: +values.origin.id,
            destinationId: +values.destination.id,
            operationalIntervalId: +values.interval.id,
            km: +values.km,
            eta: +values.eta,
            displayOrder: +values.order,
            costPerKm: +values.costPerKm,
            passengerTripCost: +values.passengerTripCost
          };
          const clientId = +values.client.id;
          addRoute(data).then(() => {
            const routesState = store.getState().routes;
            if (!routesState.loading && !routesState.error) {
              const routeId = routesState.newRouteId;
              addRouteClient({routeId, clientId}).then(() => {
                const routesState = store.getState().routes;
                setSubmitting(false);
                if (!routesState.loading && !routesState.error) {
                  setSnackbar({ show: true, autoHideDuration: SNACKBAR_TIME.SUCCESS, severity: 'success', message: t('global.success.saved') });
                  props.history.push(`/routes/${ routeId }`);
                } else {
                  handleEndpointErrors(routesState, props, setSnackbar, t);
                }
              });
            } else {
              setSubmitting(false);
              handleEndpointErrors(routesState, props, setSnackbar, t);
            }
          });
        }}
      >
        {({ submitForm, isSubmitting, values }) => (
          <CustomFormikForm>
            <DialogContent>
              <Field
              component={ FormikCheckbox }
              className={clsx( 'formik-checkbox', classes.routeActive )}
              name="active"
              label={ t('routes.active') }
              disabled={ isSubmitting }
            />
            <Grid container spacing={ 3 }>
              <Grid item xs={ 6 } md={ 4 }>
                <Field
                  component={ TextField }
                  name="name"
                  type="text"
                  label={ t('routes.name') }
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item xs={ 6 } md={ 4 }>
                <Field
                  component={ FormikAutocomplete }
                  name="direction"
                  disableClearable
                  options={ routeDirections }
                  getOptionLabel={ option => option ? t(`routes.direction.${ option.toLowerCase() }`) : '' }
                  textFieldProps={{ label: t('routes.direction.label') , variant: 'outlined', readOnly: true, fullWidth: true }}
                />
              </Grid>
              <Grid item xs={ 6 } md={ 4 }>
                <Field
                  component={ FormikAutocomplete }
                  name="type"
                  disableClearable
                  options={ routeTypes }
                  getOptionLabel={ option => option ? t(`routes.type.${ option.name.toLowerCase() }`) : '' }
                  textFieldProps={{ label: t('routes.type.label') , variant: 'outlined', readOnly: true, fullWidth: true }}
                />
              </Grid>
              <Grid item xs={ 6 } md={ 4 }>
                { adminPlacesLoading ? <LoadingCard length={ 1 } height={ 56 } /> : (
                  <Field
                    component={ FormikAutocomplete }
                    name='origin'
                    disableClearable
                    options={ adminPlaces.filter(place => ![values.origin?.id, values.destination?.id].includes(place.id)) }
                    renderOption={ option => option ? renderPlaceOption(option) : '' }
                    getOptionLabel={ option => option ? option.description : '' }
                    textFieldProps={{
                      label: t('routes.origin'),
                      variant: 'outlined',
                      fullWidth: true,
                      onChange: event => {
                        setPlaceName( event.target.value );
                      }
                    }}
                    onFocus={() => {
                      setPlaceName('');
                    }}
                  />
                ) }
              </Grid>
              <Grid item xs={ 6 } md={ 4 }>
                { adminPlacesLoading ? <LoadingCard length={ 1 } height={ 56 } /> : (
                  <Field
                    component={ FormikAutocomplete }
                    name='destination'
                    disableClearable
                    options={ adminPlaces.filter(place => ![values.origin?.id, values.destination?.id].includes(place.id)) }
                    renderOption={ option => option ? renderPlaceOption(option) : '' }
                    getOptionLabel={ option => option ? option.description : '' }
                    textFieldProps={{
                      label: t('routes.destination'),
                      variant: 'outlined',
                      fullWidth: true,
                      onChange: event => {
                        setPlaceName( event.target.value );
                      }
                    }}
                    onFocus={() => {
                      setPlaceName('');
                    }}
                  />
                ) }
              </Grid>
              <Grid item xs={ 6 } md={ 4 }>
                { operationalIntervalsLoading ? <LoadingCard length={ 1 } height={ 56 } /> : (
                  <Field
                    component={ FormikAutocomplete }
                    name='interval'
                    disableClearable
                    options={ operationalIntervals.filter(interval => ![values.interval?.id].includes(interval.id)) }
                    getOptionLabel={ option => option ? option.name : '' }
                    textFieldProps={{ label: t('routes.interval'), variant: 'outlined', readOnly: true, fullWidth: true }}
                  />
                ) }
              </Grid>
              <Grid item xs={ 6 } md={ 4 }>
                <Field
                  component={ FormikAutocomplete }
                  name='client'
                  disableClearable
                  options={ clients.filter(client => ![values.client?.id].includes(client.id)) }
                  getOptionLabel={ option => option ? option.name : '' }
                  textFieldProps={{ label: t('routes.client'), variant: 'outlined', readOnly: true, fullWidth: true }}
                />
              </Grid>
              <Grid item xs={ 6 } md={ 4 }>
                <Field
                  component={ TextField }
                  name="km"
                  type="text"
                  label={ t('routes.km') }
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item xs={ 6 } md={ 4 }>
                <Field
                  component={ TextField }
                  name="eta"
                  type="text"
                  label={ t('routes.eta') }
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item xs={ 6 } md={ 4 }>
                <Field
                  component={ TextField }
                  name="order"
                  type="text"
                  label={ t('routes.order') }
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item xs={ 6 } md={ 4 }>
                <Field
                  component={ TextField }
                  name="costPerKm"
                  type="text"
                  label={ t('routes.costPerKm') }
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item xs={ 6 } md={ 4 }>
                <Field
                  component={ TextField }
                  name="passengerTripCost"
                  type="text"
                  label={ t('routes.passengerTripCost') }
                  variant="outlined"
                  fullWidth
                />
              </Grid>
            </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={ onAddRouteModalClose } color="primary">{ t('global.modal.btnClose') }</Button>
              <Button disabled={ isSubmitting } onClick={ submitForm } color="primary">{ t('global.modal.btnAdd') }</Button>
            </DialogActions>
          </CustomFormikForm>
        )}
      </Formik>
    </React.Fragment>
  );
}

AddRoute.propTypes = {
  onAddRouteModalClose: PropTypes.func.isRequired,
};

export default withRouter(AddRoute);
