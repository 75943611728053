import React from 'react';

import PropTypes from 'prop-types';

import { useStoreState, useStoreActions, useStore } from 'easy-peasy';

// import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import RecentActorsIcon from '@material-ui/icons/RecentActors';

import * as Yup from 'yup';

import { Formik, Field } from 'formik';

import { handleEndpointErrors, SNACKBAR_TIME } from '../../shared/utilities';
import { useDebounce } from '../../shared/hooks/useDebounce';

import LoadingCard from '../LoadingCard';
import CustomFormikForm from '../CustomFormikForm';
import FormikAutocomplete from '../FormikAutocomplete';

import { useTranslation } from 'react-multi-lang';

// const useStyles = makeStyles(theme => ({
//   root: {},
// }));

const AddClientModal = (props) => {
  const { businessParkId, onGetBusinessPark, onAddClientModalClose } = props;
  // const classes = useStyles();

  const store = useStore();

  const setSnackbar = useStoreActions(actions => actions.global.setSnackbar);

  const getClients = useStoreActions(actions => actions.clients.getClients);
  const clients = useStoreState(state => state.clients.clients);
  const clientsLoading = useStoreState(state => state.clients.loading);
  const setClientsTextSearch = useStoreActions(actions => actions.clients.setTextSearch);
  const updateClient = useStoreActions(actions => actions.clients.updateClient);

  const [client, setClient] = React.useState('');
  const debouncedClient = useDebounce(client, 500);

  const onGetClients = (payload) => {
    getClients(payload).then(() => {
      const clientsState = store.getState().clients;
      if (!clientsState.loading && !clientsState.error) {
        // console.log(clientsState);
      } else {
        handleEndpointErrors(clientsState, props, setSnackbar, t);
      }
    });
  }

  React.useEffect(() => {
    onGetClients({ textSearch: debouncedClient });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedClient]);

  React.useEffect(() => {
    return () => setClientsTextSearch('');
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const t = useTranslation();

  return (
    <React.Fragment>
      <DialogTitle>{ t('businessPark.addClient.title') }</DialogTitle>
      <Formik
        initialValues={{ client: '' }}
        validationSchema={ Yup.object({
          client: Yup.string()
            // .max(15, 'Must be 15 characters or less')
            .required(t('global.errors.required', { field: t('businessPark.addClient.client') })),
        }) }
        onSubmit={(values, { setSubmitting }) => {
          const data = {
            ...values.client,
            parkId: businessParkId
          };
          updateClient({ clientId: values.client.id, data }).then(() => {
            const clientsState = store.getState().clients;
            if (!clientsState.loading && !clientsState.error) {
              setSubmitting(false);
              onGetBusinessPark();
              onAddClientModalClose();
              setSnackbar({ show: true, autoHideDuration: SNACKBAR_TIME.SUCCESS, severity: 'success', message: t('global.success.saved') });
            } else {
              handleEndpointErrors(clientsState, props, setSnackbar, t);
            }
            setSubmitting(false);
          });
        }}
      >
        {({ submitForm, isSubmitting }) => (
          <CustomFormikForm>
            <DialogContent>
              <Grid container spacing={ 3 }>
                <Grid item xs={ 6 } md={ 4 }>
                  { clientsLoading ? <LoadingCard length={ 1 } height={ 56 } /> : (
                    <Field
                      component={ FormikAutocomplete }
                      name='client'
                      disableClearable
                      options={ clients.filter(client => !client.parkId) }
                      getOptionLabel={ option => option ? option.name : '' }
                      textFieldProps={{
                        label: t('businessPark.addClient.client'),
                        variant: 'outlined',
                        fullWidth: true,
                        icon: <RecentActorsIcon />,
                        onChange: event => {
                          setClient( event.target.value );
                        }
                      }}
                      onFocus={() => {
                        setClient('');
                      }}
                    />
                  ) }
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={ onAddClientModalClose } color="primary">{ t('global.modal.btnClose') }</Button>
              <Button disabled={ isSubmitting } onClick={ submitForm } color="primary">{ t('global.modal.btnAdd') }</Button>
            </DialogActions>
          </CustomFormikForm>
        )}
      </Formik>
    </React.Fragment>
  );
}

AddClientModal.propTypes = {
  businessParkId: PropTypes.number.isRequired,
  onGetBusinessPark: PropTypes.func.isRequired,
  onAddClientModalClose: PropTypes.func.isRequired,
};

export default AddClientModal;
