import React from 'react';

import PropTypes from 'prop-types';

import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import Checkbox from '@material-ui/core/Checkbox';
import DeleteIcon from '@material-ui/icons/DeleteOutlined';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import IconButton from '@material-ui/core/IconButton';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';

import MaterialTable from 'material-table';

import LoadingCard from './LoadingCard';

import { useTranslation } from 'react-multi-lang';
import TablePagination from './TablePagination';

const useStyles = makeStyles(theme => ({
  root: {
    border: `1px solid ${ theme.palette.divider }`,
    borderRadius: '8px',
    '& .MuiToolbar-root .MuiFormControl-root': {
      backgroundColor: theme.palette.type === 'dark' ? theme.palette.background.paper : theme.palette.grey[200],
      borderRadius: theme.spacing(4),
      '& .MuiInput-underline:before, & .MuiInput-underline:after': {
        content: 'normal',
      },
    },
    '& .MuiTableCell-head': {
      backgroundColor: theme.palette.background.default,
    },
    '& .MuiTableRow-root': {
      '& .MuiFormControl-root, & .MuiInputBase-root:not(.MuiTablePagination-input):not(> .MuiInputBase-root)': {
        width: '100%',
      },
    },
    '& .MuiTable-nested': {
      border: `20px solid ${ theme.palette.type === 'dark' ? theme.palette.background.paper : theme.palette.grey[200] }`,
    },
    [theme.breakpoints.down('sm')]: {
      '& .MuiToolbar-root:not(.MuiTablePagination-toolbar)': {
        flexDirection: 'column',
        padding: '16px 8px 0',
      },
    },
  },
  paper: {
    borderRadius: '12px',
    minWidth: '192px',
    padding: theme.spacing(2, 2),
  },
  checkboxLabel: {
    alignItems: 'center',
    display: 'flex',
    '& .MuiSvgIcon-root': {
      fontSize: '18px',
      height: '18px',
      width: '18px',
    },
  }
}));

export default function Table(props) {
  const classes = useStyles();
  const { tableRef, className, title, columns, data, draggable, selection, grouping, filtering, exportButton, exportFileName, paging, pageSize, pageSizeOptions, addActions, addDetailPanels, updateEvent, deleteEvent, disableDelete, deleteTooltip, updateColumns, onSearchChange, onFilterChange, onSelectionChange, searchPlaceholder, searchText, search, customPagination } = props;
  const [state, setState] = React.useState({ columns, data });
  const [menuColumnsAnchor, setMenuColumnsAnchor] = React.useState({ clientX: null, clientY: null });
  const [isLoading, setIsLoading] = React.useState(false);

  if (JSON.stringify(data) !== JSON.stringify(state.data)) {
    setState({ columns, data });
  }

  const onCloseUpdateColumns = () => {
    setMenuColumnsAnchor({ clientX: null, clientY: null });
  };

  const onUpdateColumn = column => {
    const nonFreeActions = addActions?.filter(action => !action.isFreeAction).length || 0;
    if (nonFreeActions) {
      setIsLoading(true);
      setTimeout(() => {
        updateColumn(column);
        setIsLoading(false);
      });
    } else {
      updateColumn(column);
    }
  };

  const updateColumn = column => {
    const columnsArray = [...state.columns];
    const updatedColumn = columnsArray.find(col => col.field === column.field);
    updatedColumn.hidden = !updatedColumn.hidden;
    setState({ data: [...state.data], columns: columnsArray });
    localStorage.setItem(`table${ updateColumns }`, JSON.stringify(columnsArray));
  };

  const onMoveColumn = (event, direction, index) => {
    event.preventDefault();
    event.stopPropagation();
    let columnsArray = [...state.columns];
    const lastIndex = columnsArray.length - 1;
    const updatedIndex = direction === 'up' ?
      index === 0 ? lastIndex : index - 1 :
      index === lastIndex ? 0 : index + 1;
    columnsArray.splice(updatedIndex, 0, columnsArray.splice(index, 1)[0]);
    columnsArray = columnsArray.map((column, colIndex) => ({
      ...column,
      tableData: {
        ...column.tableData,
        id: colIndex,
        columnOrder: colIndex
      }
    }));
    setState({ data: [...state.data], columns: columnsArray });
    localStorage.setItem(`table${ updateColumns }`, JSON.stringify(columnsArray));
  };

  const t = useTranslation();

  return (
    <React.Fragment>
      { updateColumns ? (
        <Popover
          classes={{ paper: classes.paper }}
          anchorReference="anchorPosition"
          anchorPosition={
            menuColumnsAnchor.clientY !== null && menuColumnsAnchor.clientX !== null
              ? { top: menuColumnsAnchor.clientY, left: menuColumnsAnchor.clientX }
              : undefined
          }
          keepMounted
          open={ menuColumnsAnchor.clientX !== null }
          onClose={ onCloseUpdateColumns }
          anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
          transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        >
          <FormGroup>
            { state.columns.map((column, index) => (
              <FormControlLabel key={ column.field }
                control={
                  <Checkbox checked={ !column.hidden } onChange={ () => onUpdateColumn(column) } name={ column.field } />
                }
                label={
                  <div className={ classes.checkboxLabel }>
                    <Typography variant="body1">{ column.title }</Typography>
                    <IconButton onClick={ (event) => onMoveColumn(event, 'up', index) } aria-label={ t('global.table.custom.moveUp') }>
                      <ArrowUpwardIcon />
                    </IconButton>
                    <IconButton onClick={ (event) => onMoveColumn(event, 'down', index) } aria-label={ t('global.table.custom.moveDown') }>
                      <ArrowDownwardIcon />
                    </IconButton>
                  </div>
                }
              />
            )) }
          </FormGroup>
        </Popover>
      ) : null }

      { isLoading ? <LoadingCard length={ 1 } height={ 1565 } /> : <MaterialTable
        tableRef={ tableRef }
        title={ title }
        columns={ state.columns }
        data={ state.data }
        editable={{
          ...(updateEvent ? { onRowUpdate: (newData, oldData) =>
            new Promise((resolve, reject) => {
              updateEvent(newData, oldData, resolve, reject);
            }) } : {}),
          ...(deleteEvent ? { onRowDelete: (oldData) =>
            new Promise((resolve, reject) => {
              deleteEvent(oldData, resolve, reject);
            }) } : {}),
          ...(disableDelete ? { isDeletable: () => false } : {}),
          ...(deleteTooltip ? { deleteTooltip: () => deleteTooltip } : {}),
        }}
        options={{
          draggable: draggable === undefined ? false : draggable,
          actionsColumnIndex: -1,
          selection,
          grouping,
          filtering,
          exportButton,
          exportFileName,
          paging: paging === undefined ? true : paging,
          pageSize: customPagination?.page ? 100 : pageSize || 15,
          pageSizeOptions: pageSizeOptions || [15, 30, 50],
          debounceInterval: onSearchChange ? 900 : 200,
          emptyRowsWhenPaging: false,
          searchText: searchText || '',
          search: search === undefined ? true : search,
        }}
        actions={[
          ...(addActions ? addActions : []),
          ...(updateColumns ? [{
            icon: 'view_column',
            tooltip: t('global.table.custom.updateColumns'),
            isFreeAction: true,
            onClick: event => {
              event.persist();
              setMenuColumnsAnchor(event);
            }
          }] : []),
        ]}
        detailPanel={ addDetailPanels }
        components={{
          Container: props => (
            <div className={ clsx(classes.root, className) } { ...props }>
            </div>
          ),
          ...(customPagination?.page ? { Pagination: () => (
            <TablePagination { ...customPagination } />
          ) } : {}),
        }}
        onSearchChange={ typeof onSearchChange === 'function' ? (data) => {
          onSearchChange(data);
          setTimeout(() => {
            document.querySelector('input[aria-label="Search"]').focus();
          }, 1000);
        } : null }
        onFilterChange={ onFilterChange }
        onSelectionChange={ onSelectionChange }
        icons={{
          Delete: () => <DeleteIcon color={ disableDelete ? 'disabled' : 'error' } />
        }}
        localization={{
          header: {
            actions: t('global.table.header.actions')
          },
          body: {
            emptyDataSourceMessage: t('global.table.body.emptyDataSourceMessage'),
            addTooltip: t('global.table.body.addTooltip'),
            deleteTooltip: t('global.table.body.deleteTooltip'),
            editTooltip: t('global.table.body.editTooltip'),
            editRow: {
              deleteText: t('global.table.body.editRow.deleteText'),
              cancelTooltip: t('global.table.body.editRow.cancelTooltip'),
              saveTooltip: t('global.table.body.editRow.saveTooltip'),
            },
            filterRow: {
              filterTooltip: t('global.table.body.filterRow.filterTooltip'),
            },
          },
          grouping: {
            placeholder: t('global.table.grouping.placeholder'),
            groupedBy: t('global.table.grouping.groupedBy'),
          },
          pagination: {
            labelDisplayedRows: t('global.table.pagination.labelDisplayedRows'),
            labelRowsSelect: t('global.table.pagination.labelRowsSelect'),
            labelRowsPerPage: t('global.table.pagination.labelRowsPerPage'),
            firstAriaLabel: t('global.table.pagination.firstAriaLabel'),
            firstTooltip: t('global.table.pagination.firstTooltip'),
            previousAriaLabel: t('global.table.pagination.previousAriaLabel'),
            previousTooltip: t('global.table.pagination.previousTooltip'),
            nextAriaLabel: t('global.table.pagination.nextAriaLabel'),
            nextTooltip: t('global.table.pagination.nextTooltip'),
            lastAriaLabel: t('global.table.pagination.lastAriaLabel'),
            lastTooltip: t('global.table.pagination.lastTooltip'),
          },
          toolbar: {
            exportTitle: t('global.table.toolbar.exportTitle'),
            exportAriaLabel: t('global.table.toolbar.exportAriaLabel'),
            exportCSVName: t('global.table.toolbar.exportCSVName'),
            exportPDFName: t('global.table.toolbar.exportPDFName'),
            searchTooltip: t('global.table.toolbar.searchTooltip'),
            searchPlaceholder: searchPlaceholder || t('global.table.toolbar.searchPlaceholder'),
            nRowsSelected: t('global.table.toolbar.nRowsSelected'),
          }
        }}
      /> }
    </React.Fragment>
  );
}

Table.propTypes = {
  tableRef: PropTypes.any,
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  addActions: PropTypes.array,
  addDetailPanels: PropTypes.array,
  draggable: PropTypes.bool,
  selection: PropTypes.bool,
  grouping: PropTypes.bool,
  filtering: PropTypes.bool,
  exportButton: PropTypes.bool,
  exportFileName: PropTypes.string,
  paging: PropTypes.bool,
  pageSize: PropTypes.number,
  pageSizeOptions: PropTypes.array,
  updateEvent: PropTypes.func,
  deleteEvent: PropTypes.func,
  disableDelete: PropTypes.bool,
  deleteTooltip: PropTypes.string,
  updateColumns: PropTypes.string,
  onSearchChange: PropTypes.func,
  onFilterChange: PropTypes.func,
  onSelectionChange: PropTypes.func,
  searchPlaceholder: PropTypes.string,
  searchText: PropTypes.string,
  search: PropTypes.bool,
  customPagination: PropTypes.exact({
    page: PropTypes.number.isRequired,
    size: PropTypes.number.isRequired,
    responseLength: PropTypes.number.isRequired,
    loading: PropTypes.bool.isRequired,
    hasRowsSelection: PropTypes.bool,
    clickEvent: PropTypes.func.isRequired,
  }),
};
