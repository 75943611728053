import React from 'react';

import PropTypes from 'prop-types';

import { useStoreState, useStoreActions, useStore } from 'easy-peasy';

import { makeStyles } from '@material-ui/core/styles';

import { handleEndpointErrors, DEVICE_NAMES, SNACKBAR_TIME } from '../../shared/utilities';

import Table from '../Table';
import CustomDialog from '../CustomDialog';
import AddDevice from './AddDevice';

import { useTranslation } from 'react-multi-lang';

const useStyles = makeStyles(theme => ({
  table: {
    '& .MuiTableCell-head:nth-child(3)': {
      minWidth: '260px',
    },
    // '& .MuiTableCell-body:nth-child(4)': {
    //   wordBreak: 'break-word',
    // },
  }
}));

const Devices = (props) => {
  const { userId } = props;
  const classes = useStyles();
  const [devicesTableData, setDevicesTableData] = React.useState([]);
  const [addDeviceModalOpen, setAddDeviceModalOpen] = React.useState(false);
  const [editDevice, setEditDevice] = React.useState(null);

  const store = useStore();

  const setSnackbar = useStoreActions(actions => actions.global.setSnackbar);

  const devices = useStoreState(state => state.users.devices);
  const getDevices = useStoreActions(actions => actions.users.getDevices);
  const deleteDevice = useStoreActions(actions => actions.users.deleteDevice);

  const t = useTranslation();

  const getDeviceNames = () => {
    const deviceNames = {};
    // eslint-disable-next-line array-callback-return
    DEVICE_NAMES.map(name => {
      deviceNames[name] = t(`user.devices.names.${ name }`);
    });
    return deviceNames;
  };

  const devicesTableColumns = [
    { title: t('user.devices.id'), field: 'id', type: 'numeric', align: 'left', defaultSort: 'asc' },
    { title: t('user.devices.deviceId'), field: 'deviceId' },
    { title: t('user.devices.value'), field: 'value' },
    { title: t('user.devices.name'), field: 'name', lookup: getDeviceNames() },
    { title: t('user.devices.active'), field: 'active', type: 'boolean', sorting: false },
  ];

  React.useEffect(() => {
    getDevices(userId);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    setDevicesTableData(devices);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [devices]);

  const onDeleteDevice = (oldData, resolve, reject) => {
    deleteDevice(oldData.id).then(() => {
      const users = store.getState().users;
      if (!users.error) {
        // console.log('success', users);
        resolve();
        const data = [...devicesTableData].filter(device => device.id !== oldData.id);
        setDevicesTableData(data);
        setSnackbar({ show: true, autoHideDuration: SNACKBAR_TIME.SUCCESS, severity: 'success', message: t('global.success.saved') });
      } else {
        reject();
        handleEndpointErrors(users, props, setSnackbar, t);
      }
    });
  };

  const onAddDeviceModalClose = () => {
    setAddDeviceModalOpen(false);
  };

  return (
    <React.Fragment>
      <Table
        className={ classes.table }
        title=""
        columns={ devicesTableColumns }
        data={ devicesTableData }
        paging={ false }
        deleteEvent={ onDeleteDevice }
        addActions={[
          {
            icon: 'add',
            iconProps: {
              color: 'primary'
            },
            tooltip: t('user.devices.add.action'),
            isFreeAction: true,
            onClick: () => {
              setEditDevice({});
              setAddDeviceModalOpen(true);
            }
          },
          {
            icon: 'edit',
            tooltip: t('user.devices.edit.action'),
            onClick: (_, rowData) => {
              setEditDevice(rowData);
              setAddDeviceModalOpen(true);
            }
          }
        ]}
      />

      <CustomDialog open={ addDeviceModalOpen } onClose={ onAddDeviceModalClose } fullWidth={ true } maxWidth="md">
        <AddDevice device={ editDevice || {} } userId={ userId } onAddDeviceModalClose={ onAddDeviceModalClose } />
      </CustomDialog>
    </React.Fragment>
  );
}

Devices.propTypes = {
  userId: PropTypes.number.isRequired,
};

export default Devices;
