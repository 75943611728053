import React from 'react';

import PropTypes from 'prop-types';

import { useStoreState } from 'easy-peasy';

import { makeStyles, withTheme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';

import QRCode from 'qrcode.react';

import { useTranslation } from 'react-multi-lang';

const useStyles = makeStyles(theme => ({
  root: {
    textAlign: 'center',
    '@media print' : {
      backgroundColor: 'white',
      color: 'black',
      '& .MuiDialogTitle-root, & .MuiButtonBase-root': {
        display: 'none',
      },
    },
  },
  content: {
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: theme.spacing(-1),
    '& .MuiTypography-subtitle2': {
      textTransform: 'lowercase',
    },
    '& .MuiTypography-subtitle2:first-letter, & .MuiTypography-subtitle2:first-line': {
      textTransform: 'capitalize',
    },
    '@media print' : {
      padding: 0,
    },
  },
  qr: {
    marginTop: theme.spacing(1),
    pageBreakInside: 'avoid',
    width: '20%',
    [theme.breakpoints.down('sm')]: {
      width: '50%',
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
    '& canvas': {
      border: `2px solid ${ theme.palette.divider }`,
      borderRadius: '8px',
      '@media print' : {
        border: '2px solid #e0e0e0',
      },
    },
  }
}));

const ViewPageQrCodesModal = (props) => {
  const { onViewPageQrCodesModalClose } = props;
  const classes = useStyles();

  const users = useStoreState(actions => actions.users.users);

  const t = useTranslation();

  return (
    <div className={ classes.root }>
      <DialogTitle>{  t('users.table.actionViewPageQrCodes') }</DialogTitle>
      <DialogContent className={ classes.content }>
        { users.map(user => <div key={ user.id } className={ classes.qr }>
          <Typography variant="subtitle2" gutterBottom>{ user.name }</Typography>
          <QRCode
            value={ JSON.stringify(user.expirationDate ? { moveOnPassengerExpirationDate: user.expirationDate } : { moveOnPassengerId: user.id }) }
            size={ 150 }
            includeMargin
          />
        </div> ) }
      </DialogContent>
      <DialogActions>
        <Button onClick={ onViewPageQrCodesModalClose } color="primary">{ t('global.modal.btnClose') }</Button>
      </DialogActions>
    </div>
  );
}

ViewPageQrCodesModal.propTypes = {
  onViewPageQrCodesModalClose: PropTypes.func.isRequired,
};

export default withTheme(ViewPageQrCodesModal);
