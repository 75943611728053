import { action, thunk } from 'easy-peasy';

import { getProvinces, getCounties } from '../../services/countries/get';

const placesModel = {
  provinces: [],
  counties: [],
  loading: false,
  error: '',
  getProvinces: thunk(async (actions) => {
    actions.setLoading(true);
    actions.setError('');
    return await getProvinces(actions);
  }),
  getCounties: thunk(async (actions, payload) => {
    actions.setLoading(true);
    actions.setError('');
    return await getCounties(actions, payload);
  }),
  storeGetProvinces: action((state, payload) => {
    state.provinces = payload;
  }),
  storeGetCounties: action((state, payload) => {
    state.counties = payload;
  }),
  setLoading: action((state, payload) => {
    state.loading = payload;
  }),
  setError: action((state, payload) => {
    state.error = payload;
  }),
}

export default placesModel;
