import React from 'react';
import { withRouter } from 'react-router-dom';

import { useStoreState, useStoreActions, useStore } from 'easy-peasy';

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import EditIcon from '@material-ui/icons/Edit';
import Grid from '@material-ui/core/Grid';
import HomeIcon from '@material-ui/icons/Home';
import Link from '@material-ui/core/Link';
import LocalActivityIcon from '@material-ui/icons/LocalActivity';
import Typography from '@material-ui/core/Typography';

import { Formik, Field } from 'formik';
import { TextField } from 'formik-material-ui';

import { handleEndpointErrors, formatDateYear, formatTime, titleCase, SNACKBAR_TIME } from '../shared/utilities';

import Breadcrumbs from '../components/Breadcrumbs';
import LoadingCard from '../components/LoadingCard';
import CustomFormikForm from '../components/CustomFormikForm';
import FormikAutocomplete from '../components/FormikAutocomplete';
import CustomDialog from '../components/CustomDialog';

import UpdateReservationModal from '../components/EditReservation/UpdateReservationModal';
import DeleteReservationModal from '../components/EditReservation/DeleteReservationModal';

import { useTranslation } from 'react-multi-lang';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3, 2),
  },
  pageTitle: {
    marginBottom: theme.spacing(2.5),
  },
  changeRouteMsg: {
    marginTop: theme.spacing(1),
    '& ul': {
      listStyle: 'none',
      margin: `${theme.spacing(1)}px 0 ${theme.spacing(2)}px`,
      padding: 0,
    }
  },
  deleteBtn: {
    color: theme.palette.error.main,
  },
}));

const EditReservation = (props) => {
  const classes = useStyles();

  const store = useStore();

  const storeMenuTitle = useStoreActions(actions => actions.global.storeTitle);
  const setSnackbar = useStoreActions(actions => actions.global.setSnackbar);

  const reservation = useStoreState(state => state.reservations.reservation);
  const reservationLoading = useStoreState(state => state.reservations.loading);
  const reservationRouteAlternatives = useStoreState(state => state.reservations.reservationRouteAlternatives);
  const getReservation = useStoreActions(actions => actions.reservations.getReservation);
  const storeGetReservation = useStoreActions(actions => actions.reservations.storeGetReservation);
  const getReservationRouteAlternatives = useStoreActions(actions => actions.reservations.getReservationRouteAlternatives);
  const updateReservation = useStoreActions(actions => actions.reservations.updateReservation);
  const deleteReservation = useStoreActions(actions => actions.reservations.deleteReservation);

  const [updatedReservation, setUpdatedReservation] = React.useState({});
  const [updateReservationModalOpen, setUpdateReservationModalOpen] = React.useState(false);
  const [deleteReservationModalOpen, setDeleteReservationModalOpen] = React.useState(false);

  const onGetReservation = () => {
    getReservation(props.match.params.id).then(() => {
      const reservationsState = store.getState().reservations;
      if (!reservationsState.loading && !reservationsState.error) {
        // console.log(reservationsState);
        getReservationRouteAlternatives(reservationsState.reservation.id).then(() => {
          const reservationsState = store.getState().reservations;
          if (!reservationsState.loading && !reservationsState.error) {
            // console.log(reservationsState);
          } else {
            handleEndpointErrors(reservationsState, props, setSnackbar, t);
          }
        });
      } else {
        handleEndpointErrors(reservationsState, props, setSnackbar, t);
      }
    });
  };

  const onDeleteReservation = () => {
    deleteReservation(reservation.id).then(() => {
      const reservationsState = store.getState().reservations;
      if (!reservationsState.loading && !reservationsState.error) {
        // console.log('success', reservationsState);
        props.history.push({ pathname: '/reservations' });
        setSnackbar({ show: true, autoHideDuration: SNACKBAR_TIME.SUCCESS, severity: 'success', message: t('global.success.saved') });
      } else {
        handleEndpointErrors(reservationsState, props, setSnackbar, t);
      }
    });
  };

  const onUpdateReservationModalOpen = () => {
    setUpdateReservationModalOpen(true);
  };

  const onUpdateReservationModalClose = () => {
    setUpdateReservationModalOpen(false);
  };

  const onDeleteReservationModalOpen = () => {
    setDeleteReservationModalOpen(true);
  };

  const onDeleteReservationModalClose = () => {
    setDeleteReservationModalOpen(false);
  };

  React.useEffect(() => {
    onGetReservation();

    return () => {
      storeGetReservation({});
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const t = useTranslation();

  const formatChangeRouteLabel = option => (
    `${ option.routeName } | ${ t('reservation.changeRoute.routeId') }: ${ option.routeId }${ option.tripId ? ` | ${ t('reservation.changeRoute.tripId') }: ${ option.tripId }` : '' }${ option.reservationsCount && option.capacity ? ` | ${ t('reservation.changeRoute.reservations') }: ${ option.reservationsCount } / ${ option.capacity }` : '' }`
  );

  const onUpdateReservation = (reservationId, data, setSubmitting) => {
    updateReservation({ reservationId, data }).then(() => {
      const reservationsState = store.getState().trips;
      if (!reservationsState.loading && !reservationsState.error) {
        // console.log('success', reservationsState);
        onUpdateReservationModalClose();
        setSnackbar({ show: true, autoHideDuration: SNACKBAR_TIME.SUCCESS, severity: 'success', message: t('global.success.saved') });
      } else {
        handleEndpointErrors(reservationsState, props, setSnackbar, t);
      }
      !!setSubmitting && setSubmitting(false);
    });
  };

  storeMenuTitle(t('reservation.pageTitle'));

  const formRef = React.useRef();

  return (
    <div className={ classes.root }>
      <Breadcrumbs links={[
        { name: t('home.pageTitle'), path: '/', icon: <HomeIcon /> },
        { name: t('reservations.pageTitle'), path: '/reservations', icon: <LocalActivityIcon /> },
        { name: t('reservation.pageTitle'), icon: <EditIcon /> },
      ]} />

      <Typography className={ classes.pageTitle } variant="h5">{ t('reservation.pageTitle') }</Typography>
      { !reservation.id ? <LoadingCard length={ 1 } height={ 196 } /> : (
        <React.Fragment>
          <Formik
            innerRef={ formRef }
            initialValues={{
              ...reservation,
              changeRoute: { routeName: reservation.route.name, routeId: reservation.route.id, tripId: reservation.tripId },
              date: formatDateYear(reservation.date, t),
              schedule: {
                departureTime: formatTime(reservation.schedule.departureTime),
                arrivalTime: formatTime(reservation.schedule.arrivalTime),
              },
              tripId: reservation.tripId ? reservation.tripId : t('reservation.noTripAssigned')
            }}
            onSubmit={(values, { setSubmitting }) => {
              const routeId = values.changeRoute.routeId;
              const alternativeRoute = reservationRouteAlternatives.find(route => route.routeId === routeId);
              const data = {
                status: values.status,
                routeId,
                routeScheduleId: alternativeRoute?.routeScheduleId ?? reservation.schedule.id,
                tripId: alternativeRoute?.tripId ?? null
              };
              if ( reservation.route.id === data.routeId ) {
                onUpdateReservation(reservation.id, data, setSubmitting);
              } else {
                setSubmitting(false);
                setUpdatedReservation({ ...alternativeRoute, data });
                onUpdateReservationModalOpen();
              }
            }}
          >
            {({ submitForm, setFieldValue, isSubmitting }) => (
              <CustomFormikForm>
                <Grid container spacing={ 3 } alignItems="flex-end">
                <Grid item xs={ 6 } md={ 3 }>
                    <Field
                      component={ TextField }
                      name="id"
                      type="text"
                      label={ t('reservations.id') }
                      variant="outlined"
                      fullWidth
                      disabled
                    />
                  </Grid>
                  <Grid item xs={ 6 } md={ 3 }>
                    <Field
                      component={ TextField }
                      name="passenger.name"
                      type="text"
                      label={ t('reservations.passenger') }
                      variant="outlined"
                      fullWidth
                      disabled
                    />
                  </Grid>
                  <Grid item xs={ 6 } md={ 3 }>
                    <Field
                      component={ TextField }
                      name="date"
                      type="text"
                      label={ t('reservations.date') }
                      variant="outlined"
                      fullWidth
                      disabled
                    />
                  </Grid>
                  <Grid item xs={ 6 } md={ 3 }>
                    <Field
                      component={ TextField }
                      name="ticket"
                      type="text"
                      label={ t('reservations.ticket') }
                      variant="outlined"
                      fullWidth
                      disabled
                    />
                  </Grid>
                  <Grid item xs={ 6 } md={ 3 }>
                    <Field
                      component={ FormikAutocomplete }
                      name="status"
                      disableClearable
                      options={ JSON.parse(localStorage.getItem('enums')).reservationStatus }
                      getOptionLabel={ option => option ? titleCase(t(`global.status.${ option.toLowerCase() }`)) : '' }
                      textFieldProps={{ label: t('reservations.status') , variant: 'outlined', readOnly: true, fullWidth: true }}
                    />
                  </Grid>
                  <Grid item xs={ 6 } md={ 3 }>
                    <Field
                      component={ TextField }
                      name="schedule.departureTime"
                      type="text"
                      label={ t('reservations.departureTime') }
                      variant="outlined"
                      fullWidth
                      disabled
                    />
                  </Grid>
                  <Grid item xs={ 6 } md={ 3 }>
                    <Field
                      component={ TextField }
                      name="schedule.arrivalTime"
                      type="text"
                      label={ t('reservations.arrivalTime') }
                      variant="outlined"
                      fullWidth
                      disabled
                    />
                  </Grid>
                  <Grid item xs={ 6 } md={ 3 }>
                    <Field
                      component={ TextField }
                      name="tripId"
                      type="text"
                      label={ t('reservations.tripId') }
                      variant="outlined"
                      fullWidth
                      disabled
                    />
                  </Grid>
                  <Grid item xs={ 12 }>
                    <Typography variant="body1"><strong>{ t('reservations.place') }</strong>: <Link target="_blank" rel="noopener" href={`https://www.google.com/maps/search/?api=1&query=${ reservation.place.latitude },${ reservation.place.longitude }`}>{ reservation.place.description }</Link></Typography>
                  </Grid>
                  <Grid item xs={ 12 }>
                    <Field
                      component={ FormikAutocomplete }
                      name="changeRoute"
                      disableClearable
                      options={ [{ routeName: reservation.route.name, routeId: reservation.route.id, tripId: reservation.tripId }, ...reservationRouteAlternatives] }
                      getOptionLabel={ option => option ? formatChangeRouteLabel(option) : '' }
                      textFieldProps={{ label: t('reservation.changeRoute.label') , variant: 'outlined', readOnly: true, fullWidth: true }}
                      onChange={() => {
                        setTimeout(() => {
                          setFieldValue('tripId', formRef.current.values.changeRoute?.tripId ?? '', false);
                        });
                      }}
                      disabled={ reservationLoading || !reservationRouteAlternatives.length }
                    />
                    <Typography className={ classes.changeRouteMsg } component="div" variant="caption" color="textSecondary">
                      <ul className={ classes.changeRouteMsg }>
                        { JSON.parse( t('reservation.changeRoute.message') ).map(condition => <li key={ condition }>- { condition }</li>) }
                      </ul>
                    </Typography>
                  </Grid>
                  <Grid item xs={ 12 }>
                    <Button disabled={ isSubmitting } onClick={ submitForm } color="primary">{ t('global.modal.btnUpdate') }</Button>
                    <Button disabled={ isSubmitting } onClick={ onDeleteReservationModalOpen } className={classes.deleteBtn}>{ t('global.modal.btnDelete') }</Button>
                  </Grid>
                </Grid>
              </CustomFormikForm>
            )}
          </Formik>
          <CustomDialog open={ updateReservationModalOpen } onClose={ onUpdateReservationModalClose } fullWidth={ true } maxWidth="md">
            <UpdateReservationModal
              reservation={ reservation }
              updatedReservation={ updatedReservation }
              onUpdateReservation={ onUpdateReservation }
              onUpdateReservationModalClose={ onUpdateReservationModalClose }
            />
          </CustomDialog>
          <CustomDialog open={ deleteReservationModalOpen } onClose={ onDeleteReservationModalClose } fullWidth={ true } maxWidth="sm">
            <DeleteReservationModal
              reservation={ reservation }
              onDeleteReservation={ onDeleteReservation }
              onDeleteReservationModalClose={ onDeleteReservationModalClose }
            />
          </CustomDialog>
        </React.Fragment>
      ) }
    </div>
  );
}

export default withRouter(EditReservation);
