import React from 'react';

import PropTypes from 'prop-types';

import { useStoreActions, useStore } from 'easy-peasy';

// import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import { handleEndpointErrors, SNACKBAR_TIME } from '../../shared/utilities';

import { useTranslation } from 'react-multi-lang';

// const useStyles = makeStyles(theme => ({
//   root: {
//   },
// }));

const ReOpenTrip = (props) => {
  const { tripId, tripStatus, onGetTrips, onReOpenTripModalClose } = props;
  // const classes = useStyles();
  const [isLoading, setIsLoading] = React.useState(false);

  const store = useStore();

  const setSnackbar = useStoreActions(actions => actions.global.setSnackbar);

  const deleteTripStatus = useStoreActions(actions => actions.trips.deleteTripStatus);
  const getHeatMapTrips = useStoreActions(actions => actions.trips.getHeatMapTrips);

  const reOpenTripHandler = () => {
    setIsLoading(true);
    deleteTripStatus({ id: tripId, status: tripStatus }).then(() => {
      const tripsState = store.getState().trips;
      if (!tripsState.loading && !tripsState.error) {
        // console.log('success', tripsState);
        setIsLoading(false);
        if ( localStorage.getItem('tripsSidebarTab') === '1' ) {
          getHeatMapTrips();
        } else {
          onGetTrips();
        }
        onReOpenTripModalClose();
        setSnackbar({ show: true, autoHideDuration: SNACKBAR_TIME.SUCCESS, severity: 'success', message: t('global.success.saved') });
      } else {
        setIsLoading(false);
        handleEndpointErrors(tripsState, props, setSnackbar, t);
      }
    });
  };

  const t = useTranslation();

  return (
    <React.Fragment>
      <DialogTitle>{ t('trips.updateCompletedTrip.title') }</DialogTitle>
      <DialogContent>
        <DialogContentText>{ t('trips.updateCompletedTrip.text') }</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={ onReOpenTripModalClose } color="primary">{ t('global.modal.btnCancel') }</Button>
        <Button disabled={ isLoading } onClick={ reOpenTripHandler } color="primary" autoFocus>{ t('global.modal.btnUpdate') }</Button>
      </DialogActions>
    </React.Fragment>
  );
}

ReOpenTrip.propTypes = {
  tripId: PropTypes.number.isRequired,
  tripStatus: PropTypes.string.isRequired,
  onGetTrips: PropTypes.func.isRequired,
  onReOpenTripModalClose: PropTypes.func.isRequired,
};

export default ReOpenTrip;
