import React from 'react';

import { useStoreState, useStoreActions, useStore } from 'easy-peasy';

import { makeStyles } from '@material-ui/core/styles';
import EmojiTransportationIcon from '@material-ui/icons/EmojiTransportation';
import HomeIcon from '@material-ui/icons/Home';
import VisibilityIcon from '@material-ui/icons/Visibility';

import { handleEndpointErrors, formatDateYear, SNACKBAR_TIME } from '../shared/utilities';

import Breadcrumbs from '../components/Breadcrumbs';
import LoadingCard from '../components/LoadingCard';
import Table from '../components/Table';
import CustomDialog from '../components/CustomDialog';
import AddOperationalInterval from '../components/OperationalIntervals/AddOperationalInterval';
import EditOperationalInterval from '../components/OperationalIntervals/EditOperationalInterval';

import { useTranslation } from 'react-multi-lang';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3, 2),
  },
  table: {
    '& .MuiTableCell-head:nth-child(3), & .MuiTableCell-head:nth-child(4)': {
      minWidth: '135px',
    },
    '& .MuiTableCell-body .MuiFormControl-root': {
      width: '100%',
    },
  }
}));

const OperationalIntervals = (props) => {
  const classes = useStyles();
  const [operationalIntervalsTableData, setOperationalIntervalsTableData] = React.useState([]);
  const [addOperationalIntervalModalOpen, setAddOperationalIntervalModalOpen] = React.useState(false);
  const [editOperationalIntervalModalOpen, setEditOperationalIntervalModalOpen] = React.useState(false);
  const [editOperationalInterval, setEditOperationalInterval] = React.useState({});

  const store = useStore();

  const permissions = useStoreState(state => state.global.permissions);
  const storeMenuTitle = useStoreActions(actions => actions.global.storeTitle);
  const setSnackbar = useStoreActions(actions => actions.global.setSnackbar);
  const setClientsDisabled = useStoreActions(actions => actions.global.setClientsDisabled);

  const operationalIntervals = useStoreState(state => state.operationalIntervals.operationalIntervals);
  const getOperationalIntervals = useStoreActions(actions => actions.operationalIntervals.getOperationalIntervals);
  const deleteOperationalInterval = useStoreActions(actions => actions.operationalIntervals.deleteOperationalInterval);
  const operationalIntervalsLoading = useStoreState(state => state.operationalIntervals.loading);

  const paginationPage = useStoreState(state => state.operationalIntervals.page);
  const paginationPageSize = useStoreState(state => state.operationalIntervals.pageSize);
  const [dataLoading, setDataLoading] = React.useState(false);

  const canEdit = permissions?.admin?.operationalIntervals?.edit;
  const canDelete = permissions?.admin?.operationalIntervals?.delete;

  const t = useTranslation();

  storeMenuTitle(t('operationalIntervals.pageTitle'));

  const onGetOperationalIntervals = (payload) => {
    setDataLoading(true);
    getOperationalIntervals(payload).then(() => {
      const operationalIntervalsState = store.getState().operationalIntervals;
      if (!operationalIntervalsState.loading && !operationalIntervalsState.error) {
        setDataLoading(false);
        setOperationalIntervalsTableData(operationalIntervalsState.operationalIntervals.map(operationalInterval => {
          operationalInterval.formatStartDate = formatDateYear(operationalInterval.startDate, t);
          operationalInterval.formatEndDate = formatDateYear(operationalInterval.endDate, t);
          return { ...operationalInterval };
        }));
      } else {
        handleEndpointErrors(operationalIntervalsState, props, setSnackbar, t);
      }
    });
  }

  React.useEffect(() => {
    onGetOperationalIntervals({ loading: true });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    setClientsDisabled(true);

    return () => setClientsDisabled(false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onAddOperationalIntervalModalClose = () => {
    setAddOperationalIntervalModalOpen(false);
  };

  const onEditOperationalIntervalModalClose = () => {
    setEditOperationalIntervalModalOpen(false);
  };

  const onDeleteOperationalInterval = (oldData, resolve, reject) => {
    deleteOperationalInterval(oldData.id).then(() => {
      const operationalIntervalsState = store.getState().operationalIntervals;
      if (!operationalIntervalsState.error) {
        // console.log('success', operationalIntervalsState);
        resolve();
        const data = [...operationalIntervalsTableData].filter(operationalInterval => operationalInterval.id !== oldData.id);
        setOperationalIntervalsTableData(data);
        setSnackbar({ show: true, autoHideDuration: SNACKBAR_TIME.SUCCESS, severity: 'success', message: t('global.success.saved') });
      } else {
        reject();
        handleEndpointErrors(operationalIntervalsState, props, setSnackbar, t);
      }
    });
  };

  const operationalIntervalsTableColumns = [
    { title: t('operationalIntervals.id'), field: 'id', type: 'numeric', align: 'left', defaultSort: 'asc', editable: 'never' },
    { title: t('operationalIntervals.name'), field: 'name', editable: 'never' },
    { title: t('operationalIntervals.startDate'), field: 'formatStartDate', sorting: false, editable: 'never' },
    { title: t('operationalIntervals.endDate'), field: 'formatEndDate', sorting: false, editable: 'never' },
    // { title: t('operationalIntervals.monday'), field: 'monday', type: 'boolean', sorting: false, editable: 'never' },
    // { title: t('operationalIntervals.tuesday'), field: 'tuesday', type: 'boolean', sorting: false, editable: 'never' },
    // { title: t('operationalIntervals.wednesday'), field: 'wednesday', type: 'boolean', sorting: false, editable: 'never' },
    // { title: t('operationalIntervals.thursday'), field: 'thursday', type: 'boolean', sorting: false, editable: 'never' },
    // { title: t('operationalIntervals.friday'), field: 'friday', type: 'boolean', sorting: false, editable: 'never' },
    // { title: t('operationalIntervals.saturday'), field: 'saturday', type: 'boolean', sorting: false, editable: 'never' },
    // { title: t('operationalIntervals.sunday'), field: 'sunday', type: 'boolean', sorting: false, editable: 'never' },
  ];

  return (
    <div className={ classes.root }>
      <Breadcrumbs links={[
        { name: t('home.pageTitle'), path: '/', icon: <HomeIcon /> },
        { name: t('operationalIntervals.pageTitle'), icon: <EmojiTransportationIcon /> },
      ]} />
      { operationalIntervalsLoading ? <LoadingCard length={ 1 } height={ 430 } /> : (
        <Table
          className={ classes.table }
          title=""
          columns={ operationalIntervalsTableColumns }
          data={ operationalIntervalsTableData }
          filtering
          exportButton
          exportFileName={ t('operationalIntervals.pageTitle') }
          deleteEvent={ canDelete ? onDeleteOperationalInterval : null }
          customPagination={{
            page: paginationPage,
            size: paginationPageSize,
            responseLength: operationalIntervals.length,
            loading: dataLoading,
            clickEvent: page => {
              onGetOperationalIntervals({ page });
            }
          }}
          addActions={[
            ...canEdit ? [{
              icon: 'add',
              iconProps: {
                color: 'primary'
              },
              tooltip: t('operationalIntervals.table.actionAdd'),
              isFreeAction: true,
              onClick: () => {
                setAddOperationalIntervalModalOpen(true);
              }
            }] : [],
            {
              icon: canEdit ? 'edit' : () => <VisibilityIcon />,
              tooltip: canEdit ? t('operationalIntervals.table.actionEdit') : t('operationalIntervals.table.actionView'),
              onClick: (_, rowData) => {
                setEditOperationalInterval(rowData);
                setEditOperationalIntervalModalOpen(true);
              }
            }
          ]}
        />
      ) }

      <CustomDialog open={ addOperationalIntervalModalOpen } onClose={ onAddOperationalIntervalModalClose } fullWidth={ true } maxWidth="md">
        <AddOperationalInterval
          onGetOperationalIntervals={ onGetOperationalIntervals }
          onAddOperationalIntervalModalClose={ onAddOperationalIntervalModalClose }
        />
      </CustomDialog>

      <CustomDialog open={ editOperationalIntervalModalOpen } onClose={ onEditOperationalIntervalModalClose } fullWidth={ true } maxWidth="md">
        <EditOperationalInterval
          editOperationalInterval={ editOperationalInterval }
          onGetOperationalIntervals={ onGetOperationalIntervals }
          onEditOperationalIntervalModalClose={ onEditOperationalIntervalModalClose }
        />
      </CustomDialog>
    </div>
  );
}

export default OperationalIntervals;
