import React from 'react';

import PropTypes from 'prop-types';

import { useStoreActions, useStore } from 'easy-peasy';

// import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';

import * as Yup from 'yup';

import { Formik, Field } from 'formik';
import { TextField } from 'formik-material-ui';

import { handleEndpointErrors, TIME_REGEX, SNACKBAR_TIME } from '../../shared/utilities';

import CustomFormikForm from '../CustomFormikForm';

import { useTranslation } from 'react-multi-lang';

// const useStyles = makeStyles(theme => ({
//   root: {},
// }));

const AddSchedule = (props) => {
  const { routeId, onGetRouteSchedules, onAddScheduleModalClose } = props;
  // const classes = useStyles();

  const store = useStore();

  const setSnackbar = useStoreActions(actions => actions.global.setSnackbar);

  const addRouteSchedule = useStoreActions(actions => actions.routes.addRouteSchedule);

  const t = useTranslation();

  return (
    <React.Fragment>
      <DialogTitle>{ t('route.schedules.add.title') }</DialogTitle>
      <Formik
        initialValues={{ departureTime: '00:00', arrivalTime: '00:00' }}
        validationSchema={ Yup.object({
          departureTime: Yup.string()
            .matches(TIME_REGEX, t('global.errors.time', { field: t('route.schedules.departureTime') }))
            .required(t('global.errors.required', { field: t('route.schedules.departureTime') })),
          arrivalTime: Yup.string()
            .matches(TIME_REGEX, t('global.errors.time', { field: t('route.schedules.arrivalTime') }))
            .required(t('global.errors.required', { field: t('route.schedules.arrivalTime') })),
        }) }
        onSubmit={(values, { setSubmitting }) => {
          const departureTime = values.departureTime;
          const arrivalTime = values.arrivalTime;
          const data = {
            routeId,
            departureTime,
            arrivalTime
          };

          if ( arrivalTime >= departureTime ) {
            addRouteSchedule(data).then(() => {
              const routesState = store.getState().routes;
              setSubmitting(false);
              if (!routesState.loading && !routesState.error) {
                onGetRouteSchedules(routeId);
                onAddScheduleModalClose();
                setSnackbar({ show: true, autoHideDuration: SNACKBAR_TIME.SUCCESS, severity: 'success', message: t('global.success.saved') });
              } else {
                handleEndpointErrors(routesState, props, setSnackbar, t);
              }
            });
          } else {
            setSubmitting(false);
            setSnackbar({ show: true, autoHideDuration: SNACKBAR_TIME.ERROR, severity: 'error', message: t('route.schedules.error') });
          }
        }}
      >
        {({ submitForm, isSubmitting }) => (
          <CustomFormikForm>
            <DialogContent>
              <Grid container spacing={ 3 }>
                <Grid item xs={ 6 } md={ 4 }>
                  <Field
                    component={ TextField }
                    name="departureTime"
                    type="text"
                    label={`${ t('route.schedules.departureTime') } (24h)`}
                    variant="outlined"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={ 6 } md={ 4 }>
                  <Field
                    component={ TextField }
                    name="arrivalTime"
                    type="text"
                    label={`${ t('route.schedules.arrivalTime') } (24h)`}
                    variant="outlined"
                    fullWidth
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={ onAddScheduleModalClose } color="primary">{ t('global.modal.btnClose') }</Button>
              <Button disabled={ isSubmitting } onClick={ submitForm } color="primary">{ t('global.modal.btnAdd') }</Button>
            </DialogActions>
          </CustomFormikForm>
        )}
      </Formik>
    </React.Fragment>
  );
}

AddSchedule.propTypes = {
  routeId: PropTypes.number.isRequired,
  onGetRouteSchedules: PropTypes.func.isRequired,
  onAddScheduleModalClose: PropTypes.func.isRequired,
};

export default AddSchedule;
