import React from 'react';

import PropTypes from 'prop-types';

import { useStoreActions, useStore } from 'easy-peasy';

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

import * as Yup from 'yup';

import { Formik, Field } from 'formik';
import { TextField } from 'formik-material-ui';

import { handleEndpointErrors, PASSWORD_REGEX, SNACKBAR_TIME } from '../../shared/utilities';

import CustomFormikForm from '../CustomFormikForm';

import { useTranslation } from 'react-multi-lang';

const useStyles = makeStyles(theme => ({
  root: {},
}));

const ChangePassword = (props) => {
  const { userId } = props;
  const classes = useStyles();

  const store = useStore();

  const setSnackbar = useStoreActions(actions => actions.global.setSnackbar);

  const changePassword = useStoreActions(actions => actions.users.changePassword);

  const t = useTranslation();

  return (
    <Formik
      // initialValues={{ currentPassword: '', newPassword: '', confirmNewPassword: '' }}
      initialValues={{ newPassword: '', confirmNewPassword: '' }}
      validationSchema={ Yup.object({
        // currentPassword: Yup.string()
        //   .required(t('global.errors.required', { field: t('user.changePassword.currentPassword') })),
        newPassword: Yup.string()
          .matches(PASSWORD_REGEX, t('global.errors.password', { field: t('user.changePassword.newPassword') }))
          .required(t('global.errors.required', { field: t('user.changePassword.newPassword') })),
        confirmNewPassword: Yup.string()
          .oneOf([Yup.ref('newPassword'), null], t('user.changePassword.confirmNewPassword.error'))
          .required(t('global.errors.required', { field: t('user.changePassword.confirmNewPassword.label') })),
      }) }
      onSubmit={(values, { setSubmitting }) => {
        const data = {
          userId,
          // oldPassword: values.currentPassword,
          newPassword: values.newPassword,
        };
        changePassword(data).then(() => {
          const usersState = store.getState().users;
          if (!usersState.loading && !usersState.error) {
            setSubmitting(false);
            setSnackbar({ show: true, autoHideDuration: SNACKBAR_TIME.SUCCESS, severity: 'success', message: t('global.success.saved') });
          } else {
            handleEndpointErrors(usersState, props, setSnackbar, t);
          }
        });
      }}
    >
      {({ submitForm, isSubmitting }) => (
        <CustomFormikForm className={ classes.form }>
          <Grid container spacing={ 3 }>
            {/* <Grid item xs={ 12 } md={ 6 }>
              <Field
                component={ TextField }
                name="currentPassword"
                type="password"
                label={ t('user.changePassword.currentPassword') }
                variant="outlined"
                fullWidth
              />
            </Grid> */}
            <Grid item xs={ 12 } md={ 6 }>
              <Field
                component={ TextField }
                name="newPassword"
                type="password"
                label={ t('user.changePassword.newPassword') }
                variant="outlined"
                fullWidth
              />
            </Grid>
            <Grid item xs={ 12 } md={ 6 }>
              <Field
                component={ TextField }
                name="confirmNewPassword"
                type="password"
                label={ t('user.changePassword.confirmNewPassword.label') }
                variant="outlined"
                fullWidth
              />
            </Grid>
            <Grid item xs={ 12 } md={ 8 }>
              <ul>
                { JSON.parse( t('global.errors.passwordConditions') ).map(condition => <li key={ condition }>- { condition }</li>) }
              </ul>
            </Grid>
            <Grid item xs={ 12 }>
              <Button disabled={ isSubmitting } onClick={ submitForm } color="primary">{ t('global.modal.btnUpdate') }</Button>
            </Grid>
          </Grid>
        </CustomFormikForm>
      )}
    </Formik>
  );
}

ChangePassword.propTypes = {
  userId: PropTypes.number.isRequired,
};

export default ChangePassword;
