import React from 'react';

import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import FormControl from '@material-ui/core/FormControl';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

import { useTranslation } from 'react-multi-lang';

const useStyles = makeStyles(theme => ({
  root: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'flex-end',
    '& .MuiInput-input': {
      fontSize: '14px',
    },
    '& .MuiTypography-body2': {
      color: theme.palette.type === 'dark' ? 'rgba(255, 255, 255, 0.7)' : 'rgba(0, 0, 0, 0.54)',
      fontSize: '0.75rem',
      letterSpacing: '0.03333em',
      lineHeight: 1.66,
    },
  },
}));

const TablePagination = (props) => {
  const { page, size, responseLength, loading, hasRowsSelection, clickEvent } = props;
  const classes = useStyles();

  const from = page === 1 ? page : (page - 1) * size + 1;
  const to = page === 1 ? responseLength : (page - 1) * size + responseLength;

  const t = useTranslation();

  const handlePageSizeChange = pageSize => {
    clickEvent(1, pageSize);
  };

  return (
    <th className={ classes.root }>
      { hasRowsSelection && <FormControl>
        <Select
          value={size}
          onChange={event => handlePageSizeChange(event.target.value)}
          disableUnderline
        >
          <MenuItem value={25}>25 { t('global.table.pagination.labelRowsSelect') }</MenuItem>
          <MenuItem value={70}>70 { t('global.table.pagination.labelRowsSelect') }</MenuItem>
          <MenuItem value={100}>100 { t('global.table.pagination.labelRowsSelect') }</MenuItem>
        </Select>
      </FormControl> }
      <Tooltip title={ t('global.table.pagination.firstTooltip') }><span><IconButton disabled={ loading || page === 1 } onClick={ () => clickEvent(1) }><FirstPageIcon /></IconButton></span></Tooltip>
      <Tooltip title={ t('global.table.pagination.previousTooltip') }><span><IconButton disabled={ loading || page === 1 } onClick={ () => clickEvent(page - 1) }><ChevronLeftIcon /></IconButton></span></Tooltip>
      <Typography variant="body2" component="span">{`${ from }-${ to }`}</Typography>
      <Tooltip title={ t('global.table.pagination.nextTooltip') }><span><IconButton disabled={ loading || responseLength < size } onClick={ () => clickEvent(page + 1) }><ChevronRightIcon /></IconButton></span></Tooltip>
    </th>
  );
}

TablePagination.propTypes = {
  page: PropTypes.number.isRequired,
  size: PropTypes.number.isRequired,
  responseLength: PropTypes.number.isRequired,
  loading: PropTypes.bool.isRequired,
  hasRowsSelection: PropTypes.bool,
  clickEvent: PropTypes.func.isRequired,
};

export default TablePagination;
