import React from 'react';

import PropTypes from 'prop-types';

import { useStoreState, useStoreActions, useStore } from 'easy-peasy';

import { makeStyles } from '@material-ui/core/styles';

import { handleEndpointErrors, TIME_REGEX, toHoursAndMinutes, SNACKBAR_TIME } from '../../shared/utilities';

import Table from '../Table';
import CustomDialog from '../CustomDialog';
import AddSchedule from './AddSchedule';

import { useTranslation } from 'react-multi-lang';

const useStyles = makeStyles(theme => ({
  table: {
  }
}));

const Schedules = (props) => {
  const { route } = props;
  const classes = useStyles();
  const [schedulesTableData, setSchedulesTableData] = React.useState([]);
  const [addScheduleModalOpen, setAddScheduleModalOpen] = React.useState(false);

  const store = useStore();

  const permissions = useStoreState(state => state.global.permissions);
  const setSnackbar = useStoreActions(actions => actions.global.setSnackbar);
  
  const routeSchedules = useStoreState(state => state.routes.routeSchedules);
  const getRouteSchedules = useStoreActions(actions => actions.routes.getRouteSchedules);
  const updateRouteSchedule = useStoreActions(actions => actions.routes.updateRouteSchedule);
  const deleteRouteSchedule = useStoreActions(actions => actions.routes.deleteRouteSchedule);

  const canEdit = permissions?.admin?.routes?.edit;

  const t = useTranslation();

  const schedulesTableColumns = [
    { title: t('route.schedules.id'), field: 'routeScheduleId', type: 'numeric', align: 'left', editable: 'never' },
    // { title: t('route.schedules.routeId'), field: 'routeId', sorting: false, editable: 'never' },
    {
      title: `${ t('route.schedules.departureTime') } (24h)`, field: 'departureTime', defaultSort: 'asc',
      validate: rowData => rowData.departureTime.match(TIME_REGEX) ? true : { isValid: false, helperText: t('global.errors.time', { field: t('route.schedules.departureTime') }) },
    },
    {
      title: `${ t('route.schedules.arrivalTime') } (24h)`, field: 'arrivalTime', sorting: false,
      validate: rowData => rowData.arrivalTime.match(TIME_REGEX) ? true : { isValid: false, helperText: t('global.errors.time', { field: t('route.schedules.arrivalTime') }) },
    },
  ];

  const onGetRouteSchedules = (payload) => {
    getRouteSchedules(payload).then(() => {
      const routesState = store.getState().routes;
      if (!routesState.routeSchedulesLoading && !routesState.routeSchedulesError) {
        // console.log(routesState);
      } else {
        handleEndpointErrors({ ...routesState, loading: routesState.routeSchedulesLoading, error: routesState.routeSchedulesError }, props, setSnackbar, t);
      }
    });
  }

  const onUpdateRouteSchedule = (newData, oldData, resolve, reject) => {
    const departureTime = newData.departureTime;
    const arrivalTime = newData.arrivalTime;
    const validData = arrivalTime.match(TIME_REGEX) && departureTime.match(TIME_REGEX);
    if (oldData.arrivalTime === arrivalTime && oldData.departureTime === departureTime) {
      resolve();
    } else if (validData) {
      if ( arrivalTime >= departureTime ) {
        updateRouteSchedule({ scheduleId: newData.routeScheduleId, data: { arrivalTime, departureTime } }).then(() => {
          const routesState = store.getState().routes;
          if (!routesState.loading && !routesState.error) {
            // console.log('success', routesState);
            resolve();
            const data = [...schedulesTableData];
            const schedule = data.find(schedule => schedule.routeScheduleId === newData.routeScheduleId);
            schedule.arrivalTime = arrivalTime;
            schedule.departureTime = departureTime;
            setSnackbar({ show: true, autoHideDuration: SNACKBAR_TIME.SUCCESS, severity: 'success', message: t('global.success.saved') });
          } else {
            reject();
            handleEndpointErrors(routesState, props, setSnackbar, t);
          }
        });
      } else {
        reject();
        setSnackbar({ show: true, autoHideDuration: SNACKBAR_TIME.ERROR, severity: 'error', message: t('route.schedules.error') });
      }
    } else {
      reject();
    }
  };

  const onDeleteRouteSchedule = (oldData, resolve, reject) => {
    deleteRouteSchedule(oldData.routeScheduleId).then(() => {
      const routesState = store.getState().routes;
      if (!routesState.error) {
        // console.log('success', routesState);
        resolve();
        const data = [...schedulesTableData].filter(schedule => schedule.routeScheduleId !== oldData.routeScheduleId);
        setSchedulesTableData(data);
        setSnackbar({ show: true, autoHideDuration: SNACKBAR_TIME.SUCCESS, severity: 'success', message: t('global.success.saved') });
      } else {
        reject();
        handleEndpointErrors(routesState, props, setSnackbar, t);
      }
    });
  };

  const onAddScheduleModalClose = () => {
    setAddScheduleModalOpen(false);
  };

  React.useEffect(() => {
    if (route.id) {
      onGetRouteSchedules(route.id);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [route]);

  React.useEffect(() => {
    setSchedulesTableData(routeSchedules.map(schedule => ({
      ...schedule,
      arrivalTime: toHoursAndMinutes(schedule.arrivalTime),
      departureTime: toHoursAndMinutes(schedule.departureTime)
    })));
  }, [routeSchedules]);

  return (
    <React.Fragment>
      <Table
        className={ classes.table }
        title=""
        columns={ schedulesTableColumns }
        data={ schedulesTableData }
        paging={ false }
        updateEvent={ canEdit ? onUpdateRouteSchedule : null }
        deleteEvent={ canEdit ? onDeleteRouteSchedule : null }
        addActions={canEdit ? [{
          icon: 'add',
          iconProps: {
            color: 'primary'
          },
          tooltip: t('route.schedules.add.action'),
          isFreeAction: true,
          onClick: (_, rowData) => {
            setAddScheduleModalOpen(true);
          }
        }] : []}
      />

      <CustomDialog open={ addScheduleModalOpen } onClose={ onAddScheduleModalClose } fullWidth={ true } maxWidth="md">
        <AddSchedule
          routeId={ route.id }
          onGetRouteSchedules={ onGetRouteSchedules }
          onAddScheduleModalClose={ onAddScheduleModalClose }
        />
      </CustomDialog>
    </React.Fragment>
  );
}

Schedules.propTypes = {
  route: PropTypes.object.isRequired
};

export default Schedules;
