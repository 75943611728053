import React from 'react';

import PropTypes from 'prop-types';

import clsx from 'clsx';

import { useStoreActions } from 'easy-peasy';

import { makeStyles } from '@material-ui/core/styles';
import InputBase from '@material-ui/core/InputBase';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';

import { debounce } from '../../../../shared/utilities';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(1),
    backgroundColor: theme.palette.type === 'dark' ? theme.palette.background.paper : theme.palette.grey[200],
    borderRadius: theme.spacing(4),
    '& .MuiInputBase-root': {
      width: '100%',
    },
  },
}));

const Search = (props) => {
  const { className, placeholder, onGetTrips } = props;
  const classes = useStyles();

  const setSearch = useStoreActions(actions => actions.trips.setSearch);

  const searchTrips = search => {
    setSearch(search);
    onGetTrips();
  };

  const onSearchEvent = debounce(inputValue => {
    if (inputValue.length) {
      searchTrips(inputValue);
    } else {
      searchTrips('');
    }
  }, 500);

  React.useEffect(() => {
    return () => {
      setSearch('');
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={ clsx(classes.root, {
      [className]: className,
    }) }>
      <InputBase
        placeholder={ placeholder }
        startAdornment={
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        }
        onChange={ event => onSearchEvent(event.target.value) }
      />
    </div>
  );
}

Search.propTypes = {
  className: PropTypes.string,
  placeholder: PropTypes.string.isRequired,
  onGetTrips: PropTypes.func.isRequired,
};

export default Search;
