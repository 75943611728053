import { action, thunk } from 'easy-peasy';

import getVehicles from '../../services/vehicles/get';
import { addVehicle, addVehicleDriver } from '../../services/vehicles/post';
import updateVehicle from '../../services/vehicles/put';
import { deleteVehicle, deleteVehicleDriver } from '../../services/vehicles/delete';

const vehiclesModel = {
  vehicles: [],
  loading: false,
  error: '',
  page: 1,
  pageSize: localStorage.getItem('vehiclesPageSize') ? localStorage.getItem('vehiclesPageSize') : 25,
  textSearch: '',
  hasMoreVehicles: false,
  getVehicles: thunk(async (actions, payload = {}, { getState }) => {
    actions.setLoading(payload.loading);
    actions.setError('');
    return await getVehicles(actions, payload, getState());
  }),
  addVehicle: thunk(async (actions, payload) => {
    actions.setError('');
    return await addVehicle(actions, payload);
  }),
  updateVehicle: thunk(async (actions, payload) => {
    actions.setError('');
    return await updateVehicle(actions, payload);
  }),
  deleteVehicle: thunk(async (actions, payload) => {
    actions.setError('');
    return await deleteVehicle(actions, payload);
  }),
  addVehicleDriver: thunk(async (actions, payload) => {
    actions.setError('');
    return await addVehicleDriver(actions, payload);
  }),
  deleteVehicleDriver: thunk(async (actions, payload) => {
    actions.setError('');
    return await deleteVehicleDriver(actions, payload);
  }),
  storeGetVehicles: action((state, payload) => {
    state.vehicles = payload.data;
    state.page = payload.page ? payload.page : 1;
    state.textSearch = payload.textSearch ? payload.textSearch : '';
  }),
  setLoading: action((state, payload) => {
    state.loading = payload;
  }),
  setError: action((state, payload) => {
    state.error = payload;
  }),
  setTextSearch: action((state, payload) => {
    state.textSearch = payload;
  }),
}

export default vehiclesModel;
