import React from 'react';

import { useStoreState, useStoreActions, useStore } from 'easy-peasy';

import { makeStyles } from '@material-ui/core/styles';
import HomeIcon from '@material-ui/icons/Home';
import Link from '@material-ui/core/Link';
import PlaceIcon from '@material-ui/icons/Place';

import { handleEndpointErrors, SNACKBAR_TIME } from '../shared/utilities';

import Breadcrumbs from '../components/Breadcrumbs';
import LoadingCard from '../components/LoadingCard';
import Table from '../components/Table';
import CustomDialog from '../components/CustomDialog';
import AddPlace from '../components/Places/AddPlace';
import EditPlace from '../components/Places/EditPlace';
import DataFilters from '../components/DataFilters';

import { useTranslation } from 'react-multi-lang';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3, 2),
  },
  table: {
    '& .MuiTableCell-head:nth-child(2)': {
      minWidth: '350px',
    },
  },
}));

const Places = (props) => {
  const classes = useStyles();
  const [placesTableData, setPlacesTableData] = React.useState([]);
  const [addPlaceModalOpen, setAddPlaceModalOpen] = React.useState(false);
  const [editPlaceModalOpen, setEditPlaceModalOpen] = React.useState(false);
  const [editPlace, setEditPlace] = React.useState({});
  const [activeFilters, setActiveFilters] = React.useState({});

  const store = useStore();

  const storeMenuTitle = useStoreActions(actions => actions.global.storeTitle);
  const setSnackbar = useStoreActions(actions => actions.global.setSnackbar);

  const adminPlaces = useStoreState(actions => actions.places.adminPlaces);
  const getAdminPlaces = useStoreActions(actions => actions.places.getAdminPlaces);
  const deleteAdminPlace = useStoreActions(actions => actions.places.deleteAdminPlace);
  const placesLoading = useStoreState(state => state.places.loading);
  const placesTextSearch = useStoreState(state => state.places.textSearch);
  const setPlacesTextSearch = useStoreActions(actions => actions.places.setTextSearch);

  const getProvinces = useStoreActions(actions => actions.countries.getProvinces);

  const setClientsDisabled = useStoreActions(actions => actions.global.setClientsDisabled);

  const paginationPage = useStoreState(state => state.places.page);
  const paginationPageSize = useStoreState(state => state.places.pageSize);
  const [dataLoading, setDataLoading] = React.useState(false);

  const t = useTranslation();

  storeMenuTitle(t('places.pageTitle'));

  const onGetPlaces = (payload) => {
    setDataLoading(true);
    getAdminPlaces(payload).then(() => {
      const placesState = store.getState().places;
      if (!placesState.loading && !placesState.error) {
        setDataLoading(false);
        setPlacesTableData(placesState.adminPlaces.map(place => ({ ...place })));
      } else {
        handleEndpointErrors(placesState, props, setSnackbar, t);
      }
    });
  }

  React.useEffect(() => {
    onGetPlaces({ loading: true });

    return () => setPlacesTextSearch('');
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    getProvinces().then(() => {
      const countriesState = store.getState().countries;
      if (!countriesState.loading && !countriesState.error) {
        // console.log(countriesState);
      } else {
        handleEndpointErrors(countriesState, props, setSnackbar, t);
      }
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    setClientsDisabled(true);

    return () => setClientsDisabled(false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onAddPlaceModalClose = () => {
    setAddPlaceModalOpen(false);
  };

  const onEditPlaceModalClose = () => {
    setEditPlaceModalOpen(false);
  };

  const onDeletePlace = (oldData, resolve, reject) => {
    deleteAdminPlace(oldData.id).then(() => {
      const placesState = store.getState().places;
      if (!placesState.error) {
        // console.log('success', placesState);
        resolve();
        const data = [...placesTableData].filter(place => place.id !== oldData.id);
        setPlacesTableData(data);
        setSnackbar({ show: true, autoHideDuration: SNACKBAR_TIME.SUCCESS, severity: 'success', message: t('global.success.saved') });
      } else {
        reject();
        handleEndpointErrors(placesState, props, setSnackbar, t);
      }
    });
  };

  const placesTableColumns = [
    { title: t('places.id'), field: 'id', type: 'numeric', align: 'left', defaultSort: 'asc', editable: 'never' },
    { title: t('places.description'), field: 'description' },
    { title: t('places.province'), field: 'province.name', editable: 'never' },
    { title: t('places.county'), field: 'county.name', editable: 'never' },
    {
      title: t('places.latitude'), field: 'latitude',
      render: rowData => <Link target="_blank" rel="noopener" href={`https://www.google.com/maps/@${ rowData.latitude },${ rowData.longitude },15z`}>{ rowData.latitude }</Link>,
      sorting: false, editable: 'never'
    },
    {
      title: t('places.longitude'), field: 'longitude',
      render: rowData => <Link target="_blank" rel="noopener" href={`https://www.google.com/maps/@${ rowData.latitude },${ rowData.longitude },15z`}>{ rowData.longitude }</Link>,
      sorting: false, editable: 'never'
    },
    { title: t('places.active'), field: 'active', type: 'boolean', sorting: false, editable: 'never' },
    { title: t('places.stop'), field: 'stop', type: 'boolean', sorting: false, editable: 'never' },
  ];

  return (
    <div className={ classes.root }>
      <Breadcrumbs links={[
        { name: t('home.pageTitle'), path: '/', icon: <HomeIcon /> },
        { name: t('places.pageTitle'), icon: <PlaceIcon /> },
      ]} />
      <DataFilters
        setActiveFilters={ setActiveFilters }
        filters={[
          {
            type: 'text',
            name: t('places.searchPlaceholder'),
            property: 'textSearch',
            active: placesTextSearch,
            width: '250px',
            onChangeFn: (filters) => {
              onGetPlaces({ page: 1, ...filters });
            }
          },
          {
            type: 'select',
            name: t('places.active'),
            property: 'active',
            options: [{label: t('dataFilters.yes'), value: 'true'}, {label: t('dataFilters.no'), value: 'false'}],
            active: '',
            onChangeFn: (filters) => {
              onGetPlaces({ page: 1, ...filters });
            }
          },
          {
            type: 'select',
            name: t('places.stop'),
            property: 'stop',
            options: [{label: t('dataFilters.yes'), value: 'true'}, {label: t('dataFilters.no'), value: 'false'}],
            active: '',
            onChangeFn: (filters) => {
              onGetPlaces({ page: 1, ...filters });
            }
          }
        ]}
      />
      { placesLoading ? <LoadingCard length={ 1 } height={ 725 } /> : <React.Fragment>
        <Table
          className={ classes.table }
          title=""
          columns={ placesTableColumns }
          data={ placesTableData }
          exportButton
          exportFileName={ t('places.pageTitle') }
          deleteEvent={ onDeletePlace }
          search={ false }
          customPagination={{
            page: paginationPage,
            size: paginationPageSize,
            responseLength: adminPlaces.length,
            loading: dataLoading,
            clickEvent: page => {
              onGetPlaces({ page, ...activeFilters });
            }
          }}
          addActions={[
            {
              icon: 'add',
              iconProps: {
                color: 'primary'
              },
              tooltip: t('places.table.actionAdd'),
              isFreeAction: true,
              onClick: () => {
                setAddPlaceModalOpen(true);
              }
            },
            {
              icon: 'edit',
              tooltip: t('places.table.actionEdit'),
              onClick: (_, rowData) => {
                setEditPlace(rowData);
                setEditPlaceModalOpen(true);
              }
            }
          ]}
        />
      </React.Fragment> }

      <CustomDialog open={ addPlaceModalOpen } onClose={ onAddPlaceModalClose } fullWidth={ true } maxWidth="md">
        <AddPlace
          onGetPlaces={ onGetPlaces }
          onAddPlaceModalClose={ onAddPlaceModalClose }
        />
      </CustomDialog>

      <CustomDialog open={ editPlaceModalOpen } onClose={ onEditPlaceModalClose } fullWidth={ true } maxWidth="md">
        <EditPlace
          editPlace={ editPlace }
          onGetPlaces={ onGetPlaces }
          onEditPlaceModalClose={ onEditPlaceModalClose }
        />
      </CustomDialog>
    </div>
  );
}

export default Places;
