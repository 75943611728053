import React from 'react';

import PropTypes from 'prop-types';

import { useStoreActions, useStore } from 'easy-peasy';

// import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';

import * as Yup from 'yup';

import { Formik, Field } from 'formik';
import { TextField } from 'formik-material-ui';

import { handleEndpointErrors, SNACKBAR_TIME } from '../../shared/utilities';

import CustomFormikForm from '../CustomFormikForm';

import { useTranslation } from 'react-multi-lang';

// const useStyles = makeStyles(theme => ({
//   root: {},
// }));

const AddBusinessPark = (props) => {
  const { editBusinessPark, onGetBusinessParks, onAddBusinessParkModalClose } = props;
  // const classes = useStyles();

  const store = useStore();

  const setSnackbar = useStoreActions(actions => actions.global.setSnackbar);

  const addBusinessPark = useStoreActions(actions => actions.businessParks.addBusinessPark);
  const updateBusinessPark = useStoreActions(actions => actions.businessParks.updateBusinessPark);

  const t = useTranslation();

  return (
    <React.Fragment>
      <DialogTitle>{ t(`businessParks.table.${ editBusinessPark?.id ? 'actionEdit' : 'actionAdd' }`) }</DialogTitle>
      <Formik
        initialValues={{ ...editBusinessPark, name: editBusinessPark?.name || '', description: editBusinessPark?.description || '' }}
        validationSchema={ Yup.object({
          name: Yup.string()
            .max(50, t('global.errors.max', { field: t('businessParks.name'), length: 50 }))
            .required(t('global.errors.required', { field: t('businessParks.name') })),
          description: Yup.string()
            .max(300, t('global.errors.max', { field: t('businessParks.description'), length: 300 }))
            .required(t('global.errors.required', { field: t('businessParks.description') })),
        }) }
        onSubmit={(values, { setSubmitting }) => {
          const data = {
            name: values.name,
            description: values.description,
          };
          if (values?.id) {
            updateBusinessPark({ businessParkId: values.id, data }).then(() => {
              const businessParksState = store.getState().businessParks;
              if (!businessParksState.loading && !businessParksState.error) {
                setSubmitting(false);
                onGetBusinessParks();
                setSnackbar({ show: true, autoHideDuration: SNACKBAR_TIME.SUCCESS, severity: 'success', message: t('global.success.saved') });
                onAddBusinessParkModalClose();
              } else {
                handleEndpointErrors(businessParksState, props, setSnackbar, t);
              }
            });
          } else {
            addBusinessPark(data).then(() => {
              const businessParksState = store.getState().businessParks;
              if (!businessParksState.loading && !businessParksState.error) {
                setSubmitting(false);
                onGetBusinessParks();
                setSnackbar({ show: true, autoHideDuration: SNACKBAR_TIME.SUCCESS, severity: 'success', message: t('global.success.saved') });
                onAddBusinessParkModalClose();
              } else {
                handleEndpointErrors(businessParksState, props, setSnackbar, t);
              }
            });
          }
        }}
      >
        {({ submitForm, isSubmitting }) => (
          <CustomFormikForm>
            <DialogContent>
              <Grid container spacing={ 3 }>
                <Grid item xs={ 12 } md={ 6 }>
                  <Field
                    component={ TextField }
                    name="name"
                    type="text"
                    label={ t('businessParks.name') }
                    variant="outlined"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={ 12 }>
                  <Field
                    component={ TextField }
                    name="description"
                    type="text"
                    label={ t('businessParks.description') }
                    variant="outlined"
                    fullWidth
                    multiline
                    rows={3}
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={ onAddBusinessParkModalClose } color="primary">{ t('global.modal.btnClose') }</Button>
              <Button disabled={ isSubmitting } onClick={ submitForm } color="primary">{ t(`global.modal.${ editBusinessPark?.id ? 'btnUpdate' : 'btnAdd' }`) }</Button>
            </DialogActions>
          </CustomFormikForm>
        )}
      </Formik>
    </React.Fragment>
  );
}

AddBusinessPark.propTypes = {
  editBusinessPark: PropTypes.object,
  onGetBusinessParks: PropTypes.func.isRequired,
  onAddBusinessParkModalClose: PropTypes.func.isRequired,
};

export default AddBusinessPark;
