import React from 'react';

import { useStoreState, useStoreActions, useStore } from 'easy-peasy';

import { makeStyles } from '@material-ui/core/styles';
import AssessmentIcon from '@material-ui/icons/Assessment';
import CardTravelIcon from '@material-ui/icons/CardTravel';
import HomeIcon from '@material-ui/icons/Home';

import { handleEndpointErrors, getRouteDirections, titleCase, formatDateYear, formatTime } from '../../shared/utilities';

import Breadcrumbs from '../../components/Breadcrumbs';
import LoadingCard from '../../components/LoadingCard';
import DataFilters from '../../components/DataFilters';
import DateFilters from '../../components/DateFilters';
import Table from '../../components/Table';

import { useTranslation } from 'react-multi-lang';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3, 2),
  },
  table: {
    '& .MuiTableCell-body': {
      textTransform: 'capitalize',
    },
    '& .MuiTableCell-head': {
      minWidth: '145px',
    },
  },
}));

const ReportsTrips = (props) => {
  const classes = useStyles();

  const store = useStore();

  const routeDirections = useStoreState(state => state.global.routeDirections);
  const routeTypes = useStoreState(state => state.global.routeTypes);
  const storeMenuTitle = useStoreActions(actions => actions.global.storeTitle);
  const setSnackbar = useStoreActions(actions => actions.global.setSnackbar);

  const tripsReport = useStoreState(actions => actions.trips.tripsReport);
  const getTripsReport = useStoreActions(actions => actions.trips.getTripsReport);
  const tripsLoading = useStoreState(state => state.trips.loading);
  const tripsTextSearch = useStoreState(state => state.trips.tripsReportTextSearch);

  const setTripsReportDates = useStoreActions(actions => actions.trips.setTripsReportDates);

  const paginationPage = useStoreState(state => state.trips.tripsReportPage);
  const paginationPageSize = useStoreState(state => state.trips.tripsReportPageSize);
  const [dataLoading, setDataLoading] = React.useState(false);

  const [tripsTableData, setTripsTableData] = React.useState([]);
  const [datePayload, setDatePayload] = React.useState({ startDate: new Date(), endDate: new Date() });
  const [activeFilters, setActiveFilters] = React.useState({});
  const [firstTimeCall, setFirstTimeCall] = React.useState(true);

  const getTripStatus = (t) => {
    const status = {};
    // eslint-disable-next-line array-callback-return
    JSON.parse(localStorage.getItem('enums')).tripStatus.map(item => {
      status[item] = titleCase(t(`global.status.${ item }`));
    });
    return status;
  };

  const getRouteTypes = () => {
    const types = {};
    // eslint-disable-next-line array-callback-return
    routeTypes.map(type => {
      types[type.name] = t(`routes.type.${ type.name.toLowerCase() }`);
    });
    return types;
  };

  const getTrips = (payload) => {
    setTripsReportDates(payload.startDate ? payload : datePayload);
    setDatePayload(payload.startDate ? payload : datePayload);
    setDataLoading(true);
    getTripsReport({ ...activeFilters, ...payload, loading: firstTimeCall }).then(() => {
      const tripsState = store.getState().trips;

      if ( firstTimeCall ) {
        setFirstTimeCall(false);
      }
      
      if (!tripsState.loading && !tripsState.error) {
        // console.log(tripsState);
        setDataLoading(false);
      } else {
        handleEndpointErrors(tripsState, props, setSnackbar, t);
      }
    });
  };

  const t = useTranslation();

  const tripsTableColumns = localStorage.getItem('tableTripsReport') ?
    JSON.parse(localStorage.getItem('tableTripsReport')) : [
    { title: t('reports.trips.table.id'), field: 'id', type: 'numeric', align: 'left', editable: 'never' },
    { title: t('reports.trips.table.route'), field: 'route.name' },
    { title: t('reports.trips.table.direction'), field: 'route.direction', lookup: getRouteDirections(routeDirections, t) },
    { title: t('reports.trips.table.type'), field: 'route.type', lookup: getRouteTypes(routeTypes, t) },
    { title: t('reports.trips.table.departureDate'), field: 'departureDate', editable: 'never' },
    { title: t('reports.trips.table.driver'), field: 'driver.name' },
    { title: t('reports.trips.table.passengers'), field: 'passengers' },
    { title: t('reports.trips.table.departureTime'), field: 'schedule.departureTime', sorting: false },
    { title: t('reports.trips.table.arrivalTime'), field: 'schedule.arrivalTime', sorting: false },
    { title: t('reports.trips.table.status'), field: 'status', lookup: getTripStatus(t), sorting: false },
    { title: t('reports.trips.table.vehicle'), field: 'vehicle', sorting: false },
  ];

  const tableRef = React.useRef();

  React.useEffect(() => {
    setTripsTableData(tripsReport.map(trip => {
      trip.departureDate = formatDateYear(trip.departureDate, t);
      trip.passengers = trip.passengers?.length ? trip.passengers.filter(p => p.status !== 'cancelled').length : 0;
      trip.schedule.departureTime = formatTime(trip.schedule.departureTime);
      trip.schedule.arrivalTime = formatTime(trip.schedule.arrivalTime);
      trip.vehicle = `${ trip.vehicle.licensePlate } - ${ trip.vehicle.make } ${ trip.vehicle.model }, ${ trip.vehicle.year }`;
      return { ...trip };
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tripsReport]);

  storeMenuTitle(t('reports.pageTitle'));

  return (
    <div className={ classes.root }>
      <Breadcrumbs links={[
        { name: t('home.pageTitle'), path: '/', icon: <HomeIcon /> },
        { name: t('reports.pageTitle'), icon: <AssessmentIcon /> },
        { name: t('trips.pageTitle'), icon: <CardTravelIcon /> },
      ]} />

      <DataFilters
        setActiveFilters={ setActiveFilters }
        filters={[
          {
            type: 'text',
            name: t('reports.trips.searchPlaceholder'),
            property: 'textSearch',
            active: tripsTextSearch,
            width: '250px',
            onChangeFn: (filters) => {
              getTrips({ page: 1, ...filters });
            }
          }
        ]}
      >
        <DateFilters onChangeFn={ getTrips } localStorageName="dateFiltersTripsReport" defaultDateRange="last7Days" />
      </DataFilters>

      { tripsLoading ? <LoadingCard length={ 1 } height={ 1565 } /> : (
        <Table
          tableRef={ tableRef }
          className={ classes.table }
          title=""
          columns={ tripsTableColumns }
          data={ tripsTableData }
          exportButton
          exportFileName={ t('reports.trips.exportFileName', {
            startDate: `${ t(`global.months.${ datePayload.startDate.getMonth() }`).toLowerCase() }-${ datePayload.startDate.getDate() }-${ datePayload.startDate.getFullYear() }`,
            endDate: `${ t(`global.months.${ datePayload.endDate.getMonth() }`).toLowerCase() }-${ datePayload.endDate.getDate() }-${ datePayload.endDate.getFullYear() }`,
          }) }
          updateColumns="TripsReport"
          search={ false }
          customPagination={{
            page: paginationPage,
            size: paginationPageSize,
            responseLength: tripsReport.length,
            loading: dataLoading,
            clickEvent: page => {
              getTrips({ page, ...activeFilters });
            }
          }}
        />
      ) }
    </div>
  );
}

export default ReportsTrips;
