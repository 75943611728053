import React from 'react';

import PropTypes from 'prop-types';

import { useStoreState, useStoreActions, useStore } from 'easy-peasy';

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import DriveEtaIcon from '@material-ui/icons/DriveEta';
import Grid from '@material-ui/core/Grid';

import * as Yup from 'yup';

import { Formik, Field } from 'formik';
import { TextField } from 'formik-material-ui';

import { handleEndpointErrors, SNACKBAR_TIME } from '../../shared/utilities';
import { useDebounce } from '../../shared/hooks/useDebounce';

import LoadingCard from '../LoadingCard';
import CustomFormikForm from '../CustomFormikForm';
import FormikAutocomplete from '../FormikAutocomplete';

import { useTranslation } from 'react-multi-lang';

const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiDialogContentText-root': {
      marginBottom: theme.spacing(3),
      marginTop: theme.spacing(-2),
    }
  },
}));

const AddVehicle = (props) => {
  const { routeId, vehicleIds, onGetRouteVehicles, onAddVehicleModalClose } = props;
  const classes = useStyles();
  const [vehicleCapacity, setVehicleCapacity] = React.useState(0);

  const store = useStore();

  const setSnackbar = useStoreActions(actions => actions.global.setSnackbar);

  const addRouteVehicle = useStoreActions(actions => actions.routes.addRouteVehicle);

  const getVehicles = useStoreActions(actions => actions.vehicles.getVehicles);
  const vehicles = useStoreState(state => state.vehicles.vehicles);
  const vehiclesLoading = useStoreState(state => state.vehicles.loading);

  const routeCapacityRef = React.useRef();

  const [vehicleLicensePlate, setVehicleLicensePlate] = React.useState('');
  const debouncedVehicleLicensePlate = useDebounce(vehicleLicensePlate, 500);

  const onGetVehicles = (payload) => {
    getVehicles(payload).then(() => {
      const vehiclesState = store.getState().vehicles;
      if (!vehiclesState.loading && !vehiclesState.error) {
        // console.log(vehiclesState);
      } else {
        handleEndpointErrors(vehiclesState, props, setSnackbar, t);
      }
    });
  }

  React.useEffect(() => {
    onGetVehicles({ textSearch: debouncedVehicleLicensePlate });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedVehicleLicensePlate]);

  const formRef = React.useRef();

  const t = useTranslation();

  return (
    <React.Fragment>
      <DialogTitle>{ t('route.vehicles.add.title') }</DialogTitle>
      <Formik
        innerRef={ formRef }
        initialValues={{ vehicle: '', capacity: '' }}
        validationSchema={ Yup.object({
          vehicle: Yup.object()
            // .max(15, 'Must be 15 characters or less')
            .required(t('global.errors.required', { field: t('route.vehicles.add.vehicle') })),
          capacity: Yup.number()
            .typeError(t('global.errors.number', { field: t('route.vehicles.routeCapacity') }))
            .max(vehicleCapacity, t('route.vehicles.add.realCapacity.error', { capacity: vehicleCapacity }))
            .required(t('global.errors.required', { field: t('route.vehicles.routeCapacity') })),
        }) }
        onSubmit={(values, { setSubmitting }) => {
          const data = {
            routeId,
            vehicleId: values.vehicle.id,
            capacity: +values.capacity
          };
          addRouteVehicle(data).then(() => {
            const routesState = store.getState().routes;
            if (!routesState.loading && !routesState.error) {
              setSubmitting(false);
              onGetRouteVehicles(routeId);
              onAddVehicleModalClose();
              setSnackbar({ show: true, autoHideDuration: SNACKBAR_TIME.SUCCESS, severity: 'success', message: t('global.success.saved') });
            } else {
              handleEndpointErrors(routesState, props, setSnackbar, t);
            }
          });
        }}
      >
        {({ values, submitForm, isSubmitting }) => (
          <CustomFormikForm>
            <DialogContent className={ classes.root }>
              { values.vehicle && <DialogContentText>{ t('route.vehicles.add.realCapacity.message', { capacity: values.vehicle.capacity }) }</DialogContentText> }
              <Grid container spacing={ 3 }>
                <Grid item xs={ 6 } md={ 4 }>
                  { vehiclesLoading ? <LoadingCard length={ 1 } height={ 56 } /> : (
                    <Field
                      component={ FormikAutocomplete }
                      name='vehicle'
                      disableClearable
                      options={ vehicles.filter(vehicle => !vehicleIds.includes(vehicle.id)) }
                      getOptionLabel={ option => option ? `${ option.licensePlate } - ${ option.make } ${ option.model }, ${ option.year }` : '' }
                      textFieldProps={{
                        label: t('route.vehicles.add.vehicle'),
                        variant: 'outlined',
                        fullWidth: true,
                        icon: <DriveEtaIcon />,
                        onChange: event => {
                          setVehicleLicensePlate( event.target.value );
                        }
                      }}
                      onFocus={() => {
                        setVehicleLicensePlate('');
                      }}
                      onChange={() => {
                        setTimeout(() => {
                          if ( formRef.current.values.vehicle?.capacity ) {
                            setVehicleCapacity(formRef.current.values.vehicle.capacity);
                            routeCapacityRef.current.querySelector('input').select();
                          }
                        })
                      }}
                    />
                  ) }
                </Grid>
                <Grid item xs={ 6 } md={ 4 }>
                  <Field
                    innerRef={ routeCapacityRef }
                    component={ TextField }
                    name="capacity"
                    type="text"
                    label={ t('route.vehicles.routeCapacity') }
                    variant="outlined"
                    fullWidth
                    disabled={ !values.vehicle }
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={ onAddVehicleModalClose } color="primary">{ t('global.modal.btnClose') }</Button>
              <Button disabled={ isSubmitting } onClick={ submitForm } color="primary">{ t('global.modal.btnAdd') }</Button>
            </DialogActions>
          </CustomFormikForm>
        )}
      </Formik>
    </React.Fragment>
  );
}

AddVehicle.propTypes = {
  routeId: PropTypes.number.isRequired,
  vehicleIds: PropTypes.array.isRequired,
  onGetRouteVehicles: PropTypes.func.isRequired,
  onAddVehicleModalClose: PropTypes.func.isRequired,
};

export default AddVehicle;
