import React from 'react';

import PropTypes from 'prop-types';

import clsx from 'clsx';

import { useStoreActions, useStore } from 'easy-peasy';

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';

import * as Yup from 'yup';

import { Formik, Field } from 'formik';
import { TextField } from 'formik-material-ui';

import { handleEndpointErrors, DEVICE_NAMES, SNACKBAR_TIME, hexadecimalToDecimal, reverseHexadecimalInPairs } from '../../shared/utilities';

import CustomFormikForm from '../CustomFormikForm';
import FormikCheckbox from '../FormikCheckbox';
import FormikAutocomplete from '../FormikAutocomplete';

import { useTranslation } from 'react-multi-lang';

const useStyles = makeStyles(theme => ({
  deviceActive: {
    right: theme.spacing(3),
  },
}));

const AddDevice = (props) => {
  const { device, userId, onAddDeviceModalClose } = props;
  const classes = useStyles();

  const store = useStore();

  const setSnackbar = useStoreActions(actions => actions.global.setSnackbar);

  const getDevices = useStoreActions(actions => actions.users.getDevices);
  const addDevice = useStoreActions(actions => actions.users.addDevice);
  const updateDevice = useStoreActions(actions => actions.users.updateDevice);
  const deviceAction = device.id ? updateDevice : addDevice;

  const t = useTranslation();

  return (
    <React.Fragment>
      <DialogTitle>{ t(`user.devices.${ device.id ? 'edit' : 'add' }.title`) }</DialogTitle>
      <Formik
        initialValues={{ deviceId: device.deviceId || '', name: device.name || '', value: device.value || '', active: device.id ? device.active : true }}
        validationSchema={ Yup.object({
          deviceId: Yup.string()
            .required(t('global.errors.required', { field: t('user.devices.deviceId') })),
          value: Yup.string()
            .required(t('global.errors.required', { field: t('user.devices.value') })),
          name: Yup.string()
            .required(t('global.errors.required', { field: t('user.devices.name') })),
        }) }
        onSubmit={(values, { setSubmitting }) => {
          const data = {
            userId,
            deviceId: values.deviceId,
            value: values.value,
            name: values.name,
            active: values.active
          };
          deviceAction({ id: device.id || null, data }).then(() => {
            const usersState = store.getState().users;
            setSubmitting(false);
            if (!usersState.loading && !usersState.error) {
              getDevices(userId);
              onAddDeviceModalClose();
              setSnackbar({ show: true, autoHideDuration: SNACKBAR_TIME.SUCCESS, severity: 'success', message: t('global.success.saved') });
            } else {
              handleEndpointErrors(usersState, props, setSnackbar, t, 'devices');
            }
          });
        }}
      >
        {({ submitForm, isSubmitting, setFieldValue }) => (
          <CustomFormikForm>
            <DialogContent>
              <Field
                component={ FormikCheckbox }
                className={clsx( 'formik-checkbox', classes.deviceActive )}
                name="active"
                label={ t('user.devices.active') }
                disabled={ isSubmitting }
              />
              <Grid container spacing={ 3 }>
                <Grid item xs={ 6 } md={ 4 }>
                  <Field
                    component={ TextField }
                    name="deviceId"
                    type="text"
                    label={`${ t('user.devices.deviceId') }`}
                    variant="outlined"
                    fullWidth
                    inputProps={{
                      onChange: event => {
                        const newValue = hexadecimalToDecimal(reverseHexadecimalInPairs(event.target.value));
                        setFieldValue('value', !!newValue && !isNaN(newValue) ? newValue : '', false);
                      }
                    }}
                  />
                </Grid>
                <Grid item xs={ 6 } md={ 4 }>
                  <Field
                    component={ TextField }
                    name="value"
                    type="text"
                    label={`${ t('user.devices.value') }`}
                    variant="outlined"
                    fullWidth
                    disabled
                  />
                </Grid>
                <Grid item xs={ 6 } md={ 4 }>
                  <Field
                    component={ FormikAutocomplete }
                    name="name"
                    disableClearable
                    options={ DEVICE_NAMES }
                    getOptionLabel={ option => option ? t(`user.devices.names.${ option }`) : '' }
                    textFieldProps={{ label: t('user.devices.name') , variant: 'outlined', readOnly: true, fullWidth: true }}
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={ onAddDeviceModalClose } color="primary">{ t('global.modal.btnClose') }</Button>
              <Button disabled={ isSubmitting } onClick={ submitForm } color="primary">{ t(`global.modal.${ device.id ? 'btnUpdate' : 'btnAdd' }`) }</Button>
            </DialogActions>
          </CustomFormikForm>
        )}
      </Formik>
    </React.Fragment>
  );
}

AddDevice.propTypes = {
  device: PropTypes.object,
  userId: PropTypes.number.isRequired,
  onAddDeviceModalClose: PropTypes.func.isRequired
};

export default AddDevice;
