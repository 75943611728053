import { withTheme, styled } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';

const CustomAccordion = styled(Accordion)({
  backgroundColor: props => props.theme.palette.background.default,
  border: props => `1px solid ${ props.theme.palette.type === 'dark' ? props.theme.palette.common.white : props.theme.palette.grey[500]  }`,
  borderRadius: props => props.theme.spacing(1.5),
  boxShadow: 'none',
  '&:last-child': {
    borderRadius: props => props.theme.spacing(1.5),
  },
  '&::before, &::after': {
    content: 'normal',
  },
  '& .MuiAccordionSummary-root.Mui-expanded': {
    minHeight: 0,
  },
  '& .MuiAccordionSummary-content.Mui-expanded': {
    margin: 0,
  },
  '& .MuiCollapse-container': {
    borderTop: props => `1px solid ${ props.theme.palette.primary.light  }`,
  },
});

export default withTheme(CustomAccordion);
