import { action, thunk } from 'easy-peasy';

import { getRoutes, getRoute, getRouteVehicles, getRouteSchedules, getRoutePlaces, getRouteClients, getRouteLines } from '../../services/routes/get';
import { addRoute, addRouteClient, addRoutePlace, addRouteSchedule, addRouteVehicle, addRouteLine } from '../../services/routes/post';
import { updateRoute, updateRoutePlace, updateRouteSchedule, updateRouteVehicle, generateRouteToken } from '../../services/routes/put';
import { deleteRoute, deleteRouteClient, deleteRoutePlace, deleteRouteSchedule, deleteRouteVehicle, deleteRouteLine } from '../../services/routes/delete';

const routesModel = {
  routes: [],
  loadedRoutes: [],
  route: {},
  newRouteId: null,
  loading: false,
  error: '',
  page: 1,
  pageSize: localStorage.getItem('routesPageSize') ? localStorage.getItem('routesPageSize') : 25,
  textSearch: '',
  routeVehicles: [],
  routeVehiclesLoading: false,
  routeVehiclesError: '',
  routeSchedules: [],
  routeSchedulesLoading: false,
  routeSchedulesError: '',
  routePlaces: [],
  routePlacesLoading: false,
  routePlacesError: '',
  routeClients: [],
  routeClientsLoading: false,
  routeClientsError: '',
  routeLines: [],
  routeLinesLoading: false,
  routeLinesError: '',
  getRoutes: thunk(async (actions, payload = {}, { getState, getStoreState }) => {
    actions.setLoading(payload.loading);
    actions.setError('');
    return await getRoutes(actions, payload, getState(), getStoreState());
  }),
  getRoute: thunk(async (actions, payload) => {
    actions.setLoading(true);
    actions.setError('');
    return await getRoute(actions, payload);
  }),
  getRouteVehicles: thunk(async (actions, payload) => {
    actions.setRouteVehiclesLoading(true);
    actions.setRouteVehiclesError('');
    return await getRouteVehicles(actions, payload);
  }),
  getRouteSchedules: thunk(async (actions, payload) => {
    actions.setRouteSchedulesLoading(true);
    actions.setRouteSchedulesError('');
    return await getRouteSchedules(actions, payload);
  }),
  getRoutePlaces: thunk(async (actions, payload) => {
    actions.setRoutePlacesLoading(true);
    actions.setRoutePlacesError('');
    return await getRoutePlaces(actions, payload);
  }),
  getRouteClients: thunk(async (actions, payload) => {
    actions.setRouteClientsLoading(true);
    actions.setRouteClientsError('');
    return await getRouteClients(actions, payload);
  }),
  getRouteLines: thunk(async (actions, payload) => {
    actions.setRouteLinesLoading(true);
    actions.setRouteLinesError('');
    return await getRouteLines(actions, payload);
  }),
  addRoute: thunk(async (actions, payload) => {
    actions.setError('');
    return await addRoute(actions, payload);
  }),
  updateRoute: thunk(async (actions, payload) => {
    actions.setError('');
    return await updateRoute(actions, payload);
  }),
  deleteRoute: thunk(async (actions, payload) => {
    actions.setError('');
    return await deleteRoute(actions, payload);
  }),
  addRouteClient: thunk(async (actions, payload) => {
    actions.setError('');
    return await addRouteClient(actions, payload);
  }),
  deleteRouteClient: thunk(async (actions, payload) => {
    actions.setError('');
    return await deleteRouteClient(actions, payload);
  }),
  addRoutePlace: thunk(async (actions, payload) => {
    actions.setError('');
    return await addRoutePlace(actions, payload);
  }),
  updateRoutePlace: thunk(async (actions, payload) => {
    actions.setError('');
    return await updateRoutePlace(actions, payload);
  }),
  deleteRoutePlace: thunk(async (actions, payload) => {
    actions.setError('');
    return await deleteRoutePlace(actions, payload);
  }),
  addRouteSchedule: thunk(async (actions, payload) => {
    actions.setError('');
    return await addRouteSchedule(actions, payload);
  }),
  updateRouteSchedule: thunk(async (actions, payload) => {
    actions.setError('');
    return await updateRouteSchedule(actions, payload);
  }),
  deleteRouteSchedule: thunk(async (actions, payload) => {
    actions.setError('');
    return await deleteRouteSchedule(actions, payload);
  }),
  addRouteVehicle: thunk(async (actions, payload) => {
    actions.setError('');
    return await addRouteVehicle(actions, payload);
  }),
  addRouteLine: thunk(async (actions, payload) => {
    actions.setError('');
    return await addRouteLine(actions, payload);
  }),
  updateRouteVehicle: thunk(async (actions, payload) => {
    actions.setError('');
    return await updateRouteVehicle(actions, payload);
  }),
  generateRouteToken: thunk(async (actions, payload) => {
    actions.setError('');
    return await generateRouteToken(actions, payload);
  }),
  deleteRouteVehicle: thunk(async (actions, payload) => {
    actions.setError('');
    return await deleteRouteVehicle(actions, payload);
  }),
  deleteRouteLine: thunk(async (actions, payload) => {
    actions.setError('');
    return await deleteRouteLine(actions, payload);
  }),
  storeGetRoutes: action((state, payload) => {
    if (payload.update) {
      state.routes = payload.data;
    } else {
      state.loadedRoutes = payload.data;
    }
    state.page = payload.page ? payload.page : 1;
    state.textSearch = payload.textSearch ? payload.textSearch : '';
  }),
  storeGetRoute: action((state, payload) => {
    state.route = payload;
  }),
  storeGetRouteVehicles: action((state, payload) => {
    state.routeVehicles = payload;
  }),
  storeGetRouteSchedules: action((state, payload) => {
    state.routeSchedules = payload;
  }),
  storeGetRoutePlaces: action((state, payload) => {
    state.routePlaces = payload;
  }),
  storeGetRouteClients: action((state, payload) => {
    state.routeClients = payload;
  }),
  storeGetRouteLines: action((state, payload) => {
    state.routeLines = payload;
  }),
  storeAddRoute: action((state, payload) => {
    state.newRouteId = payload;
    state.route = {};
  }),
  setLoading: action((state, payload) => {
    state.loading = payload;
  }),
  setError: action((state, payload) => {
    state.error = payload;
  }),
  setTextSearch: action((state, payload) => {
    state.textSearch = payload;
  }),
  setRouteVehiclesLoading: action((state, payload) => {
    state.routeVehiclesLoading = payload;
  }),
  setRouteVehiclesError: action((state, payload) => {
    state.routeVehiclesError = payload;
  }),
  setRouteSchedulesLoading: action((state, payload) => {
    state.routeSchedulesLoading = payload;
  }),
  setRouteSchedulesError: action((state, payload) => {
    state.routeSchedulesError = payload;
  }),
  setRoutePlacesLoading: action((state, payload) => {
    state.routePlacesLoading = payload;
  }),
  setRoutePlacesError: action((state, payload) => {
    state.routePlacesError = payload;
  }),
  setRouteClientsLoading: action((state, payload) => {
    state.routeClientsLoading = payload;
  }),
  setRouteClientsError: action((state, payload) => {
    state.routeClientsError = payload;
  }),
  setRouteLinesLoading: action((state, payload) => {
    state.routeLinesLoading = payload;
  }),
  setRouteLinesError: action((state, payload) => {
    state.routeLinesError = payload;
  }),
}

export default routesModel;
