import React from 'react';

import PropTypes from 'prop-types';

import { useStoreState } from 'easy-peasy';

import { makeStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

import { formatDate, formatTime } from '../../../shared/utilities';

import SidebarHead from './Head';
import Trip from '../Trip';

import { useTranslation } from 'react-multi-lang';

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      maxHeight: 'calc(100vh - 148px)',
      overflow: 'auto',
      padding: theme.spacing(2),
    },
  },
  trip: {
    padding: theme.spacing(2),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: -theme.spacing(2),
      width: `calc(100% + ${ theme.spacing(2) }px)`,
    },
  },
  loadMore: {
    borderRadius: theme.spacing(2),
    padding: theme.spacing(2),
    width: '100%',
    '& .MuiSvgIcon-root': {
      height: theme.spacing(4),
      width: theme.spacing(4),
    },
  },
}));

const SidebarListView = (props) => {
  const {  onGetTrips, onTripSelected } = props;
  const classes = useStyles();

  const trips = useStoreState(state => state.trips.trips);
  const tripSelected = useStoreState(state => state.trips.currentTrip);
  const tripsLoading = useStoreState(state => state.trips.loading);
  const hasMoreTrips = useStoreState(state => state.trips.hasMoreTrips);

  const onLoadMore = () => {
    onGetTrips({ isLoadMore: true });
  };

  const t = useTranslation();

  return <div className={ classes.root }>
    <SidebarHead onGetTrips={ onGetTrips } showSearch />

    { trips.map(trip => <Trip
      key={ trip.id }
      className={ classes.trip }
      route={ trip.route }
      date={ formatDate(trip.departureDate, t) }
      dateRaw={ trip.departureDate }
      start={{ name: trip.route.origin.description, time: formatTime(trip.schedule.departureTime) }}
      end={{ name: trip.route.destination.description, time: formatTime(trip.schedule.arrivalTime) }}
      tags={ [
        trip.route.type.toLowerCase() === 'dtd' ? 'dtd' : 'p',
        trip.route.direction,
        ...(trip.extra ? ['extra'] : []),
      ] }
      status={ trip.status ? trip.status : 'default' }
      id={ trip.id }
      idFormat={ `${ t('trips.tripId') }: ${ trip.id }` }
      clickEvent={ !tripSelected || trip.id !== tripSelected.id ? () => onTripSelected(trip.id) : null }
      active={ tripSelected && trip.id === tripSelected.id }
      vehicle={ trip.vehicle }
      small
    /> ) }

    { hasMoreTrips && <Tooltip title={ t('trips.loadMoreTrips') }><span><IconButton
      aria-label={ t('trips.loadMoreTrips') }
      className={ classes.loadMore }
      color="primary"
      disabled={ tripsLoading }
      onClick={ onLoadMore }
    >
      <AddIcon />
    </IconButton></span></Tooltip> }

    { !trips.length && <Typography variant="body2">{ t('trips.emptyTrips') }</Typography> }
  </div>;
}

SidebarListView.propTypes = {
  onGetTrips: PropTypes.func.isRequired,
  onTripSelected: PropTypes.func.isRequired
};

export default SidebarListView;
