import axios from 'axios';

import { logSentryEndpointError } from '../../shared/utilities';

let lastPayload = {};

export const getReservations = (actions, payload, state, storeState) => {
  payload = payload.clientChange ? lastPayload : payload;
  lastPayload = payload;
  return axios({
    method: 'get',
    url: `${ process.env.REACT_APP_API_URL }/api/v1/reservations`,
    params: {
      page: payload.page ? payload.page : state.page,
      pageSize: payload.pageSize || state.pageSize,
      ...(storeState.global.clientSelected !== 'all' && !storeState.global.clientsDisabled && { clientId: storeState.global.clientSelected }),
      textSearch: payload.textSearch,
      ...(!!payload.tripId && { tripId: payload.tripId }),
      ...(!!payload.placeId && { placeId: payload.placeId }),
      ...(!!payload.routeId && { routeId: payload.routeId }),
      ...((!!payload.departureDate || !!payload.status?.length || !!payload.direction) && { filter: JSON.stringify({
        ...(!!payload.departureDate && { departureDate: payload.departureDate }),
        ...(!!payload.status?.length && { status: payload.status }),
        ...(!!payload.direction && { direction: [payload.direction] }),
      }), }),
    },
    headers: {
      'auth-token': localStorage.getItem('token'),
      // 'Access-Control-Allow-Origin': '*',
      // 'Content-Type': 'application/json'
    }
  })
  .then(response => {
    actions.setLoading(false);
    actions.storeGetReservations({ data: response.data, page: payload.page || state.page, pageSize: payload.pageSize || state.pageSize, textSearch: payload.textSearch === undefined ? state.textSearch : payload.textSearch });;
  })
  .catch(error => {
    actions.setLoading(false);
    actions.setError(error.response);
    logSentryEndpointError(error);
  });
}

export const getReservation = (actions, payload) => axios({
  method: 'get',
  url: `${ process.env.REACT_APP_API_URL }/api/v1/reservations/${ payload }`,
  headers: {
    'auth-token': localStorage.getItem('token'),
    // 'Access-Control-Allow-Origin': '*',
    // 'Content-Type': 'application/json'
  }
})
.then(response => {
  actions.setLoading(false);
  actions.storeGetReservation(response.data);
})
.catch(error => {
  actions.setLoading(false);
  actions.setError(error.response);
  logSentryEndpointError(error);
});

export const getReservationRouteAlternatives = (actions, payload) => axios({
  method: 'get',
  url: `${ process.env.REACT_APP_API_URL }/api/v1/reservations/${ payload }/route_alternatives`,
  headers: {
    'auth-token': localStorage.getItem('token'),
    // 'Access-Control-Allow-Origin': '*',
    // 'Content-Type': 'application/json'
  }
})
.then(response => {
  actions.setLoading(false);
  actions.storeGetReservationRouteAlternatives(response.data);
})
.catch(error => {
  actions.setLoading(false);
  actions.setError(error.response);
  logSentryEndpointError(error);
});

export const getReservationsReport = (actions, payload, state, storeState) => axios({
  method: 'get',
  url: `${ process.env.REACT_APP_API_URL }/api/admin/v1/reports/reservations`,
  params: {
    page: payload.page || state.reservationsReportPage,
    pageSize: payload.pageSize || state.reservationsReportPageSize,
    textSearch: payload.textSearch === undefined ? state.reservationsReportTextSearch : payload.textSearch,
    startDate: state.reservationsReportDates.startDate.toISOString().split('T')[0],
    endDate: state.reservationsReportDates.endDate.toISOString().split('T')[0],
    ...(storeState.global.clientSelected !== 'all' && !storeState.global.clientsDisabled && { clientId: storeState.global.clientSelected }),
    ...((!!payload.status?.length || !!payload.tripStatus?.length) && { filter: JSON.stringify({
      ...(!!payload.status?.length && { status: payload.status }),
      ...(!!payload.tripStatus?.length && { tripStatus: payload.tripStatus }),
    }), }),
    ...(!!payload.extra && { extra: payload.extra }),
  },
  headers: {
    'auth-token': localStorage.getItem('token'),
    // 'Access-Control-Allow-Origin': '*',
    // 'Content-Type': 'application/json'
  }
})
.then(response => {
  actions.setLoading(false);
  actions.storeGetReservationsReport({ data: response.data, page: payload.page || state.reservationsReportPage, textSearch: payload.textSearch === undefined ? state.reservationsReportTextSearch : payload.textSearch });
})
.catch(error => {
  actions.setLoading(false);
  actions.setError(error.response);
  logSentryEndpointError(error);
});

export const getReservationsReportPivot = (actions, payload, state, storeState) => axios({
  method: 'get',
  url: `${ process.env.REACT_APP_API_URL }/api/admin/v1/reports/pivot_reservations`,
  params: {
    startDate: state.reservationsReportPivotDates.startDate.toISOString().split('T')[0],
    endDate: state.reservationsReportPivotDates.endDate.toISOString().split('T')[0],
    ...(storeState.global.clientSelected !== 'all' && !storeState.global.clientsDisabled && { clientId: storeState.global.clientSelected }),
  },
  headers: {
    'auth-token': localStorage.getItem('token'),
    // 'Access-Control-Allow-Origin': '*',
    // 'Content-Type': 'application/json'
  }
})
.then(response => {
  actions.setLoading(false);
  actions.storeGetReservationsReportPivot({ data: response.data });
})
.catch(error => {
  actions.setLoading(false);
  actions.setError(error.response);
  logSentryEndpointError(error);
});
