import { action, thunk } from 'easy-peasy';

import { getBusinessParks, getBusinessPark } from '../../services/businessParks/get';
import addBusinessPark from '../../services/businessParks/post';
import updateBusinessPark from '../../services/businessParks/put';
import deleteBusinessPark from '../../services/businessParks/delete';

const businessParksModel = {
  businessParks: [],
  businessPark: {},
  loading: false,
  error: '',
  page: 1,
  pageSize: localStorage.getItem('businessParksPageSize') ? localStorage.getItem('businessParksPageSize') : 25,
  getBusinessParks: thunk(async (actions, payload = {}, { getState }) => {
    actions.setLoading(payload.loading);
    actions.setError('');
    return await getBusinessParks(actions, payload, getState());
  }),
  getBusinessPark: thunk(async (actions, payload) => {
    actions.setLoading(true);
    actions.setError('');
    return await getBusinessPark(actions, payload);
  }),
  addBusinessPark: thunk(async (actions, payload) => {
    actions.setError('');
    return await addBusinessPark(actions, payload);
  }),
  updateBusinessPark: thunk(async (actions, payload) => {
    actions.setError('');
    return await updateBusinessPark(actions, payload);
  }),
  deleteBusinessPark: thunk(async (actions, payload) => {
    actions.setError('');
    return await deleteBusinessPark(actions, payload);
  }),
  storeGetBusinessParks: action((state, payload) => {
    state.businessParks = payload.data;
    state.page = payload.page ? payload.page : 1;
  }),
  storeGetBusinessPark: action((state, payload) => {
    state.businessPark = payload;
  }),
  setLoading: action((state, payload) => {
    state.loading = payload;
  }),
  setError: action((state, payload) => {
    state.error = payload;
  }),
}

export default businessParksModel;
