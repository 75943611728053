import React from 'react';

import PropTypes from 'prop-types';

// import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';

import { useTranslation } from 'react-multi-lang';

// const useStyles = makeStyles(theme => ({
//   root: {}
// }));

const DeleteReservationModal = (props) => {
  const { reservation, onDeleteReservation, onDeleteReservationModalClose } = props;
  // const classes = useStyles();

  const t = useTranslation();

  return (
    <React.Fragment>
      <DialogTitle>{ t('reservation.deleteModal.title') }</DialogTitle>
        <DialogContent>
          <Typography gutterBottom color="textSecondary">{ t('reservation.deleteModal.content') }</Typography>
          <Typography gutterBottom color="textSecondary"><strong>{ reservation.id }</strong> - { reservation.passenger.name }</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={ onDeleteReservationModalClose } color="primary">{ t('global.modal.btnCancel') }</Button>
          <Button onClick={ onDeleteReservation } color="primary">{ t('global.modal.btnDelete') }</Button>
        </DialogActions>
    </React.Fragment>
  );
}

DeleteReservationModal.propTypes = {
  reservation: PropTypes.object.isRequired,
  onDeleteReservation: PropTypes.func.isRequired,
  onDeleteReservationModalClose: PropTypes.func.isRequired,
};

export default DeleteReservationModal;
