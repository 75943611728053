import React from 'react';
import { withRouter } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';

import { useStoreState, useStoreActions, useStore } from 'easy-peasy';

import { makeStyles } from '@material-ui/core/styles';
import HomeIcon from '@material-ui/icons/Home';
import Link from '@material-ui/core/Link';
import ShuffleIcon from '@material-ui/icons/Shuffle';
import VisibilityIcon from '@material-ui/icons/Visibility';

import { handleEndpointErrors, formatDateWithTime, dateISOWithoutTz, SNACKBAR_TIME, openLink } from '../shared/utilities';

import Breadcrumbs from '../components/Breadcrumbs';
import LoadingCard from '../components/LoadingCard';
import Table from '../components/Table';
import CustomDialog from '../components/CustomDialog';
import AddLine from '../components/Lines/AddLine';
import DataFilters from '../components/DataFilters';

import { useTranslation } from 'react-multi-lang';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3, 2),
  },
  table: {
    '& .MuiTableCell-head:nth-child(2), & .MuiTableCell-head:nth-child(6), & .MuiTableCell-head:nth-child(7)': {
      minWidth: '180px',
    },
  },
  modal: {
    '& .MuiDivider-root': {
      margin: theme.spacing(2, 3),
      width: `calc(100% - ${ theme.spacing(6) }px)`,
    },
  },
}));

const Lines = (props) => {
  const { history } = props;
  const classes = useStyles();
  const [linesTableData, setLinesTableData] = React.useState([]);
  const [addLineModalOpen, setAddLineModalOpen] = React.useState(false);
  const [fetchingLinesData, setFetchingLinesData] = React.useState(true);
  const [activeFilters, setActiveFilters] = React.useState({});

  const store = useStore();

  const permissions = useStoreState(state => state.global.permissions);
  const storeMenuTitle = useStoreActions(actions => actions.global.storeTitle);
  const setSnackbar = useStoreActions(actions => actions.global.setSnackbar);

  const lines = useStoreState(actions => actions.lines.lines);
  const getLines = useStoreActions(actions => actions.lines.getLines);
  const deleteLine = useStoreActions(actions => actions.lines.deleteLine);
  const linesLoading = useStoreState(state => state.lines.loading);
  const linesTextSearch = useStoreState(state => state.lines.textSearch);
  const setLinesTextSearch = useStoreActions(actions => actions.lines.setTextSearch);
  
  const paginationPage = useStoreState(state => state.lines.page);
  const paginationPageSize = useStoreState(state => state.lines.pageSize);
  const [dataLoading, setDataLoading] = React.useState(false);

  const users = useStoreState(actions => actions.users.users);
  const getUsers = useStoreActions(actions => actions.users.getUsers);

  const canEdit = permissions?.admin?.lines?.edit;
  const canDelete = permissions?.admin?.lines?.delete;

  const t = useTranslation();

  storeMenuTitle(t('lines.pageTitle'));

  const onGetLines = (payload) => {
    setDataLoading(true);
    getLines(payload).then(() => {
      const linesState = store.getState().lines;
      if (!linesState.loading && !linesState.error) {
        // console.log(linesState);
        setDataLoading(false);
      } else {
        handleEndpointErrors(linesState, props, setSnackbar, t);
      }
    });
  }

  React.useEffect(() => {
    getUsers({ role: 'admin', noClientFilter: true });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    onGetLines({ loading: true });
    setFetchingLinesData(false);

    return () => setLinesTextSearch('');
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    setLinesTableData(lines.map(line => {
      line.username = users.length ? users[0].name : line.createdBy;
      line.formatCreatedAt = formatDateWithTime(dateISOWithoutTz(new Date(`${ line.createdAt }`)), t);
      return { ...line };
    }));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lines, users]);

  const onAddLineModalClose = () => {
    setAddLineModalOpen(false);
  };

  const onDeleteLine = (oldData, resolve, reject) => {
    deleteLine(oldData.id).then(() => {
      const linesState = store.getState().lines;
      if (!linesState.error) {
        // console.log('success', linesState);
        resolve();
        const data = [...linesTableData].filter(line => line.id !== oldData.id);
        setLinesTableData(data);
        setSnackbar({ show: true, autoHideDuration: SNACKBAR_TIME.SUCCESS, severity: 'success', message: t('global.success.saved') });
      } else {
        reject();
        handleEndpointErrors(linesState, props, setSnackbar, t);
      }
    });
  };

  const linesTableColumns = [
    { title: t('lines.table.id'), field: 'id', type: 'numeric', align: 'left', defaultSort: 'asc', editable: 'never' },
    { title: t('lines.table.name'), field: 'name', editable: 'never' },
    { 
      title: t('lines.table.createdBy'), field: 'username',
      render: rowData => <Link component={ RouterLink } to={ `/users/${ rowData.createdBy }` }>{ rowData.username }</Link>,
      sorting: false, editable: 'never'
    },
    { title: t('lines.table.createdAt'), field: 'formatCreatedAt', sorting: false, editable: 'never' },
  ];

  return (
    <div className={ classes.root }>
      <Breadcrumbs links={[
        { name: t('home.pageTitle'), path: '/', icon: <HomeIcon /> },
        { name: t('lines.pageTitle'), icon: <ShuffleIcon /> },
      ]} />
      <DataFilters
        setActiveFilters={ setActiveFilters }
        filters={[
          {
            type: 'text',
            name: t('lines.searchPlaceholder'),
            property: 'textSearch',
            active: linesTextSearch,
            width: '250px',
            onChangeFn: (filters) => {
              onGetLines({ page: 1, ...filters });
            }
        },
        ]}
      />
      { fetchingLinesData || linesLoading ? <LoadingCard length={ 1 } height={ 725 } /> : <React.Fragment>
        <Table
          className={ classes.table }
          title=""
          columns={ linesTableColumns }
          data={ linesTableData }
          exportButton
          exportFileName={ t('lines.pageTitle') }
          deleteEvent={ canDelete ? onDeleteLine : undefined }
          search={ false }
          customPagination={{
            page: paginationPage,
            size: paginationPageSize,
            responseLength: lines.length,
            loading: dataLoading,
            clickEvent: page => {
              onGetLines({ page, ...activeFilters });
            }
          }}
          addActions={[
            ...canEdit ? [{
              icon: 'add',
              iconProps: {
                color: 'primary'
              },
              tooltip: t('lines.table.actionAdd'),
              isFreeAction: true,
              onClick: () => {
                setAddLineModalOpen(true);
              }
            }] : [],
            {
              icon: canEdit ? 'edit' : () => <VisibilityIcon />,
              tooltip: canEdit ? t('lines.table.actionEdit') : t('lines.table.actionView'),
              onClick: (event, rowData) => {
                openLink(event, history, `/lines/${ rowData.id }`);
              }
            },
          ]}
        />
      </React.Fragment> }

      <CustomDialog className={ classes.modal } open={ addLineModalOpen } onClose={ onAddLineModalClose } fullWidth={ true } maxWidth="md">
        <AddLine onAddLineModalClose={ onAddLineModalClose } />
      </CustomDialog>
    </div>
  );
}

export default withRouter(Lines);
