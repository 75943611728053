import { action, thunk } from 'easy-peasy';

import getOperationalIntervals from '../../services/operationalIntervals/get';
import addOperationalInterval from '../../services/operationalIntervals/post';
import updateOperationalInterval from '../../services/operationalIntervals/put';
import deleteOperationalInterval from '../../services/operationalIntervals/delete';

const operationalIntervalsModel = {
  operationalIntervals: [],
  loading: false,
  error: '',
  page: 1,
  pageSize: localStorage.getItem('operationalIntervalsPageSize') ? localStorage.getItem('operationalIntervalsPageSize') : 25,
  getOperationalIntervals: thunk(async (actions, payload = {}, { getState }) => {
    actions.setLoading(payload.loading);
    actions.setError('');
    return await getOperationalIntervals(actions, payload, getState());
  }),
  addOperationalInterval: thunk(async (actions, payload) => {
    actions.setError('');
    return await addOperationalInterval(actions, payload);
  }),
  updateOperationalInterval: thunk(async (actions, payload) => {
    actions.setError('');
    return await updateOperationalInterval(actions, payload);
  }),
  deleteOperationalInterval: thunk(async (actions, payload) => {
    actions.setError('');
    return await deleteOperationalInterval(actions, payload);
  }),
  storeGetOperationalIntervals: action((state, payload) => {
    state.operationalIntervals = payload.data;
    state.page = payload.page ? payload.page : 1;
  }),
  setLoading: action((state, payload) => {
    state.loading = payload;
  }),
  setError: action((state, payload) => {
    state.error = payload;
  }),
}

export default operationalIntervalsModel;
