import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

import PropTypes from 'prop-types';

import clsx from 'clsx';

import { fade, makeStyles } from '@material-ui/core/styles';
import DescriptionIcon from '@material-ui/icons/Description';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import InputLabel from '@material-ui/core/InputLabel';
import Link from '@material-ui/core/Link';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import Box from '@material-ui/core/Box';

import Route from '../Route';
import Tag from '../Tag';
import Status from '../Status';
import Note from '../Note';

import { useTranslation } from 'react-multi-lang';

const useStyles = makeStyles(theme => ({
  root: {
    cursor: props => props.clickEvent ? 'pointer' : null,
    '&:hover': {
      backgroundColor: props => props.clickEvent ? theme.palette.secondary.overlay : null,
    },
  },
  active: {
    backgroundColor: theme.palette.secondary.overlay,
  },
  tags: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  note: {
    padding: '4px',
  },
  status: {
    marginLeft: theme.spacing(.5),
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: props => theme.palette.status[props.status] ? fade(theme.palette.status[props.status], 0.24) : fade(theme.palette.text.secondary, 0.24),
    },
    '& .MuiOutlinedInput-root': {
      color: props => theme.palette.status[props.status],
    },
    '& .MuiSvgIcon-root': {
      color: props => theme.palette.status[props.status],
    },
    '& .MuiInputBase-root.Mui-disabled': {
      color: theme.palette.text.disabled,
      '& .MuiSvgIcon-root': {
        color: theme.palette.text.disabled,
      },
    },
  },
  rightItem: {
    display: 'block',
    // marginTop: theme.spacing(.5),
    textAlign: 'right',
  },
  select: {
    marginLeft: theme.spacing(.5),
    minWidth: 120,
    '& .MuiOutlinedInput-root': {
      borderRadius: theme.spacing(3),
    },
    '& .MuiInputBase-root': {
      ...theme.typography.overline,
    },
    '& .MuiOutlinedInput-input': {
      paddingBottom: 0,
      paddingTop: 0,
    },
  },
  extra: {
    minWidth: 65,
  },
  menuItem: {
    textTransform: 'capitalize',
  },
  openTripBtn: {
    border: `1px solid ${ theme.palette.primary.main }`,
    marginLeft: theme.spacing(1),
    padding: '5px',
  },
  confirmedIcon: {
    color: theme.palette.primary.main,
    display: 'flex',
    margin: theme.spacing(0, .5)
  },
}));

const Trip = (props) => {
  const { className, route, date, dateRaw, start, end, note, tags, status, statusList, onStatusUpdate, onReOpenTrip, id, idFormat, clickEvent, active, extra, onExtraUpdate, wasConfirmed, vehicle, small } = props;
  const classes = useStyles(props);
  const [currentStatus, setCurrentStatus] = React.useState('');

  const t = useTranslation();

  const logTripInfo = () => {
    const formatDate = `${ date }, ${ start.time }`;
    const timestamp = Math.floor(new Date(dateRaw.split('.000Z')[0]).getTime() / 1000);
    console.log({
      tripId: id,
      date: formatDate,
      timestamp,
      vehicleIdent: vehicle?.identifier
    });
  };

  return (
    <div className={ clsx(classes.root, {
      [className]: className,
      [classes.active]: active,
    }) } {...clickEvent && { onClick: clickEvent }}>
      <Grid container>
        { small && <Typography variant="caption" component="p" style={{ width: '100%' }}>{ route.name }</Typography> }
        { !small && <Box display={{ xs: 'block', sm: 'none' }} mb={ 1 } width='100%'><Link component={ RouterLink } to={`/routes/${ route.id }`}><Typography variant="body2" component="p">{ route.name }</Typography></Link></Box> }
        <Grid item xs={ 5 }>
          { !small && <Box display={{ xs: 'none', sm: 'block' }}><Link component={ RouterLink } to={`/routes/${ route.id }`}><Typography variant="body2" component="p">{ route.name }</Typography></Link></Box> }
          <Route variant={ small ? 'small' : 'default' } origin={ start } destination={ end } />
        </Grid>
        <Grid item xs={ 7 }>
          <div className={ classes.tags }>
            { note ? <Note
              additionalClass={ classes.note }
              icon={ <DescriptionIcon /> }
              iconText={ t('trips.note') }
              text={ note }
            /> : null }
            { wasConfirmed ? <Tooltip title={ t('trips.confirmedTrip') }><span>
              <VerifiedUserIcon className={ classes.confirmedIcon } />
            </span></Tooltip> : null }
            { tags && tags.map((tag, index) => (
              <Tag key={ index } name={ tag } />
            )) }
            { !small && ['pending', 'ready', 'confirmed'].includes(status) && (
              <FormControl variant="outlined" className={ clsx(classes.select, classes.extra) }>
                <InputLabel>{ t('trips.addTripModal.addTrip.extra.label') }</InputLabel>
                <Select
                  value={`${ extra }`}
                  disabled={ ['completed', 'cancelled'].includes(status) }
                  onChange={ (event) => onExtraUpdate(event.target.value) }
                  label={ t('trips.addTripModal.addTrip.extra.label') }
                >
                  { ['true', 'false'].map(option => <MenuItem
                                                      key={ option }
                                                      className={ classes.menuItem }
                                                      value={ option }
                                                    >{ t(`trips.addTripModal.addTrip.extra.${ option }`) }</MenuItem>) }
                </Select>
              </FormControl>
            ) }
            { !small && !['pending', 'ready', 'confirmed'].includes(status) && extra && (
              <Tag name="extra" />
            ) }
            { small ? <Status additionalClass={ classes.status } status={ status } /> : statusList && (
              <FormControl variant="outlined" className={ clsx(classes.select, classes.status) }>
                <InputLabel>{ t('trips.status') }</InputLabel>
                <Select
                  value={ status }
                  disabled={ ['completed', 'cancelled'].includes(status) }
                  onOpen={ () => setCurrentStatus(status) }
                  onChange={ (event) => onStatusUpdate(id, event.target.value, currentStatus) }
                  label={ t('trips.status') }
                >
                  { statusList.map(status => <MenuItem
                                                key={ status }
                                                className={ classes.menuItem }
                                                value={ status }
                                              >{ t(`global.status.${ status }`) }</MenuItem>) }
                </Select>
              </FormControl>
            ) }
            { !small && ['completed', 'cancelled'].includes(status) && (
              <Tooltip title={ t('trips.updateCompletedTrip.tooltip') }><span><IconButton
                aria-label={ t('trips.updateCompletedTrip.tooltip') }
                className={ classes.openTripBtn }
                color="primary"
                onClick={onReOpenTrip }
              >
                <LockOpenIcon />
              </IconButton></span></Tooltip>
            ) }
          </div>
          <Typography className={ classes.rightItem } color="textSecondary" variant={ small ? 'caption' : 'body2' }>{ date }</Typography>
          <Typography onClick={ logTripInfo } className={ classes.rightItem } color="textSecondary" variant={ small ? 'caption' : 'body2' }>{ idFormat }</Typography>
        </Grid>
      </Grid>
    </div>
  );
}

Trip.propTypes = {
  className: PropTypes.string,
  route: PropTypes.object.isRequired,
  date: PropTypes.string.isRequired,
  dateRaw: PropTypes.string.isRequired,
  start: PropTypes.object.isRequired,
  end: PropTypes.object.isRequired,
  note: PropTypes.string,
  tags: PropTypes.array,
  status: PropTypes.string.isRequired,
  statusList: PropTypes.array,
  onStatusUpdate: PropTypes.func,
  onExtraUpdate: PropTypes.func,
  onReOpenTrip: PropTypes.func,
  id: PropTypes.number.isRequired,
  idFormat: PropTypes.string.isRequired,
  clickEvent: PropTypes.func,
  active: PropTypes.bool,
  extra: PropTypes.bool,
  small: PropTypes.bool,
  vehicle: PropTypes.object,
  wasConfirmed: PropTypes.bool,
};

export default Trip;
