import axios from 'axios';

import { logSentryEndpointError } from '../../shared/utilities';

export const getUserPlaces = (actions, payload) => axios({
  method: 'get',
  url: `${ process.env.REACT_APP_API_URL }/api/admin/v1/places`,
  params: {
    userId: payload
  },
  headers: {
    'auth-token': localStorage.getItem('token'),
    // 'Access-Control-Allow-Origin': '*',
    // 'Content-Type': 'application/json'
  }
})
.then(response => {
  actions.setLoading(false);
  actions.storeGetUserPlaces(response.data);
})
.catch(error => {
  actions.setLoading(false);
  actions.setError(error.response);
  logSentryEndpointError(error);
});

export const getAdminPlaces = (actions, payload, state) => axios({
  method: 'get',
  url: `${ process.env.REACT_APP_API_URL }/api/admin/v1/places`,
  params: {
    page: payload.page ? payload.page : state.page,
    pageSize: state.pageSize,
    textSearch: payload.textSearch === undefined ? state.textSearch : payload.textSearch,
    ...(!!payload.stop && { stop: payload.stop }),
    ...(!!payload.active && { active: payload.active }),
  },
  headers: {
    'auth-token': localStorage.getItem('token'),
    // 'Access-Control-Allow-Origin': '*',
    // 'Content-Type': 'application/json'
  }
})
.then(response => {
  actions.setLoading(false);
  actions.storeGetAdminPlaces({ data: response.data, page: payload.page || state.page, textSearch: payload.textSearch === undefined ? state.textSearch : payload.textSearch });
})
.catch(error => {
  actions.setLoading(false);
  actions.setError(error.response);
  logSentryEndpointError(error);
});

export const getRoutePlaces = (actions, payload) => axios({
  method: 'get',
  url: `${ process.env.REACT_APP_API_URL }/api/v1/route_places`,
  params: {
    routeId: payload
  },
  headers: {
    'auth-token': localStorage.getItem('token'),
    // 'Access-Control-Allow-Origin': '*',
    // 'Content-Type': 'application/json'
  }
})
.then(response => {
  actions.setLoading(false);
  actions.storeGetRoutePlaces(response.data);
})
.catch(error => {
  actions.setLoading(false);
  actions.setError(error.response);
  logSentryEndpointError(error);
});

