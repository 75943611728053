import React from 'react';
import { withRouter } from 'react-router-dom';

import { useStoreState, useStoreActions, useStore } from 'easy-peasy';

import { makeStyles } from '@material-ui/core/styles';
import DirectionsIcon from '@material-ui/icons/Directions';
import HomeIcon from '@material-ui/icons/Home';
import VisibilityIcon from '@material-ui/icons/Visibility';

import { handleEndpointErrors, getRouteDirections, SNACKBAR_TIME, openLink } from '../shared/utilities';

import Breadcrumbs from '../components/Breadcrumbs';
import LoadingCard from '../components/LoadingCard';
import Table from '../components/Table';
import CustomDialog from '../components/CustomDialog';
import AddRoute from '../components/Routes/AddRoute';
import ViewQrCode from '../components/ViewQrCode';
import DataFilters from '../components/DataFilters';

import { useTranslation } from 'react-multi-lang';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3, 2),
  },
  table: {
    '& .MuiTableCell-head:nth-child(2)': {
      minWidth: '175px',
    },
  },
}));

const Routes = (props) => {
  const { history } = props;
  const classes = useStyles();
  const [routesTableData, setRoutesTableData] = React.useState([]);
  const [addRouteModalOpen, setAddRouteModalOpen] = React.useState(false);
  const [viewQrCodeModalOpen, setViewQrCodeModalOpen] = React.useState(false);
  const [editRoute, setEditRoute] = React.useState({});
  const [activeFilters, setActiveFilters] = React.useState({});

  const store = useStore();

  const permissions = useStoreState(state => state.global.permissions);
  const routeDirections = useStoreState(state => state.global.routeDirections);
  const routeTypes = useStoreState(state => state.global.routeTypes);
  const storeMenuTitle = useStoreActions(actions => actions.global.storeTitle);
  const setSnackbar = useStoreActions(actions => actions.global.setSnackbar);

  const routes = useStoreState(actions => actions.routes.routes);
  const getRoutes = useStoreActions(actions => actions.routes.getRoutes);
  const deleteRoute = useStoreActions(actions => actions.routes.deleteRoute);
  const routesLoading = useStoreState(state => state.routes.loading);
  const routesTextSearch = useStoreState(state => state.routes.textSearch);
  const setRoutesTextSearch = useStoreActions(actions => actions.routes.setTextSearch);

  const paginationPage = useStoreState(state => state.routes.page);
  const paginationPageSize = useStoreState(state => state.routes.pageSize);
  const [dataLoading, setDataLoading] = React.useState(false);

  const canEdit = permissions?.admin?.routes?.edit;
  const canDelete = permissions?.admin?.routes?.delete;

  const t = useTranslation();

  storeMenuTitle(t('routes.pageTitle'));

  const onGetRoutes = (payload) => {
    setDataLoading(true);
    getRoutes(payload).then(() => {
      const routesState = store.getState().routes;
      if (!routesState.loading && !routesState.error) {
        // console.log(routesState);
        setDataLoading(false);
      } else {
        handleEndpointErrors(routesState, props, setSnackbar, t);
      }
    });
  };

  const getRouteTypes = () => {
    const types = {};
    // eslint-disable-next-line array-callback-return
    routeTypes.map(type => {
      types[type.id] = t(`routes.type.${ type.name.toLowerCase() }`);
    });
    return types;
  };

  const onDeleteRoute = (oldData, resolve, reject) => {
    deleteRoute(oldData.id).then(() => {
      const routesState = store.getState().routes;
      if (!routesState.error) {
        // console.log('success', routesState);
        resolve();
        const data = [...routesTableData].filter(route => route.id !== oldData.id);
        setRoutesTableData(data);
        setSnackbar({ show: true, autoHideDuration: SNACKBAR_TIME.SUCCESS, severity: 'success', message: t('global.success.saved') });
      } else {
        reject();
        handleEndpointErrors(routesState, props, setSnackbar, t);
      }
    });
  };

  const onAddRouteModalClose = () => {
    setAddRouteModalOpen(false);
  };

  const onViewQrCodeModalClose = () => {
    setViewQrCodeModalOpen(false);
  };

  React.useEffect(() => {
    onGetRoutes({ loading: true });

    return () => setRoutesTextSearch('');
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    setRoutesTableData(routes.map(route => ({ ...route })));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [routes]);

  const routesTableColumns = [
    { title: t('routes.table.id'), field: 'id', type: 'numeric', align: 'left', defaultSort: 'asc', editable: 'never' },
    { title: t('routes.name'), field: 'name', editable: 'never' },
    { title: t('routes.direction.label'), field: 'direction', lookup: getRouteDirections(routeDirections, t), sorting: false, editable: 'never' },
    { title: t('routes.type.label'), field: 'routeType.id', lookup: getRouteTypes(routeTypes, t), sorting: false, editable: 'never' },
    { title: t('routes.km'), field: 'km', sorting: false, editable: 'never' },
    { title: t('routes.eta'), field: 'eta', sorting: false, editable: 'never' },
    { title: t('routes.order'), field: 'displayOrder', editable: 'never' },
    { title: t('routes.active'), field: 'active', type: 'boolean', sorting: false, editable: 'never' },
  ];

  return (
    <div className={ classes.root }>
      <Breadcrumbs links={[
        { name: t('home.pageTitle'), path: '/', icon: <HomeIcon /> },
        { name: t('routes.pageTitle'), icon: <DirectionsIcon /> },
      ]} />
      <DataFilters
        setActiveFilters={ setActiveFilters }
        filters={[
          {
            type: 'text',
            name: t('routes.searchPlaceholder'),
            property: 'textSearch',
            active: routesTextSearch,
            width: '250px',
            onChangeFn: (filters) => {
              onGetRoutes({ page: 1, ...filters });
            }
          },
          {
            type: 'select',
            name: t('routes.active'),
            property: 'active',
            options: [{label: t('dataFilters.yes'), value: 'true'}, {label: t('dataFilters.no'), value: 'false'}],
            active: '',
            onChangeFn: (filters) => {
              onGetRoutes({ page: 1, ...filters });
            }
          },
          {
            type: 'select',
            name: t('routes.direction.label'),
            property: 'direction',
            options: [{label: t('routes.direction.in'), value: 'in'}, {label: t('routes.direction.out'), value: 'out'}],
            active: '',
            onChangeFn: (filters) => {
              onGetRoutes({ page: 1, ...filters });
            }
          }
        ]}
      />
      { routesLoading ? <LoadingCard length={ 1 } height={ 430 } /> : <React.Fragment>
        <Table
          className={ classes.table }
          title=""
          columns={ routesTableColumns }
          data={ routesTableData }
          exportButton
          exportFileName={ t('routes.pageTitle') }
          search={ false }
          customPagination={{
            page: paginationPage,
            size: paginationPageSize,
            responseLength: routes.length,
            loading: dataLoading,
            clickEvent: page => {
              onGetRoutes({ page, ...activeFilters });
            }
          }}
          deleteEvent={ canDelete ? onDeleteRoute : undefined }
          addActions={[
            ...canEdit ? [{
              icon: 'add',
              iconProps: {
                color: 'primary',
              },
              tooltip: t('routes.table.actionAdd'),
              isFreeAction: true,
              onClick: () => {
                setAddRouteModalOpen(true);
              }
            }] : [],
            {
              icon: canEdit ? 'edit' : () => <VisibilityIcon />,
              tooltip: canEdit ? t('routes.table.actionEdit') : t('routes.table.actionView'),
              onClick: (event, rowData) => {
                openLink(event, history,`/routes/${ rowData.id }`);
              }
            },
            rowData => ({
              icon: 'qr_code',
              tooltip: t('global.table.custom.actionViewQrCode'),
              disabled: !rowData.token,
              onClick: () => {
                setEditRoute(rowData);
                setViewQrCodeModalOpen(true);
              }
            }),
          ]}
        />
      </React.Fragment> }

      <CustomDialog open={ addRouteModalOpen } onClose={ onAddRouteModalClose } fullWidth={ true } maxWidth="md">
        <AddRoute
          onAddRouteModalClose={ onAddRouteModalClose }
        />
      </CustomDialog>

      <CustomDialog className={ classes.modal } open={ viewQrCodeModalOpen } onClose={ onViewQrCodeModalClose } fullWidth={ true } maxWidth="sm">
        <ViewQrCode
          selectedItem={{ id: editRoute.token, name: editRoute.name, type: 'route' }}
          onViewQrCodeModalClose={ onViewQrCodeModalClose }
        />
      </CustomDialog>
    </div>
  );
}

export default withRouter(Routes);
