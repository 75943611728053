import React from 'react';

import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

import DateFnsUtils from '@date-io/date-fns';
import enLocale from 'date-fns/locale/en-US';
import esLocale from 'date-fns/locale/es';

import { useTranslation } from 'react-multi-lang';

import { transformDateString } from '../shared/utilities';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    alignItems: 'center',
    display: 'flex',
    flexWrap: 'wrap',
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(4),
  },
  input: {
    marginBottom: theme.spacing(2),
    marginRight: theme.spacing(2),
    minWidth: '170px',
    '& .MuiOutlinedInput-notchedOutline': {
      borderRadius: '12px',
      borderWidth: '2px',
    },
    '&:last-child': {
      marginRight: 0,
    },
  },
  submit: {
    marginBottom: theme.spacing(2),
  },
  manualTriggerText: {
    marginBottom: theme.spacing(4),
  },
}));

const DateFilters = (props) => {
  const { onChangeFn, manualTrigger, localStorageName, isTripsFilter, isDashboardFilter, isReportsPivot, defaultDateRange } = props;
  const classes = useStyles();

  const t = useTranslation();

  const today = transformDateString( new Date() );
  const currentMonthIndex = today.getMonth();
  const previousMonthIndex = (currentMonthIndex - 1 + 12) % 12;
  const dateRanges = [
    ...(isTripsFilter ? [{ 'name': t('global.dateFilters.none'), 'value': 'none' }] : []),
    ...((isDashboardFilter || isTripsFilter) ? [{ 'name': t('global.dateFilters.today'), 'value': 'today' }] : []),
    ...(isTripsFilter ? [{ 'name': t('global.dateFilters.next7Days'), 'value': 'next7Days' }] : []),
    ...(isTripsFilter ? [{ 'name': t('global.dateFilters.next15Days'), 'value': 'next15Days' }] : []),
    { 'name': t('global.dateFilters.last7Days'), 'value': 'last7Days' },
    ...((!isDashboardFilter && !isReportsPivot) ? [{ 'name': t('global.dateFilters.last30Days'), 'value': 'last30Days' }] : []),
    ...(!isDashboardFilter && !isTripsFilter && !isReportsPivot ? [{ 'name': t('global.dateFilters.last3Months'), 'value': 'last3Months' }] : []),
    ...((isDashboardFilter || isReportsPivot) ? [{ 'name': t(`global.fullMonths.${ currentMonthIndex }`), 'value': 'currentMonth' }] : []),
    ...((isDashboardFilter || isReportsPivot) ? [{ 'name': t(`global.fullMonths.${ previousMonthIndex }`), 'value': 'objPreviousMonth' }] : []),
    { 'name': t('global.dateFilters.custom'), 'value': 'custom' }
  ];
  const dateRangeValues = {
    ...( (isDashboardFilter || isTripsFilter) && { today: transformDateString( new Date() ) }),
    ...( isTripsFilter && { next7Days: transformDateString( new Date( new Date().setDate(new Date().getDate() + 6) )) }),
    ...( isTripsFilter && { next15Days: transformDateString( new Date( new Date().setDate(new Date().getDate() + 14) )) }),
    last7Days: transformDateString( new Date( new Date().setDate(new Date().getDate() - 6) )),
    ...( (!isDashboardFilter && !isReportsPivot) && { last30Days: transformDateString( new Date( new Date().setDate(new Date().getDate() - 29) )) }),
    ...( (isDashboardFilter || isReportsPivot) && { currentMonth: transformDateString( new Date(new Date().getFullYear(), new Date().getMonth(), 1)) }),
    ...( (isDashboardFilter || isReportsPivot) && { objPreviousMonth: {
      start: transformDateString( new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1)),
      end: transformDateString( new Date(new Date().getFullYear(), new Date().getMonth(), 0))
    } }),
    ...( !isDashboardFilter && !isTripsFilter && !isReportsPivot && { last3Months: transformDateString( new Date( new Date().setMonth(new Date().getMonth() - 3) )) }),
  };

  const [dateRange, setDateRange] = React.useState(localStorageName && localStorage.getItem(localStorageName) ? localStorage.getItem(localStorageName) : defaultDateRange ? defaultDateRange : dateRanges[0].value);
  const tripsFilterValue = JSON.parse(localStorage.getItem('filters'))?.date[0];
  const isObjRange = !!dateRangeValues[dateRange]?.start;
  const [startDate, setStartDate] = React.useState( isTripsFilter ? tripsFilterValue ? transformDateString(tripsFilterValue.startDate) : null : isObjRange ? dateRangeValues[dateRange].start : dateRangeValues[dateRange] );
  const [endDate, setEndDate] = React.useState( isTripsFilter ? tripsFilterValue ? transformDateString(tripsFilterValue.endDate) : null : isObjRange ? dateRangeValues[dateRange].end : today );
  const [showTriggerText, setShowTriggerText] = React.useState(manualTrigger);

  const checkIfMatchRange = (start, end) => {
    if ( today.getTime() === end?.getTime() ) {
      setDateRange('custom');
      // eslint-disable-next-line array-callback-return
      Object.keys( dateRangeValues ).map(range => {
        if ( !range.startsWith('obj') && start?.getTime() === dateRangeValues[range].getTime() ) {
          setDateRange(range);
        }
      })
    } else if ( today.getTime() === start?.getTime() ) {
      setDateRange('custom');
      // eslint-disable-next-line array-callback-return
      Object.keys( dateRangeValues ).map(range => {
        if ( !range.startsWith('obj') && end?.getTime() === dateRangeValues[range].getTime() ) {
          setDateRange(range);
        }
      })
    } else if (start && end) {
      setDateRange('custom');
      // eslint-disable-next-line array-callback-return
      Object.keys( dateRangeValues ).map(range => {
        if ( range.startsWith('obj') && start?.getTime() === dateRangeValues[range].start.getTime() && end?.getTime() === dateRangeValues[range].end.getTime() ) {
          setDateRange(range);
        }
      })
    }
  };

  const checkIfOtherDateIsNull = (date, newDate) => {
    const value = date === 'startDate' ? startDate : endDate;
    const setValue = date === 'startDate' ? setStartDate : setEndDate;

    if ( value === null  ) {
      setValue(newDate);
    }
  };

  const onDateRangeChange = (event) => {
    const range = event.target.value;
    const isNone = range === 'none';
    setDateRange(range);
    localStorageName && localStorage.setItem(localStorageName, range);
    if ( range !== 'custom' ) {
      if ( range.startsWith('next') ) {
        setStartDate(isNone ? null : today);
        setEndDate(isNone ? null : dateRangeValues[range]);
      } else if ( range.startsWith('obj') ) {
        setStartDate(isNone ? null : dateRangeValues[range].start);
        setEndDate(isNone ? null : dateRangeValues[range].end);
      } else {
        setStartDate(isNone ? null : dateRangeValues[range]);
        setEndDate(isNone ? null : today);
      }
    }
  };

  const onStartDateChange = (date) => {
    const newDate = transformDateString( new Date(date) );
    setDateRange('custom');
    setStartDate(newDate);
    checkIfOtherDateIsNull('endDate', newDate);
  };

  const onEndDateChange = (date) => {
    const newDate = transformDateString( new Date(date) );
    setDateRange('custom');
    setEndDate(newDate);
    checkIfOtherDateIsNull('startDate', newDate);
  };

  const onChange = () => {
    setShowTriggerText(false);
    checkIfMatchRange(startDate, endDate);
    onChangeFn({ page: 1, startDate, endDate });
  };

  React.useEffect(() => {
    if ( !manualTrigger ) {
      onChange();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate]);

  return <React.Fragment>
    <div className={ classes.root }>
      <FormControl variant="outlined" className={ classes.input }>
        <InputLabel>{ t('global.dateFilters.dateRange') }</InputLabel>
        <Select
          value={ dateRange }
          onChange={ onDateRangeChange }
          label={ t('global.dateFilters.dateRange') }
        >
          { dateRanges.map(range => <MenuItem key={ range.value } value={ range.value } disabled={ range.value === 'custom' }>{ range.name }</MenuItem>) }
        </Select>
      </FormControl>

      <MuiPickersUtilsProvider utils={ DateFnsUtils } locale={ localStorage.getItem('language') === 'en' ? enLocale : esLocale }>
        <DatePicker
          className={ classes.input }
          inputVariant="outlined"
          format="dd/MM/yyyy"
          // disableFuture
          label={ t('global.dateFilters.startDate') }
          okLabel={ t('global.modal.btnOk') }
          cancelLabel={ t('global.modal.btnCancel') }
          value={ startDate }
          onChange={ onStartDateChange }
        />

        <DatePicker
          className={ classes.input }
          inputVariant="outlined"
          format="dd/MM/yyyy"
          // disableFuture
          label={ t('global.dateFilters.endDate') }
          okLabel={ t('global.modal.btnOk') }
          cancelLabel={ t('global.modal.btnCancel') }
          value={ endDate }
          onChange={ onEndDateChange }
        />
      </MuiPickersUtilsProvider>

      { manualTrigger && (
        <Button className={ classes.submit } color="primary" onClick={ onChange }>{ t('global.dateFilters.submit') }</Button>
      ) }
    </div>
    { showTriggerText && (
      <Typography className={ classes.manualTriggerText } color="textPrimary">{ t('global.dateFilters.manualTriggerText') }</Typography>
    ) }
  </React.Fragment>;
}

DateFilters.propTypes = {
  onChangeFn: PropTypes.func.isRequired,
  manualTrigger: PropTypes.bool,
  localStorageName: PropTypes.string,
  isTripsFilter: PropTypes.bool,
  isDashboardFilter: PropTypes.bool,
  isReportsPivot: PropTypes.bool,
  defaultDateRange: PropTypes.string
};

export default DateFilters;
