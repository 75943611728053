import React from 'react';

import PropTypes from 'prop-types';

import { makeStyles, withTheme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import QRCode from 'qrcode.react';

import { useTranslation } from 'react-multi-lang';

import { IS_STAGE } from '../shared/utilities';

const useStyles = makeStyles(theme => ({
  root: {
    textAlign: 'center',
  },
  qr: {
    marginTop: theme.spacing(2),
    '& canvas': {
      border: `2px solid ${ theme.palette.divider }`,
      borderRadius: '8px',
      [theme.breakpoints.down('sm')]: {
        height: '250px !important',
        width: '250px !important',
      },
    },
  }
}));

const QrCodeModal = (props) => {
  const { selectedItem, onViewQrCodeModalClose } = props;
  const classes = useStyles();

  const t = useTranslation();

  const getQrValue = (type) => {
    switch(type) {
      case 'user':
        if ( selectedItem.expirationDate ) {
          return JSON.stringify({ moveOnPassengerExpirationDate: selectedItem.expirationDate });
        } else {
          return JSON.stringify({ moveOnPassengerId: selectedItem.id });
        }
      case 'vehicle':
        return  JSON.stringify({ moveOnVehicleId: selectedItem.id });
      case 'route':
        const lapsUrl = JSON.parse(localStorage.getItem('enums'))?.urls?.laps;
        if (process.env.NODE_ENV === 'production' && !IS_STAGE && lapsUrl) {
          return  `${lapsUrl}/?token=${selectedItem.id}`;
        } else {
          return  `${process.env.REACT_APP_LAPS_URL}/?token=${selectedItem.id}`;
        }
      default:
        return ''
    }
  };

  return (
    <div className={ classes.root }>
      <DialogTitle>{ selectedItem.name }</DialogTitle>
      <DialogContent>
        <div className={ classes.qr }>
          <QRCode
            value={ getQrValue(selectedItem.type) }
            size={ 300 }
            includeMargin
          />
        </div>
        {selectedItem.type === 'route' && <Button href={getQrValue(selectedItem.type)} target="_blank" color="primary">{ t('routes.openLaps') }</Button>}
      </DialogContent>
      <DialogActions>
        <Button onClick={ onViewQrCodeModalClose } color="primary">{ t('global.modal.btnClose') }</Button>
      </DialogActions>
    </div>
  );
}

QrCodeModal.propTypes = {
  selectedItem: PropTypes.exact({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    name: PropTypes.string.isRequired,
    expirationDate: PropTypes.string,
    type: PropTypes.oneOf(['user', 'vehicle', 'route']).isRequired
  }).isRequired,
  onViewQrCodeModalClose: PropTypes.func.isRequired,
};

export default withTheme(QrCodeModal);
