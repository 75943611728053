import React from 'react';

import PropTypes from 'prop-types';

import { useStoreState, useStoreActions } from 'easy-peasy';

import { makeStyles } from '@material-ui/core/styles';
import AlarmIcon from '@material-ui/icons/Alarm';
import Avatar from '@material-ui/core/Avatar';
import ClearAllIcon from '@material-ui/icons/ClearAll';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import ScheduleIcon from '@material-ui/icons/Schedule';
import WarningIcon from '@material-ui/icons/Warning';

import { useTranslation } from 'react-multi-lang';

const useStyles = makeStyles(theme => ({
  root: {
  },
  avatar: {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.main,
  },
  iconDescription: {
    display: 'block',
    marginTop: theme.spacing(.5),
    '& .MuiSvgIcon-root': {
      fontSize: '20px',
      marginRight: theme.spacing(.5),
      verticalAlign: 'middle',
    }
  },
}));

const SidebarHeatMapMoreInformationIconsModal = (props) => {
  const { onMoreInformationIconsModalClose } = props;
  const classes = useStyles(props);

  const heatMapIconsSelected = useStoreState(state => state.trips.heatMapIconsSelected);
  const setHeatMapIconsSelected = useStoreActions(actions => actions.trips.setHeatMapIconsSelected);

  const icons = [
    {
      name: 'hidden',
      element: ClearAllIcon
    },
    {
      name: 'overCapacity',
      element: PersonAddIcon
    },
    {
      name: 'time',
      element: ScheduleIcon,
      icons: [ScheduleIcon, AlarmIcon, WarningIcon]
    }
  ];

  const handleListItemClick = (icon) => {
    setHeatMapIconsSelected(icon);
    onMoreInformationIconsModalClose();
  };

  const t = useTranslation();

  return <div className={ classes.root }>
    <React.Fragment>
      <DialogTitle>{ t('trips.moreInformationIcons.title') }</DialogTitle>
      <DialogContent>
        <List>
          {icons.map((icon) => (
            <ListItem
              key={ icon.name }
              button
              selected={ icon.name === heatMapIconsSelected }
              onClick={ () => handleListItemClick(icon.name) }
            >
              <ListItemAvatar>
                <Avatar className={classes.avatar}>
                  <icon.element />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={ t(`trips.moreInformationIcons.${ icon.name }.title`) }
                secondary={
                  <React.Fragment>
                    { t(`trips.moreInformationIcons.${ icon.name }.description`) }<br />
                    { icon.icons?.length &&
                      JSON.parse( t(`trips.moreInformationIcons.${ icon.name }.icons`) )?.map((text, index) => {
                        const IconElement = icon.icons[index];
                        return <span className={ classes.iconDescription } key={ text }>
                          <IconElement /> { text }<br />
                        </span>;
                      })
                    }
                  </React.Fragment>
                }
              />
            </ListItem>
          ))}
        </List>
      </DialogContent>
    </React.Fragment>
  </div>;
}

SidebarHeatMapMoreInformationIconsModal.propTypes = {
  onMoreInformationIconsModalClose: PropTypes.func.isRequired,
};

export default SidebarHeatMapMoreInformationIconsModal;
