import React from 'react';

import PropTypes from 'prop-types';

import { 
  useStoreState,
  // useStoreActions
} from 'easy-peasy';

import { makeStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import Button from '@material-ui/core/Button';
import FilterListIcon from '@material-ui/icons/FilterList';
// import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
// import InputLabel from '@material-ui/core/InputLabel';
// import MenuItem from '@material-ui/core/MenuItem';
// import Select from '@material-ui/core/Select';
import Tooltip from '@material-ui/core/Tooltip';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';

import Search from './Head/Search';
import AddTripModal from './Head/AddTripModal';
import Filter from './Head/Filter';
import MoreInformationIconsModal from './HeatMap/MoreInformationIconsModal';
import CustomDialog from '../../CustomDialog';

import { useTranslation } from 'react-multi-lang';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      maxHeight: 'calc(100vh - 148px)',
      overflow: 'auto',
    },
  },
  search: {
    marginBottom: theme.spacing(1.5),
    [theme.breakpoints.up('sm')]: {
      marginBottom: theme.spacing(3),
    },
  },
  grid: {
    marginBottom: theme.spacing(1),
    [theme.breakpoints.up('sm')]: {
      marginBottom: theme.spacing(2),
    },
  },
  filterBtn: {
    ...theme.typography.body2,
    textTransform: 'none',
  },
  addBtn: {
    border: `1px solid ${ theme.palette.primary.main }`,
    padding: '5px',
    '&.Mui-disabled': {
      borderColor: theme.palette.text.disabled,
    },
  },
  dialog: {
    '& .MuiDialog-paper': {
      maxWidth: '720px',
    },
  },
  moreInformationIcons: {
    marginLeft: theme.spacing(2),
    padding: '5px',
    '&.Mui-disabled': {
      borderColor: theme.palette.text.disabled,
    },
  },
  // showTrips: {
  //   margin: theme.spacing(2, 0),
  //   width: '100%',
  //   '& .MuiOutlinedInput-root': {
  //     borderRadius: theme.spacing(3),
  //   },
  //   '& .MuiOutlinedInput-input': {
  //     paddingBottom: theme.spacing(1.5),
  //     paddingTop: theme.spacing(1.5),
  //   },
  // },
}));

const SidebarHead = (props) => {
  const {  onGetTrips, showSearch } = props;
  const classes = useStyles();
  const [filterMenu, setFilterMenu] = React.useState(null);
  const [addTripModalOpen, setAddTripModalOpen] = React.useState(false);
  const [moreInformationIconsModalOpen, setMoreInformationIconsModalOpen] = React.useState(false);

  const tripsLoading = useStoreState(state => state.trips.loading);
  // const pageSize = useStoreState(state => state.trips.pageSize);
  // const pageSizeOptions = useStoreState(state => state.trips.pageSizeOptions);
  // const setPageSize = useStoreActions(actions => actions.trips.setPageSize);

  const heatMapIconsSelected = useStoreState(state => state.trips.heatMapIconsSelected);

  const onAddTripModalOpen = () => {
    setAddTripModalOpen(true);
  };

  const onAddTripModalClose = () => {
    setAddTripModalOpen(false);
  };

  const onFilterMenuOpen = (event) => {
    setFilterMenu(event.currentTarget);
  };

  const onFilterMenuClose = () => {
    setFilterMenu(null);
  };

  // const onPageSizeChange = (e) => {
  //   setPageSize(e.target.value);
  //   onGetTrips();
  // };

  const onMoreInformationIconsModalOpen = () => {
    setMoreInformationIconsModalOpen(true);
  };

  const onMoreInformationIconsModalClose = () => {
    setMoreInformationIconsModalOpen(false);
  };

  const t = useTranslation();

  return <React.Fragment>
    { showSearch && <Search
      className={ classes.search }
      placeholder={ t('trips.search') }
      onGetTrips={ onGetTrips }
    /> }

    <Grid className={ classes.grid } container alignItems='center'>
      <Grid container justify="flex-start" item xs={ 8 } alignItems='center'>
        <Button
          color="primary"
          className={ classes.filterBtn }
          startIcon={ <FilterListIcon /> }
          disabled={ tripsLoading }
          onClick={ onFilterMenuOpen }
        >{ t('trips.filter') }</Button>
        { localStorage.getItem('tripsSidebarTab') === '1' && <Tooltip title={ t('trips.moreInformationIcons.title') }><span><IconButton
          aria-label={ t('trips.moreInformationIcons.title') }
          className={ classes.moreInformationIcons }
          color="primary"
          disabled={ tripsLoading }
          onClick={ onMoreInformationIconsModalOpen }
        >
          { heatMapIconsSelected === 'hidden' ? <VisibilityOffIcon /> : <VisibilityIcon /> }
        </IconButton></span></Tooltip> }
      </Grid>
      <Grid container justify="flex-end" item xs={ 4 } alignItems='center'>
        <Tooltip title={ t('trips.addTrip') }><span><IconButton
          aria-label={ t('trips.addTrip') }
          className={ classes.addBtn }
          color="primary"
          disabled={ tripsLoading }
          onClick={ onAddTripModalOpen }
        >
          <AddIcon />
        </IconButton></span></Tooltip>
      </Grid>
    </Grid>

    <Filter
      filterMenu={ filterMenu }
      onFilterMenuClose={ onFilterMenuClose }
      onGetTrips={ onGetTrips }
    />

    {/* <FormControl variant="outlined" className={ classes.showTrips }>
      <InputLabel>{ t('trips.showTrips') }</InputLabel>
      <Select
        value={ pageSize }
        onChange={ onPageSizeChange }
        label={ t('trips.showTrips') }
        >
          { pageSizeOptions.map(option => <MenuItem key={ option } value={ option }>{ option }</MenuItem>) }
      </Select>
    </FormControl> */}

    <CustomDialog open={ moreInformationIconsModalOpen } onClose={ onMoreInformationIconsModalClose } fullWidth={ true }  maxWidth="xs">
      <MoreInformationIconsModal onMoreInformationIconsModalClose={ onMoreInformationIconsModalClose } />
    </CustomDialog>

    <CustomDialog className={ classes.dialog } open={ addTripModalOpen } onClose={ onAddTripModalClose } fullWidth={ true }>
      <AddTripModal
        onAddTripModalClose={ onAddTripModalClose }
        onGetTrips={ onGetTrips }
      />
    </CustomDialog>
  </React.Fragment>;
}

SidebarHead.propTypes = {
  onGetTrips: PropTypes.func.isRequired,
  showSearch: PropTypes.bool,
};

export default SidebarHead;
