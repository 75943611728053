import React from 'react';

import PropTypes from 'prop-types';

import { useStoreState } from 'easy-peasy';

import { makeStyles } from '@material-ui/core/styles';
import AlarmIcon from '@material-ui/icons/Alarm';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import ScheduleIcon from '@material-ui/icons/Schedule';
import WarningIcon from '@material-ui/icons/Warning';

import { compareDateWithNowInMinutes, todayDate } from '../../../../shared/utilities';

const useStyles = makeStyles(theme => ({
  root: {
    border: ({ active }) => active ? `2px solid ${ theme.palette.secondary.main }` : '2px solid transparent',
    borderRadius: theme.spacing(.5),
    cursor: 'pointer',
    height: '36px',
    width: '36px',
    '& button': {
      alignItems: 'center',
      backgroundColor: ({ status }) => theme.palette.status[status],
      border: 'none',
      borderRadius:({ active }) => active ? theme.spacing(.25) : theme.spacing(.5),
      cursor: 'pointer',
      display: 'flex',
      height: '100%',
      justifyContent: 'center',
      width: '100%',
    },
    '& .MuiSvgIcon-root': {
      color: theme.palette.background.paper,
      fontSize: '18px',
    },
  },
}));

const SidebarHeatMapTrip = (props) => {
  const { status, departureDate, reservationsCount, capacity } = props;
  const classes = useStyles(props);

  const heatMapIconsSelected = useStoreState(state => state.trips.heatMapIconsSelected);

  const isOverCapacity = heatMapIconsSelected === 'overCapacity';
  const isTime = heatMapIconsSelected === 'time';
  const isToday = departureDate.split('T')[0] === todayDate();
  const compare = compareDateWithNowInMinutes;
  const isReadyTimingTrip = status === 'ready' && compare(departureDate) > 10 && compare(departureDate) <= 30;
  const isReadyAlarmTrip = status === 'ready' && compare(departureDate) > 0 && compare(departureDate) <= 10;
  const isReadyWarningTrip = status === 'ready' && compare(departureDate) <= 0;

  return <div className={ classes.root }>
    <button>
      { isTime && isToday && isReadyTimingTrip && <ScheduleIcon /> }
      { isTime && isToday && isReadyAlarmTrip && <AlarmIcon /> }
      { isTime && isToday && isReadyWarningTrip && <WarningIcon /> }
      { isOverCapacity && reservationsCount > capacity && <PersonAddIcon /> }
    </button>
  </div>;
}

SidebarHeatMapTrip.propTypes = {
  active: PropTypes.bool.isRequired,
  status: PropTypes.string.isRequired,
  departureDate: PropTypes.string.isRequired,
  reservationsCount: PropTypes.number.isRequired,
  capacity: PropTypes.number.isRequired,
};

export default React.memo(SidebarHeatMapTrip, arePropsEqual);

function arePropsEqual(_, newProps) {
  const isToday = newProps.departureDate.split('T')[0] === todayDate();
  const isReady = newProps.status === 'ready';
  return isToday && isReady ? false : true;
}
