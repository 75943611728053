import { createMuiTheme } from '@material-ui/core/styles';
import { enUS, esES } from '@material-ui/core/locale';

const language = localStorage.getItem('language') === 'en' ? enUS : esES;

export const lightTheme = createMuiTheme({
  palette: {
    type: 'light',
    primary: {
      light: '#ECECEC',
      main: '#3B3B3B',
      dark: '#000000',
      contrastText:  '#FFFFFF',
    },
    secondary: {
      light: '#FFBC95',
      main: '#E90C0C',
      dark: '#770000',
      overlay: '#FCE1E1',
      contrastText:  '#FFFFFF',
    },
    warning: {
      light: '#FFF176',
      main: '#F9A825',
      dark: '#F57F17',
    },
    error: {
      light: '#E57373',
      main: '#D32F2F',
      dark: '#B71C1C',
    },
    status: {
      pending: '#8E24AA',
      approved: '#2E7D32',
      onboard: '#795548',
      underway: '#1976D2',
      finished: '#000000',
      cancelled: '#D50000',
      confirmed: '#009688',
      ready: '#2E7D32',
      started: '#1976D2',
      incident: '#795548',
      delayed: '#BF360C',
      completed: '#000000',
    },
    background: {
      paper: '#FFFFFF',
      default: '#FFFFFF',
    },
    menuIllustration: '#ECECEC'
  },
}, language);

export const darkTheme = createMuiTheme({
  palette: {
    type: 'dark',
    primary: {
      light: '#FAFAFA',
      main: '#BDBDBD',
      dark: '#616161',
      contrastText:  '#3A3A46',
    },
    secondary: {
      light: '#FFBC95',
      main: '#E90C0C',
      dark: '#AE0000',
      overlay: '#4F353E',
      contrastText:  '#FFFFFF',
    },
    warning: {
      light: '#FFF59D',
      main: '#FDD835',
      dark: '#F9A825',
    },
    error: {
      light: '#FFEBEE',
      main: '#EF9A9A',
      dark: '#EF5350',
    },
    status: {
      pending: '#EA80FC',
      approved: '#81C784',
      onboard: '#BCAAA4',
      underway: '#448AFF',
      finished: '#FFFFFF',
      cancelled: '#CF6679',
      confirmed: '#009688',
      ready: '#81C784',
      started: '#448AFF',
      incident: '#BCAAA4',
      delayed: '#FF9100',
      completed: '#FFFFFF',
    },
    background: {
      paper: '#2D2D35',
      default: '#3A3A46',
    },
    menuIllustration: '#2D2D35'
  },
}, language);
