import React from 'react';

import PropTypes from 'prop-types';

import { useStoreState, useStoreActions, useStore } from 'easy-peasy';

import { makeStyles } from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';

import { handleEndpointErrors } from '../../shared/utilities';

import LoadingCard from '../LoadingCard';
import Table from '../Table';

import { useTranslation } from 'react-multi-lang';

const useStyles = makeStyles(theme => ({
  table: {
    '& .MuiTableCell-head:nth-child(2)': {
      minWidth: '250px',
    },
  }
}));

const Places = (props) => {
  const { userId } = props;
  const classes = useStyles();
  const [placesTableData, setPlacesTableData] = React.useState([]);

  const store = useStore();

  const setSnackbar = useStoreActions(actions => actions.global.setSnackbar);

  const getUserPlaces = useStoreActions(actions => actions.places.getUserPlaces);
  // const deleteUserPlace = useStoreActions(actions => actions.places.deleteUserPlace);
  const placesLoading = useStoreState(state => state.places.loading);

  const t = useTranslation();

  const placesTableColumns = [
    { title: t('user.places.id'), field: 'id', type: 'numeric', align: 'left', defaultSort: 'asc', editable: 'never' },
    { title: t('user.places.description'), field: 'description' },
    { title: t('user.places.province'), field: 'province.name', editable: 'never' },
    { title: t('user.places.county'), field: 'county.name', editable: 'never' },
    {
      title: t('user.places.latitude'), field: 'latitude',
      render: rowData => <Link target="_blank" rel="noopener" href={`https://www.google.com/maps/@${ rowData.latitude },${ rowData.longitude },15z`}>{ rowData.latitude }</Link>,
      type: 'numeric', sorting: false, editable: 'never'
    },
    {
      title: t('user.places.longitude'), field: 'longitude',
      render: rowData => <Link target="_blank" rel="noopener" href={`https://www.google.com/maps/@${ rowData.latitude },${ rowData.longitude },15z`}>{ rowData.longitude }</Link>,
      type: 'numeric', sorting: false, editable: 'never'
    },
    { title: t('user.places.active'), field: 'active', type: 'boolean', sorting: false, editable: 'never' },
  ];

  React.useEffect(() => {
    getUserPlaces(userId).then(() => {
      const placesState = store.getState().places;
      if (!placesState.loading && !placesState.error) {
        setPlacesTableData(placesState.userPlaces.map(place => ({ ...place })));
      } else {
        handleEndpointErrors(placesState, props, setSnackbar, t);
      }
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const onDeleteUserPlace = (oldData, resolve, reject) => {
  //   deleteUserPlace(oldData.id).then(() => {
  //     const placesState = store.getState().places;
  //     if (!placesState.error) {
  //       // console.log('success', placesState);
  //       resolve();
  //       const data = [...placesTableData].filter(place => place.id !== oldData.id);
  //       setPlacesTableData(data);
  //       setSnackbar({ show: true, autoHideDuration: SNACKBAR_TIME.SUCCESS, severity: 'success', message: t('global.success.saved') });
  //     } else {
  //       reject();
  //       handleEndpointErrors(placesState, props, setSnackbar, t);
  //     }
  //   });
  // };

  return (
    placesLoading ? <LoadingCard length={ 1 } height={ 725 } /> : (
      <Table
        className={ classes.table }
        title=""
        columns={ placesTableColumns }
        data={ placesTableData }
        paging={ false }
        // deleteEvent={ onDeleteUserPlace }
      />
    )
  );
}

Places.propTypes = {
  userId: PropTypes.number.isRequired,
};

export default Places;
