import React from 'react';

import PropTypes from 'prop-types';

import { useStoreState, useStoreActions } from 'easy-peasy';

import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

import { useTranslation } from 'react-multi-lang';

const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiFormControl-root': {
      marginBottom: theme.spacing(2),
      minWidth: '118px',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderRadius: '12px',
      borderWidth: '2px',
    },
  },
}));

const RefreshTimer = (props) => {
  const { changeFunction, clearTimer } = props;
  const classes = useStyles();

  const dashboardRefreshTime = useStoreState(state => state.global.dashboardRefreshTime);
  const setDashboardRefreshTime = useStoreActions(actions => actions.global.setDashboardRefreshTime);

  const [timer, setTimer] = React.useState(dashboardRefreshTime * 60000);

  const t = useTranslation();

  const handleChange = event => {
    setTimer(event.target.value * 60000);
    setDashboardRefreshTime(event.target.value);
  };

  React.useEffect(() => {
    const timerInterval = setInterval(() => {
      changeFunction();
    }, timer);

    return () => {
      clearInterval(timerInterval);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timer, clearTimer]);

  return (
    <div className={ classes.root }>
      <FormControl variant="outlined">
        <InputLabel>{ t('dashboard.refresh') }</InputLabel>
        <Select
          value={ dashboardRefreshTime }
          label={ t('dashboard.refresh') }
          onChange={ handleChange }
        >
          <MenuItem value={ 1 }>1</MenuItem>
          <MenuItem value={ 3 }>3</MenuItem>
          <MenuItem value={ 5 }>5</MenuItem>
          <MenuItem value={ 30 }>30</MenuItem>
          <MenuItem value={ 60 }>60</MenuItem>
        </Select>
      </FormControl>
    </div>
  );
}

RefreshTimer.propTypes = {
  changeFunction: PropTypes.func.isRequired,
  clearTimer: PropTypes.number.isRequired,
};

export default RefreshTimer;
