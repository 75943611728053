import axios from 'axios';

import { logSentryEndpointError } from '../../shared/utilities';

export const addVehicle = (actions, payload) => axios({
  method: 'post',
  url: `${ process.env.REACT_APP_API_URL }/api/v1/vehicles`,
  headers: {
    'auth-token': localStorage.getItem('token'),
    // 'Access-Control-Allow-Origin': '*',
    // 'Content-Type': 'application/json'
  },
  data: payload
})
.then(response => {
})
.catch(error => {
  actions.setError(error.response);
  logSentryEndpointError(error);
});

export const addVehicleDriver = (actions, payload) => axios({
  method: 'post',
  url: `${ process.env.REACT_APP_API_URL }/api/admin/v1/vehicle_drivers`,
  headers: {
    'auth-token': localStorage.getItem('token'),
    // 'Access-Control-Allow-Origin': '*',
    // 'Content-Type': 'application/json'
  },
  data: payload
})
.then(response => {
})
.catch(error => {
  actions.setError(error.response);
  logSentryEndpointError(error);
});
