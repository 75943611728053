import React from 'react';

import PropTypes from 'prop-types';

// import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';

import { useTranslation } from 'react-multi-lang';

// const useStyles = makeStyles(theme => ({
//   root: {}
// }));

const UpdateReservationModal = (props) => {
  const { reservation, updatedReservation, onUpdateReservation, onUpdateReservationModalClose } = props;
  // const classes = useStyles();

  const [isLoading, setIsLoading] = React.useState(false);

  const t = useTranslation();

  const updateReservation = () => {
    setIsLoading(true);
    onUpdateReservation(reservation.id, updatedReservation.data);
  };

  return (
    <React.Fragment>
      <DialogTitle>{ t('reservation.updateModal.title') }</DialogTitle>
        <DialogContent>
          <Typography gutterBottom color="textSecondary">{ t('reservation.updateModal.text') }</Typography>
          <Typography gutterBottom color="textSecondary"><strong>{ t('reservation.updateModal.route') }</strong>: { reservation.route.name } { t('reservation.updateModal.to') } { updatedReservation.routeName }</Typography>
          <Typography gutterBottom color="textSecondary"><strong>{ t('reservation.updateModal.trip') }</strong>: { reservation.tripId } { t('reservation.updateModal.to') } { updatedReservation.tripId }</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={ onUpdateReservationModalClose } color="primary" disabled={ isLoading }>{ t('global.modal.btnCancel') }</Button>
          <Button onClick={ updateReservation } color="primary" disabled={ isLoading }>{ t('global.modal.btnOk') }</Button>
        </DialogActions>
    </React.Fragment>
  );
}

UpdateReservationModal.propTypes = {
  reservation: PropTypes.object.isRequired,
  updatedReservation: PropTypes.object.isRequired,
  onUpdateReservation: PropTypes.func.isRequired,
  onUpdateReservationModalClose: PropTypes.func.isRequired,
};

export default UpdateReservationModal;
