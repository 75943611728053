import React from 'react';

import PropTypes from 'prop-types';

import clsx from 'clsx';

import { useStoreState, useStoreActions, useStore } from 'easy-peasy';

import { makeStyles } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

import * as Yup from 'yup';

import { Formik, Field } from 'formik';
import { TextField } from 'formik-material-ui';
import { DatePicker } from 'formik-material-ui-pickers';

import DateFnsUtils from '@date-io/date-fns';
import enLocale from 'date-fns/locale/en-US';
import esLocale from 'date-fns/locale/es';

import { handleEndpointErrors, SNACKBAR_TIME } from '../../shared/utilities';

import CustomFormikForm from '../CustomFormikForm';
import FormikCheckbox from '../FormikCheckbox';
import FormikAutocomplete from '../FormikAutocomplete';

import { useTranslation } from 'react-multi-lang';

const useStyles = makeStyles(theme => ({
  userActive: {
    right: theme.spacing(.5),
  },
  userTourist: {
    right: '100px',
  },
  username: {
    '& input': {
      textTransform: 'lowercase',
    },
  },
}));

const BasicInformation = (props) => {
  const { user } = props;
  const classes = useStyles();

  const store = useStore();

  const bloodTypes = useStoreState(state => state.global.bloodTypes);
  const roles = useStoreState(state => state.global.roles);
  const clients = useStoreState(state => state.global.clients);
  const clientSelected = useStoreState(state => state.global.clientSelected);
  const setSnackbar = useStoreActions(actions => actions.global.setSnackbar);

  const organizationTypes = useStoreState(state => state.global.organizationTypes);
  const isTourismClient = localStorage.getItem('organizationType') && localStorage.getItem('organizationType') === organizationTypes[2];
  const isValidTourist = user.expirationDate !== null && user.expirationDate !== undefined;
  const [isTourist, setIsTourist] = React.useState(isValidTourist);

  const functionalAreas = useStoreState(state => state.users.functionalAreas);
  const getFunctionalAreas = useStoreActions(actions => actions.users.getFunctionalAreas);
  const updateUser = useStoreActions(actions => actions.users.updateUser);

  const formRef = React.useRef();

  const t = useTranslation();

  React.useEffect(() => {
    getFunctionalAreas().then(() => {
      const usersState = store.getState().users;
      if (!usersState.loading && !usersState.error) {
        // console.log(usersState);
      } else {
        handleEndpointErrors(usersState, props, setSnackbar, t);
      }
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (functionalAreas.length && user.functionalAreaId) {
      const setFieldValue = formRef.current.setFieldValue;
      setFieldValue('functionalArea', functionalAreas.find(fa => fa.id === user.functionalAreaId));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [functionalAreas]);

  return (
    <Formik
      innerRef={ formRef }
      initialValues={{
        ...user,
        tourist: isTourist,
        expirationDate: isValidTourist ? new Date(user.expirationDate.split('T')[0].replace(/-/g, '/')) : new Date(),
        client: clients.find(client => client.id === user.clientUser?.clientId) || '',
        functionalArea: ''
      }}
      validationSchema={ Yup.object({
        name: Yup.string()
          .max(50, t('global.errors.max', { field: t('users.name'), length: 50 }))
          .required(t('global.errors.required', { field: t('users.name') })),
        identifier: Yup.string()
          .nullable()
          .max(35, t('global.errors.max', { field: t('users.identifier'), length: 35 })),
          // .required(t('global.errors.required', { field: t('users.identifier') })),
        username: Yup.string()
          .max(15, t('global.errors.max', { field: t('users.username'), length: 15 }))
          .required(t('global.errors.required', { field: t('users.username') })),
        phoneNumber: Yup.string()
          .nullable()
          .max(30, t('global.errors.max', { field: t('users.phoneNumber'), length: 30 })),
          // .required(t('global.errors.required', { field: t('users.phoneNumber') })),
      }) }
      onSubmit={(values, { setSubmitting }) => {
        const data = {
          active: values.active,
          name: values.name,
          ...(!!values.identifier && { identifier: values.identifier }),
          username: values.username.toLowerCase(),
          phoneNumber: values.phoneNumber,
          bloodType: values.bloodType,
          role: values.role,
          ...(values.client && values.client.id !== 'all' && { clientId: +values.client.id }),
          ...(values.functionalArea && values.functionalArea.id && { functionalAreaId: +values.functionalArea.id }),
          expirationDate: isTourist ? values.expirationDate.toLocaleDateString('en') : null
        };
        updateUser({ userId: values.id, data }).then(() => {
          const usersState = store.getState().users;
          if (!usersState.loading && !usersState.error) {
            setSubmitting(false);
            setSnackbar({ show: true, autoHideDuration: SNACKBAR_TIME.SUCCESS, severity: 'success', message: t('global.success.saved') });
          } else {
            handleEndpointErrors(usersState, props, setSnackbar, t);
          }
        });
      }}
    >
      {({ submitForm, isSubmitting, values, setFieldValue }) => (
        <CustomFormikForm className={ classes.form }>
          <Field
            component={ FormikCheckbox }
            className={clsx( 'formik-checkbox', classes.userActive )}
            name="active"
            label={ t('user.basicInformation.active') }
            disabled={ isSubmitting }
          />
          { isTourismClient && <Field
            component={ FormikCheckbox }
            className={clsx( 'formik-checkbox', classes.userTourist )}
            name="tourist"
            label={ t('users.tourist') }
            disabled={ isSubmitting }
            onChange={() => {
              setIsTourist(!isTourist);
              setTimeout(() => formRef.current.resetForm());
            }}
          /> }
          <Grid container spacing={ 3 }>
            <Grid item xs={ 6 } md={ 4 }>
              <Field
                component={ TextField }
                name="name"
                type="text"
                label={ t('users.name') }
                variant="outlined"
                fullWidth
              />
            </Grid>
            { isTourist && <Grid item xs={ 6 } md={ 4 }>
              <MuiPickersUtilsProvider utils={ DateFnsUtils } locale={ localStorage.getItem('language') === 'en' ? enLocale : esLocale }>
                <Field
                  component={ DatePicker }
                  name="expirationDate"
                  label={ t('users.expirationDate') }
                  inputVariant="outlined"
                  fullWidth
                  autoOk
                  format="MM/dd/yyyy"
                  minDate={ new Date() }
                />
              </MuiPickersUtilsProvider>
            </Grid> }
            <Grid item xs={ 6 } md={ 4 }>
              <Field
                component={ TextField }
                name="identifier"
                type="text"
                label={ t('users.identifier') }
                variant="outlined"
                fullWidth
              />
            </Grid>
            <Grid item xs={ 6 } md={ 4 }>
              <Field
                component={ TextField }
                className={ classes.username }
                name="username"
                type="text"
                label={ t('users.username') }
                variant="outlined"
                fullWidth
              />
            </Grid>
            <Grid item xs={ 6 } md={ 4 }>
              <Field
                component={ TextField }
                name="email"
                type="text"
                label={ t('users.email') }
                variant="outlined"
                fullWidth
                disabled
                // InputProps={{
                //   readOnly: true
                // }}
              />
            </Grid>
            <Grid item xs={ 6 } md={ 4 }>
              <Field
                component={ TextField }
                name="phoneNumber"
                type="text"
                label={ t('users.phoneNumber') }
                variant="outlined"
                fullWidth
              />
            </Grid>
            <Grid item xs={ 6 } md={ 4 }>
              <Field
                component={ FormikAutocomplete }
                name="bloodType"
                disableClearable
                options={ bloodTypes }
                getOptionLabel={ option => option ? option : '' }
                textFieldProps={{ label: t('user.basicInformation.bloodType') , variant: 'outlined', readOnly: true, fullWidth: true }}
              />
            </Grid>
            <Grid item xs={ 6 } md={ 4 }>
              <Field
                component={ FormikAutocomplete }
                name="role"
                disableClearable
                options={ roles }
                getOptionLabel={ option => option ? t(`global.roles.${ option }`) : '' }
                textFieldProps={{ label: t('users.role'), variant: 'outlined', readOnly: true, fullWidth: true }}
                onChange={() => {
                  setFieldValue(
                    'client',
                    clientSelected !== 'all' ? clients.find(client => client.id === +clientSelected) : ''
                  );
                }}
              />
            </Grid>
            <Grid item xs={ 6 } md={ 4 }>
              <Field
                component={ FormikAutocomplete }
                name='client'
                disableClearable
                options={ clients.filter(client => ![values.client?.id].includes(client.id)) }
                getOptionLabel={ option => option ? option.name : '' }
                textFieldProps={{ label: t('users.client'), variant: 'outlined', readOnly: true, fullWidth: true }}
                disabled={ values.role !== 'passenger' }
              />
            </Grid>
            <Grid item xs={ 6 } md={ 4 }>
              <Field
                component={ FormikAutocomplete }
                name="functionalArea"
                disableClearable
                options={ functionalAreas.filter(fa => ![values.functionalArea?.id].includes(fa.id)) }
                getOptionLabel={ option => option ? option.name : '' }
                textFieldProps={{ label: t('users.functionalArea'), variant: 'outlined', readOnly: true, fullWidth: true }}
                disabled={ !functionalAreas.length }
              />
            </Grid>
            <Grid item xs={ 12 }>
              <Button disabled={ isSubmitting } onClick={ submitForm } color="primary">{ t('global.modal.btnUpdate') }</Button>
            </Grid>
          </Grid>
        </CustomFormikForm>
      )}
    </Formik>
  );
}

BasicInformation.propTypes = {
  user: PropTypes.object.isRequired,
};

export default BasicInformation;
