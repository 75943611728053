import React from 'react';

// import { makeStyles } from '@material-ui/core/styles';

import Dashboard from './Dashboard';

// const useStyles = makeStyles(theme => ({
//   root: {
//   },
// }));

const Home = (props) => {
  // const classes = useStyles();

  return <Dashboard />;
}

export default Home;
