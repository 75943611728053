import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

import PropTypes from 'prop-types';

import { useStoreState, useStoreActions, useStore } from 'easy-peasy';

import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';
import Hidden from '@material-ui/core/Hidden';
import Link from '@material-ui/core/Link';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import Tooltip from '@material-ui/core/Tooltip';

import { handleEndpointErrors, formatDate, formatTime, getReservationStatus, SNACKBAR_TIME } from '../../shared/utilities';

import UpdateTripStatus from './UpdateTripStatus';
import AddReservations from './AddReservations';
import ReOpenTrip from './ReOpenTrip';
import Trip from './Trip';
import TripInfo from './TripInfo';
import Table from '../Table';
import CustomDialog from '../CustomDialog';

import { useTranslation } from 'react-multi-lang';

const useStyles = makeStyles(theme => ({
  root: {
  },
  image: {
    borderRadius: '8px',
    height: 'auto',
    maxWidth: '100%',
  },
  trip: {
    borderBottom: `1px solid ${ theme.palette.divider }`,
    marginBottom: theme.spacing(3),
    padding: `${ theme.spacing(1) }px 0 ${ theme.spacing(2) }px`,
  },
  table: {
    marginTop: theme.spacing(3),
    '& .MuiTableCell-head:nth-child(2), & .MuiTableCell-head:nth-child(5)': {
      minWidth: '140px',
    },
  },
}));

const Detail = (props) => {
  const { additionalClass, tripsTableData, setTripsTableData, onGetTrips } = props;
  const classes = useStyles();
  const [updateTripStatusModalOpen, setUpdateTripStatusModalOpen] = React.useState(false);
  const [reOpenTripModalOpen, setReOpenTripModalOpen] = React.useState(false);
  const [statusInfo, setStatusInfo] = React.useState({});
  const [addReservationsModalOpen, setAddReservationsModalOpen] = React.useState(false);

  const store = useStore();

  const setSnackbar = useStoreActions(actions => actions.global.setSnackbar);

  const trips = useStoreState(state => state.trips.trips);
  const heatMapTrips = useStoreState(state => state.trips.heatMapTrips);
  const tripSelected = useStoreState(state => state.trips.currentTrip);
  const updateTripPassenger = useStoreActions(actions => actions.trips.updateTripPassenger);
  const deleteTripPassenger = useStoreActions(actions => actions.trips.deleteTripPassenger);
  const updateTrip = useStoreActions(actions => actions.trips.updateTrip);

  const t = useTranslation();

  const tripsColumns = [
    {
      title: t('trips.tableTrip.columns.id'), field: 'id',
      render: rowData => <Link component={ RouterLink } to={ `/reservations/${ rowData.id }` }>{ rowData.id }</Link>,
      sorting: false, editable: 'never'
    },
    {
      title: t('trips.tableTrip.columns.name'), field: 'passengerName',
      render: rowData => <Link component={ RouterLink } to={ `/users/${ rowData.passengerId }` }>{ rowData.passengerName }</Link>,
      sorting: false, editable: 'never'
    },
    { title: t('trips.tableTrip.columns.ticketId'), field: 'ticketId', sorting: false, editable: 'never' },
    { title: t('trips.tableTrip.columns.client'), field: 'client', sorting: false, editable: 'never' },
    { title: t('trips.tableTrip.columns.reserved'), field: 'reserved', sorting: false, editable: 'never' },
    { title: t('trips.status'), field: 'status', sorting: false, lookup: getReservationStatus(t) },
    {
      title: t('trips.tableTrip.columns.location'), field: 'location',
      render: rowData => <Link target="_blank" rel="noopener" href={`https://www.google.com/maps/search/?api=1&query=${ rowData.location }`}><Tooltip title={ rowData.place }><LocationOnIcon /></Tooltip></Link>,
      sorting: false, editable: 'never'
    },
  ];

  const onUpdateTripStatusModalOpen = () => {
    setUpdateTripStatusModalOpen(true);
  };

  const onUpdateTripStatusModalClose = () => {
    setUpdateTripStatusModalOpen(false);
  };

  const onReOpenTripModalOpen = () => {
    setReOpenTripModalOpen(true);
  };

  const onReOpenTripModalClose = () => {
    setReOpenTripModalOpen(false);
  };

  const onUpdatePassenger = (newData, oldData, resolve, reject) => {
    if (oldData.status === newData.status) {
      resolve();
    } else if (oldData && (oldData.status !== newData.status)) {
      updateTripPassenger({ reservationId: newData.id, tripId: newData.tripId, status: newData.status }).then(() => {
        const tripsState = store.getState().trips;
        if (!tripsState.loading && !tripsState.error) {
          // console.log('success', tripsState);
          resolve();
          const data = [...tripsTableData];
          const passenger = data.find(passenger => passenger.id === newData.id);
          passenger.status = newData.status;
          setSnackbar({ show: true, autoHideDuration: SNACKBAR_TIME.SUCCESS, severity: 'success', message: t('global.success.saved') });
        } else {
          reject();
          handleEndpointErrors(tripsState, props, setSnackbar, t);
        }
      });
    }
  };

  const onDeletePassenger = (oldData, resolve, reject) => {
    deleteTripPassenger({ reservationId: oldData.id, tripId: oldData.tripId }).then(() => {
      const tripsState = store.getState().trips;
      if (!tripsState.loading && !tripsState.error) {
        // console.log('success', tripsState);
        updateTripPassenger({ reservationId: oldData.id, status: 'pending' });
        resolve();
        const data = [...tripsTableData].filter(passenger => passenger.id !== oldData.id);
        setTripsTableData(data);
        setSnackbar({ show: true, autoHideDuration: SNACKBAR_TIME.SUCCESS, severity: 'success', message: t('global.success.saved') });
      } else {
        reject();
        handleEndpointErrors(tripsState, props, setSnackbar, t);
      }
    });
  };

  const onAddReservationsModalOpen = () => {
    setAddReservationsModalOpen(true);
  };

  const onAddReservationsModalClose = () => {
    setAddReservationsModalOpen(false);
  };

  const [extra, setExtra] = React.useState(tripSelected.extra);
  const onExtraUpdate = extra => {
    updateTrip({ tripId: tripSelected.id, data: { extra: extra === 'true' } }).then(() => {
      const tripsState = store.getState().trips;
      if (!tripsState.loading && !tripsState.error) {
        // console.log('success', tripsState);
        setExtra(extra === 'true' ? true : false);
        onGetTrips(true);
        setSnackbar({ show: true, autoHideDuration: SNACKBAR_TIME.SUCCESS, severity: 'success', message: t('global.success.saved') });
      } else {
        handleEndpointErrors(tripsState, props, setSnackbar, t);
      }
    });
  };

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return !!Object.keys(tripSelected).length ? (
    <div className={ clsx(classes.results, additionalClass) }>
      {/* <img className={ classes.image } src={ tripsImage } alt={ `Trip from ${ tripSelected.route.origin.description } to ${ tripSelected.route.destination.description }` } /> */}

      <Trip
        className={ classes.trip }
        route={ tripSelected.route }
        date={ formatDate(tripSelected.departureDate, t) }
        dateRaw={ tripSelected.departureDate }
        start={{ name: tripSelected.route.origin.description, time: formatTime(tripSelected.schedule.departureTime) }}
        end={{ name: tripSelected.route.destination.description, time: formatTime(tripSelected.schedule.arrivalTime) }}
        note={ tripSelected.notes }
        tags={ [tripSelected.route.type.toLowerCase() === 'dtd' ? 'dtd' : 'p', tripSelected.route.direction] }
        extra={ extra }
        onExtraUpdate={ onExtraUpdate }
        status={ tripSelected.status ? tripSelected.status : 'default' }
        statusList={ JSON.parse(localStorage.getItem('enums')).tripStatus }
        wasConfirmed={ !!tripSelected.events.filter(event => event.status === 'confirmed').length || tripSelected.wasConfirmed }
        onStatusUpdate={ (id, status, currentStatus) => {
          if (status !== currentStatus) {
            setStatusInfo({ id, status });
            onUpdateTripStatusModalOpen();
          }
        } }
        onReOpenTrip={ () => {
          onReOpenTripModalOpen();
        } }
        id={ tripSelected.id }
        idFormat={ `${ t('trips.tripId') }: ${ tripSelected.id }` }
        vehicle={ tripSelected.vehicle }
      />

      <TripInfo trip={ tripSelected } />

      <Table
        className={ classes.table }
        title={`${ t('trips.tableTrip.title') } (${ tripsTableData.length }/${ tripSelected.route.capacity })`}
        columns={ tripsColumns }
        data={ tripsTableData }
        paging={ false }
        updateEvent={ onUpdatePassenger }
        deleteEvent={ ['pending', 'ready'].includes(tripSelected.status) ? onDeletePassenger : null }
        addActions={[{
          icon: 'add',
          iconProps: {
            color: ['completed', 'cancelled'].includes(tripSelected.status) ? 'disabled' : 'primary'
          },
          tooltip: t('trips.tableTrip.addTooltip'),
          isFreeAction: true,
          disabled: ['completed', 'cancelled'].includes(tripSelected.status),
          onClick: () => onAddReservationsModalOpen()
        }]}
      />

      <CustomDialog open={ addReservationsModalOpen } onClose={ onAddReservationsModalClose } fullWidth={ true } maxWidth="lg">
        <AddReservations
          onAddReservationsModalClose={ onAddReservationsModalClose }
          tripsTableData={ tripsTableData }
          setTripsTableData={ setTripsTableData }
        />
      </CustomDialog>

      <CustomDialog open={ updateTripStatusModalOpen } onClose={ onUpdateTripStatusModalClose } fullWidth={ true } maxWidth="sm">
        <UpdateTripStatus
          onUpdateTripStatusModalClose={ onUpdateTripStatusModalClose }
          statusInfo={ statusInfo }
          setTripsTableData={ setTripsTableData }
        />
      </CustomDialog>

      <CustomDialog open={ reOpenTripModalOpen } onClose={ onReOpenTripModalClose } fullWidth={ true } maxWidth="sm">
        <ReOpenTrip
          tripId={ tripSelected.id }
          tripStatus={ tripSelected.status }
          onGetTrips={ onGetTrips }
          onReOpenTripModalClose={ onReOpenTripModalClose }
        />
      </CustomDialog>
    </div>
  ) : (trips.length || heatMapTrips.length) ? <Hidden xsDown>{ t('trips.selectTrip') }</Hidden> : '';
}

Detail.propTypes = {
  additionalClass: PropTypes.string,
  tripsTableData: PropTypes.array.isRequired,
  setTripsTableData: PropTypes.func.isRequired,
  onGetTrips: PropTypes.func.isRequired,
};

export default Detail;
