import React from 'react';
import {
  Route,
  Switch,
  Redirect,
  withRouter,
  useLocation
} from 'react-router-dom';

import * as serviceWorker from './serviceWorker';

import * as Sentry from '@sentry/browser';

import { ThemeProvider } from '@material-ui/styles';
import CssBaseline from '@material-ui/core/CssBaseline';

import { useStoreState, useStoreActions, useStore } from 'easy-peasy';

import LinearProgress from '@material-ui/core/LinearProgress';
import MuiAlert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';

import Login from './pages/Login';
import Home from './pages/Home';
import Trips from './pages/Trips';
import Users from './pages/Users';
import EditUser from './pages/EditUser';
import UserActions from './pages/UserActions';
import Reservations from './pages/Reservations';
import EditReservation from './pages/EditReservation';
import Lines from './pages/Lines';
import EditLine from './pages/EditLine';
import Routes from './pages/Routes';
import EditRoute from './pages/EditRoute';
import Places from './pages/Places';
import Vehicles from './pages/Vehicles';
import Clients from './pages/Clients';
import EditClient from './pages/EditClient';
import OperationalIntervals from './pages/OperationalIntervals';
import BusinessParks from './pages/BusinessParks';
import EditBusinessPark from './pages/EditBusinessPark';
import ReportsTrips from './pages/Reports/Trips';
import ReportsReservations from './pages/Reports/Reservations';
import ReportsReservationsPivotTable from './pages/Reports/ReservationsPivotTable';
// import ReportsTripsPivotTable from './pages/Reports/TripsPivotTable';
import ReportsExcel from './pages/Reports/Excel';

import UpdateAppOverlay from './components/UpdateAppOverlay';
import UpdateSWAlert from './components/UpdateSWAlert';
import Menu from './components/Menu';
import ErrorBoundary from './components/ErrorBoundary';

import { WHITE_LABEL, SNACKBAR_TIME, DEFAULT_SNACKBAR, updateManifestAndIcons } from './shared/utilities';

import { useTranslation } from 'react-multi-lang';

function Alert(props) {
  return <MuiAlert elevation={ 6 } variant="filled" { ...props } />;
}

function App(props) {
  const [state, setState] = React.useState({
    newVersionAvailable: false,
    waitingWorker: {},
    auth: localStorage.getItem('token') !== null,
  });

  updateManifestAndIcons();

  const store = useStore();

  const globalVersion = useStoreState(state => state.global.version);
  const globalTheme = useStoreState(state => state.global.theme);
  const snackbar = useStoreState(state => state.global.snackbar);
  const setSnackbar = useStoreActions(actions => actions.global.setSnackbar);
  const setOrganizationTypes = useStoreActions(actions => actions.global.setOrganizationTypes);
  const setRouteTypes = useStoreActions(actions => actions.global.setRouteTypes);
  const getVersion = useStoreActions(actions => actions.global.getVersion);
  const getUserMetadata = useStoreActions(actions => actions.global.getUserMetadata);

  const reqThemeImport = require.context('./white-label', true, /^\.\/.*\.js$/);
  const { lightTheme, darkTheme } = reqThemeImport(`./${ WHITE_LABEL }/theme.js`);

  React.useEffect(() => {
    getVersion().then(() => {
      const globalState = store.getState().global;
      if (!globalState.version.loading && !globalState.version.error) {
        // console.log(globalState);
      } else {
        setSnackbar({ show: true, autoHideDuration: SNACKBAR_TIME.ERROR, severity: 'error', message: t('global.errors.endpoint.default') });
      }
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onServiceWorkerUpdate = (registration) => {
    setState({
      ...state,
      waitingWorker: registration && registration.waiting,
      newVersionAvailable: true,
    });
  };

  const updateServiceWorker = () => {
    const { waitingWorker } = state;
    waitingWorker && waitingWorker.postMessage({ type: "SKIP_WAITING" });
    setState({ ...state, newVersionAvailable: false });
    window.location.reload();
  };

  if (process.env.NODE_ENV === 'production') {
    serviceWorker.register({ onUpdate: onServiceWorkerUpdate });
  }

  const clearLocalStorage = () => {
    const selectedTheme = localStorage.getItem('theme');
    const selectedLanguage = localStorage.getItem('language');
    localStorage.clear();
    selectedTheme && localStorage.setItem('theme', selectedTheme);
    selectedLanguage && localStorage.setItem('language', selectedLanguage);
  };

  const handleAuth = () => (logout = true, showLogoutMsg = false) => {
    props.history.push({ pathname: '/' });

    if (state.auth) {
      clearLocalStorage();
    }

    if (showLogoutMsg) {
      setSnackbar({ show: true, autoHideDuration: SNACKBAR_TIME.INFO, severity: 'info', message: t('login.expiredSession') });
    }

    setState({ ...state, auth: !logout });
  };

  const userId = localStorage.getItem('id');
  const appVersion =  `v${ globalVersion.version } (${ process.env.REACT_APP_LAST_COMMIT_SHA })`;
  if (userId !== null) {
    const clarity = window?.clarity;
    Sentry.setUser({
      id: userId,
      username: localStorage.getItem('username'),
      appVersion,
    });

    if (typeof clarity === 'function') {
      clarity('set', 'userId', userId);
      clarity('set', 'appVersion', appVersion);
    };
  }

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackbar(DEFAULT_SNACKBAR);
  };

  const location = useLocation();
  const updatedLocation = location.pathname.indexOf('/trips') === 0 ? '/trips' : location.pathname;

  const t = useTranslation();

  setOrganizationTypes();
  setRouteTypes();

  const renderApp = <React.Fragment>
    { state.auth && (
      <React.Fragment>
        <Menu logout={ handleAuth() } />
        <ErrorBoundary key={ updatedLocation } t={ t }>
          <Switch>
            <Route exact path="/dashboard">
              <Home />
            </Route>
            <Route exact path="/trips">
              <Trips />
            </Route>
            <Route exact path="/trips/:id">
              <Trips />
            </Route>
            <Route exact path="/users">
              <Users />
            </Route>
            <Route exact path="/users/:id">
              <EditUser />
            </Route>
            <Route exact path="/users/:id/actions">
              <UserActions />
            </Route>
            <Route exact path="/reservations">
              <Reservations />
            </Route>
            <Route exact path="/reservations/:id">
              <EditReservation />
            </Route>
            <Route exact path="/lines">
              <Lines />
            </Route>
            <Route exact path="/lines/:id">
              <EditLine />
            </Route>
            <Route exact path="/routes">
              <Routes />
            </Route>
            <Route exact path="/routes/:id">
              <EditRoute />
            </Route>
            <Route exact path="/places">
              <Places />
            </Route>
            <Route exact path="/vehicles">
              <Vehicles />
            </Route>
            <Route exact path="/business-parks">
              <BusinessParks />
            </Route>
            <Route exact path="/business-parks/:id">
              <EditBusinessPark />
            </Route>
            <Route exact path="/clients">
              <Clients />
            </Route>
            <Route exact path="/clients/:id">
              <EditClient />
            </Route>
            <Route exact path="/operational-intervals">
              <OperationalIntervals />
            </Route>
            <Route exact path="/reports/trips">
              <ReportsTrips />
            </Route>
            <Route exact path="/reports/reservations">
              <ReportsReservations />
            </Route>
            <Route exact path="/reports/reservations-pivot-table">
              <ReportsReservationsPivotTable />
            </Route>
            {/* <Route exact path="/reports/trips-pivot-table">
              <ReportsTripsPivotTable />
            </Route> */}
            <Route exact path="/reports/excel">
              <ReportsExcel />
            </Route>
            <Route render={() => <Redirect to="/dashboard" />} />
          </Switch>
        </ErrorBoundary>
      </React.Fragment>
    ) }

    { !state.auth && (
      <Switch>
        <Route exact path="/login">
          <Login login={ handleAuth() } />
        </Route>
        <Route render={() => <Redirect to="/login" />} />
      </Switch>
    ) }

    <Snackbar open={ snackbar.show } autoHideDuration={ snackbar.autoHideDuration } transitionDuration={ 0 } onClose={ handleSnackbarClose }>
      <Alert onClose={ handleSnackbarClose } severity={ snackbar.severity }>
        { snackbar.message }
      </Alert>
    </Snackbar>

    { state.newVersionAvailable && <UpdateSWAlert onClick={ updateServiceWorker } /> }
  </React.Fragment>;

  React.useEffect(() => {
    if ( state.auth ) {
      getUserMetadata();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ThemeProvider theme={ globalTheme === 'dark' ? darkTheme : lightTheme }>
      <CssBaseline />
      { globalVersion.loading ? <LinearProgress /> : (
        !globalVersion.update ? renderApp : <UpdateAppOverlay clearLocalStorage={ clearLocalStorage } />
      ) }
    </ThemeProvider>
  );
}

export default withRouter(App);
