import axios from 'axios';

import { logSentryEndpointError } from '../../shared/utilities';

export const getClients = (actions, payload, state) => axios({
  method: 'get',
  url: `${ process.env.REACT_APP_API_URL }/api/admin/v1/clients`,
  params: {
    page: payload.page ? payload.page : state.page,
    pageSize: state.pageSize,
    textSearch: payload.textSearch === undefined ? state.textSearch : payload.textSearch,
  },
  headers: {
    'auth-token': localStorage.getItem('token'),
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/json'
  }
})
.then(response => {
  actions.setLoading(false);
  actions.storeGetClients({ data: response.data, page: payload.page || state.page, textSearch: payload.textSearch === undefined ? state.textSearch : payload.textSearch });
})
.catch(error => {
  actions.setLoading(false);
  actions.setError(error.response);
  logSentryEndpointError(error);
});

export const getClient = (actions, payload) => axios({
  method: 'get',
  url: `${ process.env.REACT_APP_API_URL }/api/admin/v1/clients/${ payload }`,
  headers: {
    'auth-token': localStorage.getItem('token'),
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/json'
  }
})
.then(response => {
  actions.setLoading(false);
  actions.storeGetClient(response.data);
})
.catch(error => {
  actions.setLoading(false);
  actions.setError(error.response);
  logSentryEndpointError(error);
});
