import React from 'react';

import PropTypes from 'prop-types';

import { useStoreState, useStoreActions, useStore } from 'easy-peasy';

// import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import ShuffleIcon from '@material-ui/icons/Shuffle';

import * as Yup from 'yup';

import { Formik, Field } from 'formik';

import { handleEndpointErrors, SNACKBAR_TIME } from '../../shared/utilities';
import { useDebounce } from '../../shared/hooks/useDebounce';

import LoadingCard from '../LoadingCard';
import CustomFormikForm from '../CustomFormikForm';
import FormikAutocomplete from '../FormikAutocomplete';

import { useTranslation } from 'react-multi-lang';

// const useStyles = makeStyles(theme => ({
//   root: {},
// }));

const AddLine = (props) => {
  const { routeId, lineIds, onGetRouteLines, onAddLineModalClose } = props;
  // const classes = useStyles();

  const store = useStore();

  const setSnackbar = useStoreActions(actions => actions.global.setSnackbar);

  const lines = useStoreState(state => state.lines.lines);
  const getLines = useStoreActions(actions => actions.lines.getLines);
  const linesLoading = useStoreState(state => state.lines.loading);
  const addRouteLine = useStoreActions(actions => actions.routes.addRouteLine);
  const setLinesTextSearch = useStoreActions(actions => actions.lines.setTextSearch);

  const [lineName, setLineName] = React.useState('');
  const debouncedLineName = useDebounce(lineName, 500);

  const onGetLines = (payload) => {
    getLines(payload).then(() => {
      const linesState = store.getState().lines;
      if (!linesState.loading && !linesState.error) {
        // console.log(linesState);
      } else {
        handleEndpointErrors(linesState, props, setSnackbar, t);
      }
    });
  }

  const t = useTranslation();

  React.useEffect(() => {
    onGetLines({ textSearch: debouncedLineName });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedLineName]);

  React.useEffect(() => {
    return () => setLinesTextSearch('');
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>
      <DialogTitle>{ t('route.lines.add.title') }</DialogTitle>
      <Formik
        initialValues={{ line: '' }}
        validationSchema={ Yup.object({
          line: Yup.string()
            .required(t('global.errors.required', { field: t('route.lines.line') })),
        }) }
        onSubmit={(values, { setSubmitting }) => {
          const data = {
            routeId,
            lineId: values.line.id
          };
          addRouteLine(data).then(() => {
            const routesState = store.getState().routes;
            if (!routesState.loading && !routesState.error) {
              setSubmitting(false);
              onGetRouteLines(routeId);
              onAddLineModalClose();
              setSnackbar({ show: true, autoHideDuration: SNACKBAR_TIME.SUCCESS, severity: 'success', message: t('global.success.saved') });
            } else {
              handleEndpointErrors(routesState, props, setSnackbar, t);
            }
          });
        }}
      >
        {({ submitForm, isSubmitting }) => (
          <CustomFormikForm>
            <DialogContent>
              <Grid container spacing={ 3 }>
                <Grid item xs={ 12 } md={ 6 }>
                  { linesLoading ? <LoadingCard length={ 1 } height={ 56 } /> : (
                    <Field
                      component={ FormikAutocomplete }
                      name='line'
                      disableClearable
                      options={ lines.filter(line => !lineIds.includes(line.id)) }
                      getOptionLabel={ option => option ? option.name : '' }
                      textFieldProps={{
                        label: t('route.lines.line'),
                        variant: 'outlined',
                        fullWidth: true,
                        icon: <ShuffleIcon />,
                        onChange: event => {
                          setLineName( event.target.value );
                        }
                      }}
                      onFocus={() => {
                        setLineName('');
                      }}
                    />
                  ) }
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={ onAddLineModalClose } color="primary">{ t('global.modal.btnClose') }</Button>
              <Button disabled={ isSubmitting } onClick={ submitForm } color="primary">{ t('global.modal.btnAdd') }</Button>
            </DialogActions>
          </CustomFormikForm>
        )}
      </Formik>
    </React.Fragment>
  );
}

AddLine.propTypes = {
  routeId: PropTypes.number.isRequired,
  lineIds: PropTypes.array.isRequired,
  onGetRouteLines: PropTypes.func.isRequired,
  onAddLineModalClose: PropTypes.func.isRequired,
};

export default AddLine;
