import { action, thunk } from 'easy-peasy';

import { getExcelPassengerTripCosts, getExcelPassengersTripCostsByRoute } from '../../services/reports/get';

const reportsModel = {
  loading: false,
  error: '',
  getExcelPassengerTripCosts: thunk(async (actions, payload = {}) => {
    actions.setLoading(payload.loading);
    actions.setError('');
    return await getExcelPassengerTripCosts(actions, payload);
  }),
  getExcelPassengersTripCostsByRoute: thunk(async (actions, payload = {}) => {
    actions.setLoading(payload.loading);
    actions.setError('');
    return await getExcelPassengersTripCostsByRoute(actions, payload);
  }),
  setLoading: action((state, payload) => {
    state.loading = payload;
  }),
  setError: action((state, payload) => {
    state.error = payload;
  }),
}

export default reportsModel;
