import React from 'react';
import { withRouter } from 'react-router-dom';

import PropTypes from 'prop-types';

import { useStoreActions, useStore } from 'easy-peasy';

// import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';

import * as Yup from 'yup';

import { Formik, Field } from 'formik';
import { TextField } from 'formik-material-ui';

import { handleEndpointErrors, SNACKBAR_TIME } from '../../shared/utilities';

import CustomFormikForm from '../CustomFormikForm';

import { useTranslation } from 'react-multi-lang';

// const useStyles = makeStyles(theme => ({
//   root: {},
// }));

const AddLine = (props) => {
  const { onAddLineModalClose, history } = props;
  // const classes = useStyles();

  const store = useStore();

  const setSnackbar = useStoreActions(actions => actions.global.setSnackbar);

  const addLine = useStoreActions(actions => actions.lines.addLine);

  const t = useTranslation();

  return (
    <React.Fragment>
      <DialogTitle>{ t('lines.table.actionAdd') }</DialogTitle>
      <Formik
        initialValues={{ name: '' }}
        validationSchema={ Yup.object({
          name: Yup.string()
            .max(50, t('global.errors.max', { field: t('lines.table.name'), length: 50 }))
            .required(t('global.errors.required', { field: t('lines.table.name') })),
        }) }
        onSubmit={(values, { setSubmitting }) => {
          const data = {
            name: values.name
          };
          addLine(data).then((response) => {
            const linesState = store.getState().lines;
            if (!linesState.loading && !linesState.error) {
              setSnackbar({ show: true, autoHideDuration: SNACKBAR_TIME.SUCCESS, severity: 'success', message: t('global.success.saved') });
              history.push({ pathname: `/lines/${ response.data.id }` });
            } else {
              handleEndpointErrors(linesState, props, setSnackbar, t);
              setSubmitting(false);
            }
          });
        }}
      >
        {({ submitForm, isSubmitting, values }) => (
          <CustomFormikForm>
            <DialogContent>
              <Grid container spacing={ 3 }>
                <Grid item xs={ 12 } md={ 8 }>
                  <Field
                    component={ TextField }
                    name="name"
                    type="text"
                    label={ t('lines.table.name') }
                    variant="outlined"
                    fullWidth
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={ onAddLineModalClose } color="primary">{ t('global.modal.btnClose') }</Button>
              <Button disabled={ isSubmitting } onClick={ submitForm } color="primary">{ t('global.modal.btnAdd') }</Button>
            </DialogActions>
          </CustomFormikForm>
        )}
      </Formik>
    </React.Fragment>
  );
}

AddLine.propTypes = {
  onAddLineModalClose: PropTypes.func.isRequired,
};

export default withRouter(AddLine);
