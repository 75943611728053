import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: theme.spacing(2),
  },
  link: {
    alignItems: 'center',
    display: 'flex',
    '& .MuiSvgIcon-root': {
      height: 20,
      marginRight: theme.spacing(0.5),
      width: 20,
    },
  },
}));

const CustomBreadcrumbs = (props) => {
  const { links } = props;
  const classes = useStyles();

  return (
    <Breadcrumbs aria-label="breadcrumb" className={ classes.root }>
      { links.map(link => link.path ? (
        <Link key={ link.name } color="inherit" className={ classes.link } component={ RouterLink } to={ link.path }>
          { link.icon ? link.icon : null }
          { link.name }
        </Link>
      ) : (
        <Typography key={ link.name } color="textPrimary" className={ classes.link }>
          { link.icon ? link.icon : null }
          { link.name }
        </Typography>
      )) }
    </Breadcrumbs>
  );
}

CustomBreadcrumbs.propTypes = {
  links: PropTypes.arrayOf(PropTypes.exact({
    name: PropTypes.string.isRequired,
    path: PropTypes.string,
    icon: PropTypes.node,
  })).isRequired,
};

export default CustomBreadcrumbs;
