import React from 'react';

import PropTypes from 'prop-types';

import { useStoreState, useStoreActions } from 'easy-peasy';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import AppsIcon from '@material-ui/icons/Apps';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Typography from '@material-ui/core/Typography';
import ViewStreamIcon from '@material-ui/icons/ViewStream';

import SwipeableViews from 'react-swipeable-views';

import ListView from './Sidebar/ListView';
import HeatMap from './Sidebar/HeatMap';
import LoadingCard from '../LoadingCard';

import { useTranslation } from 'react-multi-lang';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={ value !== index }
      id={ `trips-tabpanel-${ index }` }
      aria-labelledby={ `trips-tab-${ index }` }
      { ...other }
    >
      { value === index && <Box>{ children }</Box> }
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `trips-tab-${ index }`,
    'aria-controls': `trips-tabpanel-${ index }`,
  };
}

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    '& .MuiAppBar-root': {
      boxShadow: 'none',
      marginLeft: theme.spacing(2),
      width: `calc(100% - ${theme.spacing(4)}px)`,
    },
  },
  loadingContainer: {
    padding: theme.spacing(2),
  }
}));

const Sidebar = (props) => {
  const {  onGetTrips, onTripSelected } = props;
  const classes = useStyles();
  const theme = useTheme();

  const [tab, setTab] = React.useState(+localStorage.getItem('tripsSidebarTab') || 0);

  const tripsLoading = useStoreState(state => state.trips.loading);
  const heatMapLoading = useStoreState(state => state.trips.heatMapLoading);
  const getHeatMapTrips = useStoreActions(actions => actions.trips.getHeatMapTrips);

  const handleChange = (event, newValue) => {
    if (tab !== newValue) {
      setTab(newValue);
      localStorage.setItem('tripsSidebarTab', newValue);

      if ( newValue === 1 ) {
        getHeatMapTrips(true);
      } else {
        onGetTrips({ loading: true });
      }
    }
  };

  const handleChangeIndex = index => {
    setTab(index);
  };

  const t = useTranslation();

  const LoadingTrips = <Grid className={ classes.loadingContainer } container spacing={ 2 }>
    <Grid item xs={ 12 }><LoadingCard length={ 1 } height={ 50 } /></Grid>
    <Grid item xs={ 12 }><LoadingCard length={ 1 } height={ 80 } /></Grid>
    <Grid item xs={ 12 }><LoadingCard length={ 1 } height={ 180 } /></Grid>
    <Grid item xs={ 12 }><LoadingCard length={ 1 } height={ 180 } /></Grid>
    <Grid item xs={ 12 }><LoadingCard length={ 1 } height={ 180 } /></Grid>
    <Grid item xs={ 12 }><LoadingCard length={ 1 } height={ 180 } /></Grid>
  </Grid>

  return (
    <div className={ classes.root }>
      <AppBar color="inherit" position="static">
        <Tabs value={ tab } onChange={ handleChange } variant="fullWidth" aria-label={ t('trips.aria') }>
          <Tab icon={ <ViewStreamIcon /> } { ...a11yProps(0) } />
          <Tab icon={ <AppsIcon /> } { ...a11yProps(1) } />
        </Tabs>
      </AppBar>
      <SwipeableViews
        axis={ theme.direction === 'rtl' ? 'x-reverse' : 'x' }
        index={ tab }
        onChangeIndex={ handleChangeIndex }
        disabled
      >
        <TabPanel value={ tab } index={ 0 } dir={ theme.direction }>
          { tripsLoading ? LoadingTrips : (
            <ListView
              onGetTrips={ onGetTrips }
              onTripSelected={ onTripSelected }
            />
          ) }
        </TabPanel>
        <TabPanel value={ tab } index={ 1 } dir={ theme.direction }>
          { heatMapLoading ? LoadingTrips : (
            <HeatMap
              onGetTrips={ onGetTrips }
              onTripSelected={ onTripSelected }
            />
          ) }
        </TabPanel>
      </SwipeableViews>
    </div>
  );
}

Sidebar.propTypes = {
  onGetTrips: PropTypes.func.isRequired,
  onTripSelected: PropTypes.func.isRequired
};

export default Sidebar;
