import React from 'react';

import { useStoreState, useStoreActions, useStore } from 'easy-peasy';

import { makeStyles } from '@material-ui/core/styles';
import AssessmentIcon from '@material-ui/icons/Assessment';
import Avatar from '@material-ui/core/Avatar';
import CardTravelIcon from '@material-ui/icons/CardTravel';
import DirectionsIcon from '@material-ui/icons/Directions';
import GetAppIcon from '@material-ui/icons/GetApp';
import HomeIcon from '@material-ui/icons/Home';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import MuiAlert from '@material-ui/lab/Alert';

import Breadcrumbs from '../../components/Breadcrumbs';
import DataFilters from '../../components/DataFilters';
import DateFilters from '../../components/DateFilters';

import ExcelIcon from '../../icons/Excel';

import { useTranslation } from 'react-multi-lang';

import { handleEndpointErrors, dateISOWithoutTz, saveExcelFile, SNACKBAR_TIME } from '../../shared/utilities';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3, 2),
    '& .MuiAlert-root': {
      marginTop: theme.spacing(-2),
    },
    '& .MuiList-root': {
      border: `1px solid ${ theme.palette.divider }`,
      borderRadius: '12px',
      marginTop: theme.spacing(2),
      padding: theme.spacing(1, 2),
    },
    '& .MuiListItem-secondaryAction': {
      paddingRight: '70px',
    },
  },
  avatar: {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.type === 'dark' ? theme.palette.primary.dark : theme.palette.primary.main,
  },
  btnBack: {
    marginTop: theme.spacing(2),
    textAlign: 'right',
  },
}));

const ReportsExcel = (props) => {
  const classes = useStyles();

  const store = useStore();

  const storeMenuTitle = useStoreActions(actions => actions.global.storeTitle);
  const setSnackbar = useStoreActions(actions => actions.global.setSnackbar);
  const setClientsDisabled = useStoreActions(actions => actions.global.setClientsDisabled);

  const clients = useStoreState(state => state.clients.clients);

  const getExcelPassengerTripCosts = useStoreActions(actions => actions.reports.getExcelPassengerTripCosts);
  const getExcelPassengersTripCostsByRoute = useStoreActions(actions => actions.reports.getExcelPassengersTripCostsByRoute);

  const setActiveFilters = React.useState({})[1];
  const [filters, setFilters] = React.useState({});

  const onGetExcelReport = (methodFn, reportName) => {
    setSnackbar({ show: true, autoHideDuration: SNACKBAR_TIME.INFO, severity: 'info', message: t('reports.excel.loading') });
    methodFn(filters).then((response) => {
      const reportsState = store.getState().reports;
      if (!reportsState.loading && !reportsState.error) {
        // console.log(reportsState);
        setTimeout(() => setSnackbar({ show: false }), 400);
        const excelName = `${ t(`reports.excel.${ reportName }.fileName`, { client: filters.clientName.trim(), startDate: filters.startDate, endDate: filters.endDate }) }.xlsx`;
        saveExcelFile(response, excelName.replace(/\s+/g, '-').toLowerCase());
      } else {
        handleEndpointErrors(reportsState, props, setSnackbar, t);
      }
    });
  }

  const onDateChange = (payload) => {
    setFilters({
      ...filters,
      startDate: dateISOWithoutTz(payload.startDate).split('T')[0],
      endDate: dateISOWithoutTz(payload.endDate).split('T')[0],
    });
  };

  const t = useTranslation();

  storeMenuTitle(t('reports.pageTitle'));

  const clientsNames = clients.map(client => ({ label: client.name, value: client.id }));

  React.useEffect(() => {
    setClientsDisabled(true);
    setActiveFilters(currentFilters => {
      setFilters(filters => ({ ...filters, ...currentFilters }));
      return { ...currentFilters  };
    });

    return () => setClientsDisabled(false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <div className={ classes.root }>
    <Breadcrumbs links={[
      { name: t('home.pageTitle'), path: '/', icon: <HomeIcon /> },
      { name: t('reports.pageTitle'), icon: <AssessmentIcon /> },
      { name: t('reports.excel.pageTitle'), icon: <ExcelIcon /> },
    ]} />

    <DataFilters
      setActiveFilters={ setActiveFilters }
      filters={[
        {
          type: 'select',
          name: t('reports.excel.client'),
          property: 'clientId',
          options: clientsNames,
          active: '',
          onChangeFn: (currentFilters) => {
            setFilters({
              ...filters,
              clientId: currentFilters.clientId,
              clientName: clients.find(client => client.id === currentFilters?.clientId)?.name || ''
            });
          }
        },
        {
          type: 'select',
          name: t('reports.excel.canceledTrips'),
          property: 'includeCancelledRsv',
          options: [{label: t('dataFilters.yes'), value: 'true'}, {label: t('dataFilters.no'), value: 'false'}],
          required: true,
          active: localStorage.getItem('excelReportCanceledRsv') || 'true',
          width: '190px',
          onChangeFn: (currentFilters) => {
            localStorage.setItem('excelReportCanceledRsv', currentFilters.includeCancelledRsv);
            setFilters({
              ...filters,
              includeCancelledRsv: currentFilters.includeCancelledRsv
            });
          }
        },
        {
          type: 'select',
          name: t('reports.excel.extraTrips'),
          property: 'includeExtraRsv',
          options: [{label: t('dataFilters.yes'), value: 'true'}, {label: t('dataFilters.no'), value: 'false'}],
          required: true,
          active: localStorage.getItem('excelReportExtraRsv') || 'false',
          width: '150px',
          onChangeFn: (currentFilters) => {
            localStorage.setItem('excelReportExtraRsv', currentFilters.includeExtraRsv);
            setFilters({
              ...filters,
              includeExtraRsv: currentFilters.includeExtraRsv
            });
          }
        }
      ]}
    >
      <DateFilters onChangeFn={ onDateChange } isReportsPivot localStorageName="dateFiltersReportsExcel" defaultDateRange="currentMonth" />
    </DataFilters>

    { localStorage.getItem('schema') === 'srva' && <MuiAlert severity="info">{ t('reports.excel.infoMessage') }</MuiAlert> }

    <List dense disablePadding>
      <ListItem disableGutters>
        <ListItemAvatar><Avatar variant='rounded' className={ classes.avatar }><CardTravelIcon /></Avatar></ListItemAvatar>
        <ListItemText primary={ t('reports.excel.passengersTripCosts.title') } secondary={ t('reports.excel.passengersTripCosts.description') } />
        <ListItemSecondaryAction>
          <IconButton color='primary' disabled={ !filters.clientId } onClick={ () => onGetExcelReport(getExcelPassengerTripCosts, 'passengersTripCosts') }><GetAppIcon /></IconButton>
        </ListItemSecondaryAction>
      </ListItem>
      <ListItem disableGutters>
        <ListItemAvatar><Avatar variant='rounded' className={ classes.avatar }><DirectionsIcon /></Avatar></ListItemAvatar>
        <ListItemText primary={ t('reports.excel.passengersTripCostsByRoute.title') } secondary={ t('reports.excel.passengersTripCostsByRoute.description') } />
        <ListItemSecondaryAction>
        <IconButton color='primary' disabled={ !filters.clientId } onClick={ () => onGetExcelReport(getExcelPassengersTripCostsByRoute, 'passengersTripCostsByRoute') }><GetAppIcon /></IconButton>
        </ListItemSecondaryAction>
      </ListItem>
    </List>
  </div>;
}

export default ReportsExcel;
