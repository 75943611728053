import React from 'react';

import { useStoreActions } from 'easy-peasy';

import PropTypes from 'prop-types';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Typography from '@material-ui/core/Typography';

import SwipeableViews from 'react-swipeable-views';

import AddTrip from './AddTrip/AddTrip';
import GenerateTrips from './AddTrip/GenerateTrips';

import { useTranslation } from 'react-multi-lang';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={ value !== index }
      id={ `reservations-tabpanel-${ index }` }
      aria-labelledby={ `reservations-tab-${ index }` }
      { ...other }
    >
      { value === index && <Box p={ 3 }>{ children }</Box> }
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `reservations-tab-${ index }`,
    'aria-controls': `reservations-tabpanel-${ index }`,
  };
}

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.default,
    flexGrow: 1,
  },
}));

const AddTripModal = (props) => {
  const { onAddTripModalClose, onGetTrips } = props;
  const classes = useStyles();
  const theme = useTheme();

  const setRoutesTextSearch = useStoreActions(actions => actions.routes.setTextSearch);

  const [tab, setTab] = React.useState(0);

  const handleChange = (event, newValue) => {
    setTab(newValue);
  };

  const handleChangeIndex = index => {
    setTab(index);
  };

  const t = useTranslation();

  React.useEffect(() => {
    return () => setRoutesTextSearch('');
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={ classes.root }>
      <AppBar color="inherit" position="static">
        <Tabs value={ tab } onChange={ handleChange } variant="fullWidth" aria-label={ t('reservations.aria') }>
          <Tab label={ t('trips.addTripModal.addTripTitle') } { ...a11yProps(0) } />
          <Tab label={ t('trips.addTripModal.generateTripsTitle') } { ...a11yProps(1) } />
        </Tabs>
      </AppBar>
      <SwipeableViews
        axis={ theme.direction === 'rtl' ? 'x-reverse' : 'x' }
        index={ tab }
        onChangeIndex={ handleChangeIndex }
      >
        <TabPanel value={ tab } index={ 0 } dir={ theme.direction }>
          <AddTrip
            onAddTripModalClose={ onAddTripModalClose }
            onGetTrips={ onGetTrips }
          />
        </TabPanel>
        <TabPanel value={ tab } index={ 1 } dir={ theme.direction }>
          <GenerateTrips
            onAddTripModalClose={ onAddTripModalClose }
            onGetTrips={ onGetTrips }
          />
        </TabPanel>
      </SwipeableViews>
    </div>
  );
}

AddTripModal.propTypes = {
  onAddTripModalClose: PropTypes.func.isRequired,
  onGetTrips: PropTypes.func.isRequired,
};

export default AddTripModal;
