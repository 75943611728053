import React from 'react';
import { withRouter } from 'react-router-dom';

import { useStoreState, useStoreActions, useStore } from 'easy-peasy';

import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import BusinessIcon from '@material-ui/icons/Business';
import Button from '@material-ui/core/Button';
import CategoryIcon from '@material-ui/icons/Category';
import Chip from '@material-ui/core/Chip';
import DashboardIcon from '@material-ui/icons/Dashboard';
import DirectionsIcon from '@material-ui/icons/Directions';
import EmojiTransportationIcon from '@material-ui/icons/EmojiTransportation';
import GroupIcon from '@material-ui/icons/Group';
import HomeIcon from '@material-ui/icons/Home';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import LockIcon from '@material-ui/icons/Lock';
import RecentActorsIcon from '@material-ui/icons/RecentActors';
import ShuffleIcon from '@material-ui/icons/Shuffle';
import Switch from '@material-ui/core/Switch';
import Typography from '@material-ui/core/Typography';

import Breadcrumbs from '../components/Breadcrumbs';
import LoadingCard from '../components/LoadingCard';

import { handleEndpointErrors, SNACKBAR_TIME } from '../shared/utilities';

import { useTranslation } from 'react-multi-lang';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3, 2),
    '& .MuiList-root': {
      border: `1px solid ${ theme.palette.divider }`,
      borderRadius: '12px',
      padding: theme.spacing(1, 2),
    },
    '& .MuiListItem-secondaryAction': {
      paddingRight: '70px',
    },
  },
  categories: {
    marginBottom: theme.spacing(1),
    '& .MuiChip-root': {
      marginBottom: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
  },
  avatar: {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.type === 'dark' ? theme.palette.primary.dark : theme.palette.primary.main,
  },
  btnBack: {
    marginTop: theme.spacing(2),
    textAlign: 'right',
  },
}));

const UserActions = (props) => {
  const classes = useStyles();

  const [userActionsData, setUserActionsData] = React.useState([]);

  const store = useStore();

  const storeMenuTitle = useStoreActions(actions => actions.global.storeTitle);
  const setSnackbar = useStoreActions(actions => actions.global.setSnackbar);
  const setClientsDisabled = useStoreActions(actions => actions.global.setClientsDisabled);
  const getUserMetadata = useStoreActions(actions => actions.global.getUserMetadata);

  const usersActions = useStoreState(state => state.users.usersActions);
  const usersActionsLoading = useStoreState(state => state.users.usersActionsLoading);
  const user = useStoreState(state => state.users.user);
  const getUsersActions = useStoreActions(actions => actions.users.getUsersActions);
  const updateUserActions = useStoreActions(actions => actions.users.updateUserActions);
  const getUser = useStoreActions(actions => actions.users.getUser);
  const storeGetUser = useStoreActions(actions => actions.users.storeGetUser);

  const [userActions, setUserActions] = React.useState([]);
  const [activeCategory, setActiveCategory] = React.useState('all');
  const [categories, setCategories] = React.useState([]);

  const userRole = user?.role;
  const type = userRole === 'admin' ? 'adminActions' : userRole === 'driver' ? 'driverActions' : 'passengerActions';
  const userName = `${ user?.name }${ user?.username ? ` (${ user?.username })` : '' }`;
  const validCategories = ['routes','dashboard','users','lines','parks','clients','operational_intervals'];
  const getCategory = actionData => {
    switch (true) {
      case actionData.includes(':routes:'):
        return { name: 'routes', icon: <DirectionsIcon /> }
      case actionData.includes(':dashboard:'):
        return { name: 'dashboard', icon: <DashboardIcon /> }
      case actionData.includes(':users:'):
        return { name: 'users', icon: <GroupIcon /> }
      case actionData.includes(':lines:'):
        return { name: 'lines', icon: <ShuffleIcon /> }
      case actionData.includes(':parks:'):
        return { name: 'parks', icon: <BusinessIcon /> }
      case actionData.includes(':clients:'):
        return { name: 'clients', icon: <RecentActorsIcon /> }
      case actionData.includes(':operational_intervals:'):
        return { name: 'operationalIntervals', icon: <EmojiTransportationIcon /> }
      default:
        return { name: 'other', icon: <CategoryIcon /> }
    }    
  }

  const t = useTranslation();

  storeMenuTitle(t('userActions.pageTitle'));

  const onGetUser = () => {
    getUser(props.match.params.id).then(() => {
      const usersState = store.getState().users;
      if (!usersState.loading && !usersState.error) {
        // console.log(usersState);
      } else {
        handleEndpointErrors(usersState, props, setSnackbar, t);
      }
    });
  };

  const onGetUsersActions = () => {
    getUsersActions().then(() => {
      const usersState = store.getState().users;
      if (!usersState.usersActionsLoading && !usersState.usersActionsError) {
        // console.log(usersState);
        const newCategories = ['all'];
        Object.values(usersState.usersActions[type]).forEach(action => {
          const category = action.split(':')[1];
          newCategories.push(validCategories.includes(category) ? category : 'other');
        });
        setCategories([...new Set(newCategories)].sort());
      } else {
        const state = { ...usersState, error: usersState.usersActionsError };
        handleEndpointErrors(state, props, setSnackbar, t);
      }
    });
  };

  const onUpdateUserAction = (action, active) => {
    let actions = userActions;
    if (active) {
      actions = [...actions, action];
    } else {
      actions = actions.filter(prevAction => prevAction !== action);
    }
    updateUserActions({ userId: user.id, data: { actions } }).then(() => {
      const usersState = store.getState().users;
      if (!usersState.loading && !usersState.error) {
        // console.log('success', usersState);
        setUserActions(actions);
        if (`${ user.id }` === localStorage.getItem('id')) {
          getUserMetadata();
        }
        setSnackbar({ show: true, autoHideDuration: SNACKBAR_TIME.SUCCESS, severity: 'success', message: t('global.success.saved') });
      } else {
        handleEndpointErrors(usersState, props, setSnackbar, t);
      }
    });
  }

  const onUpdateCategory = (category) => {
    setActiveCategory(category);
  }

  const handleBack = () => {
    props.history.push({ pathname: '/users' });
  }

  React.useEffect(() => {
    if (user?.id) {
      setUserActions(user.actions || []);
      onGetUsersActions();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  React.useEffect(() => {
    const actionsType = usersActions[type] || {};
    const actions = Object.keys(actionsType) || [];
    setUserActionsData(actions.map(action => ({
      name: t(`userActions.${ action }.actionName`) !== `userActions.${ action }.actionName` ? t(`userActions.${ action }.actionName`) : actionsType[action],
      description: t(`userActions.${ action }.actionDescription`) !== `userActions.${ action }.actionDescription` ? t(`userActions.${ action }.actionDescription`) : actionsType[action],
      category: validCategories.includes(actionsType[action].split(':')[1]) ? actionsType[action].split(':')[1] : 'other',
      data: actionsType[action],
      active: userActions.includes(actionsType[action])
    })));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userActions, usersActions]);

  React.useEffect(() => {
    setClientsDisabled(true);
    onGetUser();

    return () => {
      setClientsDisabled(false);
      storeGetUser({});
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={ classes.root }>
      <Breadcrumbs links={[
        { name: t('home.pageTitle'), path: '/', icon: <HomeIcon /> },
        { name: t('users.pageTitle'), path: '/users', icon: <GroupIcon /> },
        { name: t('userActions.pageTitle'), icon: <LockIcon /> },
      ]} />
      { usersActionsLoading || !user?.id ? <LoadingCard length={ 1 } height={ 430 } /> : (
        <React.Fragment>
          <Typography variant="h6" gutterBottom>{ userName }</Typography>
          { !!categories.length && userActionsData.length > 1 && <div className={ classes.categories }>
            { categories.map(category => 
              <Chip
                key={ category }
                label={ t(`userActions.categories.${ category }`) }
                variant={ category === activeCategory ? 'default' : 'outlined' }
                color={ category === activeCategory ? 'primary' : 'default' }
                clickable={ category !== activeCategory }
                onClick={ category !== activeCategory ? () => onUpdateCategory(category) : undefined }
              />)
            }
          </div> }
          { !!userActionsData.length && <List dense disablePadding>
            { userActionsData
              .filter(action => activeCategory !== 'all' ? action.category === activeCategory : true )
              .map(action => <ListItem disableGutters key={ action.name }>
              <ListItemAvatar>
                <Avatar variant='rounded' className={ classes.avatar }>
                  { getCategory(action.data).icon }
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={ action.name } secondary={ action.description } />
              <ListItemSecondaryAction>
                <Switch
                  edge='end'
                  onChange={event => onUpdateUserAction(action.data, event.target.checked)}
                  checked={ action.active }
                />
              </ListItemSecondaryAction>
            </ListItem>) }
          </List> }
          { !userActionsData.length && <Typography variant="body1" gutterBottom>{ t('userActions.noUserActionsMsg') }</Typography> }
          <div className={ classes.btnBack }>
            <Button onClick={ handleBack } color="primary">{ t('global.modal.btnBack') }</Button>
          </div>
        </React.Fragment>
      ) }
    </div>
  );
}

export default withRouter(UserActions);
