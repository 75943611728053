import axios from 'axios';

import { logSentryEndpointError } from '../../shared/utilities';

const addLine = (actions, payload) => axios({
  method: 'post',
  url: `${ process.env.REACT_APP_API_URL }/api/admin/v1/lines`,
  headers: {
    'auth-token': localStorage.getItem('token'),
    // 'Access-Control-Allow-Origin': '*',
    // 'Content-Type': 'application/json'
  },
  data: payload
})
.then(response => {
  return response;
})
.catch(error => {
  actions.setError(error.response);
  logSentryEndpointError(error);
});

export default addLine;