import axios from 'axios';

import { logSentryEndpointError } from '../../shared/utilities';

export const updateRoute = (actions, payload) => axios({
  method: 'put',
  url: `${ process.env.REACT_APP_API_URL }/api/admin/v1/routes/${ payload.routeId }`,
  headers: {
    'auth-token': localStorage.getItem('token'),
    // 'Access-Control-Allow-Origin': '*',
    // 'Content-Type': 'application/json'
  },
  data: payload.data
})
.then(response => {
})
.catch(error => {
  actions.setError(error.response);
  logSentryEndpointError(error);
});

export const updateRoutePlace = (actions, payload) => axios({
  method: 'put',
  url: `${ process.env.REACT_APP_API_URL }/api/admin/v1/route_places/${ payload.placeId }`,
  headers: {
    'auth-token': localStorage.getItem('token'),
    // 'Access-Control-Allow-Origin': '*',
    // 'Content-Type': 'application/json'
  },
  data: payload.data
})
.then(response => {
})
.catch(error => {
  actions.setError(error.response);
  logSentryEndpointError(error);
});

export const updateRouteSchedule = (actions, payload) => axios({
  method: 'put',
  url: `${ process.env.REACT_APP_API_URL }/api/admin/v1/route_schedules/${ payload.scheduleId }`,
  headers: {
    'auth-token': localStorage.getItem('token'),
    // 'Access-Control-Allow-Origin': '*',
    // 'Content-Type': 'application/json'
  },
  data: payload.data
})
.then(response => {
})
.catch(error => {
  actions.setError(error.response);
  logSentryEndpointError(error);
});

export const updateRouteVehicle = (actions, payload) => axios({
  method: 'put',
  url: `${ process.env.REACT_APP_API_URL }/api/admin/v1/route_vehicles/${ payload.vehicleId }`,
  headers: {
    'auth-token': localStorage.getItem('token'),
    // 'Access-Control-Allow-Origin': '*',
    // 'Content-Type': 'application/json'
  },
  data: payload.data
})
.then(response => {
})
.catch(error => {
  actions.setError(error.response);
  logSentryEndpointError(error);
});

export const generateRouteToken = (actions, payload) => axios({
  method: 'put',
  url: `${ process.env.REACT_APP_API_URL }/api/admin/v1/routes/${ payload.routeId }/generate_token`,
  headers: {
    'auth-token': localStorage.getItem('token'),
    // 'Access-Control-Allow-Origin': '*',
    // 'Content-Type': 'application/json'
  },
  data: { direction: payload.direction }
})
.then(response => {
})
.catch(error => {
  actions.setError(error.response);
  logSentryEndpointError(error);
});
