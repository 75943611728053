import React from 'react';

import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import AirportShuttleIcon from '@material-ui/icons/AirportShuttle';
import Tooltip from '@material-ui/core/Tooltip';

import ReactMapGL, { Marker, NavigationControl, FlyToInterpolator, FullscreenControl } from 'react-map-gl';

import { mqttService } from '../../services/mqtt/mqtt';

const ZOOM = 9;
const MAX_ZOOM = 17;
const MIN_ZOOM = 6;

const useStyles = makeStyles(theme => ({
  root: {
    height: props => props.height100 ? '100%' : '500px',
    minHeight: '500px',
    position: 'relative',
    width: '100%',
    [`${theme.breakpoints.down('xs')}`]: {
      height: 'calc(50vh)',
    },
    '& .mapboxgl-ctrl-logo, & .mapboxgl-ctrl-bottom-right': {
      display: 'none',
    },
  },
  navigation: {
    bottom: '33px',
    position: 'absolute',
    right: '17px',
    zIndex: 3,
    '& > div': {
      borderRadius: '16px',
      boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
    },
    '& .mapboxgl-ctrl-icon:focus': {
      boxShadow: 'none',
    },
  },
  marker: {
    color: theme.palette.secondary.dark,
    cursor: 'pointer !important'
  },
}));

const TripsMap = (props) => {
  const { vehicleIds, vehiclePlates } = props;
  const classes = useStyles(props);
  const [markers, setMarkers] = React.useState([]);

  const [viewport, setViewport] = React.useState({
    latitude: 9.9360366,
    longitude: -84.1007609,
    zoom: ZOOM,
    maxZoom: MAX_ZOOM,
    minZoom: MIN_ZOOM,
  });

  const onCenterMapOnMarkerHandler = (latitude, longitude) => {
    const newViewport = {
      ...viewport,
      latitude: latitude,
      longitude: longitude,
      zoom: MAX_ZOOM,
      transitionDuration: 1000,
      transitionInterpolator: new FlyToInterpolator(),
    };

    setViewport( newViewport );
  }

  React.useEffect(() => {
    mqttService.connect(
      'vehicles',
      { ident: process.env.REACT_APP_VEHICLES_MQTT_TOPIC, options: {} },
      message => {
        const jsonMessage = JSON.parse(message.payloadString);
        // console.log('jsonMessage', jsonMessage);

        if (
          jsonMessage['ident']
          && jsonMessage['position.latitude']
          && jsonMessage['position.longitude']
          && vehicleIds.includes(jsonMessage['ident'])
        ) {
          const vehicleIndex = vehicleIds.indexOf(jsonMessage['ident']);
          const messageMarker = { 
            id: jsonMessage['ident'],
            latitude: jsonMessage['position.latitude'],
            longitude: jsonMessage['position.longitude'],
            tooltip: vehiclePlates[vehicleIndex]
          };

          setMarkers(state => {
            let updatedMarkers = [...state];
            const findMarkerIndex = updatedMarkers.findIndex(m => m.id === messageMarker.id);
            if ( findMarkerIndex !== -1 ) {
              updatedMarkers[findMarkerIndex] = messageMarker;
            } else {
              updatedMarkers = [...updatedMarkers, messageMarker]
            }

            // console.log('findMarkerIndex', findMarkerIndex);
            // console.log('updatedMarkers', updatedMarkers);
            
            return updatedMarkers;
          });
        }
      }
    );

    return () => {
      mqttService.disconnect();
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vehicleIds]);

  return (
    <div className={ classes.root }>
      <ReactMapGL
        { ...viewport }
        width="100%"
        height="100%"
        // mapStyle={ `mapbox://styles/mapbox/${ theme }-v10` }
        onViewportChange={ viewport => {
          if ( viewport.zoom >= MIN_ZOOM && viewport.zoom <= MAX_ZOOM ) {
            setViewport(viewport);
          }
        } }
        mapStyle="mapbox://styles/mapbox/streets-v11"
        mapboxApiAccessToken={ process.env.REACT_APP_MAP_TOKEN }
      >
        <NavigationControl className={ classes.navigation } showCompass={ false } />
        <FullscreenControl />
        { markers.map(marker => <Marker
          key={ marker.id }
          className={ classes.marker }
          latitude={ marker.latitude }
          longitude={ marker.longitude }
          onClick={ ()=> onCenterMapOnMarkerHandler(marker.latitude, marker.longitude) }
        >
          <Tooltip title={ marker.tooltip }>
            <AirportShuttleIcon />
          </Tooltip>
        </Marker>) }
      </ReactMapGL>
    </div>
  );
}

TripsMap.propTypes = {
  vehicleIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  vehiclePlates: PropTypes.arrayOf(PropTypes.string).isRequired,
  height100: PropTypes.bool,
};

export default TripsMap;
