import { action, thunk } from 'easy-peasy';

import { getLines, getLine } from '../../services/lines/get';
import addLine from '../../services/lines/post';
import updateLine from '../../services/lines/put';
import deleteLine from '../../services/lines/delete';

const linesModel = {
  lines: [],
  line: {},
  loading: false,
  error: '',
  page: 1,
  pageSize: localStorage.getItem('linesPageSize') ? localStorage.getItem('linesPageSize') : 25,
  textSearch: '',
  getLines: thunk(async (actions, payload = {}, { getState, getStoreState }) => {
    actions.setLoading(payload.loading);
    actions.setError('');
    return await getLines(actions, payload, getState(), getStoreState());
  }),
  getLine: thunk(async (actions, payload) => {
    actions.setLoading(true);
    actions.setError('');
    return await getLine(actions, payload);
  }),
  addLine: thunk(async (actions, payload) => {
    actions.setError('');
    return await addLine(actions, payload);
  }),
  updateLine: thunk(async (actions, payload) => {
    actions.setError('');
    return await updateLine(actions, payload);
  }),
  deleteLine: thunk(async (actions, payload) => {
    actions.setError('');
    return await deleteLine(actions, payload);
  }),
  storeGetLines: action((state, payload) => {
    state.lines = payload.data;
    state.page = payload.page ? payload.page : 1;
    state.textSearch = payload.textSearch ? payload.textSearch : '';
  }),
  storeGetLine: action((state, payload) => {
    state.line = payload;
  }),
  setLoading: action((state, payload) => {
    state.loading = payload;
  }),
  setError: action((state, payload) => {
    state.error = payload;
  }),
  setTextSearch: action((state, payload) => {
    state.textSearch = payload;
  }),
}

export default linesModel;
