import axios from 'axios';

import { logSentryEndpointError } from '../../shared/utilities';

export const addTrip = (actions, payload) => axios({
  method: 'post',
  url: `${ process.env.REACT_APP_API_URL }/api/v1/trips`,
  headers: {
    'auth-token': localStorage.getItem('token'),
    // 'Access-Control-Allow-Origin': '*',
    // 'Content-Type': 'application/json'
  },
  data: payload
})
.then(response => {
})
.catch(error => {
  actions.setError(error.response);
  logSentryEndpointError(error);
});

export const updateTripStatus = (actions, payload) => axios({
  method: 'post',
  url: `${ process.env.REACT_APP_API_URL }/api/v1/trips/${ payload.id }/events`,
  headers: {
    'auth-token': localStorage.getItem('token'),
    // 'Access-Control-Allow-Origin': '*',
    // 'Content-Type': 'application/json'
  },
  data: {
    status: payload.status,
    ...(payload.note && { notes: payload.note }),
  }
})
.then(response => {
  actions.storeUpdateTripStatus(payload);
})
.catch(error => {
  actions.setError(error.response);
  logSentryEndpointError(error);
});

export const generateTrips = (actions, payload) => axios({
  method: 'post',
  url: `${ process.env.REACT_APP_API_URL }/api/admin/v1/trips/generate_trips`,
  headers: {
    'auth-token': localStorage.getItem('token'),
    // 'Access-Control-Allow-Origin': '*',
    // 'Content-Type': 'application/json'
  },
  params: payload,
})
.then(response => {
  actions.setError('');
  return response;
})
.catch(error => {
  actions.setError(error.response);
  logSentryEndpointError(error);
});

export const generateReservations = (actions, payload) => axios({
  method: 'post',
  url: `${ process.env.REACT_APP_API_URL }/api/admin/v1/reservations/generate_reservations`,
  headers: {
    'auth-token': localStorage.getItem('token'),
    // 'Access-Control-Allow-Origin': '*',
    // 'Content-Type': 'application/json'
  },
  params: payload,
})
.then(response => {
  actions.setError('');
  return response;
})
.catch(error => {
  actions.setError(error.response);
  logSentryEndpointError(error);
});

export const assignReservations = (actions, payload) => axios({
  method: 'post',
  url: `${ process.env.REACT_APP_API_URL }/api/admin/v1/reservations/assign_to_trips`,
  headers: {
    'auth-token': localStorage.getItem('token'),
    // 'Access-Control-Allow-Origin': '*',
    // 'Content-Type': 'application/json'
  },
  params: payload,
})
.then(response => {
  actions.setError('');
  return response;
})
.catch(error => {
  actions.setError(error.response);
  logSentryEndpointError(error);
});
