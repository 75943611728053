import axios from 'axios';

import { logSentryEndpointError } from '../../shared/utilities';

let lastPayload = {};

export const getRoutes = (actions, payload, state, storeState) => {
  payload = payload.clientChange ? lastPayload : payload;
  lastPayload = payload;
  return axios({
    method: 'get',
    url: `${ process.env.REACT_APP_API_URL }/api/admin/v1/routes`,
    params: {
      page: payload.page ? payload.page : state.page,
      pageSize: state.pageSize,
      ...(storeState.global.clientSelected !== 'all' && !storeState.global.clientsDisabled && { clientId: storeState.global.clientSelected }),
      ...(!!payload.clientId && { clientId: payload.clientId }),
      textSearch: payload.textSearch,
      ...(!!payload.active && { active: payload.active }),
      ...(!!payload.lineId && { lineId: payload.lineId }),
      ...(!!payload.direction && { filter: JSON.stringify({
        direction: [payload.direction],
      }), }),
    },
    headers: {
      'auth-token': localStorage.getItem('token'),
      // 'Access-Control-Allow-Origin': '*',
      // 'Content-Type': 'application/json'
    }
  })
  .then(response => {
    actions.setLoading(false);
    actions.storeGetRoutes({ update: !payload.noUpdateList, data: response.data, page: payload.page || state.page, textSearch: payload.textSearch === undefined ? state.textSearch : payload.textSearch });
  })
  .catch(error => {
    actions.setLoading(false);
    actions.setError(error.response);
    logSentryEndpointError(error);
  });
}

export const getRoute = (actions, payload) => axios({
  method: 'get',
  url: `${ process.env.REACT_APP_API_URL }/api/admin/v1/routes/${ payload }`,
  headers: {
    'auth-token': localStorage.getItem('token'),
    // 'Access-Control-Allow-Origin': '*',
    // 'Content-Type': 'application/json'
  }
})
.then(response => {
  actions.setLoading(false);
  actions.storeGetRoute(response.data[0]);
})
.catch(error => {
  actions.setLoading(false);
  actions.setError(error.response);
  logSentryEndpointError(error);
});

export const getRouteVehicles = (actions, payload) => axios({
  method: 'get',
  url: `${ process.env.REACT_APP_API_URL }/api/admin/v1/route_vehicles`,
  params: {
    routeId: payload
  },
  headers: {
    'auth-token': localStorage.getItem('token'),
    // 'Access-Control-Allow-Origin': '*',
    // 'Content-Type': 'application/json'
  }
})
.then(response => {
  actions.setRouteVehiclesLoading(false);
  actions.storeGetRouteVehicles(response.data);
})
.catch(error => {
  actions.setRouteVehiclesLoading(false);
  actions.setRouteVehiclesError(error.response);
  logSentryEndpointError(error);
});

export const getRouteSchedules = (actions, payload) => axios({
  method: 'get',
  url: `${ process.env.REACT_APP_API_URL }/api/admin/v1/route_schedules`,
  params: {
    routeId: payload
  },
  headers: {
    'auth-token': localStorage.getItem('token'),
    // 'Access-Control-Allow-Origin': '*',
    // 'Content-Type': 'application/json'
  }
})
.then(response => {
  actions.setRouteSchedulesLoading(false);
  actions.storeGetRouteSchedules(response.data);
})
.catch(error => {
  actions.setRouteSchedulesLoading(false);
  actions.setRouteSchedulesError(error.response);
  logSentryEndpointError(error);
});

export const getRoutePlaces = (actions, payload) => axios({
  method: 'get',
  url: `${ process.env.REACT_APP_API_URL }/api/v1/route_places`,
  params: {
    routeId: payload
  },
  headers: {
    'auth-token': localStorage.getItem('token'),
    // 'Access-Control-Allow-Origin': '*',
    // 'Content-Type': 'application/json'
  }
})
.then(response => {
  actions.setRoutePlacesLoading(false);
  actions.storeGetRoutePlaces(response.data);
})
.catch(error => {
  actions.setRoutePlacesLoading(false);
  actions.setRoutePlacesError(error.response);
  logSentryEndpointError(error);
});

export const getRouteClients = (actions, payload) => axios({
  method: 'get',
  url: `${ process.env.REACT_APP_API_URL }/api/admin/v1/route_clients`,
  params: {
    routeId: payload
  },
  headers: {
    'auth-token': localStorage.getItem('token'),
    // 'Access-Control-Allow-Origin': '*',
    // 'Content-Type': 'application/json'
  }
})
.then(response => {
  actions.setRouteClientsLoading(false);
  actions.storeGetRouteClients(response.data);
})
.catch(error => {
  actions.setRouteClientsLoading(false);
  actions.setRouteClientsError(error.response);
  logSentryEndpointError(error);
});

export const getRouteLines = (actions, payload) => axios({
  method: 'get',
  url: `${ process.env.REACT_APP_API_URL }/api/admin/v1/line_routes`,
  params: {
    routeId: payload
  },
  headers: {
    'auth-token': localStorage.getItem('token'),
    // 'Access-Control-Allow-Origin': '*',
    // 'Content-Type': 'application/json'
  }
})
.then(response => {
  actions.setRouteLinesLoading(false);
  actions.storeGetRouteLines(response.data);
})
.catch(error => {
  actions.setRouteLinesLoading(false);
  actions.setRouteLinesError(error.response);
  logSentryEndpointError(error);
});