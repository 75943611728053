import React from 'react';

import PropTypes from 'prop-types';

import { makeStyles, fade } from '@material-ui/core/styles';
import Skeleton from '@material-ui/lab/Skeleton';

const useStyles = makeStyles(theme => ({
  card: {
    borderRadius: '8px',
    marginBottom: props => props.marginBottom ? `${ props.marginBottom }px` : 0,
    '&:last-of-type': {
      marginBottom: 0,
    },
  },
  skeletonRoot: {
    backgroundColor: fade(
      theme.palette.text.primary,
      theme.palette.type === 'light' ? 0.11 : 0.13,
    ),
  },
}));

const LoadingCard = props => {
  const classes = useStyles(props);

  return (
    [...Array(props.length).keys()].map((_, index) => (
      <Skeleton classes={{ root: classes.skeletonRoot }} key={ index } className={ classes.card } variant="rect" width={ props.width } height={ props.height } />
    ))
  );
}

LoadingCard.propTypes = {
  length: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  marginBottom: PropTypes.number,
};

export default LoadingCard;
