import React from 'react';

import PropTypes from 'prop-types';

import { useStoreState, useStoreActions, useStore } from 'easy-peasy';

// import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import PersonIcon from '@material-ui/icons/Person';

import * as Yup from 'yup';

import { Formik, Field } from 'formik';

import { handleEndpointErrors, SNACKBAR_TIME } from '../../shared/utilities';
import { useDebounce } from '../../shared/hooks/useDebounce';

import LoadingCard from '../LoadingCard';
import CustomFormikForm from '../CustomFormikForm';
import FormikAutocomplete from '../FormikAutocomplete';

import { useTranslation } from 'react-multi-lang';

// const useStyles = makeStyles(theme => ({
//   root: {},
// }));

const AddDriver = (props) => {
  const { routeId, vehicleId, driverIds, onGetRouteVehicles, onAddDriverModalClose } = props;
  // const classes = useStyles();

  const store = useStore();

  const setSnackbar = useStoreActions(actions => actions.global.setSnackbar);

  const addVehicleDriver = useStoreActions(actions => actions.vehicles.addVehicleDriver);

  const getUsers = useStoreActions(actions => actions.users.getUsers);
  const users = useStoreState(state => state.users.users);
  const usersLoading = useStoreState(state => state.users.loading);
  
  const [userName, setUserName] = React.useState('');
  const debouncedUserName = useDebounce(userName, 500);

  const onGetUsers = (payload) => {
    getUsers(payload).then(() => {
      const usersState = store.getState().users;
      if (!usersState.loading && !usersState.error) {
        // console.log(usersState);
      } else {
        handleEndpointErrors(usersState, props, setSnackbar, t);
      }
    });
  }

  React.useEffect(() => {
    onGetUsers({ role: 'driver', textSearch: debouncedUserName });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedUserName]);

  const t = useTranslation();

  return (
    <React.Fragment>
      <DialogTitle>{ t('route.drivers.add.title') }</DialogTitle>
      <Formik
        initialValues={{ driver: '' }}
        validationSchema={ Yup.object({
          driver: Yup.string()
            // .max(15, 'Must be 15 characters or less')
            .required(t('global.errors.required', { field: t('route.drivers.add.driver') })),
        }) }
        onSubmit={(values, { setSubmitting }) => {
          const data = {
            vehicleId,
            driverId: values.driver.id
          };
          addVehicleDriver(data).then(() => {
            const vehiclesState = store.getState().vehicles;
            if (!vehiclesState.loading && !vehiclesState.error) {
              setSubmitting(false);
              onGetRouteVehicles(routeId);
              onAddDriverModalClose();
              setSnackbar({ show: true, autoHideDuration: SNACKBAR_TIME.SUCCESS, severity: 'success', message: t('global.success.saved') });
            } else {
              handleEndpointErrors(vehiclesState, props, setSnackbar, t);
            }
          });
        }}
      >
        {({ submitForm, isSubmitting }) => (
          <CustomFormikForm>
            <DialogContent>
              <Grid container spacing={ 3 }>
                <Grid item xs={ 6 } md={ 4 }>
                  { usersLoading ? <LoadingCard length={ 1 } height={ 56 } /> : (
                    <Field
                      component={ FormikAutocomplete }
                      name='driver'
                      disableClearable
                      options={ users.filter(user => !driverIds.includes(user.id)) }
                      getOptionLabel={ option => option ? option.name : '' }
                      textFieldProps={{
                        label: t('route.drivers.add.driver'),
                        variant: 'outlined',
                        fullWidth: true,
                        icon: <PersonIcon />,
                        onChange: event => {
                          setUserName( event.target.value );
                        }
                      }}
                      onFocus={() => {
                        setUserName('');
                      }}
                    />
                  ) }
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={ onAddDriverModalClose } color="primary">{ t('global.modal.btnClose') }</Button>
              <Button disabled={ isSubmitting } onClick={ submitForm } color="primary">{ t('global.modal.btnAdd') }</Button>
            </DialogActions>
          </CustomFormikForm>
        )}
      </Formik>
    </React.Fragment>
  );
}

AddDriver.propTypes = {
  routeId: PropTypes.number.isRequired,
  vehicleId: PropTypes.number.isRequired,
  driverIds: PropTypes.array.isRequired,
  onGetRouteVehicles: PropTypes.func.isRequired,
  onAddDriverModalClose: PropTypes.func.isRequired,
};

export default AddDriver;
