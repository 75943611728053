import { withTheme, styled } from '@material-ui/core/styles';
import { ResponsiveContainer } from 'recharts';

const ChartContainer = styled(ResponsiveContainer)({
  minHeight: '350px !important',
  '& .recharts-default-tooltip': {
    border: props => `1px solid ${ props.theme.palette.divider } !important`,
    borderRadius: props => props.theme.spacing(1),
    backgroundColor: props => `${ props.theme.palette.background.paper } !important`
  },
  '& .recharts-tooltip-cursor': {
    fill: props => `${ props.theme.palette.divider } !important`,
  },
  '& .recharts-pie .recharts-tooltip-item': {
    color: props => `${ props.theme.palette.text.primary } !important`,
  },
  '& .recharts-tooltip-label': {
    color: props => props.theme.palette.text.primary,
  },
  '& text': {
    fill: props => props.theme.palette.text.primary,
  },
  '& .recharts-pie .recharts-layer text': {
    fill: props => props.theme.palette.common.white,
  },
  '& line': {
    fill: props => props.theme.palette.divider,
  },
});

export default withTheme(ChartContainer);
