import React from 'react';

import PropTypes from 'prop-types';

import { useStoreState, useStoreActions, useStore } from 'easy-peasy';

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import { handleEndpointErrors, SNACKBAR_TIME } from '../../shared/utilities';

import Table from '../Table';
import CustomDialog from '../CustomDialog';
import AddDriver from './AddDriver';

import { useTranslation } from 'react-multi-lang';

const useStyles = makeStyles(theme => ({
  table: {
  }
}));

const Drivers = (props) => {
  const { editVehicle, onGetVehicles, onDriversVehicleModalClose } = props;
  const classes = useStyles();
  const [driversTableData, setDriversTableData] = React.useState([]);
  const [addDriverModalOpen, setAddDriverModalOpen] = React.useState(false);

  const store = useStore();

  const setSnackbar = useStoreActions(actions => actions.global.setSnackbar);

  const vehicles = useStoreState(state => state.vehicles.vehicles);
  const deleteVehicleDriver = useStoreActions(actions => actions.vehicles.deleteVehicleDriver);

  const t = useTranslation();

  const driversTableColumns = [
    { title: t('route.drivers.id'), field: 'id', type: 'numeric', align: 'left', defaultSort: 'asc', editable: 'never' },
    { title: t('route.drivers.identifier'), field: 'identifier', sorting: false, editable: 'never' },
    { title: t('route.drivers.name'), field: 'name', sorting: false, editable: 'never' },
    { title: t('route.drivers.email'), field: 'email', sorting: false, editable: 'never' },
    { title: t('route.drivers.phoneNumber'), field: 'phoneNumber', sorting: false, editable: 'never' },
    { title: t('route.drivers.active'), field: 'active', type: 'boolean', sorting: false, editable: 'never' },
  ];

  React.useEffect(() => {
    setDriversTableData(vehicles.find(vehicle => vehicle.id === editVehicle.id).drivers.map(driver => ({ ...driver })));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vehicles.find(vehicle => vehicle.id === editVehicle.id).drivers]);

  const onDeleteVehicleDriver = (oldData, resolve, reject) => {
    deleteVehicleDriver(oldData.VehicleDriver.id).then(() => {
      const vehiclesState = store.getState().vehicles;
      if (!vehiclesState.error) {
        // console.log('success', vehiclesState);
        resolve();
        const data = [...driversTableData].filter(driver => driver.id !== oldData.id);
        setDriversTableData(data);
        setSnackbar({ show: true, autoHideDuration: SNACKBAR_TIME.SUCCESS, severity: 'success', message: t('global.success.saved') });
        onGetVehicles();
      } else {
        reject();
        handleEndpointErrors(vehiclesState, props, setSnackbar, t);
      }
    });
  };

  const onAddDriverModalClose = () => {
    setAddDriverModalOpen(false);
  };

  return (
    <React.Fragment>
      <DialogTitle>{ t('route.drivers.title') } - { editVehicle.licensePlate } - { editVehicle.make } { editVehicle.model } { editVehicle.year }</DialogTitle>
      <DialogContent>
        <Table
          className={ classes.table }
          title=""
          columns={ driversTableColumns }
          data={ driversTableData }
          paging={ false }
          deleteEvent={ onDeleteVehicleDriver }
          addActions={[{
            icon: 'add',
            iconProps: {
              color: 'primary'
            },
            tooltip: t('route.drivers.add.action'),
            isFreeAction: true,
            onClick: (_, rowData) => {
              setAddDriverModalOpen(true);
            }
          }]}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={ onDriversVehicleModalClose } color="primary">{ t('global.modal.btnClose') }</Button>
      </DialogActions>

      <CustomDialog open={ addDriverModalOpen } onClose={ onAddDriverModalClose } fullWidth={ true } maxWidth="md">
        <AddDriver
          vehicleId={ editVehicle.id }
          driverIds={ driversTableData.map(driver => driver.id) }
          onGetVehicles={ onGetVehicles }
          onAddDriverModalClose={ onAddDriverModalClose }
        />
      </CustomDialog>
    </React.Fragment>
  );
}

Drivers.propTypes = {
  editVehicle: PropTypes.object.isRequired,
  onGetVehicles: PropTypes.func.isRequired,
  onDriversVehicleModalClose: PropTypes.func.isRequired,
};

export default Drivers;
