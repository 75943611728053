import axios from 'axios';

import { logSentryEndpointError } from '../../shared/utilities';

export const addRoute = (actions, payload) => axios({
  method: 'post',
  url: `${ process.env.REACT_APP_API_URL }/api/admin/v1/routes`,
  headers: {
    'auth-token': localStorage.getItem('token'),
    // 'Access-Control-Allow-Origin': '*',
    // 'Content-Type': 'application/json'
  },
  data: payload
})
.then(response => {
  actions.storeAddRoute(response.data.id);
})
.catch(error => {
  actions.setError(error.response);
  logSentryEndpointError(error);
});

export const addRouteClient = (actions, payload) => axios({
  method: 'post',
  url: `${ process.env.REACT_APP_API_URL }/api/admin/v1/route_clients`,
  headers: {
    'auth-token': localStorage.getItem('token'),
    // 'Access-Control-Allow-Origin': '*',
    // 'Content-Type': 'application/json'
  },
  data: payload
})
.then(response => {
})
.catch(error => {
  actions.setError(error.response);
  logSentryEndpointError(error);
});

export const addRoutePlace = (actions, payload) => axios({
  method: 'post',
  url: `${ process.env.REACT_APP_API_URL }/api/admin/v1/route_places`,
  headers: {
    'auth-token': localStorage.getItem('token'),
    // 'Access-Control-Allow-Origin': '*',
    // 'Content-Type': 'application/json'
  },
  data: payload
})
.then(response => {
})
.catch(error => {
  actions.setError(error.response);
  logSentryEndpointError(error);
});

export const addRouteSchedule = (actions, payload) => axios({
  method: 'post',
  url: `${ process.env.REACT_APP_API_URL }/api/admin/v1/route_schedules`,
  headers: {
    'auth-token': localStorage.getItem('token'),
    // 'Access-Control-Allow-Origin': '*',
    // 'Content-Type': 'application/json'
  },
  data: payload
})
.then(response => {
})
.catch(error => {
  actions.setError(error.response);
  logSentryEndpointError(error);
});

export const addRouteVehicle = (actions, payload) => axios({
  method: 'post',
  url: `${ process.env.REACT_APP_API_URL }/api/admin/v1/route_vehicles`,
  headers: {
    'auth-token': localStorage.getItem('token'),
    // 'Access-Control-Allow-Origin': '*',
    // 'Content-Type': 'application/json'
  },
  data: payload
})
.then(response => {
})
.catch(error => {
  actions.setError(error.response);
  logSentryEndpointError(error);
});

export const addRouteLine = (actions, payload) => axios({
  method: 'post',
  url: `${ process.env.REACT_APP_API_URL }/api/admin/v1/line_routes`,
  headers: {
    'auth-token': localStorage.getItem('token'),
    // 'Access-Control-Allow-Origin': '*',
    // 'Content-Type': 'application/json'
  },
  data: payload
})
.then(response => {
})
.catch(error => {
  actions.setError(error.response);
  logSentryEndpointError(error);
});
