import React from 'react';

import PropTypes from 'prop-types';

import { useStoreState, useStoreActions, useStore } from 'easy-peasy';

import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Typography from '@material-ui/core/Typography';

import { handleEndpointErrors, SNACKBAR_TIME } from '../../shared/utilities';

import LoadingCard from '../LoadingCard';

import { useTranslation } from 'react-multi-lang';

const useStyles = makeStyles(theme => ({
  root: {
    '& > .MuiGrid-root:nth-child(2)': {
      paddingTop: theme.spacing(3),
    },
    [theme.breakpoints.up('sm')]: {
      '& > .MuiGrid-root:nth-child(2)': {
        display: 'flex',
        justifyContent: 'flex-end',
        padding: 0,
      },
    },
  },
  select: {
    maxWidth: `calc(100% - ${ theme.spacing(1) }px)`,
    minWidth: 120,
    '& .MuiInputBase-root': {
      ...theme.typography.overline,
    },
    '& .MuiOutlinedInput-root': {
      borderRadius: theme.spacing(3),
    },
    '& .MuiOutlinedInput-input': {
      paddingBottom: 0,
      paddingTop: 0,
    },
  },
}));

const TripInfo = (props) => {
  const { trip } = props;
  const classes = useStyles();

  const store = useStore();

  const setSnackbar = useStoreActions(actions => actions.global.setSnackbar);

  const getTrip = useStoreActions(actions => actions.trips.getTrip);
  const updateTrip = useStoreActions(actions => actions.trips.updateTrip);

  const routeVehicles = useStoreState(state => state.routes.routeVehicles);
  const routeVehiclesLoading = useStoreState(state => state.routes.routeVehiclesLoading);
  const getRouteVehicles = useStoreActions(actions => actions.routes.getRouteVehicles);

  React.useEffect(() => {
    getRouteVehicles(trip.route.id).then(() => {
      const routesState = store.getState().routes;
      if (!routesState.routeVehiclesLoading && !routesState.routeVehiclesError) {
        // console.log(routesState);
      } else {
        handleEndpointErrors({ ...routesState, loading: routesState.routeVehiclesLoading, error: routesState.routeVehiclesError }, props, setSnackbar, t);
      }
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const t = useTranslation();

  const onDriverUpdate = driverId => {
    updateTrip({ tripId: trip.id, data: { driverId } }).then(() => {
      const tripsState = store.getState().trips;
      if (!tripsState.loading && !tripsState.error) {
        // console.log('success', tripsState);
        getTrip({ id: trip.id });
        setSnackbar({ show: true, autoHideDuration: SNACKBAR_TIME.SUCCESS, severity: 'success', message: t('global.success.saved') });
      } else {
        handleEndpointErrors(tripsState, props, setSnackbar, t);
      }
    });
  };

  const onVehicleUpdate = vehicleId => {
    const drivers = routeVehicles.find(vehicle => vehicle.vehicleId === vehicleId).drivers;
    const defaultDriver = drivers.find(driver => driver.default);
    const driverId = defaultDriver ? defaultDriver.id : drivers[0].id;
    updateTrip({ tripId: trip.id, data: { vehicleId, ...(drivers.length && { driverId }) } }).then(() => {
      const tripsState = store.getState().trips;
      if (!tripsState.loading && !tripsState.error) {
        // console.log('success', tripsState);
        getTrip({ id: trip.id });
        setSnackbar({ show: true, autoHideDuration: SNACKBAR_TIME.SUCCESS, severity: 'success', message: t('global.success.saved') });
      } else {
        handleEndpointErrors(tripsState, props, setSnackbar, t);
      }
    });
  };

  return (
    <Grid className={ classes.root } container alignItems="center">
      <Grid item xs={ 12 } sm={ 7 }>
        { routeVehiclesLoading ? <LoadingCard width="200px" length={ 1 } height={ 31 } /> : <FormControl variant="outlined" className={ classes.select }>
          <InputLabel>{ t('trips.vehicle') }</InputLabel>
          <Select
            value={ trip.vehicle.id }
            disabled={ ['completed', 'cancelled'].includes(trip.status) }
            onChange={ event => onVehicleUpdate(event.target.value) }
            label={ t('trips.vehicle') }
          >
            { routeVehicles.map(vehicle => <MenuItem key={ vehicle.vehicleId } value={ vehicle.vehicleId }>{`${ vehicle.licensePlate } - ${ vehicle.make } ${ vehicle.model }, ${ vehicle.year }`}</MenuItem>) }
          </Select>
        </FormControl> }
      </Grid>
      <Grid item xs={ 12 } sm={ 5 }>
        { routeVehiclesLoading ? <LoadingCard width="200px" length={ 1 } height={ 31 } /> : routeVehicles.find(vehicle => vehicle.vehicleId === trip.vehicle.id)?.drivers.length ? (
            <FormControl variant="outlined" className={ classes.select }>
              <InputLabel>{ t('trips.driver') }</InputLabel>
              <Select
                value={ trip.driver.id }
                disabled={ ['completed', 'cancelled'].includes(trip.status) }
                onChange={ event => onDriverUpdate(event.target.value) }
                label={ t('trips.driver') }
              >
                { routeVehicles.find(vehicle => vehicle.vehicleId === trip.vehicle.id).drivers.map(driver => <MenuItem key={ driver.id } value={ driver.id }>{`${ driver.id } - ${ driver.name }`}</MenuItem>) }
              </Select>
            </FormControl>
          ) : (
            <Typography variant="caption">{ t('trips.noDrivers') }</Typography>
          ) }
      </Grid>
    </Grid>
  );
}

TripInfo.propTypes = {
  trip: PropTypes.object.isRequired,
};

export default TripInfo;
