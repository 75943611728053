import { action, thunk } from 'easy-peasy';

import getDashboardData from '../../services/dashboard/get';

const dashboardModel = {
  data: {},
  error: '',
  getDashboardData: thunk(async (actions, payload = {}) => {
    actions.setError('');
    return await getDashboardData(actions, payload);
  }),
  storeGetDashboardData: action((state, payload) => {
    state.data = payload;
  }),
  setError: action((state, payload) => {
    state.error = payload;
  }),
}

export default dashboardModel;
