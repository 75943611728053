import React from 'react';
// import { useHistory } from 'react-router-dom';

import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';

import ChartContainer from './ChartContainer';

import { useTranslation } from 'react-multi-lang';

const useStyles = makeStyles(theme => ({
  root: {
    '& .recharts-cartesian-grid-horizontal': {
      '& line': {
        stroke: theme.palette.divider,
      },
    },
  },
  tooltip: {
    background: theme.palette.background.default,
    border: `2px solid ${ theme.palette.type === 'dark' ? theme.palette.grey[700] : theme.palette.divider }`,
    color: theme.palette.text.primary,
    padding: theme.spacing(1),
    borderRadius: theme.spacing(1),
  },
}));

const CustomBarChart = (props) => {
  const { data, xField, yField, barColor, showTooltip, showLegend } = props;
  // const history = useHistory();
  const classes = useStyles();

  const t = useTranslation();

  const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div className={ classes.tooltip }>
        <Typography variant="caption" component="p"><strong>{ t('dashboard.reservationsPerDay.date') }:</strong> { label }</Typography>
        <Typography variant="caption" component="p"><strong>{ t('dashboard.reservationsPerDay.reservations') }:</strong> { payload[0].value }</Typography>
      </div>
    );
  }

  return null;
};

  return (
    <div className={ classes.root }>
      <ChartContainer>
        {/* <BarChart data={ data } onClick={(chart) => {
          const date = chart?.activePayload[0]?.payload?.date;
          const status = encodeURIComponent(JSON.stringify(['approved', 'onboard', 'underway', 'finished']));
          if (date) {
            history.push({ pathname: '/reservations', search: `?status=${ status }&date=${ date }` });
          }
        }} cursor="pointer"> */}
        <BarChart data={ data }>
          <CartesianGrid vertical={ false } />
          <XAxis dataKey={ xField } axisLine={ false } />
          <YAxis axisLine={ false } tickLine={ false } />
          { showTooltip !== false && <Tooltip content={<CustomTooltip />} /> }
          { showLegend && <Legend /> }
          <Bar dataKey={ yField } fill={ barColor } radius={[5, 5, 5, 5]} />
        </BarChart>
      </ChartContainer>
    </div>
  );
}

CustomBarChart.propTypes = {
  data: PropTypes.array.isRequired,
  xField: PropTypes.string.isRequired,
  yField: PropTypes.string.isRequired,
  barColor: PropTypes.string.isRequired,
  showTooltip: PropTypes.bool,
  showLegend: PropTypes.bool
};

export default CustomBarChart;
