import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormHelperText from '@material-ui/core/FormHelperText';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    '& .Mui-checked': {
      color: theme.palette.secondary.dark,
    },
    '& .MuiFormControlLabel-root': {
      marginRight: 0,
    },
  },
}));

const FormikDays = ({
  field,
  form: { setFieldTouched, touched, errors, values, setFieldValue },
  name,
  label,
  ...props
}) => {
  const classes = useStyles();
  const fieldName = name || field.name;

  return (
    <div { ...props }>
      <FormControl component="fieldset" error={ !!touched[fieldName] && !!errors[fieldName] } className={ classes.root }>
        <FormGroup { ...field } name={ fieldName } className={ classes.checkbox }>
          <FormControlLabel
            key={ label }
            label={ label }
            control={ <Checkbox
                        name={ fieldName }
                        checked={ values[fieldName] }
                        disabled={ props.disabled }
                        onChange={ () => {
                          setFieldValue(fieldName, !values[fieldName], false);
                          setFieldTouched(fieldName, false);
                        } }
                      />
            }
          />
        </FormGroup>

        { touched[fieldName] && errors[fieldName] && (
          <FormHelperText>{ errors[fieldName] }</FormHelperText>
        )}
      </FormControl>
    </div>
  );
};

export default FormikDays;
