import axios from 'axios';

import { logSentryEndpointError } from '../../shared/utilities';

let lastPayload = {};

export const getUsers = (actions, payload, state, storeState) => {
  payload = payload.clientChange ? lastPayload : payload;
  lastPayload = payload;
  return axios({
    method: 'get',
    url: `${ process.env.REACT_APP_API_URL }/api/admin/v1/users`,
    params: {
      page: payload.page ? payload.page : state.page,
      pageSize: payload.pageSize || state.pageSize,
      ...(storeState.global.clientSelected !== 'all' && !storeState.global.clientsDisabled && !payload.noClientFilter && { clientId: storeState.global.clientSelected }),
      textSearch: payload.textSearch,
      ...(!!payload.username && { username: payload.username }),
      ...(!!payload.role && { role: payload.role }),
      ...(!!payload.functionalAreaId && { functionalAreaId: payload.functionalAreaId }),
      ...(!!payload.active && { active: payload.active }),
    },
    headers: {
      'auth-token': localStorage.getItem('token'),
      // 'Access-Control-Allow-Origin': '*',
      // 'Content-Type': 'application/json'
    }
  })
  .then(response => {
    actions.setLoading(false);
    actions.storeGetUsers({ data: response.data, page: payload.page || state.page, pageSize: payload.pageSize || state.pageSize, textSearch: payload.textSearch === undefined ? state.textSearch : payload.textSearch });
  })
  .catch(error => {
    actions.setLoading(false);
    actions.setError(error.response);
    logSentryEndpointError(error);
  });
}


export const getUser = (actions, payload) => axios({
  method: 'get',
  url: `${ process.env.REACT_APP_API_URL }/api/admin/v1/users/${ payload }`,
  headers: {
    'auth-token': localStorage.getItem('token'),
    // 'Access-Control-Allow-Origin': '*',
    // 'Content-Type': 'application/json'
  }
})
.then(response => {
  actions.setLoading(false);
  actions.storeGetUser(response.data);
})
.catch(error => {
  actions.setLoading(false);
  actions.setError(error.response);
  logSentryEndpointError(error);
});

export const getUsersActions = (actions) => axios({
  method: 'get',
  url: `${ process.env.REACT_APP_API_URL }/api/admin/v1/users/actions`,
  headers: {
    'auth-token': localStorage.getItem('token'),
    // 'Access-Control-Allow-Origin': '*',
    // 'Content-Type': 'application/json'
  }
})
.then(response => {
  actions.setUsersActionsLoading(false);
  actions.storeGetUsersActions(response.data);
})
.catch(error => {
  actions.setUsersActionsLoading(false);
  actions.setUsersActionsError(error.response);
  logSentryEndpointError(error);
});

export const getFunctionalAreas = (actions) => axios({
  method: 'get',
  url: `${ process.env.REACT_APP_API_URL }/api/v1/functional_areas`,
  params: {
    page: 1,
    pageSize: 25,
    relatedKey: 'all'
  },
  headers: {
    'auth-token': localStorage.getItem('token'),
    // 'Access-Control-Allow-Origin': '*',
    // 'Content-Type': 'application/json'
  }
})
.then(response => {
  actions.storeGetFunctionalAreas(response.data);
})
.catch(error => {
  actions.setError(error.response);
  logSentryEndpointError(error);
});

export const getDevices = (actions, payload) => axios({
  method: 'get',
  url: `${ process.env.REACT_APP_API_URL }/api/v1/user_devices`,
  params: {
    userId: payload
  },
  headers: {
    'auth-token': localStorage.getItem('token'),
    // 'Access-Control-Allow-Origin': '*',
    // 'Content-Type': 'application/json'
  }
})
.then(response => {
  actions.storeGetDevices(response.data);
})
.catch(error => {
  actions.setError(error.response);
  logSentryEndpointError(error);
});

export const getFavoriteRoutes = (actions, payload) => axios({
  method: 'get',
  url: `${ process.env.REACT_APP_API_URL }/api/v1/favorite_routes`,
  params: {
    userId: payload.userId,
    ...(!!payload.clientId && { clientId: payload.clientId }),
  },
  headers: {
    'auth-token': localStorage.getItem('token'),
    // 'Access-Control-Allow-Origin': '*',
    // 'Content-Type': 'application/json'
  }
})
.then(response => {
  actions.storeGetFavoriteRoutes(response.data);
})
.catch(error => {
  actions.setError(error.response);
  logSentryEndpointError(error);
});
