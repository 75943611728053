import React from 'react';

import PropTypes from 'prop-types';

import { useStoreState, useStoreActions, useStore } from 'easy-peasy';

import { makeStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import ClearIcon from '@material-ui/icons/Clear';
import FilterListIcon from '@material-ui/icons/FilterList';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormLabel from '@material-ui/core/FormLabel';
import IconButton from '@material-ui/core/IconButton';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Popover from '@material-ui/core/Popover';
import Select from '@material-ui/core/Select';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

import DateFilters from '../../../DateFilters';
import Status from '../../../Status';
import Tag from '../../../Tag';

import { useTranslation } from 'react-multi-lang';

import { formatDate } from '../../../../shared/utilities';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
  },
  text: {
    alignItems: 'center',
    color: theme.palette.text.secondary,
    display: 'flex',
    '& .MuiSvgIcon-root': {
      marginRight: '5px',
    },
    marginBottom: theme.spacing(2),
  },
  paper: {
    borderRadius: '12px',
    maxWidth: `calc(100vw - ${ theme.spacing(4) }px)`,
    minWidth: '192px',
    padding: theme.spacing(2, 2),
    '& .MuiFormControlLabel-root': {
      textTransform: 'capitalize',
    },
    '& .MuiFormGroup-root': {
      flexDirection: 'row',
      marginBottom: theme.spacing(2),
      '&:last-of-type': {
        marginBottom: 0,
      },
    },
    [theme.breakpoints.up('sm')]: {
      maxWidth: '670px',
    },
  },
  dateLabel: {
    '& + div': {
      marginTop: theme.spacing(2)
    }
  },
  clear: {
    color: theme.palette.primary.main,
    left: '-6px',
    padding: '8px',
    position: 'absolute',
    top: 0,
  },
  chips: {
    alignItems: 'center',
    display: 'flex',
    marginBottom: theme.spacing(2),
    marginLeft: '32px',
    overflow: 'auto',
    position: 'relative',
  },
  select: {
    display: 'block',
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(3),
    '& .MuiOutlinedInput-root': {
      borderRadius: theme.spacing(1.5),
      minWidth: '125px',
    },
    '& .MuiOutlinedInput-input': {
      padding: theme.spacing('18.5px', 5, '18.5px', '14px'),
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderWidth: '2px',
    },
  },
}));

const Filter = (props) => {
  const { filterMenu, onFilterMenuClose, onGetTrips } = props;
  const classes = useStyles();

  const store = useStore();

  const clientSelected = useStoreState(state => state.global.clientSelected);
  const routeTypes = useStoreState(state => state.global.routeTypes);

  const filtersArray = useStoreState(state => state.trips.filtersArray);
  const setFilters = useStoreActions(actions => actions.trips.setFilters);
  const setTripSelected = useStoreActions(actions => actions.trips.setCurrentTrip);
  const getHeatMapTrips = useStoreActions(actions => actions.trips.getHeatMapTrips);

  const businessParks = useStoreState(state => state.businessParks.businessParks);

  const [activeFilters, setActiveFilters] = React.useState(store.getState().trips.filters);

  const t = useTranslation();

  const updateTagText = filter => {
    let name = '';
    switch(filter.type) {
      case 'date':
        const getDate = date => new Date( new Date(date).toLocaleDateString('en') ).toISOString();
        const renderName = filter.name.startDate === filter.name.endDate ?
          `${ formatDate(getDate(filter.name.startDate), t) }` :
          `${ formatDate(getDate(filter.name.startDate), t) } - ${ formatDate(getDate(filter.name.endDate), t) }`;
        name = renderName;
        break;
      case 'parkId':
        name = businessParks.find(park => park.id === filter.name)?.name;
        break;
      case 'direction':
        name = t(`routes.direction.${ filter.name }`);
        break;
      case 'type':
        name = t(`routes.type.${ filter.name }`);
        break;
      case 'tripExtra':
        name = filter.name === 'true' ? t('trips.addTripModal.addTrip.extra.filter.true') : t('trips.addTripModal.addTrip.extra.filter.false');
        break;
      default:
        name = filter.name;
    }
    return name;
  };

  const onParkChange = (parkId) => {
    setActiveFilters({
      ...activeFilters,
      parkId: parkId ? [parkId] : []
    })
  };

  const onOptionChange = (option, filter) => {
    option.checked ? (
      setActiveFilters({
        ...activeFilters,
        [filter]: [ ...activeFilters[filter], option.name ]
      })
    ) : (
      setActiveFilters({
        ...activeFilters,
        [filter]: [...activeFilters[filter]].filter(item => item !== option.name)
      })
    );
  };

  const onUpdateFilters = () => {
    if ( JSON.stringify(store.getState().trips.filters) !== JSON.stringify(activeFilters) ) {
      setFilters(activeFilters);
      setTripSelected({});
      if ( localStorage.getItem('tripsSidebarTab') === '1' ) {
        getHeatMapTrips();
      } else {
        onGetTrips();
      }
    }
    onFilterMenuClose();
  };

  const onClearFilters = () => {
    const filters = store.getState().trips.filters;
    const cleanFilters = filters;
    // eslint-disable-next-line array-callback-return
    Object.keys(filters).map(filterName => {
      cleanFilters[filterName] = [];
    });
    setFilters(cleanFilters);
    if ( localStorage.getItem('tripsSidebarTab') === '1' ) {
      getHeatMapTrips();
    } else {
      onGetTrips();
    }
  };

  const getTripsDate = ({startDate, endDate}) => {
    setActiveFilters({
      ...activeFilters,
      date: startDate && endDate ? [{startDate, endDate}] : []
    });
  };

  React.useEffect(() => {
    setFilters(store.getState().trips.filters);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={ classes.root }>
      <Popover
        classes={{ paper: classes.paper }}
        open={ Boolean(filterMenu) }
        anchorEl={ filterMenu }
        onClose={ onUpdateFilters }
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
      >
        <Typography className={ classes.text } variant="body2"><FilterListIcon /> { t('trips.filter') }</Typography>

        { !!businessParks.length && <FormControl variant="outlined" className={ classes.select }>
          <InputLabel>{ t('trips.addTripModal.generateTrips.businessPark') }</InputLabel>
          <Select
            value={ activeFilters.parkId[0] || '' }
            onChange={ event => onParkChange(event.target.value) }
            label={ t('trips.addTripModal.generateTrips.businessPark') }
            disabled={ clientSelected !== 'all' }
            >
              <MenuItem value=""><em>-</em></MenuItem>
              { businessParks.map(park => <MenuItem key={ park.id } value={ park.id }>{ park.name }</MenuItem>) }
          </Select>
        </FormControl> }

        <FormLabel className={ classes.dateLabel } component="label">{ t('trips.filterDate.label') }</FormLabel>
        <DateFilters onChangeFn={ getTripsDate } isTripsFilter />

        <FormLabel component="label">{ t('trips.status') }</FormLabel>
        <FormGroup>
          { JSON.parse(localStorage.getItem('enums')).tripStatus.map(status => <FormControlLabel
            key={ status }
            control={
              <Checkbox
                checked={ activeFilters.status.includes(status) }
                onChange={ event => onOptionChange(event.target, 'status') }
                name={ status }
                color="secondary"
              />
            }
            label={ t(`global.status.${ status }`) }
          />) }
        </FormGroup>

        <FormLabel component="label">{ t('routes.type.label') }</FormLabel>
        <FormGroup>
          { routeTypes.map(type => <FormControlLabel
            key={ type.id }
            control={
              <Checkbox
                checked={ activeFilters.routeTypes.includes(type.name) }
                onChange={ event => onOptionChange(event.target, 'routeTypes') }
                name={ type.name }
                color="secondary"
              />
            }
            label={ t(`routes.type.${ type.name.toLowerCase() }`) }
          />) }
        </FormGroup>

        <FormLabel component="label">{ t('routes.direction.label') }</FormLabel>
        <FormGroup>
          { ['in', 'out'].map(direction => <FormControlLabel
            key={ direction }
            control={
              <Checkbox
                checked={ activeFilters.routeDirections.includes(direction) }
                onChange={ event => onOptionChange(event.target, 'routeDirections') }
                name={ direction }
                color="secondary"
              />
            }
            label={ t(`routes.direction.${ direction }`) }
          />) }
        </FormGroup>

        <FormLabel component="label">{ t('trips.addTripModal.addTrip.extra.label') }</FormLabel>
        <FormGroup>
          { ['true', 'false'].map(extra => <FormControlLabel
            key={ extra }
            control={
              <Checkbox
                checked={ activeFilters.tripExtra.includes(extra) }
                onChange={ event => onOptionChange(event.target, 'tripExtra') }
                name={ extra }
                color="secondary"
              />
            }
            label={ t(`trips.addTripModal.addTrip.extra.${ extra }`) }
          />) }
        </FormGroup>

      </Popover>
      { filtersArray.length ? (
        <React.Fragment>
          <Tooltip title={ t('trips.clearFilters') }><IconButton
            className={ classes.clear }
            aria-label={ t('trips.clearFilters') }
            onClick={ onClearFilters }
          >
            <ClearIcon />
          </IconButton></Tooltip>
          <div className={ classes.chips }>
            { filtersArray.map(filter => (
              filter.type === 'status' ? <Status key={ filter.name } status={ filter.name } /> : <Tag key={ filter.name } name={ updateTagText(filter) } />
            )) }
          </div>
        </React.Fragment>
      ) : null }
    </div>
  );
}

Filter.propTypes = {
  filterMenu: PropTypes.object,
  onFilterMenuClose: PropTypes.func.isRequired,
  onGetTrips: PropTypes.func.isRequired,
};

export default Filter;
