import * as Sentry from '@sentry/browser';
import bigInt from 'big-integer';

export const SNACKBAR_TIME = {
  SUCCESS: 3000,
  INFO: 4000,
  ERROR: 5000,
};
export const DEFAULT_SNACKBAR = { show: false, autoHideDuration: SNACKBAR_TIME.SUCCESS, severity: 'success', message: '' };
export const NFC_ERROR_STATUS_CODE = 4010;

const clients = ['sertuva'];
export const WHITE_LABEL = clients.find(client => window.location.host.includes(client)) || 'app';

export const IS_STAGE = window.location.host.startsWith('stage.');

export const updateManifestAndIcons = () => {
  const manifest = document.querySelector('link[rel="manifest"]');
  if (manifest) {
    manifest.href = `${ process.env.PUBLIC_URL }/${ WHITE_LABEL }/manifest.json`;
  }

  const icon = document.querySelector('link[rel="icon"]');
  if (icon) {
    icon.href = `${ process.env.PUBLIC_URL }/${ WHITE_LABEL }/favicon.ico`;
  }

  const appleTouchIcon = document.querySelector('link[rel="apple-touch-icon"]');
  if (appleTouchIcon) {
    appleTouchIcon.href = `${ process.env.PUBLIC_URL }/${ WHITE_LABEL }/logo192.png`;
  }
};

/**
 * RegEx for time format (valid: 00:00)
 */
export const TIME_REGEX = /^([01][0-9]|2[0-3]):[0-5][0-9]$/g;

/**
 * RegEx for password
 *   6 characters minimum &
 *   50 character maximum &
 *   At least:
 *     Two lowercase/uppercase characters &
 *     Two numbers &
 */
export const PASSWORD_REGEX = /^(?=.*[a-zA-Z])(?=.*[a-zA-Z])(?=.*\d)(?=.*\d)[A-Za-z\d@$!%*?&_]{6,50}$/;


export const DEVICE_NAMES = ['qr_code', 'push_token', 'nfc_card'];

const AdminActions = {
  UsersActionsEdit: 'admin:users:actions:edit',
  ClientsEdit: 'admin:clients:edit',
  ClientsDelete: 'admin:clients:delete',
  LinesEdit: 'admin:lines:edit',
  LinesDelete: 'admin:lines:delete',
  ParksEdit: 'admin:parks:edit',
  ParksDelete: 'admin:parks:delete',
  RoutesEdit: 'admin:routes:edit',
  RoutesDelete: 'admin:routes:delete',
  RoutesClientsEdit: 'admin:routes:clients:edit',
  RoutesClientsDelete: 'admin:routes:clients:delete',
  RoutesLinesEdit: 'admin:routes:lines:edit',
  RoutesLinesDelete: 'admin:routes:lines:delete',
  OperationalIntervalsEdit: 'admin:operational_intervals:edit',
  OperationalIntervalsDelete: 'admin:operational_intervals:delete',
  DashboardBillingsShow: 'admin:dashboard:billings:show',
}

/**
 * This function `updatePermissions` takes an array of user actions and returns an object with
 * permissions for various features based on the presence of specific action enum in the string array.
 * @param userActions - string array
 */
export const updatePermissions = (userActions) => ({
  admin: {
    userActions: {
      edit: userActions.includes(AdminActions.UsersActionsEdit),
    },
    dashboard: {
      showBillings: userActions.includes(AdminActions.DashboardBillingsShow),
    },
    lines: {
      edit: userActions.includes(AdminActions.LinesEdit),
      delete: userActions.includes(AdminActions.LinesDelete),
    },
    routes: {
      edit: userActions.includes(AdminActions.RoutesEdit),
      delete: userActions.includes(AdminActions.RoutesDelete),
      editLines: userActions.includes(AdminActions.RoutesLinesEdit),
      deleteLines: userActions.includes(AdminActions.RoutesLinesDelete),
      editClients: userActions.includes(AdminActions.RoutesClientsEdit),
      deleteClients: userActions.includes(AdminActions.RoutesClientsDelete),
    },
    businessParks: {
      edit: userActions.includes(AdminActions.ParksEdit),
      delete: userActions.includes(AdminActions.ParksDelete),
    },
    clients: {
      edit: userActions.includes(AdminActions.ClientsEdit),
      delete: userActions.includes(AdminActions.ClientsDelete),
    },
    operationalIntervals: {
      edit: userActions.includes(AdminActions.OperationalIntervalsEdit),
      delete: userActions.includes(AdminActions.OperationalIntervalsDelete),
    },
  },
});

/**
 * Return hours and minutes
 * @param {string} time - The time that needs to be updated (00:00:00).
 */
export const toHoursAndMinutes = time => time.split(':').length > 2 ? time.split(':').splice(0,2).join(':') : time;

/**
 * Render Place Option
 */
export const renderPlaceOption = option => `${ option.description }${ cleanString(option.county.name) !== cleanString(option.description) ? `, ${ option.county.name }` : '' }${ cleanString(option.province.name) !== cleanString(option.county.name) ? `, ${ option.province.name }` : '' }`;

/**
 * Get reservation status
 */
export const getReservationStatus = (t) => {
  const status = {};
  // eslint-disable-next-line array-callback-return
  JSON.parse(localStorage.getItem('enums')).reservationStatus.map(item => {
    status[item] = titleCase(t(`global.status.${ item }`));
  });
  return status;
};

/**
 * Get trip status
 */
 export const getTripStatus = (t) => {
  const status = {};
  // eslint-disable-next-line array-callback-return
  JSON.parse(localStorage.getItem('enums')).tripStatus.map(item => {
    status[item] = titleCase(t(`global.status.${ item }`));
  });
  return status;
};

/**
 * Get route directions
 */
export const getRouteDirections = (routeDirections, t) => {
  const directions = {};
  // eslint-disable-next-line array-callback-return
  routeDirections.map(direction => {
    directions[direction] = t(`routes.direction.${ direction.toLowerCase() }`);
  });
  return directions;
};

export const getCurrentWeekMonday = () => {
  const date = new Date();
  const day = date.getDay(),
        diff = date.getDate() - day + (day === 0 ? -6 : 1); // adjust when day is sunday
  date.setHours(0,0,0,0);
  return new Date(date.setDate(diff));
};
export const getCurrentWeekSunday = () => new Date( getCurrentWeekMonday().setDate(getCurrentWeekMonday().getDate() + 6) );
export const getNextWeekMonday = () => new Date( getCurrentWeekMonday().setDate(getCurrentWeekMonday().getDate() + 7) );
export const getNextWeekSunday = () => new Date( getCurrentWeekMonday().setDate(getCurrentWeekMonday().getDate() + 13) );

/**
 * Remove time zone from date string
 * @param {string} date - The date that needs to be updated.
 */
export const transformDate = date => date.split('T')[0].replace(/-/g, '/');

/**
 * Create a new date string
 * @param {string} date - The date that needs to be updated.
 */
export const createDate = date => {
  const fullDate = typeof date === 'string' ? new Date(transformDate(date)) : date;
  return `${ fullDate.getFullYear() }/${ fullDate.getMonth() + 1 }/${ fullDate.getDate() }`;
};

/**
 * Create a new date string
 */
export const todayDate = () => {
  const newDate = new Date();
  return `${ newDate.getFullYear() }-${ (newDate.getMonth() + 1) < 10 ? '0' : '' }${ newDate.getMonth() + 1 }-${ newDate.getDate() }`
};

/**
 * Create a new date string
 */
export const threeMonthsAgoDate = () => {
  const newDate = new Date();
  return new Date(newDate.setMonth(newDate.getMonth() - 3)).toISOString().split('T')[0];
};

/**
 * Remove time zone from date string
 * @param {string} date - The date that needs to be updated.
 */
export const transformDateDashes = date => date.split('T')[0];

/**
 * Transform date to be used on date pickers
 * @param {string} date - The date that needs to be updated.
 */
export const transformDateString = date => new Date( new Date(date).toLocaleDateString('en') );

/**
 * Transform date without timezone
 * @param {string} date - The date that needs to be updated.
 */
export const dateISOWithoutTz = date => new Date(date.getTime() - (date.getTimezoneOffset() * 60000)).toISOString();

/**
 * Remove time zone from date string and create a more readable date
 * @param {string} date - The date that needs to be updated.
 * @param {string} time - The time that needs to be updated.
 * @param {function} t - The translation function.
 */
export const formatDateTime = (date, time, t) => {
  let fullDate = new Date( transformDate(date) );
  const fullTime = time.split(':');
  let hours = fullTime[0];
  let minutes = fullTime[1];
  const ampm = hours >= 12 ? 'PM' : 'AM';

  hours = hours % 12 || 12;

  return `${ t(`global.months.${ fullDate.getMonth() }`) } ${ fullDate.getDate() }, ${ fullDate.getFullYear() } - ${ hours }:${ minutes } ${ ampm }`;
  // return `${ t(`global.months.${ fullDate.getMonth() }`) } ${ fullDate.getDate() }, ${ hours }:${ minutes } ${ ampm }`;
};

/**
 * Remove time zone from date string and create a more readable date
 * @param {string} date - The date that needs to be updated.
 * @param {string} time - The time that needs to be updated.
 * @param {function} t - The translation function.
 */
export const formatDateWithTime = (date, t) => {
  let fullDate = new Date( transformDate(date) );
  const time = date.split('T')[1].split('.')[0].split(':');
  let hours = time[0];
  let minutes = time[1];
  const ampm = hours >= 12 ? 'PM' : 'AM';

  hours = hours % 12 || 12;

  return `${ t(`global.months.${ fullDate.getMonth() }`) } ${ fullDate.getDate() }, ${ fullDate.getFullYear() }, ${ hours }:${ minutes } ${ ampm }`;
  // return `${ t(`global.months.${ fullDate.getMonth() }`) } ${ fullDate.getDate() }, ${ hours }:${ minutes } ${ ampm }`;
};

/**
 * Remove time zone from date string and create a more readable date
 * @param {string} date - The date that needs to be updated.
 * @param {function} t - The translation function.
 */
export const formatDateYear = (date, t) => {
  let fullDate = new Date( transformDate(date) );
  return `${ t(`global.months.${ fullDate.getMonth() }`) } ${ fullDate.getDate() }, ${ fullDate.getFullYear() }`;
};

/**
 * Remove time zone from date string and create a more readable date
 * @param {string} date - The date that needs to be updated.
 * @param {function} t - The translation function.
 */
export const formatDate = (date, t) => {
  let fullDate = new Date( transformDate(date) );
  return `${ t(`global.months.${ fullDate.getMonth() }`) } ${ fullDate.getDate() }, ${ fullDate.getFullYear() }`;
};

/**
 * Update time string and create a more readable time
 * @param {string} time - The time that needs to be updated.
 */
export const formatTime = (time) => {
  const formatTime = time.split(':');
  let hours = formatTime[0];
  let minutes = formatTime[1];
  const ampm = hours >= 12 ? 'PM' : 'AM';

  hours = hours % 12 || 12;

  return `${ hours }:${ minutes } ${ ampm }`;
};

/**
 * Currency formatter
 */
 export const currencyFormatter = new Intl.NumberFormat('es-CR', {
  style: 'currency',
  currency: 'CRC',
  maximumFractionDigits: 0
});

/**
 * Sort by time string
 * @param {string} times - The times array
 * @param {string} property - If it needs a sort property from the array
 */
export const sortByTime = (times, property) => {
  return times ? times.sort((a, b) => property ? a[property].localeCompare(b[property]) : a.localeCompare(b)) : [];
};

/**
 * The function `sortByProperty` sorts an array of objects based on a specified property in a
 * case-insensitive manner.
 * @param array - The `array` parameter is an array of objects that you want to sort based on a
 * specific property. Each object in the array should have the property you want to sort by.
 * @param property - The `property` parameter in the `sortByProperty` function is the name of the
 * property by which you want to sort the array. It should be a string representing the name of the
 * property.
 * @returns The `sortByProperty` function is returning the sorted array based on the specified
 * property.
 */
export const sortByProperty = (array, property) => {
  return array.sort((a,b) => (a[property].trim().toLowerCase() < b[property].trim().toLowerCase()) ? -1 : ((b[property].trim().toLowerCase() > a[property].trim().toLowerCase()) ? 1 : 0));
};

/**
 * Compare date with today
 * @param {string} date - The date that needs to compared
 */
export const compareDateWithNowInMinutes = (date) => {
  const fd = new Date( transformDate(date) );
  const time = date.split('T')[1].split('.')[0].split(':');
  const hours = time[0];
  const minutes = time[1];
  const seconds = time[2];
  return (new Date(fd.getFullYear(), fd.getMonth(), fd.getDate(), hours, minutes, seconds).getTime() - new Date().getTime()) / 60000;
}; 

/**
 * Title Case a Sentence
 * @param {string} str - The sentence that needs to be updated.
 */
export const titleCase = str => {
  return str.toLowerCase().split(' ').map(function(word) {
    return word.replace(word[0], word[0].toUpperCase());
  }).join(' ');
}

/**
 * Debounce
 */
export const debounce = (func, wait) => {
  let timeout;

  return function executedFunction(...args) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };

    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
};

/**
 * Search across all data for property
 */
export const searchByProp = (data, key) => key.split(/\].|\]\[|\[|\./).map( el => el.replace(/\]$/,'')).reduce( (obj, el) => obj ? obj[el] : undefined, data);

/**
 * Remove accents/diacritics/tildes in a string
 * @param {string} str - The string that needs to be updated.
 */
export const cleanString = str => str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

/**
 * The function `reverseHexadecimalInPairs` takes a hexadecimal number as input, ensures it has an even
 * length, splits it into pairs of digits, reverses the order of the pairs, and returns the reversed
 * hexadecimal number.
 * @param hex - The `hex` parameter is a string representing a hexadecimal number.
 * @returns the reversed hexadecimal number.
 */
export const reverseHexadecimalInPairs = (hex) => {
  // Ensure the hexadecimal number has an even length by adding a '0' at the beginning if necessary.
  if (hex.length % 2 !== 0) {
    hex = '0' + hex;
  }

  // Split the hexadecimal number into pairs of digits.
  const pairs = [];
  for (let i = 0; i < hex.length; i += 2) {
    pairs.push(hex.slice(i, i + 2));
  }

  // Reverse the order of the pairs and join them back together.
  const reversedHexadecimal = pairs.reverse().join('');

  return reversedHexadecimal;
}

/**
 * The function `hexadecimalToDecimal` converts a hexadecimal number to its decimal equivalent.
 * @param hex - The `hex` parameter is a string representing a hexadecimal number.
 * @returns the decimal representation of the given hexadecimal number.
 */
export const hexadecimalToDecimal = (hex) => {
  // Convert the hexadecimal number to decimal
  const decimalNumber = bigInt(hex, 16).toString();

  return decimalNumber;
}

/**
 * The function `saveExcelFile` downloads an Excel file from a response object with a specified file
 * name.
 * @param response - The `response` parameter is typically an object that contains the data received
 * from an API request. It usually includes information such as headers, status, and the actual data
 * returned by the server. In the provided code snippet, the `response` object is used to extract the
 * content type and data
 * @param fileName - The `fileName` parameter is a string that represents the name you want to give to
 * the Excel file when it is downloaded. This will be the name of the file that the user sees when they
 * download the file to their device.
 */
export const saveExcelFile = (response, fileName) => {
  const contentType = response.headers['content-type'];
  const blob = new Blob([response.data], { contentType });
  const href = window.URL.createObjectURL(blob);
  const el = document.createElement('a');

  el.setAttribute('href', href);
  el.setAttribute('download', fileName);
  el.click();
  el.remove();
  window.URL.revokeObjectURL(blob);
}

/**
 * The openLink function checks if a control key or meta key is pressed and either opens a new window
 * with the specified pathname or updates the history with the new pathname.
 * @param history - The `history` parameter is typically an object that is used for navigation in a web
 * application. It is commonly provided by routing libraries like React Router. The `history` object
 * allows you to programmatically navigate to different URLs within your application. In the context of
 * your `openLink` function, the
 * @param pathname - The `pathname` parameter in the `openLink` function is the URL path where you want
 * to navigate. It could be a relative or absolute path to a specific location within your application
 * or a different website.
 */
export const openLink = (event, history, pathname) => {
  if (event.ctrlKey || event.metaKey) {
    window.open(pathname, '_blank', 'noopener noreferrer');
  } else {
    history.push({ pathname });
  }
}

/**
 * Handle endpoint errors
 * @param {Object} state - The current state that you want to check.
 * @param {Object} props - The props of the page.
 * @param {function} setSnackbar - The action to call the snackbar component.
 * @param {function} t - The translation function.
 * @param {string} entity - Optional: The entity of the current action to be used on some messages
 */
export const handleEndpointErrors = (state, props, setSnackbar, t, entity) => {
  if (state.error.status === 401) {
    props.logout && props.logout(true, true);
  } else if (state.error.status === 420) {
    const errors = state.error?.data?.errors;
    if (entity === 'devices' && errors?.includes('name must be unique')) {
      setSnackbar({ show: true, autoHideDuration: SNACKBAR_TIME.ERROR, severity: 'error', message: t('user.devices.errors.uniqueActiveType') });
    } else if (entity === 'devices' && errors?.includes('device_id must be unique')) {
      setSnackbar({ show: true, autoHideDuration: SNACKBAR_TIME.ERROR, severity: 'error', message: t('user.devices.errors.uniqueDeviceId') });
    } else if (entity === 'parks') {
      setSnackbar({ show: true, autoHideDuration: SNACKBAR_TIME.ERROR, severity: 'error', message: t('trips.addTripModal.generateTrips.errors.parkWithoutClients') });
    } else if (entity) {
      setSnackbar({ show: true, autoHideDuration: SNACKBAR_TIME.ERROR, severity: 'error', message: t('global.errors.endpoint.420withEntity', { entity }) });
    } else {
      setSnackbar({ show: true, autoHideDuration: SNACKBAR_TIME.ERROR, severity: 'error', message: t('global.errors.endpoint.420withoutEntity') });
    }
  } else if (state.error.status === 404) {
    if (entity) {
      setSnackbar({ show: true, autoHideDuration: SNACKBAR_TIME.ERROR, severity: 'info', message: t('global.errors.endpoint.404withEntity', { entity }) });
    } else {
      setSnackbar({ show: true, autoHideDuration: SNACKBAR_TIME.ERROR, severity: 'info', message: t('global.errors.endpoint.404withoutEntity') });
    }
  } else if (state.error.status === 422 && entity === 'generateTrips') {
    setSnackbar({ show: true, autoHideDuration: SNACKBAR_TIME.ERROR, severity: 'error', message: t('trips.addTripModal.generateTrips.error422') });
  } else if (state.error.status === 440) {
    setSnackbar({ show: true, autoHideDuration: SNACKBAR_TIME.ERROR, severity: 'info', message: t('global.errors.endpoint.440') });
  } else if (state.error.status === 451) {
    setSnackbar({ show: true, autoHideDuration: SNACKBAR_TIME.ERROR, severity: 'info', message: t('global.errors.endpoint.451') });
  } else {
    setSnackbar({ show: true, autoHideDuration: SNACKBAR_TIME.ERROR, severity: 'error', message: t('global.errors.endpoint.default') });
  }
}

/**
 * Log Sentry endpoint error
 * @param {Object} error - Error that occurred.
 */
export const logSentryEndpointError = error => {
  if (error.response && ![401, 404, 420, 422, 440, 451].includes(error.response.status)) {
    Sentry.withScope(function(scope) {
      scope.setTag('request', `${ error.response.config.url }`);
      scope.setTag('method', `${ error.config.method }`);
      Sentry.setContext('Request Information', {
        'URL': error.response.config.url,
        'Method': error.config.method,
        'Status': error.request.status,
        'Status Text': error.request.statusText,
        'Data': error.config.data,
        'Error': error.request.response,
      });
      Sentry.captureException(error);
    });
  }
}
