import React from 'react';

import PropTypes from 'prop-types';

import { useStoreState } from 'easy-peasy';

import { makeStyles } from '@material-ui/core/styles';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

import HeatMapTrip from './HeatMap/Trip';

import { formatDate, formatDateWithTime, compareDateWithNowInMinutes } from '../../../shared/utilities';

import SidebarHead from './Head';

import { useTranslation } from 'react-multi-lang';

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      maxHeight: 'calc(100vh - 148px)',
      overflow: 'auto',
      padding: theme.spacing(2),
    },
  },
  date: {
    '& .MuiTypography-root': {
      marginLeft: theme.spacing(1),
    },
  },
  trips: {
    display: 'flex',
    flexWrap: 'wrap',
    marginBottom: theme.spacing(2),
  },
  tooltip: {
    background: theme.palette.background.default,
    border: `2px solid ${ theme.palette.type === 'dark' ? theme.palette.grey[700] : theme.palette.divider }`,
    borderRadius: theme.spacing(1),
    color: theme.palette.text.primary,
    '& div > span': {
      ...theme.typography.caption,
    },
    '& .MuiTooltip-arrow': {
      bottom: '-2px !important',
      color: theme.palette.type === 'dark' ? theme.palette.grey[700] : theme.palette.divider,
    },
    '& .status': {
      textTransform: 'capitalize',
    },
  },
  overCapacity: {
    marginLeft: theme.spacing(1),
    '& .MuiSvgIcon-root': {
      fontSize: '22px',
      marginLeft: theme.spacing(.5),
      verticalAlign: 'bottom',
    },
  },
  hidden: {
    display: 'none !important',
  },
}));

const SidebarHeatMap = (props) => {
  const { onGetTrips, onTripSelected } = props;
  const classes = useStyles();
  const [tripsByDate, setTripsByDate] = React.useState([]);

  const trips = useStoreState(state => state.trips.heatMapTrips);
  const tripSelected = useStoreState(state => state.trips.currentTrip);
  const heatMapIconsSelected = useStoreState(state => state.trips.heatMapIconsSelected);

  const compare = compareDateWithNowInMinutes;
  const isOverCapacity = heatMapIconsSelected === 'overCapacity';

  React.useEffect(() => {
    const groupByDate = trips.reduce((group, trip) => {
      const category = trip.departureDate.split('T')[0];
      group[category] = group[category] ?? [];

      const totalMinutes = Math.round(compare(trip.departureDate));
      const hours = Math.floor(totalMinutes / 60);
      const minutes = totalMinutes % 60;
      group[category].unshift({
        ...trip,
        departureTime: `${ hours > 0 ? `${ hours }h ` : '' }${ minutes }m`,
      });
      return group;
    }, {});
    setTripsByDate(groupByDate);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trips]);

  const t = useTranslation();

  return <div className={ classes.root }>
    <SidebarHead onGetTrips={ onGetTrips } />

    { Object.keys(tripsByDate).map((date) => <div className={classes.date} key={date}>
      <Typography variant="body2" color="textSecondary" gutterBottom>
        { formatDate(date, t) }
        { isOverCapacity && (
          <span className={ classes.overCapacity }>| <PersonAddIcon /> { tripsByDate[date].filter(trip => trip.reservationsCount > trip.capacity).length }</span>
        ) }
      </Typography>
      <div className={ classes.trips }>
        {
          tripsByDate[date].map(trip => <div key={ trip.id } onClick={ !tripSelected || trip.id !== tripSelected.id ? () => onTripSelected(trip.id) : null }>
            <Tooltip classes={{ tooltip: classes.tooltip }} title={
              <div>
                <span><strong>{`${ t('trips.tripId') }:`}</strong> {`${ trip.id }`}<br /></span>
                <span><strong>{`${ t('trips.status') }:`}</strong> <span className="status">{`${ t(`global.status.${ trip.status }`) }`}</span><br /></span>
                { trip.status === 'ready' && compare(trip.departureDate) > 0 && <span className={ classes.hidden }>{`${t('trips.departureTime').toUpperCase()}: ${ trip.departureTime }`}<br /></span> }
                { trip.status === 'ready' && compare(trip.departureDate) <= 0 && <span className={ classes.hidden }>{t('trips.delayed').toUpperCase()}<br /></span> }
                <span><strong>{`${ t('trips.departureTime') }:`}</strong> {`${ formatDateWithTime(trip.departureDate, t).split(',')[2] }`}<br /></span>
                <span><strong>{`${ t('trips.capacity') }:`}</strong> { trip.reservationsCount }/{ trip.capacity }<br /></span>
              </div>
            } placement="top" arrow><span>
              <HeatMapTrip
                status={ trip.status }
                active={ tripSelected && trip.id === tripSelected.id }
                departureDate={ trip.departureDate }
                reservationsCount={ trip.reservationsCount || 0 }
                capacity={ trip.capacity || 0 }
              />
            </span></Tooltip>
          </div> )
        }
      </div>
    </div>) }
    
    { !trips.length && <Typography variant="body2">{ t('trips.emptyTrips') }</Typography> }
  </div>;
}

SidebarHeatMap.propTypes = {
  onGetTrips: PropTypes.func.isRequired,
  onTripSelected: PropTypes.func.isRequired
};

export default SidebarHeatMap;
