import React from 'react';

import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';

import DateFnsUtils from '@date-io/date-fns';
import enLocale from 'date-fns/locale/en-US';
import esLocale from 'date-fns/locale/es';

import { getCurrentWeekMonday, getCurrentWeekSunday, getNextWeekMonday, getNextWeekSunday, transformDateString } from '../../../../../shared/utilities';

import { useTranslation } from 'react-multi-lang';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(4),
  },
  input: {
    marginBottom: theme.spacing(2),
    marginRight: theme.spacing(2),
    minWidth: '170px',
    '& .MuiOutlinedInput-notchedOutline': {
      borderRadius: '12px',
      borderWidth: '2px',
    },
    '&:last-child': {
      marginRight: 0,
    },
  },
}));

const GenerateTripsFilters = (props) => {
  const { onChangeFn, isSource, isDisabled } = props;
  const classes = useStyles();

  const t = useTranslation();

  const [startDate, setStartDate] = React.useState( isSource ? getCurrentWeekMonday() : getNextWeekMonday() );
  const [endDate, setEndDate] = React.useState( isSource ? getCurrentWeekSunday() : getNextWeekSunday() );
  const sixWeeks = transformDateString( new Date( getNextWeekMonday().setDate(getNextWeekMonday().getDate() + 42) ));

  const onStartDateChange = (date) => {
    const newDate = transformDateString( new Date(date) );
    setStartDate(newDate);
  };

  const onEndDateChange = (date) => {
    const newDate = transformDateString( new Date(date) );
    setEndDate(newDate);
  };

  React.useEffect(() => {
    onChangeFn({ startDate, endDate });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate]);

  return <div className={ classes.root }>
    <MuiPickersUtilsProvider utils={ DateFnsUtils } locale={ localStorage.getItem('language') === 'en' ? enLocale : esLocale }>
      <DatePicker
        className={ classes.input }
        inputVariant="outlined"
        format="dd/MM/yyyy"
        maxDate={ endDate }
        label={ t('global.dateFilters.startDate') }
        okLabel={ t('global.modal.btnOk') }
        cancelLabel={ t('global.modal.btnCancel') }
        value={ startDate }
        onChange={ onStartDateChange }
        disabled={ isDisabled }
      />

      <DatePicker
        className={ classes.input }
        inputVariant="outlined"
        format="dd/MM/yyyy"
        minDate={ startDate }
        maxDate={ sixWeeks }
        label={ t('global.dateFilters.endDate') }
        okLabel={ t('global.modal.btnOk') }
        cancelLabel={ t('global.modal.btnCancel') }
        value={ endDate }
        onChange={ onEndDateChange }
        disabled={ isDisabled }
      />
    </MuiPickersUtilsProvider>
  </div>;
}

GenerateTripsFilters.propTypes = {
  onChangeFn: PropTypes.func.isRequired,
  isSource: PropTypes.bool.isRequired,
  isDisabled: PropTypes.bool,
};

export default GenerateTripsFilters;
