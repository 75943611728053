import React from 'react';
import { withRouter } from 'react-router-dom';

import { useStoreState, useStoreActions, useStore } from 'easy-peasy';

import { makeStyles } from '@material-ui/core/styles';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';

import { handleEndpointErrors, formatDateYear, dateISOWithoutTz } from '../shared/utilities';

import Sidebar from '../components/Trips/Sidebar';
import Detail from '../components/Trips/Detail';
import LoadingCard from '../components/LoadingCard';

import { useTranslation } from 'react-multi-lang';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      paddingLeft: 0,
    },
  },
  sidebarWithTrip: {
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  detail: {
    [theme.breakpoints.down('xs')]: {

    },
    [theme.breakpoints.up('sm')]: {
      maxHeight: 'calc(100vh - 100px)',
      overflow: 'auto',
    },
  },
  allTrips: {
    marginBottom: theme.spacing(1),
    paddingLeft: '2px',
  },
}));

const Trips = (props) => {
  const classes = useStyles();
  const [tripsTableData, setTripsTableData] = React.useState([]);

  const store = useStore();

  const storeMenuTitle = useStoreActions(actions => actions.global.storeTitle);
  const setSnackbar = useStoreActions(actions => actions.global.setSnackbar);

  const currentTripLoading = useStoreState(state => state.trips.currentTripLoading);
  const getTrips = useStoreActions(actions => actions.trips.getTrips);
  const getHeatMapTrips = useStoreActions(actions => actions.trips.getHeatMapTrips);
  const getTrip = useStoreActions(actions => actions.trips.getTrip);
  const tripSelected = useStoreState(state => state.trips.currentTrip);
  const setTripSelected = useStoreActions(actions => actions.trips.setCurrentTrip);

  const onGetTrips = (payload = { loading: false, isLoadMore: false }) => {
    getTrips(payload).then(() => {
      const tripsState = store.getState().trips;
      if (!tripsState.loading && !tripsState.error) {
        // console.log(tripsState);
      } else {
        handleEndpointErrors(tripsState, props, setSnackbar, t);
      }
    });
  };

  React.useEffect(() => {
    if ( localStorage.getItem('tripsSidebarTab') === '1' ) {
      getHeatMapTrips(true);
    } else {
      onGetTrips({ loading: true });
    }

    const interval = setInterval(() => getHeatMapTrips(), 120000);

    const tripId = +props.match.params.id;
    if (tripId) {
      onTripSelected(tripId)
    }

    return () => {
      clearInterval( interval );
      setTripSelected({});
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const t = useTranslation();

  storeMenuTitle(t('trips.pageTitle'));

  const handleAllTrips = () => {
    props.history.push('/trips');
    setTripSelected({});
  }

  const onTripSelected = tripId => {
    getTrip({ loading: true, id: tripId }).then(() => {
      const tripsState = store.getState().trips;
      if (!tripsState.currentTripLoading && !tripsState.currentTripError) {
        // console.log(tripsState);
        let currentTrip = tripsState.currentTrip;
        props.history.push(`/trips/${ currentTrip.id }`);
        const tripData = [];
        // eslint-disable-next-line array-callback-return
        currentTrip.passengers && currentTrip.passengers.map(passenger => {
          tripData.push({
            passengerName: passenger.name,
            passengerId: passenger.id,
            ticketId: passenger.reservation.ticket,
            id: passenger.reservation.id,
            client: passenger.client.name,
            reserved: formatDateYear(dateISOWithoutTz(new Date(`${ passenger.reservation.created_at }`)), t),
            status: passenger.status,
            tripId: currentTrip.id,
            place: passenger.place.description,
            location: `${ passenger.place.latitude },${ passenger.place.longitude }`,
          });
        });
        setTripsTableData(tripData);
        setTripSelected(currentTrip);
      } else {
        const state = { ...tripsState, error: tripsState.currentTripError }
        handleEndpointErrors(state, props, setSnackbar, t, t('trips.errorEntity'));
      }
    });
  }

  return (
    <div className={ classes.root }>
      <Grid container spacing={ 2 }>
        <Grid item xs={ 12 } sm={ 5 } md={ 4 } className={ tripSelected?.id && classes.sidebarWithTrip }>
          <Sidebar
            onGetTrips={ onGetTrips }
            onTripSelected={ onTripSelected }
          />
        </Grid>
        <Grid item xs={ 12 } sm={ 7 } md={ 8 }>
          { currentTripLoading ? (
              <Grid container spacing={ 2 }>
                <Grid item xs={ 12 } sm={ 6 } lg={ 4 }><LoadingCard length={ 1 } height={ 100 } /></Grid>
                <Grid item xs={ 12 } sm={ 6 } lg={ 4 }><LoadingCard length={ 1 } height={ 100 } /></Grid>
                <Grid item xs={ 12 } sm={ 6 } lg={ 2 }><LoadingCard length={ 1 } height={ 50 } /></Grid>
                <Grid item xs={ 12 } sm={ 6 } lg={ 2   }><LoadingCard length={ 1 } height={ 50 } /></Grid>
                <Grid item xs={ 12 }><LoadingCard length={ 1 } height={ 600 } /></Grid>
              </Grid>
            ) : (
              <React.Fragment>
                { tripSelected?.id && <Hidden smUp>
                  <Button
                    // variant="contained"
                    className={ classes.allTrips }
                    startIcon={<ArrowBackIcon /> }
                    onClick={ handleAllTrips }
                  >{ t('trips.allTrips') }</Button>
                </Hidden> }
                <Detail
                  additionalClass={ classes.detail }
                  tripsTableData={ tripsTableData }
                  setTripsTableData={ setTripsTableData }
                  onGetTrips={ onGetTrips }
                />
              </React.Fragment>
            ) }
        </Grid>
      </Grid>
    </div>
  );
}

export default withRouter(Trips);
