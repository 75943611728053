import React from 'react';

import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';

const useStyles = makeStyles(theme => ({
  root: {
    border: `1px solid ${ theme.palette.divider }`,
    borderRadius: '8px',
    boxShadow: 'none',
    height: props => props.height100 ? '100%' : null,
    marginBottom: props => props.marginBottom ? theme.spacing(props.marginBottom) : null,
    '& .MuiCardContent-root': {
      height: '100%',
      padding: props => props.noPadding ? 0 : theme.spacing(2),
    },
    '& .MuiAvatar-root': {
      background: 'none',
      border: props => `1px solid ${ props.avatar?.borderColor ? props.avatar.borderColor : theme.palette.text.primary }`,
      color: props => props.avatar?.borderColor ? props.avatar.color : theme.palette.background.paper,
      height: '64px',
      width: '64px',
    },
    '& .MuiSvgIcon-root': {
      height: '40px',
      width: '40px',
    },
    '& .MuiTypography-h5': {
      fontSize: '1rem',
      fontWeight: 500,
      paddingLeft: theme.spacing(3),
    },
    '& .MuiCardHeader-subheader': {
      fontSize: '2.5rem',
    },
    '& .MuiChip-root': {
      marginBottom: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
  },
}));

const CustomCard = (props) => {
  const { avatar, title, subheader, component, actions } = props;
  const classes = useStyles(props);

  return (
    <Card className={ classes.root }>
      { (avatar || title || subheader) && <CardHeader
        avatar={
          avatar?.icon && <Avatar>{ avatar.icon }</Avatar>
        }
        title={ title }
        subheader={ subheader }
        subheaderTypographyProps={{ color: 'textPrimary' }}
      /> }
      { component && <CardContent>
        { component }
      </CardContent> }
      { actions && <CardActions>
        { actions.map((action, index) => <Button
          key={ index }
          size="small"
          onClick={ action.click }
        >{ action.title }
        </Button>) }
      </CardActions> }
    </Card>
  );
}

CustomCard.propTypes = {
  avatar: PropTypes.exact({
    icon: PropTypes.node.isRequired,
    borderColor: PropTypes.string,
    color: PropTypes.string,
  }),
  title: PropTypes.string,
  subheader: PropTypes.string,
  component: PropTypes.node,
  actions: PropTypes.arrayOf(PropTypes.exact({
    title: PropTypes.string.isRequired,
    click: PropTypes.func.isRequired,
  })),
  noPadding: PropTypes.bool,
  height100: PropTypes.bool,
  marginBottom: PropTypes.number,
};

export default CustomCard;
