import React from 'react';

import PropTypes from 'prop-types';

import { useStoreActions, useStore } from 'easy-peasy';

// import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';

import * as Yup from 'yup';

import { Formik, Field } from 'formik';
import { TextField } from 'formik-material-ui';

import { handleEndpointErrors, formatDateYear, dateISOWithoutTz, SNACKBAR_TIME } from '../../shared/utilities';

import CustomFormikForm from '../CustomFormikForm';

import { useTranslation } from 'react-multi-lang';

// const useStyles = makeStyles(theme => ({
//   root: {
//   },
// }));

const UpdateTripStatus = (props) => {
  const { onUpdateTripStatusModalClose, statusInfo, setTripsTableData } = props;
  // const classes = useStyles();

  const store = useStore();

  const setSnackbar = useStoreActions(actions => actions.global.setSnackbar);

  const updateTripStatus = useStoreActions(actions => actions.trips.updateTripStatus);
  const getTrip = useStoreActions(actions => actions.trips.getTrip);

  const t = useTranslation();

  return (
    <React.Fragment>
      <DialogTitle>{ t('trips.updateStatus.title') }</DialogTitle>
      <Formik
        initialValues={{ note: '' }}
        validationSchema={ Yup.object({
          ...(statusInfo.status === 'cancelled' && { note: Yup.string()
            .min(20, t('global.errors.min', { field: t('trips.updateStatus.note'), length: 20 }))
            .required(t('global.errors.required', { field: t('trips.updateStatus.note') })),
          }),
        }) }
        onSubmit={(values, { setSubmitting }) => {
          setSnackbar({ show: true, severity: 'info', message: t('global.loading.updating') });
          updateTripStatus({ id: statusInfo.id, status: statusInfo.status, note: values.note }).then(() => {
            const tripsState = store.getState().trips;
            if (!tripsState.loading && !tripsState.error) {
              // console.log('success', tripsState);
              setSubmitting(false);
              onUpdateTripStatusModalClose();
              setSnackbar({ show: true, autoHideDuration: SNACKBAR_TIME.SUCCESS, severity: 'success', message: t('global.success.saved') });
              if ( ['started', 'completed'].includes(statusInfo.status) ) {
                getTrip({ id: statusInfo.id }).then(() => {
                  if (!tripsState.loading && !tripsState.error) {
                    const currentTrip = store.getState().trips.currentTrip;
                    const tripData = [];
                    // eslint-disable-next-line array-callback-return
                    currentTrip.passengers && currentTrip.passengers.map(passenger => {
                      tripData.push({
                        passengerName: passenger.name,
                        passengerId: passenger.id,
                        ticketId: passenger.reservation.ticket,
                        id: passenger.reservation.id,
                        client: passenger.client.name,
                        reserved: formatDateYear(dateISOWithoutTz(new Date(`${ passenger.reservation.created_at }`)), t),
                        status: passenger.status,
                        tripId: currentTrip.id,
                        place: passenger.place.description,
                        location: `${ passenger.place.latitude },${ passenger.place.longitude }`,
                      });
                    });
                    setTripsTableData(tripData);
                  } else {
                    handleEndpointErrors(tripsState, props, setSnackbar, t);
                  }
                });
              }
            } else {
              handleEndpointErrors(tripsState, props, setSnackbar, t);
            }
          });
        }}
      >
        {({ submitForm, isSubmitting }) => (
          <CustomFormikForm>
            <DialogContent>
              <DialogContentText>{ t('trips.updateStatus.text') }</DialogContentText>
              <Grid container spacing={ 3 }>
                <Grid item xs={ 12 }>
                  <Field
                    component={ TextField }
                    disabled={ isSubmitting }
                    name="note"
                    type="text"
                    label={ t('trips.updateStatus.note') }
                    variant="outlined"
                    fullWidth
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button disabled={ isSubmitting } onClick={ onUpdateTripStatusModalClose } color="primary">{ t('global.modal.btnCancel') }</Button>
              <Button disabled={ isSubmitting } onClick={ submitForm } color="primary" autoFocus>{ t('global.modal.btnUpdate') }</Button>
            </DialogActions>
          </CustomFormikForm>
        )}
      </Formik>
    </React.Fragment>
  );
}

UpdateTripStatus.propTypes = {
  onUpdateTripStatusModalClose: PropTypes.func.isRequired,
  statusInfo: PropTypes.object.isRequired,
  setTripsTableData: PropTypes.func.isRequired,
};

export default UpdateTripStatus;
