import React from 'react';

import PropTypes from 'prop-types';

import clsx from 'clsx';

import { useStoreState, useStoreActions, useStore } from 'easy-peasy';

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';

import * as Yup from 'yup';

import { Formik, Field } from 'formik';
import { TextField } from 'formik-material-ui';

import { handleEndpointErrors, SNACKBAR_TIME } from '../../shared/utilities';

import CustomFormikForm from '../CustomFormikForm';
import FormikCheckbox from '../FormikCheckbox';
import FormikAutocomplete from '../FormikAutocomplete';

import { useTranslation } from 'react-multi-lang';

const useStyles = makeStyles(theme => ({
  placeActive: {
    right: theme.spacing(3),
  },
  placeStop: {
    right: '130px',
  },
}));

const AddPlace = (props) => {
  const { onGetPlaces, onAddPlaceModalClose } = props;
  const classes = useStyles();

  const store = useStore();

  const setSnackbar = useStoreActions(actions => actions.global.setSnackbar);

  const addPlace = useStoreActions(actions => actions.places.addAdminPlace);

  const provinces = useStoreState(state => state.countries.provinces);
  const getCounties = useStoreActions(actions => actions.countries.getCounties);
  const counties = useStoreState(state => state.countries.counties);

  const formRef = React.useRef();

  const t = useTranslation();

  // eslint-disable-next-line array-callback-return
  const updateFields = (fields, setFieldValue, setFieldTouched, values) => fields.map(field => {
    setFieldValue(`${ field }`, '', false);
    setFieldTouched(`${ field }`, false);

    getCounties(values.province.id).then(() => {
      const countriesState = store.getState().countries;
      if (!countriesState.loading && !countriesState.error) {
        // console.log(countriesState);
      } else {
        handleEndpointErrors(countriesState, props, setSnackbar, t);
      }
    });
  });

  return (
    <React.Fragment>
      <DialogTitle>{ t('places.table.actionAdd') }</DialogTitle>
      <Formik
        innerRef={ formRef }
        initialValues={{ active: true, stop: false, description: '', province: '', county: '', latitude: '', longitude: '' }}
        validationSchema={ Yup.object({
          description: Yup.string()
            .max(255, t('global.errors.max', { field: t('places.description'), length: 255 }))
            .required(t('global.errors.required', { field: t('places.description') })),
          province: Yup.string()
            .required(t('global.errors.required', { field: t('places.province') })),
          county: Yup.string()
            .required(t('global.errors.required', { field: t('places.county') })),
          latitude: Yup.number()
            .typeError(t('global.errors.number', { field: t('places.latitude') }))
            .required(t('global.errors.required', { field: t('places.latitude') })),
          longitude: Yup.number()
            .typeError(t('global.errors.number', { field: t('places.longitude') }))
            .required(t('global.errors.required', { field: t('places.longitude') })),
        }) }
        onSubmit={(values, { setSubmitting }) => {
          const data = {
            active: values.active,
            stop: values.stop,
            description: values.description,
            provinceId: +values.province.id,
            countyId: +values.county.id,
            latitude: values.latitude,
            longitude: values.longitude,
          };
          addPlace(data).then(() => {
            const placesState = store.getState().places;
            if (!placesState.loading && !placesState.error) {
              setSubmitting(false);
              onGetPlaces();
              setSnackbar({ show: true, autoHideDuration: SNACKBAR_TIME.SUCCESS, severity: 'success', message: t('global.success.saved') });
              onAddPlaceModalClose();
            } else {
              handleEndpointErrors(placesState, props, setSnackbar, t);
            }
          });
        }}
      >
        {({ submitForm, isSubmitting, setFieldValue, setFieldTouched, values }) => (
          <CustomFormikForm>
            <DialogContent>
              <Field
                component={ FormikCheckbox }
                className={clsx( 'formik-checkbox', classes.placeActive )}
                name="active"
                label={ t('places.active') }
                disabled={ isSubmitting }
              />
              <Field
                component={ FormikCheckbox }
                className={clsx( 'formik-checkbox', classes.placeStop )}
                name="stop"
                label={ t('places.stop') }
                disabled={ isSubmitting }
              />
              <Grid container spacing={ 3 }>
                <Grid item xs={ 6 } md={ 4 }>
                  <Field
                    component={ TextField }
                    name="description"
                    type="text"
                    label={ t('places.description') }
                    variant="outlined"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={ 6 } md={ 4 }>
                  <Field
                    component={ FormikAutocomplete }
                    name="province"
                    disableClearable
                    options={ provinces }
                    getOptionLabel={ option => option ? option.name : '' }
                    textFieldProps={{ label: t('places.province') , variant: 'outlined', readOnly: true, fullWidth: true }}
                    onChange={() => {
                      setTimeout(() => updateFields(['county'], setFieldValue, setFieldTouched, formRef.current.values));
                    }}
                  />
                </Grid>
                { values.province ? <Grid item xs={ 6 } md={ 4 }>
                    <Field
                      component={ FormikAutocomplete }
                      name="county"
                      disableClearable
                      options={ counties }
                      getOptionLabel={ option => option ? option.name : '' }
                      textFieldProps={{ label: t('places.county') , variant: 'outlined', readOnly: true, fullWidth: true }}
                    />
                  </Grid> : null }
                <Grid item xs={ 6 } md={ 4 }>
                  <Field
                    component={ TextField }
                    name="latitude"
                    type="text"
                    label={ t('places.latitude') }
                    variant="outlined"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={ 6 } md={ 4 }>
                  <Field
                    component={ TextField }
                    name="longitude"
                    type="text"
                    label={ t('places.longitude') }
                    variant="outlined"
                    fullWidth
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={ onAddPlaceModalClose } color="primary">{ t('global.modal.btnClose') }</Button>
              <Button disabled={ isSubmitting } onClick={ submitForm } color="primary">{ t('global.modal.btnAdd') }</Button>
            </DialogActions>
          </CustomFormikForm>
        )}
      </Formik>
    </React.Fragment>
  );
}

AddPlace.propTypes = {
  onGetPlaces: PropTypes.func.isRequired,
  onAddPlaceModalClose: PropTypes.func.isRequired,
};

export default AddPlace;
