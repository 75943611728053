import React from 'react';

import PropTypes from 'prop-types';

import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(theme => ({
  text: {
    padding: theme.spacing(2),
  }
}));

const Note = (props) => {
  const { icon, iconText, text, additionalClass } = props;
  const classes = useStyles();
  const [popover, setSPopover] = React.useState(null);

  const handleClick = (event) => {
    setSPopover(event.currentTarget);
  };

  const handleClose = () => {
    setSPopover(null);
  };

  return (
    <React.Fragment>
      <IconButton className={clsx( classes.root, additionalClass )} aria-label={ iconText } onClick={ handleClick }>
        { icon }
      </IconButton>
      <Popover
        open={ Boolean(popover) }
        anchorEl={ popover }
        onClose={ handleClose }
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Typography className={ classes.text }>{ text }</Typography>
      </Popover>
    </React.Fragment>
  );
}

Note.propTypes = {
  icon: PropTypes.node.isRequired,
  iconText: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  additionalClass: PropTypes.string,
};

export default Note;
