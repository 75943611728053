import React from 'react';
import { withRouter, Link as RouterLink } from 'react-router-dom';

import { useStoreState, useStoreActions, useStore } from 'easy-peasy';

import { makeStyles } from '@material-ui/core/styles';
import HomeIcon from '@material-ui/icons/Home';
import Link from '@material-ui/core/Link';
import LocalActivityIcon from '@material-ui/icons/LocalActivity';

import { handleEndpointErrors, getReservationStatus, getRouteDirections, formatDateYear, formatTime, formatDateWithTime, dateISOWithoutTz, SNACKBAR_TIME, openLink } from '../shared/utilities';

import Breadcrumbs from '../components/Breadcrumbs';
import LoadingCard from '../components/LoadingCard';
import Table from '../components/Table';
import DataFilters from '../components/DataFilters';

import { useTranslation } from 'react-multi-lang';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3, 2),
  },
  table: {
    '& .MuiTableCell-head': {
      minWidth: '145px',
    },
  }
}));

const Reservations = (props) => {
  const { history } = props;
  const classes = useStyles();
  const [reservationsTableData, setReservationsTableData] = React.useState([]);
  const [activeFilters, setActiveFilters] = React.useState({});

  const store = useStore();

  const storeMenuTitle = useStoreActions(actions => actions.global.storeTitle);
  const setSnackbar = useStoreActions(actions => actions.global.setSnackbar);

  const reservations = useStoreState(actions => actions.reservations.reservations);
  const getReservations = useStoreActions(actions => actions.reservations.getReservations);
  const deleteReservation = useStoreActions(actions => actions.reservations.deleteReservation);
  const reservationsLoading = useStoreState(state => state.reservations.loading);
  const reservationsTextSearch = useStoreState(state => state.reservations.textSearch);
  const setReservationsTextSearch = useStoreActions(actions => actions.reservations.setTextSearch);

  const routeDirections = useStoreState(state => state.global.routeDirections);

  const paginationPage = useStoreState(state => state.reservations.page);
  const paginationPageSize = useStoreState(state => state.reservations.pageSize);
  const [dataLoading, setDataLoading] = React.useState(false);

  const queryParams = new URLSearchParams(props.location.search);
  const statusParam = queryParams.get('status') ? JSON.parse(queryParams.get('status')) : [];
  const dateParam = queryParams.get('date');

  const t = useTranslation();

  storeMenuTitle(t('reservations.pageTitle'));

  const onGetReservations = (payload) => {
    setDataLoading(true);
    getReservations(payload).then(() => {
      const reservationsState = store.getState().reservations;
      if (!reservationsState.loading && !reservationsState.error) {
        // console.log(reservationsState);
        setDataLoading(false);
      } else {
        handleEndpointErrors(reservationsState, props, setSnackbar, t);
      }
    });
  };

  React.useEffect(() => {
    onGetReservations({
      loading: true,
      ...(!!statusParam.length && { status: statusParam }),
      ...(!!dateParam && { departureDate: dateParam }),
    });

    return () => setReservationsTextSearch('');
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    setReservationsTableData(reservations.map(reservation => {
      reservation.formatDate = formatDateYear(reservation.date, t);
      reservation.formatDepartureTime = formatTime(reservation.schedule.departureTime);
      reservation.formatArrivalTime = formatTime(reservation.schedule.arrivalTime);
      reservation.formatCreatedAt = formatDateWithTime(dateISOWithoutTz(new Date(`${ reservation.createdAt }`)), t);
      return { ...reservation };
    }));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reservations]);

  const onDeleteReservation = (oldData, resolve, reject) => {
    deleteReservation(oldData.id).then(() => {
      const reservationsState = store.getState().reservations;
      if (!reservationsState.error) {
        // console.log('success', reservationsState);
        resolve();
        const data = [...reservationsTableData].filter(reservation => reservation.id !== oldData.id);
        setReservationsTableData(data);
        setSnackbar({ show: true, autoHideDuration: SNACKBAR_TIME.SUCCESS, severity: 'success', message: t('global.success.saved') });
      } else {
        reject();
        handleEndpointErrors(reservationsState, props, setSnackbar, t);
      }
    });
  };

  const reservationsTableColumns = localStorage.getItem('tableReservations') ?
    JSON.parse(localStorage.getItem('tableReservations')) : [
    { title: t('reservations.id'), field: 'id', type: 'numeric', align: 'left', defaultSort: 'asc', editable: 'never' },
    {
      title: t('reservations.tripId'), field: 'tripId',
      render: rowData => <Link component={ RouterLink } to={ `/trips/${ rowData.tripId }` }>{ rowData.tripId || '' }</Link>,
      type: 'numeric', align: 'left', editable: 'never'
    },
    { title: t('reservations.status'), field: 'status', lookup: getReservationStatus(t), sorting: false },
    { title: t('reservations.date'), field: 'formatDate', editable: 'never' },
    { title: t('reservations.departureTime'), field: 'formatDepartureTime', sorting: false, editable: 'never' },
    { title: t('reservations.arrivalTime'), field: 'formatArrivalTime', sorting: false, editable: 'never' },
    {
      title: t('reservations.route'), field: 'route.name',
      render: rowData => <Link component={ RouterLink } to={ `/routes/${ rowData.route.id }` }>{ rowData.route.name }</Link>,
      type: 'numeric', align: 'left', editable: 'never'
    },
    {
      title: t('reservations.username'), field: 'passenger.username',
      render: rowData => <Link component={ RouterLink } to={ `/users/${ rowData.passenger.id }` }>{ rowData.passenger.username }</Link>,
      type: 'numeric', align: 'left', editable: 'never'
    },
    {
      title: t('reservations.passenger'), field: 'passenger.name',
      render: rowData => <Link component={ RouterLink } to={ `/users/${ rowData.passenger.id }` }>{ rowData.passenger.name }</Link>,
      type: 'numeric', align: 'left', editable: 'never'
    },
    { title: t('reservations.direction'), field: 'route.direction', lookup: getRouteDirections(routeDirections, t), sorting: false, editable: 'never' },
    { title: t('reservations.createdAt'), field: 'formatCreatedAt', editable: 'never' },
    { title: t('trips.addTripModal.addTrip.extra.label'), field: 'extra', type: 'boolean', sorting: false, editable: 'never' },
    { title: t('reservations.ticket'), field: 'ticket', sorting: false, editable: 'never' },
    { title: t('reservations.source'), field: 'source', sorting: false, editable: 'never' },
  ];

  const reservationStatusOptions = Object.keys(getReservationStatus(t)).map(status => ({ label: getReservationStatus(t)[status], value: status }));

  return (
    <div className={ classes.root }>
      <Breadcrumbs links={[
        { name: t('home.pageTitle'), path: '/', icon: <HomeIcon /> },
        { name: t('reservations.pageTitle'), icon: <LocalActivityIcon /> },
      ]} />
      <DataFilters
        setActiveFilters={ setActiveFilters }
        filters={[
          {
            type: 'text',
            name: t('reservations.searchPlaceholder'),
            property: 'textSearch',
            active: reservationsTextSearch,
            width: '250px',
            onChangeFn: (filters) => {
              onGetReservations({ page: 1, ...filters });
            }
          },
          {
            type: 'number',
            name: t('reservations.tripId'),
            property: 'tripId',
            active: '',
            width: '155px',
            onChangeFn: (filters) => {
              onGetReservations({ page: 1, ...filters });
            }
          },
          {
            type: 'select',
            multiple: true,
            name: t('reservations.status'),
            property: 'status',
            options: reservationStatusOptions,
            active: statusParam || [],
            onChangeFn: (filters) => {
              onGetReservations({ page: 1, ...filters });
            }
          },
          {
            type: 'select',
            name: t('routes.direction.label'),
            property: 'direction',
            options: [{label: t('routes.direction.in'), value: 'in'}, {label: t('routes.direction.out'), value: 'out'}],
            active: '',
            onChangeFn: (filters) => {
              onGetReservations({ page: 1, ...filters });
            }
          },
          // {
          //   type: 'number',
          //   name: t('reservations.placeId'),
          //   property: 'placeId',
          //   active: '',
          //   onChangeFn: (filters) => {
          //     onGetReservations({ page: 1, ...filters });
          //   }
          // },
          // {
          //   type: 'number',
          //   name: t('reservations.routeId'),
          //   property: 'routeId',
          //   active: '',
          //   onChangeFn: (filters) => {
          //     onGetReservations({ page: 1, ...filters });
          //   }
          // },
          {
            type: 'date',
            name: t('reservations.date'),
            property: 'departureDate',
            active: dateParam ? dateParam.replace(/-/g, '/') : null,
            onChangeFn: (filters) => {
              onGetReservations({ page: 1, ...filters });
            }
          }
        ]}
      />
      { reservationsLoading ? <LoadingCard length={ 1 } height={ 1565 } /> : <React.Fragment>
        <Table
          className={ classes.table }
          title=""
          columns={ reservationsTableColumns }
          data={ reservationsTableData }
          exportButton
          exportFileName={ t('reservations.pageTitle') }
          deleteEvent={ onDeleteReservation }
          search={ false }
          customPagination={{
            page: paginationPage,
            size: paginationPageSize,
            responseLength: reservations.length,
            loading: dataLoading,
            hasRowsSelection: true,
            clickEvent: (page, pageSize = paginationPageSize) => {
              onGetReservations({ page, pageSize, ...activeFilters });
            }
          }}
          addActions={[
            {
              icon: 'edit',
              tooltip: t('reservations.table.actionEdit'),
              onClick: (event, rowData) => {
                openLink(event, history, `/reservations/${ rowData.id }`);
              }
            },
          ]}
          updateColumns="Reservations"
        />
      </React.Fragment> }
    </div>
  );
}

export default withRouter(Reservations);
