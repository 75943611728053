import React from 'react';
import { withRouter } from 'react-router-dom';

import { useStoreState, useStoreActions, useStore } from 'easy-peasy';

import { makeStyles } from '@material-ui/core/styles';
import GroupIcon from '@material-ui/icons/Group';
import HomeIcon from '@material-ui/icons/Home';
import ViewComfyIcon from '@material-ui/icons/ViewComfy';
import LockIcon from '@material-ui/icons/Lock';

import { handleEndpointErrors, openLink } from '../shared/utilities';

import Breadcrumbs from '../components/Breadcrumbs';
import LoadingCard from '../components/LoadingCard';
import Table from '../components/Table';
import CustomDialog from '../components/CustomDialog';
import AddUser from '../components/Users/AddUser';
import AddUsersFromCSV from '../components/Users/AddUsersFromCSV';
import ViewQrCode from '../components/ViewQrCode';
import ViewPageQrCodesModal from '../components/Users/ViewPageQrCodesModal';
import DataFilters from '../components/DataFilters';

import { useTranslation } from 'react-multi-lang';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3, 2),
  },
  table: {
    '& .MuiTableCell-head:nth-child(2), & .MuiTableCell-head:nth-child(6), & .MuiTableCell-head:nth-child(7)': {
      minWidth: '180px',
    },
  },
  modal: {
    '& .MuiDivider-root': {
      margin: theme.spacing(2, 3),
      width: `calc(100% - ${ theme.spacing(6) }px)`,
    },
    '@media print' : {
      '& .MuiPaper-root': {
        margin: 0,
        width: '100%',
      },
    },
  },
}));

const Users = (props) => {
  const { history } = props;
  const classes = useStyles();
  const [usersTableData, setUsersTableData] = React.useState([]);
  const [addUserModalOpen, setAddUserModalOpen] = React.useState(false);
  const [addUsersFromCSVModalOpen, setAddUsersFromCSVModalOpen] = React.useState(false);
  const [viewQrCodeModalOpen, setViewQrCodeModalOpen] = React.useState(false);
  const [viewPageQrCodesModalOpen, setViewPageQrCodesModalOpen] = React.useState(false);
  const [editUser, setEditUser] = React.useState({});
  const [activeFilters, setActiveFilters] = React.useState({});

  const store = useStore();

  const permissions = useStoreState(state => state.global.permissions);
  const roles = useStoreState(state => state.global.roles);
  const clients = useStoreState(state => state.global.clients);
  const clientSelected = useStoreState(state => state.global.clientSelected);
  const storeMenuTitle = useStoreActions(actions => actions.global.storeTitle);
  const setSnackbar = useStoreActions(actions => actions.global.setSnackbar);

  const users = useStoreState(actions => actions.users.users);
  const getUsers = useStoreActions(actions => actions.users.getUsers);
  // const deleteUser = useStoreActions(actions => actions.users.deleteUser);
  const getFunctionalAreas = useStoreActions(actions => actions.users.getFunctionalAreas);
  const functionalAreas = useStoreState(state => state.users.functionalAreas);
  const usersLoading = useStoreState(state => state.users.loading);
  const pageFilters = useStoreState(state => state.users.pageFilters);
  const setPageFilters = useStoreActions(actions => actions.users.setPageFilters);
  
  const paginationPage = useStoreState(state => state.users.page);
  const paginationPageSize = useStoreState(state => state.users.pageSize);
  const [dataLoading, setDataLoading] = React.useState(false);

  const canEditUserActions = permissions?.admin?.userActions?.edit;

  const t = useTranslation();

  storeMenuTitle(t('users.pageTitle'));

  const onGetUsers = (payload) => {
    const newPayload = history?.location?.pathname === '/users' ? { ...pageFilters, ...payload } : payload;
    setPageFilters(newPayload);
    setDataLoading(true);
    getUsers(newPayload).then(() => {
      const usersState = store.getState().users;
      if (!usersState.loading && !usersState.error) {
        // console.log(usersState);
        setDataLoading(false);
      } else {
        handleEndpointErrors(usersState, props, setSnackbar, t);
      }
    });
  }

  const getUserRoles = () => {
    const userRoles = {};
    // eslint-disable-next-line array-callback-return
    roles.map(role => {
      userRoles[role] = t(`global.roles.${ role.toLowerCase() }`);
    });
    return userRoles;
  };

  const getClientsByIds = () => {
    const clientsByIds = {};
    // eslint-disable-next-line array-callback-return
    clients.map(client => {
      clientsByIds[client.id] = client.name;
    });
    return clientsByIds;
  };

  React.useEffect(() => {
    onGetUsers({ loading: true });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    getFunctionalAreas().then(() => {
      const usersState = store.getState().users;
      if (!usersState.error) {
        // console.log(usersState);
      } else {
        handleEndpointErrors(usersState, props, setSnackbar, t);
      }
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    setUsersTableData(users.map(user => ({ ...user })));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [users]);

  const onAddUserModalClose = () => {
    setAddUserModalOpen(false);
  };

  const onAddUsersFromCSVModalClose = () => {
    setAddUsersFromCSVModalOpen(false);
  };

  const onViewQrCodeModalClose = () => {
    setViewQrCodeModalOpen(false);
  };

  const onViewPageQrCodesModalClose = () => {
    setViewPageQrCodesModalOpen(false);
  };

  // const onDeleteUser = (oldData, resolve, reject) => {
  //   deleteUser(oldData.id).then(() => {
  //     const usersState = store.getState().users;
  //     if (!usersState.error) {
  //       // console.log('success', usersState);
  //       resolve();
  //       const data = [...usersTableData].filter(user => user.id !== oldData.id);
  //       setUsersTableData(data);
  //       setSnackbar({ show: true, autoHideDuration: SNACKBAR_TIME.SUCCESS, severity: 'success', message: t('global.success.saved') });
  //     } else {
  //       reject();
  //       handleEndpointErrors(usersState, props, setSnackbar, t);
  //     }
  //   });
  // };

  const usersTableColumns = [
    { title: t('users.table.id'), field: 'id', type: 'numeric', align: 'left', defaultSort: 'asc', editable: 'never' },
    { title: t('users.name'), field: 'name', editable: 'never' },
    { title: t('users.client'), field: 'clientUser.clientId', lookup: getClientsByIds(), sorting: false, editable: 'never' },
    // { title: t('users.identifier'), field: 'identifier', sorting: false, editable: 'never' },
    { title: t('users.username'), field: 'username', sorting: false, editable: 'never' },
    { title: t('users.email'), field: 'email', sorting: false, editable: 'never' },
    // { title: t('users.phoneNumber'), field: 'phoneNumber', sorting: false, editable: 'never' },
    { title: t('users.role'), field: 'role', lookup: getUserRoles(), sorting: false, editable: 'never' },
    { title: t('users.functionalArea'), field: 'functionalArea.name', sorting: false, editable: 'never' },
    { title: t('users.active'), field: 'active', type: 'boolean', sorting: false, editable: 'never' },
  ];

  const clientTitle = clients.length ? clients.find(client => +client.id === +clientSelected)?.name : '';

  return (
    <div className={ classes.root }>
      <Breadcrumbs links={[
        { name: t('home.pageTitle'), path: '/', icon: <HomeIcon /> },
        { name: t('users.pageTitle'), icon: <GroupIcon /> },
      ]} />
      <DataFilters
        setActiveFilters={ setActiveFilters }
        filters={[
          {
            type: 'text',
            name: t('users.searchByName'),
            property: 'textSearch',
            active: pageFilters?.textSearch || '',
            width: '250px',
            onChangeFn: (filters) => {
              onGetUsers({ page: 1, ...filters });
            }
          },
          {
            type: 'text',
            name: t('users.searchByUsername'),
            property: 'username',
            active: pageFilters?.username || '',
            width: '250px',
            onChangeFn: (filters) => {
              onGetUsers({ page: 1, ...filters });
            }
          },
          {
            type: 'select',
            name: t('users.role'),
            property: 'role',
            options: [{label: t('global.roles.admin'), value: 'admin'}, {label: t('global.roles.driver'), value: 'driver'}, {label: t('global.roles.passenger'), value: 'passenger'}],
            active: pageFilters?.role || '',
            onChangeFn: (filters) => {
              onGetUsers({ page: 1, ...filters });
            }
          },
          {
            type: 'select',
            name: t('users.functionalArea'),
            property: 'functionalAreaId',
            options: functionalAreas.map(functionalArea => ({ label: functionalArea.name, value: functionalArea.id })),
            active: `${ pageFilters?.functionalAreaId }` || '',
            onChangeFn: (filters) => {
              onGetUsers({ page: 1, ...filters });
            }
          },
          {
            type: 'select',
            name: t('users.active'),
            property: 'active',
            options: [{label: t('dataFilters.yes'), value: 'true'}, {label: t('dataFilters.no'), value: 'false'}],
            active: (pageFilters?.active === 'true' || pageFilters?.active === 'false') ? pageFilters.active : '',
            onChangeFn: (filters) => {
              onGetUsers({ page: 1, ...filters });
            }
          }
        ]}
      />
      { usersLoading ? <LoadingCard length={ 1 } height={ 725 } /> : <React.Fragment>
        <Table
          className={ classes.table }
          title={ clientSelected === 'all' ? t('users.allUsers') : t('users.clientPassengers', { client: clientTitle }) }
          columns={ usersTableColumns }
          data={ usersTableData }
          exportButton
          exportFileName={ t('users.pageTitle') }
          // deleteEvent={ onDeleteUser }
          search={ false }
          customPagination={{
            page: paginationPage,
            size: paginationPageSize,
            responseLength: users.length,
            loading: dataLoading,
            hasRowsSelection: true,
            clickEvent: (page, pageSize = paginationPageSize) => {
              onGetUsers({ page, pageSize, ...activeFilters });
            }
          }}
          addActions={[
            {
              icon: () => <ViewComfyIcon />,
              tooltip: t('users.table.actionViewPageQrCodes'),
              isFreeAction: true,
              onClick: () => {
                setViewPageQrCodesModalOpen(true);
              }
            },
            {
              icon: 'add',
              iconProps: {
                color: 'primary'
              },
              tooltip: t('users.table.actionAdd'),
              isFreeAction: true,
              onClick: () => {
                setAddUserModalOpen(true);
              }
            },
            {
              icon: 'import_contacts',
              tooltip: t('users.table.actionAddUsersFromCSV'),
              isFreeAction: true,
              onClick: () => {
                setAddUsersFromCSVModalOpen(true);
              }
            },
            {
              icon: 'edit',
              tooltip: t('users.table.actionEdit'),
              onClick: (event, rowData) => {
                openLink(event, history, `/users/${ rowData.id }`);
              }
            },
            rowData => canEditUserActions && rowData.role === 'admin' ? {
              icon: () => <LockIcon />,
              tooltip: t('users.table.actionUpdatePermissions'),
              onClick: (event, rowData) => {
                openLink(event, history, `/users/${ rowData.id }/actions`);
              }
            } : null,
            {
              icon: 'qr_code',
              tooltip: t('global.table.custom.actionViewQrCode'),
              onClick: (_, rowData) => {
                setEditUser(rowData);
                setViewQrCodeModalOpen(true);
              }
            }
          ]}
        />
      </React.Fragment> }

      <CustomDialog className={ classes.modal } open={ addUserModalOpen } onClose={ onAddUserModalClose } fullWidth={ true } maxWidth="md">
        <AddUser
          onGetUsers={ onGetUsers }
          onAddUserModalClose={ onAddUserModalClose }
        />
      </CustomDialog>

      <CustomDialog className={ classes.modal } open={ addUsersFromCSVModalOpen } onClose={ onAddUsersFromCSVModalClose } fullWidth={ true } maxWidth="md">
        <AddUsersFromCSV
          onGetUsers={ onGetUsers }
          onAddUsersFromCSVModalClose={ onAddUsersFromCSVModalClose }
        />
      </CustomDialog>

      <CustomDialog className={ classes.modal } open={ viewQrCodeModalOpen } onClose={ onViewQrCodeModalClose } fullWidth={ true } maxWidth="sm">
        <ViewQrCode
          selectedItem={{ id: editUser.id, name: editUser.name, expirationDate: editUser.expirationDate, type: 'user' }}
          onViewQrCodeModalClose={ onViewQrCodeModalClose }
        />
      </CustomDialog>

      <CustomDialog className={ classes.modal } open={ viewPageQrCodesModalOpen } onClose={ onViewPageQrCodesModalClose } fullWidth={ true } maxWidth="lg">
        <ViewPageQrCodesModal onViewPageQrCodesModalClose={ onViewPageQrCodesModalClose } />
      </CustomDialog>
    </div>
  );
}

export default withRouter(Users);
