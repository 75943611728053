import axios from 'axios';

import { logSentryEndpointError } from '../../shared/utilities';

export const updateTrip = (actions, payload) => axios({
  method: 'put',
  url: `${ process.env.REACT_APP_API_URL }/api/v1/trips/${ payload.tripId }`,
  headers: {
    'auth-token': localStorage.getItem('token'),
    // 'Access-Control-Allow-Origin': '*',
    // 'Content-Type': 'application/json'
  },
  data: payload.data
})
.then(response => {
})
.catch(error => {
  actions.setError(error.response);
  logSentryEndpointError(error);
});

export const deleteTripPassenger = (actions, payload) => axios({
  method: 'put',
  url: `${ process.env.REACT_APP_API_URL }/api/v1/reservations/${ payload.reservationId }`,
  headers: {
    'auth-token': localStorage.getItem('token'),
    // 'Access-Control-Allow-Origin': '*',
    // 'Content-Type': 'application/json'
  },
  data: {
    'tripId': null
  }
})
.then(response => {
  actions.storeDeleteTripPassenger(payload);
})
.catch(error => {
  actions.setError(error.response);
  logSentryEndpointError(error);
});

export const updateTripPassenger = (actions, payload) => axios({
  method: 'put',
  url: `${ process.env.REACT_APP_API_URL }/api/v1/reservations/${ payload.reservationId }`,
  headers: {
    'auth-token': localStorage.getItem('token'),
    // 'Access-Control-Allow-Origin': '*',
    // 'Content-Type': 'application/json'
  },
  data: {
    status: payload.status,
    displayOrder: payload.displayOrder,
    notes: ''
  }
})
.then(response => {
  actions.storeUpdateTripPassenger(payload);
})
.catch(error => {
  actions.setError(error.response);
  logSentryEndpointError(error);
});

export const addMultipleReservations = (actions, payload) => axios({
  method: 'put',
  url: `${ process.env.REACT_APP_API_URL }/api/v1/trips/${ payload.tripId }/reservations`,
  headers: {
    'auth-token': localStorage.getItem('token'),
    // 'Access-Control-Allow-Origin': '*',
    // 'Content-Type': 'application/json'
  },
  data: {
    'reservation_ids': payload.reservationIds
  }
})
.then(response => {
})
.catch(error => {
  actions.setError(error.response);
  logSentryEndpointError(error);
});
