import React from 'react';

import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import DateFnsUtils from '@date-io/date-fns';
import enLocale from 'date-fns/locale/en-US';
import esLocale from 'date-fns/locale/es';

import { useTranslation } from 'react-multi-lang';

import { useDebounce } from '../shared/hooks/useDebounce';
import { transformDateString } from '../shared/utilities';

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: theme.spacing(4),
    '& .MuiFormControl-root': {
      marginRight: theme.spacing(2),
      marginBottom: theme.spacing(2),
      '&:last-of-type': {
        marginRight: 0,
      },
    },
    '& .MuiInputBase-root': {
      minWidth: '155px',
    },
    '& .MuiOutlinedInput-root': {
      borderRadius: '12px',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderWidth: '2px',
    },
  },
  children: {
    display: 'inline-block',
    marginRight: theme.spacing(2),
    '& > div': {
      margin: 0,
    },
  },
}));

const DataFilters = props => {
  const { filters, setActiveFilters, children } = props;
  const classes = useStyles(props);

  const [stateFilters, setStateFilters] = React.useState(filters);

  const [inputDefault, setInputDefault] = React.useState('');
  const [input, setInput] = React.useState({ value: '', filter: null, index: null });
  const debouncedInput = useDebounce(input, 500);

  const [date, setDate] = React.useState(null);

  const getLabel = (value, options) => options.find(option => option.value === value).label;

  const updateFilters = (value, filter, index, callOnChange = true) => {
    setStateFilters(currentFilters => {
      currentFilters[index] = {
        ...currentFilters[index],
        active: value
      } 
      return currentFilters;
    });
    setActiveFilters(activeFilters => {
      const newFilters = { ...activeFilters, [filter.property]: value };
      callOnChange && filter.onChangeFn(newFilters);
      return newFilters;
    });
  };

  React.useEffect(() => {
    if (debouncedInput.value !== inputDefault) {
      const { value, filter, index } = debouncedInput;
      setInputDefault(debouncedInput.value);
      updateFilters(value, filter, index);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedInput]);

  React.useEffect(() => {
    // eslint-disable-next-line array-callback-return
    filters.map((filter, index) => {
      const isValid = typeof(filter.active) === 'string' ? !!filter.active : filter.active?.length;
      if ( isValid ) {
        updateFilters(filter.active, filter, index, false);
      }
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const t = useTranslation();

  return <div className={ classes.root }>
    <Typography variant="h6" gutterBottom>{ t('dataFilters.name') }</Typography>
    { !!children && (
      <div className={ classes.children }>{ children }</div>
    ) }
    { filters.map((filter, index) => <React.Fragment key={ filter.property }>
      { filter.type === 'select' && <FormControl variant="outlined">
        <InputLabel>{filter.name}</InputLabel>
        <Select
          name={`filter-${filter.property}`}
          style={ filter.width ? { width: filter.width } : null }
          label={ filter.name }
          value={ stateFilters[index].active }
          multiple={ filter.multiple }
          renderValue={ filter.multiple ? (selected) => selected.map(option => getLabel(option, filter.options)).join(', ') : null }
          onChange={ event => {
            updateFilters(event.target.value, filter, index, !filter.multiple);
          }}
          onClose={ () => {
            if ( filter.multiple ) {
              updateFilters(stateFilters[index].active, filter, index);
            }
          } }
        >
          { (!filter.multiple && !filter.required) && <MenuItem value=""><em>-</em></MenuItem> }
          {filter.options.map(option => (
            !filter.multiple ? (
              <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
            ) : (
              <MenuItem key={option.value} value={option.value}>
                <Checkbox checked={stateFilters[index].active.indexOf(option.value) > -1} />
                <ListItemText primary={ getLabel(option.value, filter.options) } />
              </MenuItem>
            )
          ))}
        </Select>
      </FormControl> }
      { (filter.type === 'text' || filter.type === 'number') && <TextField
        name={`filter-${filter.property}`}
        InputProps={{
          style: filter.width ? { width: filter.width } : null
        }}
        label={ filter.name }
        variant="outlined"
        type={ filter.type }
        defaultValue={ stateFilters[index].active }
        onChange={(event) => {
          setInput({ value: event.target.value || null, filter, index });
        }}
      /> }
      { filter.type === 'date' && <MuiPickersUtilsProvider utils={ DateFnsUtils } locale={ localStorage.getItem('language') === 'en' ? enLocale : esLocale }>
        <DatePicker
          name={`filter-${filter.property}`}
          clearable={ !!date }
          inputVariant="outlined"
          format="dd/MM/yyyy"
          label={ filter.name }
          okLabel={ t('global.modal.btnOk') }
          cancelLabel={ t('global.modal.btnCancel') }
          clearLabel={ t('global.modal.btnClear') }
          value={ date || stateFilters[index].active }
          onChange={ value => {
            if (value === null) {
              setDate(null);
              updateFilters(null, filter, index);
            } else {
              const newDate = transformDateString( new Date(value) );
              setDate(newDate);
              updateFilters(newDate.toISOString().split('T')[0], filter, index);
            }
          } }
        />
      </MuiPickersUtilsProvider> }
    </React.Fragment>) }
  </div>;
}

DataFilters.propTypes = {
  setActiveFilters: PropTypes.func.isRequired,
  filters: PropTypes.arrayOf(PropTypes.exact({
    type: PropTypes.oneOf(['select', 'text', 'number', 'date']).isRequired,
    multiple: PropTypes.bool,
    required: PropTypes.bool,
    width: PropTypes.string,
    name: PropTypes.string.isRequired,
    property: PropTypes.string.isRequired,
    options: PropTypes.arrayOf(PropTypes.exact({
      label: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    })),
    active: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    onChangeFn: PropTypes.func.isRequired
  })).isRequired,
  children: PropTypes.node,
};

export default DataFilters;
