import axios from 'axios';

import { logSentryEndpointError } from '../../shared/utilities';

const getDashboardData = (actions, payload) => axios({
  method: 'get',
  url: `${ process.env.REACT_APP_API_URL }/api/admin/v1/dashboard`,
  params: {
    startDate: payload.startDate,
    endDate: payload.endDate,
    ...(payload.clientId !== 'all' && { clientId: payload.clientId }),
  },
  headers: {
    'auth-token': localStorage.getItem('token'),
    // 'Access-Control-Allow-Origin': '*',
    // 'Content-Type': 'application/json'
  }
})
.then(response => {
  actions.storeGetDashboardData(response.data[0]);
})
.catch(error => {
  actions.setError(error.response);
  logSentryEndpointError(error);
});

export default getDashboardData;
