import React from 'react';

import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import { RadialBarChart, RadialBar } from 'recharts';

import ChartContainer from './ChartContainer';

// const otherdata = [
//   {
//     "name": "Total de Vehículos",
//     "value": 7,
//     "fill": "#8C9EFF"
//   },
//   {
//     "name": "Total de Viajes",
//     "value": 45,
//     "fill": "#3D5AFE"
//   },
//   {
//     "name": "Viajes en Camino",
//     "value": 5,
//     "fill": "#3949AB"
//   },
//   {
//     "name": "Total de Rutas",
//     "value": 11,
//     "fill": "#1A237E"
//   }
// ];

const useStyles = makeStyles(theme => ({
  root: {
  },
  legend: {
    padding: theme.spacing(0, 4),
  },
  legendItem: {
    borderBottom: `1px solid ${ theme.palette.divider }`,
    padding: theme.spacing(2, 4),
    position: 'relative',
    '& .color': {
      borderRadius: '50%',
      height: '12px',
      left: theme.spacing(1),
      position: 'absolute',
      top: `${ theme.spacing(2) + 4 }px`,
      width: '12px',
    },
    '&:last-child': {
      borderBottom: 'none'
    },
  },
}));

const CustomRadialBarChart = (props) => {
  const { data, dataKey } = props;
  const classes = useStyles();

  return (
    <div className={ classes.root }>
      <ChartContainer>
        <RadialBarChart innerRadius="40%" outerRadius="100%" barSize={ 10 } data={ data.reverse() }>
          <RadialBar
            minAngle={ 15 }
            background
            clockWise
            dataKey={ dataKey }
          />
        </RadialBarChart>
      </ChartContainer>
      <div className={ classes.legend }>
        { [...data].reverse().map(item => (
          <div key={ item.name } className={ classes.legendItem }>
            <span className="color" style={{ backgroundColor: item.fill }}></span>
            <Typography variant="body2" color="textSecondary">{ item.name }</Typography>
            <Typography variant="h6">{ item.value }</Typography>
          </div>
        )) }
      </div>
    </div>
  );
}

CustomRadialBarChart.propTypes = {
  data: PropTypes.array.isRequired,
  dataKey: PropTypes.string.isRequired
};

export default CustomRadialBarChart;
