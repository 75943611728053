import { action, thunk } from 'easy-peasy';

import { getUserPlaces, getAdminPlaces, getRoutePlaces } from '../../services/places/get';
import addAdminPlace from '../../services/places/post';
import updateAdminPlace from '../../services/places/put';
import { deleteUserPlace, deleteAdminPlace } from '../../services/places/delete';

const placesModel = {
  userPlaces: [],
  adminPlaces: [],
  routePlaces: [],
  newAdminPlaceId: null,
  loading: false,
  error: '',
  page: 1,
  pageSize: localStorage.getItem('placesPageSize') ? localStorage.getItem('placesPageSize') : 25,
  textSearch: '',
  getUserPlaces: thunk(async (actions, payload) => {
    actions.setLoading(true);
    actions.setError('');
    return await getUserPlaces(actions, payload);
  }),
  deleteUserPlace: thunk(async (actions, payload) => {
    actions.setError('');
    return await deleteUserPlace(actions, payload);
  }),
  getAdminPlaces: thunk(async (actions, payload = {}, { getState }) => {
    actions.setLoading(payload.loading);
    actions.setError('');
    return await getAdminPlaces(actions, payload, getState());
  }),
  addAdminPlace: thunk(async (actions, payload) => {
    actions.setError('');
    return await addAdminPlace(actions, payload);
  }),
  updateAdminPlace: thunk(async (actions, payload) => {
    actions.setError('');
    return await updateAdminPlace(actions, payload);
  }),
  deleteAdminPlace: thunk(async (actions, payload) => {
    actions.setError('');
    return await deleteAdminPlace(actions, payload);
  }),
  getRoutePlaces: thunk(async (actions, payload) => {
    actions.setLoading(true);
    actions.setError('');
    return await getRoutePlaces(actions, payload);
  }),
  storeGetUserPlaces: action((state, payload) => {
    state.userPlaces = payload;
  }),
  storeGetAdminPlaces: action((state, payload) => {
    state.adminPlaces = payload.data;
    state.page = payload.page ? payload.page : 1;
    state.textSearch = payload.textSearch ? payload.textSearch : '';
  }),
  storeAddAdminPlace: action((state, payload) => {
    state.newAdminPlaceId = payload;
  }),
  storeGetRoutePlaces: action((state, payload) => {
    state.routePlaces = payload;
  }),
  setLoading: action((state, payload) => {
    state.loading = payload;
  }),
  setError: action((state, payload) => {
    state.error = payload;
  }),
  setTextSearch: action((state, payload) => {
    state.textSearch = payload;
  }),
}

export default placesModel;
