import { action, thunk } from 'easy-peasy';

import { getReservations, getReservation, getReservationRouteAlternatives, getReservationsReport, getReservationsReportPivot } from '../../services/reservations/get';
import updateReservation from '../../services/reservations/put';
import deleteReservation from '../../services/reservations/delete';

const reservationsModel = {
  reservations: [],
  reservation: {},
  reservationRouteAlternatives: [],
  reservationsReport: [],
  reservationsReportDates: {},
  reservationsReportPage: 1,
  reservationsReportPageSize: localStorage.getItem('reservationsReportPageSize') ? localStorage.getItem('reservationsReportPageSize') : 50,
  reservationsReportTextSearch: '',
  reservationsReportPivot: [],
  reservationsReportPivotDates: {},
  loading: false,
  error: '',
  page: 1,
  pageSize: localStorage.getItem('reservationsPageSize') ? localStorage.getItem('reservationsPageSize') : 25,
  textSearch: '',
  hasMoreReservations: false,
  getReservations: thunk(async (actions, payload = {}, { getState, getStoreState }) => {
    actions.setLoading(payload.loading);
    actions.setError('');
    return await getReservations(actions, payload, getState(), getStoreState());
  }),
  updateReservation: thunk(async (actions, payload) => {
    actions.setError('');
    return await updateReservation(actions, payload);
  }),
  deleteReservation: thunk(async (actions, payload) => {
    actions.setError('');
    return await deleteReservation(actions, payload);
  }),
  getReservation: thunk(async (actions, payload) => {
    actions.setLoading(true);
    actions.setError('');
    return await getReservation(actions, payload);
  }),
  getReservationRouteAlternatives: thunk(async (actions, payload) => {
    actions.setLoading(true);
    actions.setError('');
    return await getReservationRouteAlternatives(actions, payload);
  }),
  getReservationsReport:thunk(async (actions, payload = {}, { getState, getStoreState }) => {
    actions.setLoading(payload.loading);
    actions.setError('');
    return await getReservationsReport(actions, payload, getState(), getStoreState());
  }),
  getReservationsReportPivot:thunk(async (actions, payload = {}, { getState, getStoreState }) => {
    actions.setLoading(payload.loading);
    actions.setError('');
    return await getReservationsReportPivot(actions, payload, getState(), getStoreState());
  }),
  storeGetReservations: action((state, payload) => {
    state.reservations = payload.data;
    state.page = payload.page ? payload.page : 1;
    state.pageSize = payload.pageSize ? payload.pageSize : 25;
    state.textSearch = payload.textSearch ? payload.textSearch : '';
  }),
  storeGetReservation: action((state, payload) => {
    state.reservation = payload;
  }),
  storeGetReservationRouteAlternatives: action((state, payload) => {
    state.reservationRouteAlternatives = payload;
  }),
  storeGetReservationsReport: action((state, payload) => {
    state.reservationsReport = payload.data;
    state.reservationsReportPage = payload.page ? payload.page : 1;
    state.reservationsReportTextSearch = payload.textSearch ? payload.textSearch : '';
  }),
  storeGetReservationsReportPivot: action((state, payload) => {
    state.reservationsReportPivot = payload.data;
  }),
  setReservationsReportDates: action((state, payload) => {
    state.reservationsReportDates = payload;
  }),
  setReservationsReportPivotDates: action((state, payload) => {
    state.reservationsReportPivotDates = payload;
  }),
  setLoading: action((state, payload) => {
    state.loading = payload;
  }),
  setError: action((state, payload) => {
    state.error = payload;
  }),
  setTextSearch: action((state, payload) => {
    state.textSearch = payload;
  }),
}

export default reservationsModel;
