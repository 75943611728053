import React from 'react';

import PropTypes from 'prop-types';

import { useStoreState, useStoreActions, useStore } from 'easy-peasy';

import { makeStyles } from '@material-ui/core/styles';
import Badge from '@material-ui/core/Badge';
import GroupIcon from '@material-ui/icons/Group';

import { handleEndpointErrors, SNACKBAR_TIME } from '../../shared/utilities';

import Table from '../Table';
import CustomDialog from '../CustomDialog';
import AddVehicle from './AddVehicle';
import Drivers from './Drivers';

import { useTranslation } from 'react-multi-lang';

const useStyles = makeStyles(theme => ({
  table: {
  }
}));

const Vehicles = (props) => {
  const { route } = props;
  const classes = useStyles();
  const [vehiclesTableData, setVehiclesTableData] = React.useState([]);
  const [addVehicleModalOpen, setAddVehicleModalOpen] = React.useState(false);
  const [driversVehicleModalOpen, setDriversVehicleModalOpen] = React.useState(false);
  const [editVehicle, setEditVehicle] = React.useState({});

  const store = useStore();

  const permissions = useStoreState(state => state.global.permissions);
  const setSnackbar = useStoreActions(actions => actions.global.setSnackbar);

  const routeVehicles = useStoreState(state => state.routes.routeVehicles);
  const getRouteVehicles = useStoreActions(actions => actions.routes.getRouteVehicles);
  const updateRouteVehicle = useStoreActions(actions => actions.routes.updateRouteVehicle);
  const deleteRouteVehicle = useStoreActions(actions => actions.routes.deleteRouteVehicle);

  const canEdit = permissions?.admin?.routes?.edit;

  const t = useTranslation();

  const vehiclesTableColumns = [
    { title: t('route.vehicles.id'), field: 'vehicleId', type: 'numeric', align: 'left', defaultSort: 'asc', editable: 'never' },
    { title: t('route.vehicles.licensePlate'), field: 'licensePlate', sorting: false, editable: 'never' },
    { title: t('route.vehicles.make'), field: 'make', sorting: false, editable: 'never' },
    { title: t('route.vehicles.model'), field: 'model', sorting: false, editable: 'never' },
    { title: t('route.vehicles.year'), field: 'year', sorting: false, editable: 'never' },
    {
      title: t('route.vehicles.routeCapacity'), field: 'routeVehicleCapacity', type: 'numeric', align: 'left', sorting: false,
      validate: rowData => rowData.routeVehicleCapacity <= rowData.vehicleCapacity ? true : { isValid: false, helperText: t('route.vehicles.add.realCapacity.error', { capacity: rowData.vehicleCapacity }) },
    },
    { title: t('route.vehicles.realCapacity'), field: 'vehicleCapacity', type: 'numeric', align: 'left', sorting: false, editable: 'never' },
    { title: t('route.vehicles.active'), field: 'active', type: 'boolean', sorting: false, editable: 'never' },
  ];

  const onGetRouteVehicles = (payload) => {
    getRouteVehicles(payload).then(() => {
      const routesState = store.getState().routes;
      if (!routesState.routeVehiclesLoading && !routesState.routeVehiclesError) {
        // console.log(routesState);
      } else {
        handleEndpointErrors({ ...routesState, loading: routesState.routeVehiclesLoading, error: routesState.routeVehiclesError }, props, setSnackbar, t);
      }
    });
  }

  const onUpdateRouteVehicle = (newData, oldData, resolve, reject) => {
    const oldCapacity = oldData.routeVehicleCapacity;
    const newCapacity = newData.routeVehicleCapacity;
    if (oldCapacity === newCapacity) {
      resolve();
    } else {
      updateRouteVehicle({ vehicleId: newData.routeVehicleId, data: { capacity: newCapacity } }).then(() => {
        const routesState = store.getState().routes;
        if (!routesState.loading && !routesState.error) {
          // console.log('success', routesState);
          resolve();
          const data = [...vehiclesTableData];
          const vehicle = data.find(vehicle => vehicle.vehicleId === newData.vehicleId);
          vehicle.routeVehicleCapacity = newCapacity;
          setSnackbar({ show: true, autoHideDuration: SNACKBAR_TIME.SUCCESS, severity: 'success', message: t('global.success.saved') });
        } else {
          reject();
          handleEndpointErrors(routesState, props, setSnackbar, t);
        }
      });
    }
  };

  const onDeleteRouteVehicle = (oldData, resolve, reject) => {
    deleteRouteVehicle(oldData.routeVehicleId).then(() => {
      const routesState = store.getState().routes;
      if (!routesState.error) {
        // console.log('success', routesState);
        resolve();
        const data = [...vehiclesTableData].filter(vehicle => vehicle.vehicleId !== oldData.vehicleId);
        setVehiclesTableData(data);
        setSnackbar({ show: true, autoHideDuration: SNACKBAR_TIME.SUCCESS, severity: 'success', message: t('global.success.saved') });
      } else {
        reject();
        handleEndpointErrors(routesState, props, setSnackbar, t);
      }
    });
  };

  const onAddVehicleModalClose = () => {
    setAddVehicleModalOpen(false);
  };

  const onDriversVehicleModalClose = () => {
    setDriversVehicleModalOpen(false);
  };

  React.useEffect(() => {
    if (route.id) {
      onGetRouteVehicles(route.id);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [route]);

  React.useEffect(() => {
    setVehiclesTableData(routeVehicles);
  }, [routeVehicles]);

  return (
    <React.Fragment>
      <Table
        className={ classes.table }
        title=""
        columns={ vehiclesTableColumns }
        data={ vehiclesTableData }
        paging={ false }
        updateEvent={ canEdit ? onUpdateRouteVehicle : null }
        deleteEvent={ canEdit ? onDeleteRouteVehicle : null }
        addActions={[
          ...(canEdit ? [{
            icon: 'add',
            iconProps: {
              color: 'primary'
            },
            tooltip: t('route.vehicles.add.action'),
            isFreeAction: true,
            onClick: () => {
              setAddVehicleModalOpen(true);
            }
          }] : []),
          rowData => ({
            icon: () => <Badge badgeContent={ rowData.drivers?.length } color="primary"><GroupIcon /></Badge>,
            tooltip: t('route.drivers.title'),
            onClick: () => {
              setEditVehicle(rowData);
              setDriversVehicleModalOpen(true);
            }
          }),
        ]}
      />

      <CustomDialog open={ addVehicleModalOpen } onClose={ onAddVehicleModalClose } fullWidth={ true } maxWidth="md">
        <AddVehicle
          routeId={ route.id }
          vehicleIds={ vehiclesTableData.map(vehicle => vehicle.vehicleId) }
          onGetRouteVehicles={ onGetRouteVehicles }
          onAddVehicleModalClose={ onAddVehicleModalClose }
        />
      </CustomDialog>

      <CustomDialog open={ driversVehicleModalOpen } onClose={ onDriversVehicleModalClose } fullWidth={ true } maxWidth="lg">
        <Drivers
          routeId={ route.id }
          vehicles={ routeVehicles }
          editVehicle={ editVehicle }
          onGetRouteVehicles={ onGetRouteVehicles }
          onDriversVehicleModalClose={ onDriversVehicleModalClose }
        />
      </CustomDialog>
    </React.Fragment>
  );
}

Vehicles.propTypes = {
  route: PropTypes.object.isRequired
};

export default Vehicles;
