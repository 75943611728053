import axios from 'axios';

import { logSentryEndpointError } from '../../shared/utilities';

const getVersion = (actions) => axios({
  method: 'get',
  url: `${ process.env.REACT_APP_API_URL }/_versions?app=admin`,
  headers: {
    // 'auth-token': localStorage.getItem('token'),
    // 'Access-Control-Allow-Origin': '*',
    // 'Content-Type': 'application/json'
  }
})
.then(response => {
  actions.setVersionLoading(false);
  actions.storeGetVersion(response.data.version);
})
.catch(error => {
  actions.setVersionLoading(false);
  actions.setVersionError(error.response);
  logSentryEndpointError(error);
});

export default getVersion;
