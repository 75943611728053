import React from 'react';

import PropTypes from 'prop-types';

import { useStoreState, useStoreActions, useStore } from 'easy-peasy';

import { makeStyles } from '@material-ui/core/styles';
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Alert from '@material-ui/lab/Alert';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Checkbox from '@material-ui/core/Checkbox';
import DialogActions from '@material-ui/core/DialogActions';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormLabel from '@material-ui/core/FormLabel';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Select from '@material-ui/core/Select';
import Typography from '@material-ui/core/Typography';

import GenerateTripsFilters from './GenerateTripsFilters';
import { DEFAULT_TRIP_FILTERS } from '../../../../../store/models/trips';
import CustomAccordion from '../../../../CustomAccordion';

import { handleEndpointErrors, SNACKBAR_TIME } from '../../../../../shared/utilities';

import { useTranslation } from 'react-multi-lang';

const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiTypography-root + div:not(.MuiFormControl-root)': {
      marginBottom: 0,
      marginTop: theme.spacing(1),
    },
    '& ul': {
      listStyle: 'none',
      margin: `${theme.spacing(1)}px 0 ${theme.spacing(2)}px`,
      padding: 0,
    },
    '& li': {
      ...theme.typography.caption,
      color: theme.palette.error.main
    },
  },
  generateTripsBy: {
    margin: theme.spacing(2, 0, -2),
    '& .Mui-checked': {
      color: theme.palette.secondary.dark,
    },
  },
  select: {
    margin: theme.spacing(3, 0, 2),
    display: 'block',
    '& .MuiOutlinedInput-root': {
      borderRadius: theme.spacing(1.5),
      minWidth: '125px',
    },
    '& .MuiOutlinedInput-input': {
      padding: theme.spacing('18.5px', 5, '18.5px', '14px'),
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderWidth: '2px',
    },
  },
  parkOptions: {
    margin: theme.spacing(2, 0, 2),
    '& .MuiButtonGroup-root': {
      margin: theme.spacing(2, 0, 1),
    },
  },
  parkAlerts: {
    marginTop: theme.spacing(2),
    '& .MuiAlert-root': {
      marginBottom: theme.spacing(1),
    },
  }
}));

const GenerateTrips = (props) => {
  const { onAddTripModalClose, onGetTrips } = props;
  const classes = useStyles();

  const store = useStore();

  const clients = useStoreState(state => state.global.clients);
  const clientSelected = useStoreState(state => state.global.clientSelected);
  const setSnackbar = useStoreActions(actions => actions.global.setSnackbar);

  const businessParks = useStoreState(state => state.businessParks.businessParks);

  const setFilters = useStoreActions(actions => actions.trips.setFilters);
  const setTripSelected = useStoreActions(actions => actions.trips.setCurrentTrip);
  const getHeatMapTrips = useStoreActions(actions => actions.trips.getHeatMapTrips);
  const generateTrips = useStoreActions(actions => actions.trips.generateTrips);
  const generateReservations = useStoreActions(actions => actions.trips.generateReservations);
  const assignReservations = useStoreActions(actions => actions.trips.assignReservations);
  
  const [generateTripsBy, setGenerateTripsBy] = React.useState('client');
  const [tripsClientSelected, setTripsClientSelected] = React.useState(clientSelected);
  const [tripsParkSelected, setTripsParkSelected] = React.useState('');
  const [dates, setDates] = React.useState({});
  const [isSameLength, setIsSameLength] = React.useState(false);
  const [isWeekOrLessLength, setIsWeekOrLessLength] = React.useState(false);
  const [isDateOk, setIsDateOk] = React.useState(false);
  const [filterDatesChecked, setFilterDatesChecked] = React.useState(false);
  const [parkActionsStatus, setParkActionsStatus] = React.useState({ // info, error, success
    generateTrips: null,
    generateReservations: null,
    assignReservations: null,
  });
  const [disabledParkActions, setDisabledParkActions] = React.useState([]);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const DAY_MILLISECONDS = 86400000;
  const isClientSelected = tripsClientSelected !== 'all';
  const isParkSelected = !!tripsParkSelected;
  const hasTakenParkAction = Object.values(parkActionsStatus).some(action => !!action);
  const parkActionsText = 'trips.addTripModal.generateTrips.parkActions';

  const onClientSelectedChange = (e) => {
    setTripsClientSelected(e.target.value);
  };

  const onParkSelectedChange = (e) => {
    setTripsParkSelected(e.target.value);
  };

  const handleGenerateTripsByChange = (event) => {
    setGenerateTripsBy(event.target.value);
  };

  // const sumDateDays = (date, days) => new Date( date.setDate(date.getDate() + days) );
  // const restDateDays = (date, days) => new Date( date.setDate(date.getDate() - days) );
  const getDatesDiffDays = (startDate, endDate) => startDate && endDate ? Math.round((endDate-startDate) / (1000*60*60*24)) : '';
  const compareDates = (firstDate, secondDate) =>  firstDate?.getTime()-secondDate?.getTime() >= DAY_MILLISECONDS;

  const getSourceDates = ({startDate, endDate}) => {
    setDates(state => {
      setIsSameLength( getDatesDiffDays(state.target?.startDate, state.target?.endDate) === getDatesDiffDays(startDate, endDate) );
      setIsWeekOrLessLength(getDatesDiffDays(startDate, endDate) <= 6);
      setIsDateOk( compareDates(state.target?.startDate, endDate) );
      return {
        ...state,
        source: { startDate, endDate }
      };
    });
  };

  const getTargetDates = ({startDate, endDate}) => {
    setDates(state => {
      setIsSameLength( getDatesDiffDays(state.source?.startDate, state.source?.endDate) === getDatesDiffDays(startDate, endDate) );
      setIsWeekOrLessLength(getDatesDiffDays(startDate, endDate) <= 6);
      setIsDateOk( compareDates(startDate, state.source?.endDate) );
      return {
        ...state,
        target: { startDate, endDate }
      };
    });
  };

  const onFilterDatesChange = event => {
    setFilterDatesChecked(event.target.checked);
  };

  const getTrips = () => {
    if ( localStorage.getItem('tripsSidebarTab') === '1' ) {
      getHeatMapTrips();
    } else {
      onGetTrips();
    }
  };

  const updateDateFilter = () => {
    if ( filterDatesChecked ) {
      setFilters({
        ...DEFAULT_TRIP_FILTERS,
        date: [{ startDate: dates.target.startDate, endDate: dates.target.endDate }]
      });
    }
  };

  const onGenerateTrips = () => {
    setIsSubmitting(true);

    if (generateTripsBy === 'client') {
      const data = {
        sourceDateFrom: dates.source.startDate.toISOString().split('T')[0],
        sourceDateTo: dates.source.endDate.toISOString().split('T')[0],
        targetDateFrom: dates.target.startDate.toISOString().split('T')[0],
        targetDateTo: dates.target.endDate.toISOString().split('T')[0],
        clientId: tripsClientSelected
      };
      
      generateTrips(data).then((response) => {
        const tripsState = store.getState().trips;
        if (!tripsState.error) {
          updateDateFilter();
          setTripSelected({});
          getTrips();
          onAddTripModalClose();
          if (tripsState.generatedTripsCount !== 1) {
            setSnackbar({ show: true, autoHideDuration: SNACKBAR_TIME.SUCCESS, severity: 'success', message: t('trips.addTripModal.generateTrips.multipleSuccessMessage', {count: response.data.count}) });
          } else {
            setSnackbar({ show: true, autoHideDuration: SNACKBAR_TIME.SUCCESS, severity: 'success', message: t('trips.addTripModal.generateTrips.singleSuccessMessage', {count: response.data.count}) });
          }
        } else {
          setIsSubmitting(false);
          handleEndpointErrors(tripsState, props, setSnackbar, t, 'generateTrips');
        }
      });
    } else {
      onParkGenerateTrips(true);
    }
  };

  const handleCountMessage = (count, action) => {
    switch (count) {
      case 0:
        setParkActionsStatus(prevStatus => ({ ...prevStatus, [action]: { severity: 'info', message: t(`${ parkActionsText }.${action}.success.zero`) } }));
        switch (action) {
          case 'generateTrips':
            setDisabledParkActions(['generateTrips', 'generateReservations', 'assignReservations']);
            break;
          case 'generateReservations':
            setDisabledParkActions(['generateReservations', 'assignReservations']);
            break;
          default:
            setDisabledParkActions(['assignReservations']);
        }
        break;
      case 1:
        setParkActionsStatus(prevStatus => ({ ...prevStatus, [action]: { severity: 'success', message: t(`${ parkActionsText }.${action}.success.single`, {count: count}) } }));
        break;
      default:
        setParkActionsStatus(prevStatus => ({ ...prevStatus, [action]: { severity: 'success', message: t(`${ parkActionsText }.${action}.success.multiple`, {count: count}) } }));
    }
  };

  const onParkGenerateTrips = (isGenerate = false) => {
    setIsSubmitting(true);

    const data = {
      sourceDateFrom: dates.source.startDate.toISOString().split('T')[0],
      sourceDateTo: dates.source.endDate.toISOString().split('T')[0],
      targetDateFrom: dates.target.startDate.toISOString().split('T')[0],
      targetDateTo: dates.target.endDate.toISOString().split('T')[0],
      parkId: tripsParkSelected
    };

    setParkActionsStatus(prevStatus => ({ ...prevStatus, generateTrips: { severity: 'info', message: t(`${ parkActionsText }.generateTrips.info`) } }));

    generateTrips(data).then((response) => {
      setIsSubmitting(false);

      const tripsState = store.getState().trips;
      if (!tripsState.error) {
        updateDateFilter();
        getTrips();
        handleCountMessage(response.data.count, 'generateTrips');
        if (isGenerate && response.data.count) {
          onParkGenerateReservations(true);
        }
      } else {
        setParkActionsStatus(prevStatus => ({ ...prevStatus, generateTrips: { severity: 'error', message: t(`${ parkActionsText }.generateTrips.error`) } }));
        handleEndpointErrors(tripsState, props, setSnackbar, t, 'parks');
      }
    });
  };

  const onParkGenerateReservations = (isGenerate = false) => {
    setIsSubmitting(true);

    const data = {
      targetDateFrom: dates.target.startDate.toISOString().split('T')[0],
      targetDateTo: dates.target.endDate.toISOString().split('T')[0],
      parkId: tripsParkSelected
    };

    setParkActionsStatus(prevStatus => ({ ...prevStatus, generateReservations: { severity: 'info', message: t(`${ parkActionsText }.generateReservations.info`) } }));

    generateReservations(data).then((response) => {
      setIsSubmitting(false);

      const tripsState = store.getState().trips;
      if (!tripsState.error) {
        updateDateFilter();
        getTrips();
        handleCountMessage(response.data.count, 'generateReservations');
        if (isGenerate && response.data.count) {
          onParkAssignReservations();
        }
      } else {
        setParkActionsStatus(prevStatus => ({ ...prevStatus, generateReservations: { severity: 'error', message: t(`${ parkActionsText }.generateReservations.error`) } }));
        handleEndpointErrors(tripsState, props, setSnackbar, t, 'parks');
      }
    });
  };

  const onParkAssignReservations = () => {
    setIsSubmitting(true);

    const data = {
      targetDateFrom: dates.target.startDate.toISOString().split('T')[0],
      targetDateTo: dates.target.endDate.toISOString().split('T')[0],
      parkId: tripsParkSelected
    };

    setParkActionsStatus(prevStatus => ({ ...prevStatus, assignReservations: { severity: 'info', message: t(`${ parkActionsText }.assignReservations.info`) } }));

    assignReservations(data).then((response) => {
      setIsSubmitting(false);

      const tripsState = store.getState().trips;
      if (!tripsState.error) {
        updateDateFilter();
        getTrips();
        handleCountMessage(response.data.count, 'assignReservations');
      } else {
        setParkActionsStatus(prevStatus => ({ ...prevStatus, assignReservations: { severity: 'error', message: t(`${ parkActionsText }.assignReservations.error`) } }));
        handleEndpointErrors(tripsState, props, setSnackbar, t, 'parks');
      }
    });
  };

  const t = useTranslation();

  return (
    <div className={ classes.root }>
      <Typography variant="body2" gutterBottom>{ t('trips.addTripModal.generateTrips.description') }</Typography>
      { !!businessParks.length && <FormControl component="fieldset" className={ classes.generateTripsBy }>
        <FormLabel component="legend">{ t('trips.addTripModal.generateTrips.generateTripsBy') }</FormLabel>
        <RadioGroup row value={generateTripsBy} onChange={handleGenerateTripsByChange}>
          <FormControlLabel value="client" control={<Radio disabled={ hasTakenParkAction } />} label={ t('trips.addTripModal.generateTrips.client') } />
          <FormControlLabel value="businessPark" control={<Radio disabled={ hasTakenParkAction } />} label={ t('trips.addTripModal.generateTrips.businessPark') } />
        </RadioGroup>
      </FormControl> }
      { generateTripsBy === 'client' && <FormControl variant="outlined" className={ classes.select }>
        <InputLabel>{ t('trips.addTripModal.generateTrips.client') }</InputLabel>
        <Select
          value={ isClientSelected ? tripsClientSelected : '' }
          onChange={ onClientSelectedChange }
          label={ t('trips.addTripModal.generateTrips.client') }
          >
            { clients.map(client => <MenuItem key={ client.id } value={ client.id }>{ client.name }</MenuItem>) }
        </Select>
      </FormControl> }
      { !!businessParks.length && generateTripsBy === 'businessPark' && <FormControl variant="outlined" className={ classes.select }>
        <InputLabel>{ t('trips.addTripModal.generateTrips.businessPark') }</InputLabel>
        <Select
          value={ tripsParkSelected }
          onChange={ onParkSelectedChange }
          label={ t('trips.addTripModal.generateTrips.businessPark') }
          disabled={ hasTakenParkAction }
          >
            { businessParks.map(park => <MenuItem key={ park.id } value={ park.id }>{ park.name }</MenuItem>) }
        </Select>
      </FormControl> }
      <Typography variant="overline">{ t('trips.addTripModal.generateTrips.source') }</Typography>
      <GenerateTripsFilters onChangeFn={ getSourceDates } isSource isDisabled={ hasTakenParkAction } />
      <Typography variant="overline">{ t('trips.addTripModal.generateTrips.target') }</Typography>
      <GenerateTripsFilters onChangeFn={ getTargetDates } isSource={ false } isDisabled={ hasTakenParkAction } />
      <FormGroup row>
        <FormControlLabel
          control={<Checkbox checked={ filterDatesChecked } onChange={ onFilterDatesChange } name="filterDates" />}
          label={ t('trips.addTripModal.generateTrips.filterDates') }
        />
      </FormGroup>
      { isParkSelected && <div className={ classes.parkOptions }>
        <CustomAccordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>{ t(`${ parkActionsText }.advancedOptions`) }</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <ButtonGroup orientation={ isWidthUp('sm', props.width) ? 'horizontal' : 'vertical' } color="primary" disabled={ isSubmitting || !isParkSelected || !isSameLength || !isDateOk || !isWeekOrLessLength }>
              <Button 
                disabled={ parkActionsStatus.generateTrips?.severity === 'info' || parkActionsStatus.generateTrips?.severity === 'success' || disabledParkActions.includes('generateTrips') }
                onClick={ () => onParkGenerateTrips() }
              >{ t(`${ parkActionsText }.generateTrips.name`) }</Button>
              <Button 
                disabled={ parkActionsStatus.generateReservations?.severity === 'info' || parkActionsStatus.generateReservations?.severity === 'success' || disabledParkActions.includes('generateReservations') }
                onClick={ () => onParkGenerateReservations() }
              >{ t(`${ parkActionsText }.generateReservations.name`) }</Button>
              <Button 
                disabled={ parkActionsStatus.assignReservations?.severity === 'info' || parkActionsStatus.assignReservations?.severity === 'success' || disabledParkActions.includes('assignReservations') }
                onClick={ () => onParkAssignReservations() }
              >{ t(`${ parkActionsText }.assignReservations.name`) }</Button>
            </ButtonGroup>
          </AccordionDetails>
        </CustomAccordion>
      </div> }
      <div className={ classes.parkAlerts }>
        { Object.keys(parkActionsStatus).filter(action => parkActionsStatus[action]).map(action => (
          <Alert key={action} variant="outlined" severity={parkActionsStatus[action].severity}>{ parkActionsStatus[action].message }</Alert>
        )) }
      </div>
      <ul>
        { generateTripsBy === 'client' && !isClientSelected && <li>- { t('trips.addTripModal.generateTrips.errors.selectClient') }</li> }
        { generateTripsBy === 'businessPark' && !isParkSelected && <li>- { t('trips.addTripModal.generateTrips.errors.selectBusinessPark') }</li> }
        { !isSameLength && <li>- { t('trips.addTripModal.generateTrips.errors.sameLength') }</li> }
        { !isDateOk && <li>- { t('trips.addTripModal.generateTrips.errors.dateOk') }</li> }
        { !isWeekOrLessLength && <li>- { t('trips.addTripModal.generateTrips.errors.weekOrLessLength') }</li> }
      </ul>
      <DialogActions>
        <Button onClick={ onAddTripModalClose } color="primary">{ t('global.modal.btnClose') }</Button>
        <Button disabled={ isSubmitting || hasTakenParkAction || (generateTripsBy === 'client' && !isClientSelected) || (generateTripsBy === 'businessPark' && !isParkSelected) || !isSameLength || !isDateOk || !isWeekOrLessLength } onClick={ onGenerateTrips } color="primary">{ t('trips.addTripModal.generateTrips.generateBtn') }</Button>
      </DialogActions>
    </div>
  );
}

GenerateTrips.propTypes = {
  onAddTripModalClose: PropTypes.func.isRequired,
  onGetTrips: PropTypes.func.isRequired,
};

export default withWidth()(GenerateTrips);