import React from 'react';
import { withRouter } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';

import { useStoreState, useStoreActions, useStore } from 'easy-peasy';

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import EditIcon from '@material-ui/icons/Edit';
import Grid from '@material-ui/core/Grid';
import HomeIcon from '@material-ui/icons/Home';
import Link from '@material-ui/core/Link';
import ShuffleIcon from '@material-ui/icons/Shuffle';
import Typography from '@material-ui/core/Typography';
import VisibilityIcon from '@material-ui/icons/Visibility';

import * as Yup from 'yup';

import { Formik, Field } from 'formik';
import { TextField } from 'formik-material-ui';

import { handleEndpointErrors, getRouteDirections, SNACKBAR_TIME } from '../shared/utilities';

import Breadcrumbs from '../components/Breadcrumbs';
import LoadingCard from '../components/LoadingCard';
import CustomFormikForm from '../components/CustomFormikForm';
import Table from '../components/Table';
import CustomDialog from '../components/CustomDialog';
import AddRoute from '../components/EditLine/AddRoute';

import { useTranslation } from 'react-multi-lang';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3, 2),
  },
  pageTitle: {
    marginBottom: theme.spacing(2.5),
  },
  routes: {
    marginTop: theme.spacing(2.5),
    marginBottom: theme.spacing(2.5),
  },
}));

const EditLine = (props) => {
  const classes = useStyles();
  const [routesTableData, setRoutesTableData] = React.useState([]);
  const [addRouteModalOpen, setAddRouteModalOpen] = React.useState(false);

  const store = useStore();

  const permissions = useStoreState(state => state.global.permissions);
  const storeMenuTitle = useStoreActions(actions => actions.global.storeTitle);
  const setSnackbar = useStoreActions(actions => actions.global.setSnackbar);
  const routeDirections = useStoreState(state => state.global.routeDirections);
  const routeTypes = useStoreState(state => state.global.routeTypes);

  const line = useStoreState(state => state.lines.line);
  const getLine = useStoreActions(actions => actions.lines.getLine);
  const storeGetLine = useStoreActions(actions => actions.lines.storeGetLine);
  const updateLine = useStoreActions(actions => actions.lines.updateLine);
  const [lineName, setLineName] = React.useState(line.name);

  const routes = useStoreState(actions => actions.routes.routes);
  const getRoutes = useStoreActions(actions => actions.routes.getRoutes);
  const deleteRouteLine = useStoreActions(actions => actions.routes.deleteRouteLine);
  const routesLoading = useStoreState(state => state.routes.loading);

  const canEdit = permissions?.admin?.lines?.edit;

  const onGetLine = () => {
    getLine(props.match.params.id).then(() => {
      const linesState = store.getState().lines;
      if (!linesState.loading && !linesState.error) {
        // console.log(linesState);
      } else {
        handleEndpointErrors(linesState, props, setSnackbar, t);
      }
    });
  };

  const getRouteTypes = () => {
    const types = {};
    // eslint-disable-next-line array-callback-return
    routeTypes.map(type => {
      types[type.id] = t(`routes.type.${ type.name.toLowerCase() }`);
    });
    return types;
  };

  const t = useTranslation();

  const pageTitle = canEdit ? t('lines.table.actionEdit') : t('line.line');

  const routesTableColumns = [
    { title: t('routes.table.id'), field: 'id', type: 'numeric', align: 'left', defaultSort: 'asc', editable: 'never' },
    { 
      title: t('routes.name'), field: 'name',
      render: rowData => <Link component={ RouterLink } to={ `/routes/${ rowData.id }` }>{ rowData.name }</Link>,
      editable: 'never'
    },
    { title: t('routes.direction.label'), field: 'direction', lookup: getRouteDirections(routeDirections, t), sorting: false, editable: 'never' },
    { title: t('routes.type.label'), field: 'routeType.id', lookup: getRouteTypes(routeTypes, t), sorting: false, editable: 'never' },
    { title: t('routes.active'), field: 'active', type: 'boolean', sorting: false, editable: 'never' },
  ];

  const onDeleteRouteFromLine = (oldData, resolve, reject) => {
    deleteRouteLine(oldData.lineRouteId).then(() => {
      const routesState = store.getState().routes;
      if (!routesState.error) {
        // console.log('success', routesState);
        resolve();
        const data = [...routesTableData].filter(route => route.id !== oldData.id);
        setRoutesTableData(data);
        setSnackbar({ show: true, autoHideDuration: SNACKBAR_TIME.SUCCESS, severity: 'success', message: t('global.success.saved') });
      } else {
        reject();
        handleEndpointErrors(routesState, props, setSnackbar, t);
      }
    });
  };

  const onAddRouteModalClose = () => {
    setAddRouteModalOpen(false);
  };

  React.useEffect(() => {
    return () => {
      storeGetLine({});
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    onGetLine();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if ( line?.id ) {
      getRoutes({ loading: !routes.length, lineId: line.id, textSearch: '', clientId: 'all' });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [line]);

  React.useEffect(() => {
    if (line?.id) {
      setRoutesTableData(routes.map(route => ({ ...route, lineRouteId: line.routes.find(lineRoute => lineRoute.id === route.id)?.lineRouteId })));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [routes]);

  const formRef = React.useRef();

  storeMenuTitle(pageTitle);

  return (
    <div className={ classes.root }>
      <Breadcrumbs links={[
        { name: t('home.pageTitle'), path: '/', icon: <HomeIcon /> },
        { name: t('lines.pageTitle'), path: '/lines', icon: <ShuffleIcon /> },
        { 
          name: canEdit ? t('lines.table.actionEdit') : line?.name || pageTitle,
          icon: canEdit ? <EditIcon /> : <VisibilityIcon />
        },
      ]} />
      <div>
        <Typography className={ classes.pageTitle } variant="h5">{ pageTitle }</Typography>
        { !line?.id ? <LoadingCard length={ 1 } height={ 196 } /> : (
          <React.Fragment>
            <Formik
              innerRef={ formRef }
              initialValues={{ ...line }}
              validationSchema={ Yup.object({
                name: Yup.string()
                  .required(t('global.errors.required', { field: t('lines.table.name') })),
              }) }
              onSubmit={(values, { setSubmitting }) => {
                const data = { name: values.name };
                updateLine({ lineId: line.id, data }).then(() => {
                  const linesState = store.getState().lines;
                  if (!linesState.loading && !linesState.error) {
                    // console.log('success', linesState);
                    setSnackbar({ show: true, autoHideDuration: SNACKBAR_TIME.SUCCESS, severity: 'success', message: t('global.success.saved') });
                  } else {
                    handleEndpointErrors(linesState, props, setSnackbar, t);
                  }
                  setSubmitting(false);
                });
              }}
            >
              {({ submitForm, isSubmitting }) => (
                <CustomFormikForm>
                  <Grid container spacing={ 3 }>
                    <Grid item xs={ 6 } md={ 8 }>
                      <Field
                        component={ TextField }
                        name="name"
                        type="text"
                        label={ t('lines.table.name') }
                        variant="outlined"
                        fullWidth
                        inputProps={{
                          onChange: event => setLineName(event.target.value)
                        }}
                        disabled={ isSubmitting || !canEdit }
                      />
                    </Grid>
                    { canEdit && <Grid item xs={ 12 }>
                      <Button disabled={ isSubmitting } onClick={ submitForm } color="primary">{ t('global.modal.btnUpdate') }</Button>
                    </Grid> }
                  </Grid>
                </CustomFormikForm>
              )}
            </Formik>

            <CustomDialog open={ addRouteModalOpen } onClose={ onAddRouteModalClose } fullWidth={ true } maxWidth="md">
              <AddRoute
                line={{ ...line, name: lineName }}
                routeIds={ routesTableData.map(route => route.id) }
                onGetLine={ onGetLine }
                onAddRouteModalClose={ onAddRouteModalClose }
              />
            </CustomDialog>
          </React.Fragment>
        ) }
        <Typography className={ classes.routes } variant="h5">{ t('line.routes') }</Typography>
        { !line?.id || routesLoading ? <LoadingCard length={ 1 } height={ 430 } /> : <React.Fragment>
          <Table
            title=""
            columns={ routesTableColumns }
            data={ routesTableData }
            exportButton
            exportFileName={ pageTitle }
            deleteEvent={ canEdit ? onDeleteRouteFromLine : null }
            addActions={canEdit ? [{
              icon: 'add',
              iconProps: {
                color: 'primary'
              },
              tooltip: t('line.addRoute.action'),
              isFreeAction: true,
              onClick: (_, rowData) => {
                setAddRouteModalOpen(true);
              }
            }] : []}
          />
        </React.Fragment> }
      </div>
    </div>
  );
}

export default withRouter(EditLine);
