import React from 'react';

import { useStoreState, useStoreActions, useStore } from 'easy-peasy';

import { makeStyles } from '@material-ui/core/styles';
import Badge from '@material-ui/core/Badge';
import CommuteIcon from '@material-ui/icons/Commute';
import GroupIcon from '@material-ui/icons/Group';
import HomeIcon from '@material-ui/icons/Home';

import { handleEndpointErrors, SNACKBAR_TIME } from '../shared/utilities';

import Breadcrumbs from '../components/Breadcrumbs';
import LoadingCard from '../components/LoadingCard';
import Table from '../components/Table';
import CustomDialog from '../components/CustomDialog';
import AddVehicle from '../components/Vehicles/AddVehicle';
import EditVehicle from '../components/Vehicles/EditVehicle';
import Drivers from '../components/Vehicles/Drivers';
import ViewQrCode from '../components/ViewQrCode';
import DataFilters from '../components/DataFilters';

import { useTranslation } from 'react-multi-lang';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3, 2),
  },
  table: {
    '& .MuiTableCell-head:nth-child(8)': {
      minWidth: '160px',
    },
  },
}));

const Vehicles = (props) => {
  const classes = useStyles();
  const [vehiclesTableData, setVehiclesTableData] = React.useState([]);
  const [addVehicleModalOpen, setAddVehicleModalOpen] = React.useState(false);
  const [driversVehicleModalOpen, setDriversVehicleModalOpen] = React.useState(false);
  const [editVehicleModalOpen, setEditVehicleModalOpen] = React.useState(false);
  const [viewQrCodeModalOpen, setViewQrCodeModalOpen] = React.useState(false);
  const [editVehicle, setEditVehicle] = React.useState({});
  const [activeFilters, setActiveFilters] = React.useState({});

  const store = useStore();

  const storeMenuTitle = useStoreActions(actions => actions.global.storeTitle);
  const setSnackbar = useStoreActions(actions => actions.global.setSnackbar);

  const vehicles = useStoreState(state => state.vehicles.vehicles);
  const getVehicles = useStoreActions(actions => actions.vehicles.getVehicles);
  const deleteVehicle = useStoreActions(actions => actions.vehicles.deleteVehicle);
  const vehiclesLoading = useStoreState(state => state.vehicles.loading);
  const vehiclesTextSearch = useStoreState(state => state.vehicles.textSearch);
  const setVehiclesTextSearch = useStoreActions(actions => actions.vehicles.setTextSearch);

  const setClientsDisabled = useStoreActions(actions => actions.global.setClientsDisabled);

  const paginationPage = useStoreState(state => state.vehicles.page);
  const paginationPageSize = useStoreState(state => state.vehicles.pageSize);
  const [dataLoading, setDataLoading] = React.useState(false);

  const t = useTranslation();

  storeMenuTitle(t('vehicles.pageTitle'));

  const onGetVehicles = (payload) => {
    setDataLoading(true);
    getVehicles(payload).then(() => {
      const vehiclesState = store.getState().vehicles;
      if (!vehiclesState.loading && !vehiclesState.error) {
        setDataLoading(false);
        setVehiclesTableData(vehiclesState.vehicles.map(vehicle => ({ ...vehicle })));
      } else {
        handleEndpointErrors(vehiclesState, props, setSnackbar, t);
      }
    });
  }

  React.useEffect(() => {
    onGetVehicles({ loading: true });

    return () => setVehiclesTextSearch('');
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    setClientsDisabled(true);

    return () => setClientsDisabled(false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onAddVehicleModalClose = () => {
    setAddVehicleModalOpen(false);
  };

  const onDriversVehicleModalClose = () => {
    setDriversVehicleModalOpen(false);
  };

  const onEditVehicleModalClose = () => {
    setEditVehicleModalOpen(false);
  };

  const onViewQrCodeModalClose = () => {
    setViewQrCodeModalOpen(false);
  };

  const onDeleteVehicle = (oldData, resolve, reject) => {
    deleteVehicle(oldData.id).then(() => {
      const vehiclesState = store.getState().vehicles;
      if (!vehiclesState.error) {
        // console.log('success', vehiclesState);
        resolve();
        const data = [...vehiclesTableData].filter(vehicle => vehicle.id !== oldData.id);
        setVehiclesTableData(data);
        setSnackbar({ show: true, autoHideDuration: SNACKBAR_TIME.SUCCESS, severity: 'success', message: t('global.success.saved') });
      } else {
        reject();
        handleEndpointErrors(vehiclesState, props, setSnackbar, t);
      }
    });
  };

  const vehiclesTableColumns = [
    { title: t('vehicles.table.id'), field: 'id', type: 'numeric', align: 'left', defaultSort: 'asc', editable: 'never' },
    { title: t('vehicles.table.identifier'), field: 'identifier', editable: 'never' },
    { title: t('vehicles.licensePlate'), field: 'licensePlate', editable: 'never' },
    { title: t('vehicles.make'), field: 'make', editable: 'never' },
    { title: t('vehicles.model'), field: 'model', editable: 'never' },
    { title: t('vehicles.year'), field: 'year', editable: 'never' },
    { title: t('vehicles.km'), field: 'km', sorting: false, editable: 'never' },
    { title: t('vehicles.capacity'), field: 'capacity', editable: 'never' },
    { title: t('vehicles.active'), field: 'active', type: 'boolean', sorting: false, editable: 'never' },
  ];

  return (
    <div className={ classes.root }>
      <Breadcrumbs links={[
        { name: t('home.pageTitle'), path: '/', icon: <HomeIcon /> },
        { name: t('vehicles.pageTitle'), icon: <CommuteIcon /> },
      ]} />
      <DataFilters
        setActiveFilters={ setActiveFilters }
        filters={[
          {
            type: 'text',
            name: t('vehicles.searchPlaceholder'),
            property: 'textSearch',
            active: vehiclesTextSearch,
            width: '250px',
            onChangeFn: (filters) => {
              onGetVehicles({ page: 1, ...filters });
            }
          }
        ]}
      />
      { vehiclesLoading ? <LoadingCard length={ 1 } height={ 430 } /> : (
        <Table
          className={ classes.table }
          title=""
          columns={ vehiclesTableColumns }
          data={ vehiclesTableData }
          exportButton
          exportFileName={ t('vehicles.pageTitle') }
          deleteEvent={ onDeleteVehicle }
          search={ false }
          customPagination={{
            page: paginationPage,
            size: paginationPageSize,
            responseLength: vehicles.length,
            loading: dataLoading,
            clickEvent: page => {
              onGetVehicles({ page, ...activeFilters });
            }
          }}
          addActions={[
            {
              icon: 'add',
              iconProps: {
                color: 'primary'
              },
              tooltip: t('vehicles.table.actionAdd'),
              isFreeAction: true,
              onClick: () => {
                setAddVehicleModalOpen(true);
              }
            },
            rowData => ({
              icon: () => <Badge badgeContent={ rowData.drivers.length } color="primary"><GroupIcon /></Badge>,
              tooltip: t('route.drivers.title'),
              onClick: () => {
                setEditVehicle(rowData);
                setDriversVehicleModalOpen(true);
              }
            }),
            {
              icon: 'edit',
              tooltip: t('vehicles.table.actionEdit'),
              onClick: (_, rowData) => {
                setEditVehicle(rowData);
                setEditVehicleModalOpen(true);
              }
            },
            {
              icon: 'qr_code',
              tooltip: t('global.table.custom.actionViewQrCode'),
              onClick: (_, rowData) => {
                setEditVehicle(rowData);
                setViewQrCodeModalOpen(true);
              }
            },
          ]}
        />
      ) }

      <CustomDialog open={ addVehicleModalOpen } onClose={ onAddVehicleModalClose } fullWidth={ true } maxWidth="md">
        <AddVehicle
          onGetVehicles={ onGetVehicles }
          onAddVehicleModalClose={ onAddVehicleModalClose }
        />
      </CustomDialog>

      <CustomDialog open={ driversVehicleModalOpen } onClose={ onDriversVehicleModalClose } fullWidth={ true } maxWidth="lg">
        <Drivers
          editVehicle={ editVehicle }
          onGetVehicles={ onGetVehicles }
          onDriversVehicleModalClose={ onDriversVehicleModalClose }
        />
      </CustomDialog>

      <CustomDialog open={ editVehicleModalOpen } onClose={ onEditVehicleModalClose } fullWidth={ true } maxWidth="md">
        <EditVehicle
          editVehicle={ editVehicle }
          onGetVehicles={ onGetVehicles }
          onEditVehicleModalClose={ onEditVehicleModalClose }
        />
      </CustomDialog>

      <CustomDialog className={ classes.modal } open={ viewQrCodeModalOpen } onClose={ onViewQrCodeModalClose } fullWidth={ true } maxWidth="sm">
        <ViewQrCode
          selectedItem={{ id: editVehicle.id, name: `${ editVehicle.licensePlate } - ${ editVehicle.make } ${ editVehicle.model } ${ editVehicle.year }`, type: 'vehicle' }}
          onViewQrCodeModalClose={ onViewQrCodeModalClose }
        />
      </CustomDialog>
    </div>
  );
}

export default Vehicles;
