import { action, thunk } from 'easy-peasy';

import { getUsers, getUser, getUsersActions, getFunctionalAreas, getDevices, getFavoriteRoutes } from '../../services/users/get';
import { addUser, changePassword, addDevice, addFavoriteRoute } from '../../services/users/post';
import { updateUser, updateUserActions, updateDevice } from '../../services/users/put';
import { deleteUser, deleteDevice, deleteFavoriteRoute } from '../../services/users/delete';

const usersModel = {
  users: [],
  user: {},
  usersActions: {},
  loading: false,
  error: '',
  usersActionsLoading: false,
  usersActionsError: '',
  page: 1,
  pageSize: localStorage.getItem('usersPageSize') ? localStorage.getItem('usersPageSize') : 25,
  functionalAreas: [],
  devices: [],
  favoriteRoutes: [],
  pageFilters: {},
  getUsers: thunk(async (actions, payload = {}, { getState, getStoreState }) => {
    actions.setLoading(payload.loading);
    actions.setError('');
    return await getUsers(actions, payload, getState(), getStoreState());
  }),
  getUser: thunk(async (actions, payload) => {
    actions.setLoading(true);
    actions.setError('');
    return await getUser(actions, payload);
  }),
  getUsersActions: thunk(async (actions) => {
    actions.setUsersActionsLoading(true);
    actions.setUsersActionsError('');
    return await getUsersActions(actions);
  }),
  getFunctionalAreas: thunk(async (actions) => {
    actions.setError('');
    return await getFunctionalAreas(actions);
  }),
  getDevices: thunk(async (actions, payload) => {
    actions.setError('');
    return await getDevices(actions, payload);
  }),
  getFavoriteRoutes: thunk(async (actions, payload) => {
    actions.setError('');
    return await getFavoriteRoutes(actions, payload);
  }),
  addUser: thunk(async (actions, payload) => {
    actions.setError('');
    return await addUser(actions, payload);
  }),
  addDevice: thunk(async (actions, payload) => {
    actions.setError('');
    return await addDevice(actions, payload);
  }),
  addFavoriteRoute: thunk(async (actions, payload) => {
    actions.setError('');
    return await addFavoriteRoute(actions, payload);
  }),
  updateUser: thunk(async (actions, payload) => {
    actions.setError('');
    return await updateUser(actions, payload);
  }),
  updateUserActions: thunk(async (actions, payload) => {
    actions.setError('');
    return await updateUserActions(actions, payload);
  }),
  updateDevice: thunk(async (actions, payload) => {
    actions.setError('');
    return await updateDevice(actions, payload);
  }),
  deleteUser: thunk(async (actions, payload) => {
    actions.setError('');
    return await deleteUser(actions, payload);
  }),
  changePassword: thunk(async (actions, payload) => {
    actions.setError('');
    return await changePassword(actions, payload);
  }),
  deleteDevice: thunk(async (actions, payload) => {
    actions.setError('');
    return await deleteDevice(actions, payload);
  }),
  deleteFavoriteRoute: thunk(async (actions, payload) => {
    actions.setError('');
    return await deleteFavoriteRoute(actions, payload);
  }),
  storeGetUsers: action((state, payload) => {
    state.users = payload.data;
    state.page = payload.page ? payload.page : 1;
    state.pageSize = payload.pageSize ? payload.pageSize : 25;
    state.textSearch = payload.textSearch ? payload.textSearch : '';
  }),
  storeGetUser: action((state, payload) => {
    state.user = payload;
  }),
  storeGetUsersActions: action((state, payload) => {
    state.usersActions = payload;
  }),
  storeGetFunctionalAreas: action((state, payload) => {
    state.functionalAreas = payload;
  }),
  storeGetDevices: action((state, payload) => {
    state.devices = payload;
  }),
  storeGetFavoriteRoutes: action((state, payload) => {
    state.favoriteRoutes = payload;
  }),
  setLoading: action((state, payload) => {
    state.loading = payload;
  }),
  setError: action((state, payload) => {
    state.error = payload;
  }),
  setUsersActionsLoading: action((state, payload) => {
    state.usersActionsLoading = payload;
  }),
  setUsersActionsError: action((state, payload) => {
    state.usersActionsError = payload;
  }),
  setPageFilters: action((state, payload) => {
    state.pageFilters = payload;
  }),
}

export default usersModel;
