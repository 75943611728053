import React from 'react';

import PropTypes from 'prop-types';

import { useStoreState, useStoreActions, useStore } from 'easy-peasy';

// import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import PlaceIcon from '@material-ui/icons/Place';

import * as Yup from 'yup';

import { Formik, Field } from 'formik';
import { TextField } from 'formik-material-ui';

import { handleEndpointErrors, TIME_REGEX, renderPlaceOption, SNACKBAR_TIME } from '../../shared/utilities';
import { useDebounce } from '../../shared/hooks/useDebounce';

import LoadingCard from '../LoadingCard';
import CustomFormikForm from '../CustomFormikForm';
import FormikAutocomplete from '../FormikAutocomplete';

import { useTranslation } from 'react-multi-lang';

// const useStyles = makeStyles(theme => ({
//   root: {},
// }));

const LinkPlace = (props) => {
  const { routeId, placeIds, onGetRoutePlaces, onLinkPlaceModalClose } = props;
  // const classes = useStyles();

  const store = useStore();

  const setSnackbar = useStoreActions(actions => actions.global.setSnackbar);

  const addRoutePlace = useStoreActions(actions => actions.routes.addRoutePlace);

  const getAdminPlaces = useStoreActions(actions => actions.places.getAdminPlaces);
  const adminPlaces = useStoreState(state => state.places.adminPlaces);
  const adminPlacesLoading = useStoreState(state => state.places.loading);

  const arrivalTimeRef = React.useRef();

  const [placeName, setPlaceName] = React.useState('');
  const debouncedPlaceName = useDebounce(placeName, 500);

  const onGetAdminPlaces = (payload) => {
    getAdminPlaces(payload).then(() => {
      const placesState = store.getState().places;
      if (!placesState.loading && !placesState.error) {
        // console.log(placesState);
      } else {
        handleEndpointErrors(placesState, props, setSnackbar, t);
      }
    });
  }

  React.useEffect(() => {
    onGetAdminPlaces({ textSearch: debouncedPlaceName });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedPlaceName]);

  const t = useTranslation();

  return (
    <React.Fragment>
      <DialogTitle>{ t('route.places.link.title') }</DialogTitle>
      <Formik
        initialValues={{ place: '', arrivalTime: '00:00' }}
        validationSchema={ Yup.object({
          place: Yup.string()
            .required(t('global.errors.required', { field: t('route.places.link.place') })),
          arrivalTime: Yup.string()
            .matches(TIME_REGEX, t('global.errors.time', { field: t('route.places.arrivalTime') }))
            .required(t('global.errors.required', { field: t('route.places.arrivalTime') })),
        }) }
        onSubmit={(values, { setSubmitting }) => {
          const data = {
            routeId,
            placeId: values.place.id,
            arrivalTime: values.arrivalTime
          };
          addRoutePlace(data).then(() => {
            const routesState = store.getState().routes;
            if (!routesState.loading && !routesState.error) {
              setSubmitting(false);
              onGetRoutePlaces(routeId);
              onLinkPlaceModalClose();
              setSnackbar({ show: true, autoHideDuration: SNACKBAR_TIME.SUCCESS, severity: 'success', message: t('global.success.saved') });
            } else {
              handleEndpointErrors(routesState, props, setSnackbar, t);
            }
          });
        }}
      >
        {({ submitForm, isSubmitting }) => (
          <CustomFormikForm>
            <DialogContent>
              <Grid container spacing={ 3 }>
                <Grid item xs={ 6 } md={ 8 }>
                  { adminPlacesLoading ? <LoadingCard length={ 1 } height={ 56 } /> : (
                    <Field
                      component={ FormikAutocomplete }
                      name='place'
                      disableClearable
                      options={ adminPlaces.filter(place => !placeIds.includes(place.id)) }
                      renderOption={ option => option ? renderPlaceOption(option) : '' }
                      getOptionLabel={ option => option ? option.description : '' }
                      textFieldProps={{
                        label: t('route.places.link.place'),
                        variant: 'outlined',
                        fullWidth: true,
                        icon: <PlaceIcon />,
                        onChange: event => {
                          setPlaceName( event.target.value );
                        }
                      }}
                      onFocus={() => {
                        setPlaceName('');
                      }}
                      onChange={ () => arrivalTimeRef.current.querySelector('input').select() }
                    />
                  ) }
                </Grid>
                <Grid item xs={ 6 } md={ 4 }>
                  <Field
                    innerRef={ arrivalTimeRef }
                    component={ TextField }
                    name="arrivalTime"
                    type="text"
                    label={`${ t('route.places.arrivalTime') } (24h)`}
                    variant="outlined"
                    fullWidth
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={ onLinkPlaceModalClose } color="primary">{ t('global.modal.btnClose') }</Button>
              <Button disabled={ isSubmitting } onClick={ submitForm } color="primary">{ t('global.modal.btnLink') }</Button>
            </DialogActions>
          </CustomFormikForm>
        )}
      </Formik>
    </React.Fragment>
  );
}

LinkPlace.propTypes = {
  routeId: PropTypes.number.isRequired,
  placeIds: PropTypes.array.isRequired,
  onGetRoutePlaces: PropTypes.func.isRequired,
  onLinkPlaceModalClose: PropTypes.func.isRequired,
};

export default LinkPlace;
