import React from 'react';
import { withRouter } from 'react-router-dom';

import { useStoreState, useStoreActions, useStore } from 'easy-peasy';

import { makeStyles } from '@material-ui/core/styles';
import BusinessIcon from '@material-ui/icons/Business';
import HomeIcon from '@material-ui/icons/Home';
import VisibilityIcon from '@material-ui/icons/Visibility';

import { handleEndpointErrors, formatDateWithTime, dateISOWithoutTz, openLink } from '../shared/utilities';

import Breadcrumbs from '../components/Breadcrumbs';
import LoadingCard from '../components/LoadingCard';
import Table from '../components/Table';
import CustomDialog from '../components/CustomDialog';
import AddBusinessPark from '../components/BusinessParks/AddBusinessPark';

import { useTranslation } from 'react-multi-lang';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3, 2),
  },
  table: {
    // '& .MuiTableCell-head:nth-child(3)': {
    //   minWidth: '400px',
    // },
  }
}));

const BusinessParks = (props) => {
  const { history } = props;
  const classes = useStyles();
  const [businessParksTableData, setBusinessParksTableData] = React.useState([]);
  const [addBusinessParkModalOpen, setAddBusinessParkModalOpen] = React.useState(false);

  const store = useStore();

  const permissions = useStoreState(state => state.global.permissions);
  const storeMenuTitle = useStoreActions(actions => actions.global.storeTitle);
  const setSnackbar = useStoreActions(actions => actions.global.setSnackbar);

  const businessParks = useStoreState(state => state.businessParks.businessParks);
  const getBusinessParks = useStoreActions(actions => actions.businessParks.getBusinessParks);
  // const deleteBusinessPark = useStoreActions(actions => actions.businessParks.deleteBusinessPark);
  const businessParksLoading = useStoreState(state => state.businessParks.loading);

  const setClientsDisabled = useStoreActions(actions => actions.global.setClientsDisabled);

  const paginationPage = useStoreState(state => state.businessParks.page);
  const paginationPageSize = useStoreState(state => state.businessParks.pageSize);
  const [dataLoading, setDataLoading] = React.useState(false);

  const canEdit = permissions?.admin?.businessParks?.edit;
  // const canDelete = permissions?.admin?.businessParks?.delete;

  const t = useTranslation();

  storeMenuTitle(t('businessParks.pageTitle'));

  const onGetBusinessParks = (payload) => {
    setDataLoading(true);
    getBusinessParks(payload).then(() => {
      const businessParksState = store.getState().businessParks;
      if (!businessParksState.loading && !businessParksState.error) {
        setDataLoading(false);
        setBusinessParksTableData(businessParksState.businessParks.map(businessPark => {
          businessPark.formatCreatedAt = formatDateWithTime(dateISOWithoutTz(new Date(`${ businessPark.createdAt }`)), t);
          return { ...businessPark };
        }));
      } else {
        handleEndpointErrors(businessParksState, props, setSnackbar, t);
      }
    });
  }

  React.useEffect(() => {
    onGetBusinessParks({ loading: true });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    setClientsDisabled(true);

    return () => setClientsDisabled(false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onAddBusinessParkModalClose = () => {
    setAddBusinessParkModalOpen(false);
  };

  // const onDeleteBusinessPark = (oldData, resolve, reject) => {
  //   deleteBusinessPark(oldData.id).then(() => {
  //     const businessParksState = store.getState().businessParks;
  //     if (!businessParksState.error) {
  //       // console.log('success', businessParksState);
  //       resolve();
  //       const data = [...businessParksTableData].filter(businessPark => businessPark.id !== oldData.id);
  //       setBusinessParksTableData(data);
  //       setSnackbar({ show: true, autoHideDuration: SNACKBAR_TIME.SUCCESS, severity: 'success', message: t('global.success.saved') });
  //     } else {
  //       reject();
  //       handleEndpointErrors(businessParksState, props, setSnackbar, t);
  //     }
  //   });
  // };

  const businessParksTableColumns = [
    { title: t('businessParks.id'), field: 'id', defaultSort: 'asc', editable: 'never' },
    { title: t('businessParks.name'), field: 'name', editable: 'never' },
    { title: t('businessParks.createdAt'), field: 'formatCreatedAt', editable: 'never' },
  ];

  return (
    <div className={ classes.root }>
      <Breadcrumbs links={[
        { name: t('home.pageTitle'), path: '/', icon: <HomeIcon /> },
        { name: t('businessParks.pageTitle'), icon: <BusinessIcon /> },
      ]} />
      { businessParksLoading ? <LoadingCard length={ 1 } height={ 430 } /> : (
        <Table
          className={ classes.table }
          title=""
          columns={ businessParksTableColumns }
          data={ businessParksTableData }
          filtering
          exportButton
          exportFileName={ t('businessParks.pageTitle') }
          // deleteEvent={ canDelete ? onDeleteBusinessPark : null }
          customPagination={{
            page: paginationPage,
            size: paginationPageSize,
            responseLength: businessParks.length,
            loading: dataLoading,
            clickEvent: page => {
              onGetBusinessParks({ page });
            }
          }}
          addActions={[
            ...canEdit ? [{
              icon: 'add',
              iconProps: {
                color: 'primary'
              },
              tooltip: t('businessParks.table.actionAdd'),
              isFreeAction: true,
              onClick: () => {
                setAddBusinessParkModalOpen(true);
              }
            }] : [],
            {
              icon: canEdit ? 'edit' : () => <VisibilityIcon />,
              tooltip: canEdit ? t('businessParks.table.actionEdit') : t('businessParks.table.actionView'),
              onClick: (event, rowData) => {
                openLink(event, history, `/business-parks/${ rowData.id }`);
              }
            }
          ]}
        />
      ) }

      <CustomDialog open={ addBusinessParkModalOpen } onClose={ onAddBusinessParkModalClose } fullWidth={ true } maxWidth="md">
        <AddBusinessPark
          onGetBusinessParks={ onGetBusinessParks }
          onAddBusinessParkModalClose={ onAddBusinessParkModalClose }
        />
      </CustomDialog>
    </div>
  );
}

export default withRouter(BusinessParks);
