import React from 'react';

import { useStoreActions } from 'easy-peasy';

import { makeStyles } from '@material-ui/core/styles';
import Zoom from '@material-ui/core/Zoom';
import MuiAlert from '@material-ui/lab/Alert';

import { mqttService } from '../services/mqtt/mqtt';

import { NFC_ERROR_STATUS_CODE } from '../shared/utilities';

import { useTranslation } from 'react-multi-lang';

const TIME_MESSAGE = 5000;
const TIME_WAIT = 500;

const useStyles = makeStyles(theme => ({
  snackbar: {
    left: '50%',
    maxWidth: '300px',
    position: 'fixed',
    top: '7px',
    transform: 'translate(-50%, 0)',
    width: '100%',
    zIndex: 9999,
    '& .MuiAlert-action': {
      display: 'none',
    },
  },
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const NFC = () => {
  const classes = useStyles();
  const [alertOpen, setAlertOpen] = React.useState(false);
  const [messages, setMessages] = React.useState([]);
  const setShowAlert = React.useState(true)[1];

  const getNotifications = useStoreActions(actions => actions.notifications.getNotifications);

  const t = useTranslation();

  const onAlertClose = () => {
    setAlertOpen(false);
    setMessages(currentState => {
      currentState.shift();

      if ( currentState.length ) {
        setTimeout(() => {
          setAlertOpen(true);
          setTimeout(() => {
            onAlertClose();
          }, TIME_MESSAGE);
        }, TIME_WAIT);
      } else {
        setShowAlert(true);
      }

      return currentState;
    });
  }

  const connectMqtt = () => {
    mqttService.connect(
      'nfc',
      { ident: process.env.REACT_APP_NFC_MQTT_TOPIC.replace('schema', localStorage.getItem('schema')), options: {} },
      message => {
        const jsonMessage = JSON.parse(message.payloadString);
        if (jsonMessage['status.code'] === NFC_ERROR_STATUS_CODE && jsonMessage['vehicle']) {
          getNotifications();
          setMessages(currentState => [...currentState, jsonMessage['vehicle']]);
          setShowAlert(currentState => {
            if ( currentState ) {
              setAlertOpen(true);
              setTimeout(() => {
                onAlertClose();
              }, TIME_MESSAGE);
            }
            return false;
          });
        }
      }
    );
  };

  React.useEffect(() => {
    setInterval(() => {
      if ( !mqttService?.isConnected() ) {
        connectMqtt();
      }
    }, 10000);

    connectMqtt();

    return () => mqttService.disconnect();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return !!messages.length ? (
    <div className={ classes.snackbar }>
      <Zoom in={ alertOpen }>
        <Alert severity="warning" onClose={ onAlertClose }>{ t('global.nfc', { vehicle: messages[0] }) }</Alert>
      </Zoom>
    </div>
  ) : null;
}

export default NFC;
