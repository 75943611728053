import { createStore } from 'easy-peasy';

import globalModel from './models/global';
import loginModel from './models/login';
import notificationsModel from './models/notifications';
import dashboardModel from './models/dashboard';
import clientsModel from './models/clients';
import tripsModel from './models/trips';
import reservationsModel from './models/reservations';
import linesModel from './models/lines';
import routesModel from './models/routes';
import usersModel from './models/users';
import vehiclesModel from './models/vehicles';
import operationalIntervalsModel from './models/operationalIntervals';
import placesModel from './models/places';
import countriesModel from './models/countries';
import businessParksModel from './models/businessParks';
import reportsModel from './models/reports';

const storeModel = {
  global: globalModel,
  login: loginModel,
  notifications: notificationsModel,
  dashboard: dashboardModel,
  clients: clientsModel,
  trips: tripsModel,
  reservations: reservationsModel,
  lines: linesModel,
  routes: routesModel,
  users: usersModel,
  vehicles: vehiclesModel,
  operationalIntervals: operationalIntervalsModel,
  places: placesModel,
  countries: countriesModel,
  businessParks: businessParksModel,
  reports: reportsModel,
};

const store = createStore(storeModel);

export default store;
