import React from 'react';

import PropTypes from 'prop-types';

import clsx from 'clsx';

import { useStoreState, useStoreActions, useStore } from 'easy-peasy';

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

import * as Yup from 'yup';

import { Formik, Field } from 'formik';
import { TextField } from 'formik-material-ui';

import { handleEndpointErrors, renderPlaceOption, SNACKBAR_TIME } from '../../shared/utilities';
import { useDebounce } from '../../shared/hooks/useDebounce';

import LoadingCard from '../LoadingCard';
import CustomFormikForm from '../CustomFormikForm';
import FormikCheckbox from '../FormikCheckbox';
import FormikAutocomplete from '../FormikAutocomplete';

import { useTranslation } from 'react-multi-lang';

const useStyles = makeStyles(theme => ({
  routeActive: {
    right: theme.spacing(3),
    top: '128px !important',
  },
}));

const BasicInformation = (props) => {
  const { route, onGetRoute } = props;
  const classes = useStyles();
  const [isGeneratingToken, setIsGeneratingToken] = React.useState(false);

  const store = useStore();

  const permissions = useStoreState(state => state.global.permissions);
  const routeDirections = useStoreState(state => state.global.routeDirections);
  const routeTypes = useStoreState(state => state.global.routeTypes);
  const setSnackbar = useStoreActions(actions => actions.global.setSnackbar);

  const updateRoute = useStoreActions(actions => actions.routes.updateRoute);

  const getAdminPlaces = useStoreActions(actions => actions.places.getAdminPlaces);
  const adminPlaces = useStoreState(state => state.places.adminPlaces);
  const adminPlacesLoading = useStoreState(state => state.places.loading);

  const getOperationalIntervals = useStoreActions(actions => actions.operationalIntervals.getOperationalIntervals);
  const operationalIntervals = useStoreState(state => state.operationalIntervals.operationalIntervals);
  const operationalIntervalsLoading = useStoreState(state => state.operationalIntervals.loading);

  const generateRouteToken = useStoreActions(actions => actions.routes.generateRouteToken);

  const [placeName, setPlaceName] = React.useState('');
  const debouncedPlaceName = useDebounce(placeName, 500);

  const canEdit = permissions?.admin?.routes?.edit;

  const onGenerateRouteToken = () => {
    setIsGeneratingToken(true);
    generateRouteToken({ routeId: route.id, direction: route.direction }).then(() => {
      const routesState = store.getState().routes;
      if (!routesState.loading && !routesState.error) {
        onGetRoute();
        setSnackbar({ show: true, autoHideDuration: SNACKBAR_TIME.SUCCESS, severity: 'success', message: t('global.success.saved') });
      } else {
        setIsGeneratingToken(false);
        handleEndpointErrors(routesState, props, setSnackbar, t);
      }
    });
  }

  const onGetAdminPlaces = (payload) => {
    getAdminPlaces(payload).then(() => {
      const placesState = store.getState().places;
      if (!placesState.loading && !placesState.error) {
        // console.log(placesState);
      } else {
        handleEndpointErrors(placesState, props, setSnackbar, t);
      }
    });
  }

  React.useEffect(() => {
    onGetAdminPlaces({ textSearch: debouncedPlaceName });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedPlaceName]);

  React.useEffect(() => {
    getOperationalIntervals().then(() => {
      const operationalIntervalsState = store.getState().operationalIntervals;
      if (!operationalIntervalsState.loading && !operationalIntervalsState.error) {
        // console.log(operationalIntervalsState);
      } else {
        handleEndpointErrors(operationalIntervalsState, props, setSnackbar, t);
      }
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const formRef = React.useRef();

  const t = useTranslation();

  return (
    <Formik
      innerRef={ formRef }
      initialValues={{ ...route }}
      validationSchema={ Yup.object({
        name: Yup.string()
          .max(50, t('global.errors.max', { field: t('routes.name'), length: 50 }))
          .required(t('global.errors.required', { field: t('routes.name') })),
        km: Yup.number()
          .typeError(t('global.errors.number', { field: t('routes.km') }))
          .required(t('global.errors.required', { field: t('routes.km') })),
        eta: Yup.number()
          .typeError(t('global.errors.number', { field: t('routes.eta') }))
          .required(t('global.errors.required', { field: t('routes.eta') })),
        displayOrder: Yup.number()
          .typeError(t('global.errors.number', { field: t('routes.order') }))
          .required(t('global.errors.required', { field: t('routes.order') })),
        costPerKm: Yup.number()
          .typeError(t('global.errors.number', { field: t('routes.costPerKm') })),
        passengerTripCost: Yup.number()
          .typeError(t('global.errors.number', { field: t('routes.passengerTripCost') }))
      }) }
      onSubmit={(values, { setSubmitting }) => {
        const data = {
          active: values.active,
          name: values.name,
          direction: values.direction,
          routeTypeId: values.routeType.id,
          originId: +values.origin.id,
          destinationId: +values.destination.id,
          operationalIntervalId: +values.interval.id,
          km: +values.km,
          eta: +values.eta,
          displayOrder: +values.displayOrder,
          costPerKm: +values.costPerKm,
          passengerTripCost: +values.passengerTripCost
        };
        updateRoute({ routeId: values.id, data }).then(() => {
          const routesState = store.getState().routes;
          setSubmitting(false);
          if (!routesState.loading && !routesState.error) {
            onGetRoute();
            setSnackbar({ show: true, autoHideDuration: SNACKBAR_TIME.SUCCESS, severity: 'success', message: t('global.success.saved') });
          } else {
            handleEndpointErrors(routesState, props, setSnackbar, t);
          }
        });
      }}
    >
      {({ submitForm, isSubmitting, values, setFieldValue }) => (
        <CustomFormikForm className={ classes.form }>
          <Field
            component={ FormikCheckbox }
            className={clsx( 'formik-checkbox', classes.routeActive )}
            name="active"
            label={ t('routes.active') }
            disabled={ isSubmitting || !canEdit }
          />
          <Grid container spacing={ 3 }>
            <Grid item xs={ 6 } md={ 4 }>
              <Field
                component={ TextField }
                name="name"
                type="text"
                label={ t('routes.name') }
                variant="outlined"
                fullWidth
                disabled={ isSubmitting || !canEdit }
              />
            </Grid>
            <Grid item xs={ 6 } md={ 4 }>
              <Field
                component={ FormikAutocomplete }
                name="direction"
                disableClearable
                options={ routeDirections }
                getOptionLabel={ option => option ? t(`routes.direction.${ option.toLowerCase() }`) : '' }
                textFieldProps={{ label: t('routes.direction.label') , variant: 'outlined', readOnly: true, fullWidth: true }}
                disabled={ isSubmitting || !canEdit }
              />
            </Grid>
            <Grid item xs={ 6 } md={ 4 }>
              <Field
                component={ FormikAutocomplete }
                name="routeType"
                disableClearable
                options={ routeTypes }
                getOptionLabel={ option => option ? t(`routes.type.${ option.name.toLowerCase() }`) : '' }
                textFieldProps={{ label: t('routes.type.label') , variant: 'outlined', readOnly: true, fullWidth: true }}
                disabled={ isSubmitting || !canEdit }
              />
            </Grid>
            <Grid item xs={ 6 } md={ 4 }>
              { adminPlacesLoading ? <LoadingCard length={ 1 } height={ 56 } /> : (
                <Field
                  component={ FormikAutocomplete }
                  name='origin'
                  disableClearable
                  options={ adminPlaces.filter(place => ![values.origin.id, values.destination.id].includes(place.id)) }
                  renderOption={ option => option ? renderPlaceOption(option) : '' }
                  getOptionLabel={ option => option ? option.description : '' }
                  textFieldProps={{
                    label: t('routes.origin'),
                    variant: 'outlined',
                    fullWidth: true,
                    onChange: event => {
                      setPlaceName( event.target.value );
                    }
                  }}
                  onFocus={() => {
                    setPlaceName('');
                  }}
                  disabled={ isSubmitting || !canEdit }
                />
              ) }
            </Grid>
            <Grid item xs={ 6 } md={ 4 }>
              { adminPlacesLoading ? <LoadingCard length={ 1 } height={ 56 } /> : (
                <Field
                  component={ FormikAutocomplete }
                  name='destination'
                  disableClearable
                  options={ adminPlaces.filter(place => ![values.origin.id, values.destination.id].includes(place.id)) }
                  renderOption={ option => option ? renderPlaceOption(option) : '' }
                  getOptionLabel={ option => option ? option.description : '' }
                  textFieldProps={{
                    label: t('routes.destination'),
                    variant: 'outlined',
                    fullWidth: true,
                    onChange: event => {
                      setPlaceName( event.target.value );
                    }
                  }}
                  onFocus={() => {
                    setPlaceName('');
                  }}
                  disabled={ isSubmitting || !canEdit }
                />
              ) }
            </Grid>
            <Grid item xs={ 6 } md={ 4 }>
              { operationalIntervalsLoading ? <LoadingCard length={ 1 } height={ 56 } /> : (
                <Field
                  component={ FormikAutocomplete }
                  name='interval'
                  disableClearable
                  options={ operationalIntervals.filter(interval => ![values.interval.id].includes(interval.id)) }
                  getOptionLabel={ option => option ? option.name : '' }
                  textFieldProps={{ label: t('routes.interval'), variant: 'outlined', readOnly: true, fullWidth: true }}
                  disabled={ isSubmitting || !canEdit }
                />
              ) }
            </Grid>
            <Grid item xs={ 6 } md={ 4 }>
              <Field
                component={ TextField }
                name="km"
                type="text"
                label={ t('routes.km') }
                variant="outlined"
                fullWidth
                disabled={ isSubmitting || !canEdit }
              />
            </Grid>
            <Grid item xs={ 6 } md={ 4 }>
              <Field
                component={ TextField }
                name="eta"
                type="text"
                label={ t('routes.eta') }
                variant="outlined"
                fullWidth
                disabled={ isSubmitting || !canEdit }
              />
            </Grid>
            <Grid item xs={ 6 } md={ 4 }>
              <Field
                component={ TextField }
                name="displayOrder"
                type="text"
                label={ t('routes.order') }
                variant="outlined"
                fullWidth
                disabled={ isSubmitting || !canEdit }
              />
            </Grid>
            <Grid item xs={ 6 } md={ 4 }>
              <Field
                component={ TextField }
                name="costPerKm"
                type="text"
                label={ t('routes.costPerKm') }
                variant="outlined"
                fullWidth
                disabled={ isSubmitting || !canEdit }
              />
            </Grid>
            <Grid item xs={ 6 } md={ 4 }>
              <Field
                component={ TextField }
                name="passengerTripCost"
                type="text"
                label={ t('routes.passengerTripCost') }
                variant="outlined"
                fullWidth
              />
            </Grid>
            { canEdit && <Grid item xs={ 12 }>
              <Button disabled={ isSubmitting } onClick={ submitForm } color="primary">{ t('global.modal.btnUpdate') }</Button>
              <Button disabled={ isSubmitting || isGeneratingToken || !!route.token } onClick={ !route.token ? onGenerateRouteToken : null } color="primary">{ t('route.basicInformation.generateToken') }</Button>
            </Grid> }
          </Grid>
        </CustomFormikForm>
      )}
    </Formik>
  );
}

BasicInformation.propTypes = {
  route: PropTypes.object.isRequired,
  onGetRoute: PropTypes.func.isRequired,
};

export default BasicInformation;
