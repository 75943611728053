import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

import PropTypes from 'prop-types';

import { useStoreState, useStoreActions, useStore } from 'easy-peasy';

import { makeStyles } from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';

import { handleEndpointErrors, SNACKBAR_TIME } from '../../shared/utilities';

import Table from '../Table';
import CustomDialog from '../CustomDialog';
import AddLine from './AddLine';

import { useTranslation } from 'react-multi-lang';

const useStyles = makeStyles(theme => ({
  table: {
  }
}));

const Lines = (props) => {
  const { route } = props;
  const classes = useStyles();
  const [linesTableData, setLinesTableData] = React.useState([]);
  const [addLineModalOpen, setAddLineModalOpen] = React.useState(false);

  const store = useStore();

  const permissions = useStoreState(state => state.global.permissions);
  const setSnackbar = useStoreActions(actions => actions.global.setSnackbar);

  const routeLines = useStoreState(state => state.routes.routeLines);
  const getRouteLines = useStoreActions(actions => actions.routes.getRouteLines);
  const deleteRouteLine = useStoreActions(actions => actions.routes.deleteRouteLine);

  const canEdit = permissions?.admin?.routes?.edit && permissions?.admin?.routes?.editLines;
  const canDelete = permissions?.admin?.routes?.edit && permissions?.admin?.routes?.deleteLines;

  const t = useTranslation();

  const linesTableColumns = [
    { title: t('route.lines.id'), field: 'id', type: 'numeric', align: 'left' },
    { 
      title: t('route.lines.name'), field: 'name', sorting: false,
      render: rowData => <Link component={ RouterLink } to={ `/lines/${ rowData.id }` }>{ rowData.name }</Link>
    },
  ];

  const onGetRouteLines = (payload) => {
    getRouteLines(payload).then(() => {
      const routesState = store.getState().routes;
      if (!routesState.routeLinesLoading && !routesState.routeLinesError) {
        // console.log(routesState);
      } else {
        handleEndpointErrors({ ...routesState, loading: routesState.routeLinesLoading, error: routesState.routeLinesError }, props, setSnackbar, t);
      }
    });
  }

  const onDeleteRouteLine = (oldData, resolve, reject) => {
    const lineRouteId = oldData.routes.find(lineRoute => lineRoute.id === route.id)?.lineRouteId;
    deleteRouteLine(lineRouteId).then(() => {
      const routesState = store.getState().routes;
      if (!routesState.error) {
        // console.log('success', routesState);
        resolve();
        const data = [...linesTableData].filter(line => line.id !== oldData.id);
        setLinesTableData(data);
        setSnackbar({ show: true, autoHideDuration: SNACKBAR_TIME.SUCCESS, severity: 'success', message: t('global.success.saved') });
      } else {
        reject();
        handleEndpointErrors(routesState, props, setSnackbar, t);
      }
    });
  };

  const onAddLineModalClose = () => {
    setAddLineModalOpen(false);
  };

  React.useEffect(() => {
    if (route.id) {
      onGetRouteLines(route.id);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [route]);

  React.useEffect(() => {
    setLinesTableData(routeLines);
  }, [routeLines]);

  return (
    <React.Fragment>
      <Table
        className={ classes.table }
        title=""
        columns={ linesTableColumns }
        data={ linesTableData }
        paging={ false }
        deleteEvent={ canDelete ? onDeleteRouteLine : null }
        addActions={canEdit ? [{
          icon: 'add',
          iconProps: {
            color: 'primary'
          },
          tooltip: t('route.lines.add.action'),
          isFreeAction: true,
          onClick: (_, rowData) => {
            setAddLineModalOpen(true);
          }
        }] : []}
      />

      <CustomDialog open={ addLineModalOpen } onClose={ onAddLineModalClose } fullWidth={ true } maxWidth="md">
        <AddLine
          routeId={ route.id }
          lineIds={ linesTableData.map(line => line.id) }
          onGetRouteLines={ onGetRouteLines }
          onAddLineModalClose={ onAddLineModalClose }
        />
      </CustomDialog>
    </React.Fragment>
  );
}

Lines.propTypes = {
  route: PropTypes.object.isRequired
};

export default Lines;
