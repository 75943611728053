import axios from 'axios';

import { logSentryEndpointError } from '../../shared/utilities';

export const getExcelPassengerTripCosts = (actions, payload) => axios({
  method: 'get',
  url: `${ process.env.REACT_APP_API_URL }/api/admin/v1/reports/excel/passenger_trip_costs`,
  params: {
    ...payload,
    sortField: 'name',
    sortDirection: 'desc',
    includeCancelledRsv: payload?.includeCancelledRsv === 'true' ? true : false,
    includeExtraRsv: payload?.includeExtraRsv === 'true' ? true : false
  },
  responseType: 'blob',
  headers: {
    'auth-token': localStorage.getItem('token'),
    // 'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  }
})
.then(response => {
  actions.setLoading(false);  
  return response;
})
.catch(error => {
  actions.setLoading(false);
  actions.setError(error.response);
  logSentryEndpointError(error);
});

export const getExcelPassengersTripCostsByRoute = (actions, payload) => axios({
  method: 'get',
  url: `${ process.env.REACT_APP_API_URL }/api/admin/v1/reports/excel/passenger_trip_costs_by_route`,
  params: {
    ...payload,
    sortField: 'name',
    sortDirection: 'desc'
  },
  responseType: 'blob',
  headers: {
    'auth-token': localStorage.getItem('token'),
    // 'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  }
})
.then(response => {
  actions.setLoading(false);  
  return response;
})
.catch(error => {
  actions.setLoading(false);
  actions.setError(error.response);
  logSentryEndpointError(error);
});
