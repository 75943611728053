import React from 'react';
import { useHistory } from 'react-router-dom';

import PropTypes from 'prop-types';

import { useStoreState, useStoreActions } from 'easy-peasy';

import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

import { useTranslation } from 'react-multi-lang';

const useStyles = makeStyles(theme => ({
  root: {
    margin: theme.spacing(1, 2),
    width: '100%',
    '& .MuiOutlinedInput-root': {
      borderRadius: theme.spacing(3),
    },
    '& .MuiOutlinedInput-input': {
      paddingBottom: theme.spacing(1),
      paddingTop: theme.spacing(1),
    },
    [theme.breakpoints.down('xs')]: {
      maxWidth: '150px',
    },
  },
}));

const ClientsSelector = (props) => {
  const { data } = props;
  const classes = useStyles();
  const history = useHistory();
  
  const clients = useStoreState(state => state.global.clients);
  const clientSelected = useStoreState(state => state.global.clientSelected);
  const clientsDisabled = useStoreState(state => state.global.clientsDisabled);
  const setClients = useStoreActions(actions => actions.global.setClients);
  const setClientSelected = useStoreActions(actions => actions.global.setClientSelected);

  const clientsUpdated = useStoreState(state => state.clients.clientsUpdated);
  const setClientsUpdated = useStoreActions(actions => actions.clients.setClientsUpdated);

  const getTrips = useStoreActions(actions => actions.trips.getTrips);
  const getHeatMapTrips = useStoreActions(actions => actions.trips.getHeatMapTrips);
  const setTripSelected = useStoreActions(actions => actions.trips.setCurrentTrip);
  const filters = useStoreState(state => state.trips.filters);
  const setFilters = useStoreActions(actions => actions.trips.setFilters);

  const getUsers = useStoreActions(actions => actions.users.getUsers);

  const getReservations = useStoreActions(actions => actions.reservations.getReservations);

  const getLines = useStoreActions(actions => actions.lines.getLines);
  
  const getRoutes = useStoreActions(actions => actions.routes.getRoutes);

  const getTripsReport = useStoreActions(actions => actions.trips.getTripsReport);
  const getReservationsReport = useStoreActions(actions => actions.reservations.getReservationsReport);

  const initialValue = !clientsDisabled ? clientSelected : 'all';

  const onClientSelectedChange = (e) => {
    const client = e.target.value;
    const path = history.location.pathname.split('/')[1];
    const pathChild = history.location.pathname.split('/')[2];
    setClientSelected(e.target.value);

    if (client !== 'all') {
      setFilters({...filters, parkId: []})
    }

    switch(path) {
      case '':
      case 'trips':
        setTripSelected({});
        if ( localStorage.getItem('tripsSidebarTab') === '1' ) {
          getHeatMapTrips(true);
        } else {
          getTrips({ loading: true });
        }
        break;
      case 'users':
        getUsers({ clientChange: true, loading: true });
        break;
      case 'reservations':
        getReservations({ clientChange: true, loading: true });
        break;
      case 'lines':
        getLines({ loading: true });
        break;
      case 'routes':
        getRoutes({ clientChange: true, loading: true });
        break;
      case 'reports':
        switch(pathChild) {
          case 'trips':
            getTripsReport();
            break;
          case 'reservations':
            getReservationsReport();
            break;
          default:
        }
        break;
      default:
    }
  };

  React.useEffect(() => {
    if ((data.length && !clients.length) || (clientsUpdated && JSON.stringify(data) !== JSON.stringify(clients))) {
      setClients(data);
      setClientsUpdated(false);
      if (!data.filter(client => client.id === +clientSelected).length) {
        setClientSelected('all');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, clients, clientsUpdated]);

  const t = useTranslation();

  return (
    !!clients.length && <FormControl variant="outlined" className={ classes.root }>
      <InputLabel>{ t('clients.client') }</InputLabel>
      <Select
        value={ initialValue }
        onChange={ onClientSelectedChange }
        label={ t('clients.client') }
        disabled={ clientsDisabled }
        >
          <MenuItem value="all">{ t('clients.all') }</MenuItem>
          { clients.map(client => <MenuItem key={ client.id } value={ client.id }>{ client.name }</MenuItem>) }
      </Select>
    </FormControl>
  );
}

ClientsSelector.propTypes = {
  data: PropTypes.arrayOf(PropTypes.exact({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  })).isRequired,
};

export default ClientsSelector;
