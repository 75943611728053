import React from 'react';

import PropTypes from 'prop-types';

import { useStoreState, useStoreActions, useStore } from 'easy-peasy';

// import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DirectionsIcon from '@material-ui/icons/Directions';
import Grid from '@material-ui/core/Grid';

import * as Yup from 'yup';

import { Formik, Field } from 'formik';

import { handleEndpointErrors, SNACKBAR_TIME } from '../../shared/utilities';
import { useDebounce } from '../../shared/hooks/useDebounce';

import LoadingCard from '../LoadingCard';
import CustomFormikForm from '../CustomFormikForm';
import FormikAutocomplete from '../FormikAutocomplete';

import { useTranslation } from 'react-multi-lang';

// const useStyles = makeStyles(theme => ({
//   root: {},
// }));

const AddRoute = (props) => {
  const { line, routeIds, onGetLine, onAddRouteModalClose } = props;
  // const classes = useStyles();

  const store = useStore();

  const setSnackbar = useStoreActions(actions => actions.global.setSnackbar);

  const getRoutes = useStoreActions(actions => actions.routes.getRoutes);
  const routes = useStoreState(state => state.routes.loadedRoutes);
  const routesLoading = useStoreState(state => state.routes.loading);
  const addRouteLine = useStoreActions(actions => actions.routes.addRouteLine);
  const setRoutesTextSearch = useStoreActions(actions => actions.routes.setTextSearch);

  const [routeName, setRouteName] = React.useState('');
  const debouncedRouteName = useDebounce(routeName, 500);

  const onGetRoutes = (payload) => {
    getRoutes(payload).then(() => {
      const routesState = store.getState().routes;
      if (!routesState.loading && !routesState.error) {
        // console.log(routesState);
      } else {
        handleEndpointErrors(routesState, props, setSnackbar, t);
      }
    });
  }

  React.useEffect(() => {
    onGetRoutes({ noUpdateList: true, textSearch: debouncedRouteName, active: true });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedRouteName]);

  React.useEffect(() => {
    return () => setRoutesTextSearch('');
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const t = useTranslation();

  return (
    <React.Fragment>
      <DialogTitle>{ t('line.addRoute.title') }</DialogTitle>
      <Formik
        initialValues={{ route: '' }}
        validationSchema={ Yup.object({
          route: Yup.string()
            .required(t('global.errors.required', { field: t('line.addRoute.route') })),
        }) }
        onSubmit={(values, { setSubmitting }) => {
          const data = {
            lineId: line.id,
            routeId: values.route.id
          };
          addRouteLine(data).then(() => {
            const routesState = store.getState().routes;
            if (!routesState.loading && !routesState.error) {
              // console.log('success', routesState);
              onGetLine();
              onAddRouteModalClose();
              setSnackbar({ show: true, autoHideDuration: SNACKBAR_TIME.SUCCESS, severity: 'success', message: t('global.success.saved') });
            } else {
              handleEndpointErrors(routesState, props, setSnackbar, t);
            }
            setSubmitting(false);
          });
        }}
      >
        {({ submitForm, isSubmitting }) => (
          <CustomFormikForm>
            <DialogContent>
              <Grid container spacing={ 3 }>
                <Grid item xs={ 12 } md={ 6 }>
                  { routesLoading ? <LoadingCard length={ 1 } height={ 56 } /> : (
                    <Field
                      component={ FormikAutocomplete }
                      name='route'
                      disableClearable
                      options={ routes.filter(route => !routeIds.includes(route.id)) }
                      getOptionLabel={ option => option ? option.name : '' }
                      textFieldProps={{
                        label: t('line.addRoute.route'),
                        variant: 'outlined',
                        fullWidth: true,
                        icon: <DirectionsIcon />,
                        onChange: event => {
                          setRouteName( event.target.value );
                        }
                      }}
                      onFocus={() => {
                        setRouteName('');
                      }}
                    />
                  ) }
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={ onAddRouteModalClose } color="primary">{ t('global.modal.btnClose') }</Button>
              <Button disabled={ isSubmitting } onClick={ submitForm } color="primary">{ t('global.modal.btnAdd') }</Button>
            </DialogActions>
          </CustomFormikForm>
        )}
      </Formik>
    </React.Fragment>
  );
}

AddRoute.propTypes = {
  line: PropTypes.object.isRequired,
  routeIds: PropTypes.array.isRequired,
  onGetLine: PropTypes.func.isRequired,
  onAddRouteModalClose: PropTypes.func.isRequired,
};

export default AddRoute;
