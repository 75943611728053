import axios from 'axios';

import { logSentryEndpointError, todayDate, threeMonthsAgoDate } from '../../shared/utilities';

export const getTrips = (actions, isLoadMore, state, storeState) => axios({
  method: 'get',
  url: `${ process.env.REACT_APP_API_URL }/api/v1/trips`,
  params: {
    page: isLoadMore ? state.page + 1 : 1,
    pageSize: state.pageSize,
    ...(state.search.length && { textSearch: state.search }),
    sortDirection: 'desc',
    filter: JSON.stringify({
      ...(state.filters.status.length && { status: state.filters.status }),
      ...(state.filters.routeDirections.length && { direction: state.filters.routeDirections }),
      ...(state.filters.routeTypes.length && { routeType: state.filters.routeTypes }),
    }),
    ...(state.filters.date.length && {
      startDate: state.filters.date[0].startDate,
      endDate: state.filters.date[0].endDate,
    }),
    ...(state.filters.tripExtra.length === 1 && { extra: state.filters.tripExtra[0] }),
    ...(state.filters.parkId.length && storeState.global.clientSelected === 'all' && { parkId: state.filters.parkId[0] }),
    ...(storeState.global.clientSelected !== 'all' && !storeState.global.clientsDisabled && { clientId: storeState.global.clientSelected }),
  },
  headers: {
    'auth-token': localStorage.getItem('token'),
    // 'Access-Control-Allow-Origin': '*',
    // 'Content-Type': 'application/json'
  }
})
.then(response => {
  actions.setLoading(false);
  actions.storeGetTrips({ data: response.data, isLoadMore });
})
.catch(error => {
  actions.setLoading(false);
  actions.setError(error.response);
  logSentryEndpointError(error);
});

export const getHeatMapTrips = (actions, state, storeState) => axios({
  method: 'get',
  url: `${ process.env.REACT_APP_API_URL }/api/admin/v1/trips/status`,
  params: {
    page: 1,
    pageSize: 500,
    filter: JSON.stringify({
      ...(state.filters.status.length && { status: state.filters.status }),
      ...(state.filters.routeDirections.length && { direction: state.filters.routeDirections }),
      ...(state.filters.routeTypes.length && { routeType: state.filters.routeTypes }),
    }),
    startDate: state.filters.date.length ? state.filters.date[0].startDate : threeMonthsAgoDate(),
    endDate: state.filters.date.length ? state.filters.date[0].endDate : todayDate(),
    sortDirection: 'desc',
    ...(state.filters.tripExtra.length === 1 && { extra: state.filters.tripExtra[0] }),
    ...(state.filters.parkId.length && storeState.global.clientSelected === 'all' && { parkId: state.filters.parkId[0] }),
    ...(storeState.global.clientSelected !== 'all' && !storeState.global.clientsDisabled && { clientId: storeState.global.clientSelected }),
  },
  headers: {
    'auth-token': localStorage.getItem('token'),
    // 'Access-Control-Allow-Origin': '*',
    // 'Content-Type': 'application/json'
  }
})
.then(response => {
  actions.storeGetHeatMapTrips(response.data);
  actions.setHeatMapLoading(false);
})
.catch(error => {
  actions.setHeatMapLoading(false);
  actions.setHeatMapError(error.response);
  logSentryEndpointError(error);
});

export const getTrip = (actions, payload) => axios({
  method: 'get',
  url: `${ process.env.REACT_APP_API_URL }/api/v1/trips/${payload.id}`,
  headers: {
    'auth-token': localStorage.getItem('token'),
    // 'Access-Control-Allow-Origin': '*',
    // 'Content-Type': 'application/json'
  }
})
.then(response => {
  actions.setCurrentTrip({
    ...response.data,
    passengers: response.data.passengers || []
  });
  actions.setCurrentTripLoading(false);
})
.catch(error => {
  actions.setCurrentTripLoading(false);
  actions.setCurrentTripError(error.response);
  logSentryEndpointError(error);
});

export const getReservations = (actions, payload, state) => axios({
  method: 'get',
  url: `${ process.env.REACT_APP_API_URL }/api/v1/reservations`,
  params: {
    page: payload.page ? payload.page : state.reservationsPage,
    pageSize: payload.pageSize || state.reservationsPageSize,
    routeId: payload.routeId,
    ...(!!payload.textSearch && { textSearch: payload.textSearch }),
    filter: JSON.stringify({
      status: payload.status,
      departureDate: payload.departureDate,
      ...(payload.routeDirection.toLowerCase() === 'in' && { arrivalTime: payload.scheduleArrivalTime }),
      ...(payload.routeDirection.toLowerCase() === 'out' && { departureTime: payload.scheduleDepartureTime }),
    })
  },
  headers: {
    'auth-token': localStorage.getItem('token'),
    // 'Access-Control-Allow-Origin': '*',
    // 'Content-Type': 'application/json'
  }
})
.then(response => {
  actions.setReservationsLoading(false);
  actions.storeGetReservations({ data: response.data, tripId: payload.tripId, page: payload.page || state.reservationsPage, pageSize: payload.pageSize || state.reservationsPageSize });
})
.catch(error => {
  actions.setReservationsLoading(false);
  actions.setReservationsError(error.response);
  logSentryEndpointError(error);
});

export const getTripsReport = (actions, payload, state, storeState) => axios({
  method: 'get',
  url: `${ process.env.REACT_APP_API_URL }/api/v1/trips`,
  params: {
    page: payload.page || state.tripsReportPage,
    pageSize: payload.pageSize || state.tripsReportPageSize,
    textSearch: payload.textSearch === undefined ? state.tripsReportTextSearch : payload.textSearch,
    sortDirection: 'desc',
    startDate: state.tripsReportDates.startDate.toISOString().split('T')[0],
    endDate: state.tripsReportDates.endDate.toISOString().split('T')[0],
    ...(storeState.global.clientSelected !== 'all' && !storeState.global.clientsDisabled && { clientId: storeState.global.clientSelected }),
  },
  headers: {
    'auth-token': localStorage.getItem('token'),
    // 'Access-Control-Allow-Origin': '*',
    // 'Content-Type': 'application/json'
  }
})
.then(response => {
  actions.setLoading(false);
  actions.storeGetTripsReport({ data: response.data, page: payload.page || state.tripsReportPage, textSearch: payload.textSearch === undefined ? state.tripsReportTextSearch : payload.textSearch });
})
.catch(error => {
  actions.setLoading(false);
  actions.setError(error.response);
  logSentryEndpointError(error);
});
