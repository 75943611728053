import React from 'react';
import { withRouter } from 'react-router-dom';

import PropTypes from 'prop-types';

import { useStoreState, useStoreActions, useStore } from 'easy-peasy';

import { makeStyles } from '@material-ui/core/styles';

import { handleEndpointErrors, toHoursAndMinutes, getRouteDirections, SNACKBAR_TIME } from '../../shared/utilities';

import Table from '../Table';
import CustomDialog from '../CustomDialog';
import AddFavoriteRoute from './AddFavoriteRoute';

import { useTranslation } from 'react-multi-lang';

const useStyles = makeStyles(theme => ({
  table: {
    '& .MuiTableCell-head:nth-child(2), & .MuiTableCell-head:nth-child(6), & .MuiTableCell-head:nth-child(7), & .MuiTableCell-head:nth-child(8)': {
      minWidth: '160px',
    },
    '& .MuiTableCell-head:nth-child(3)': {
      minWidth: '250px',
    },
  }
}));

const FavoriteRoutes = (props) => {
  const { userId, clientId } = props;
  const classes = useStyles();
  const [favoriteRoutesTableData, setFavoriteRoutesTableData] = React.useState([]);
  const [addFavoriteRouteModalOpen, setAddFavoriteRouteModalOpen] = React.useState(false);

  const store = useStore();

  const routeDirections = useStoreState(state => state.global.routeDirections);
  const routeTypes = useStoreState(state => state.global.routeTypes);
  const setSnackbar = useStoreActions(actions => actions.global.setSnackbar);

  const favoriteRoutes = useStoreState(state => state.users.favoriteRoutes);
  const getFavoriteRoutes = useStoreActions(actions => actions.users.getFavoriteRoutes);
  const deleteFavoriteRoute = useStoreActions(actions => actions.users.deleteFavoriteRoute);

  const t = useTranslation();

  const getRouteTypes = () => {
    const types = {};
    // eslint-disable-next-line array-callback-return
    routeTypes.map(type => {
      types[type.id] = t(`user.favoriteRoutes.type.${ type.name.toLowerCase() }`);
    });
    return types;
  };

  const favoriteRoutesTableColumns = [
    { title: t('user.favoriteRoutes.id'), field: 'id', type: 'numeric', align: 'left', defaultSort: 'asc', editable: 'never' },
    { title: t('user.favoriteRoutes.routeId'), field: 'route.id', editable: 'never' },
    { title: t('user.favoriteRoutes.name'), field: 'route.name', editable: 'never' },
    { title: t('user.favoriteRoutes.direction.label'), field: 'route.direction', lookup: getRouteDirections(routeDirections, t), sorting: false, editable: 'never' },
    { title: t('user.favoriteRoutes.type.label'), field: 'route.routeTypeId', lookup: getRouteTypes(routeTypes, t), sorting: false, editable: 'never' },
    { title: t('user.favoriteRoutes.departureTime'), field: 'formatDepartureTime', editable: 'never' },
    { title: t('user.favoriteRoutes.arrivalTime'), field: 'formatArrivalTime', editable: 'never' },
    { title: t('user.favoriteRoutes.place'), field: 'place.description', editable: 'never' },
    { title: t('user.favoriteRoutes.active'), field: 'route.active', type: 'boolean', sorting: false, editable: 'never' },
  ];

  React.useEffect(() => {
    getFavoriteRoutes({ userId });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    setFavoriteRoutesTableData(favoriteRoutes.map(favoriteRoute => {
      favoriteRoute.formatArrivalTime = toHoursAndMinutes(favoriteRoute.routeSchedule.arrivalTime);
      favoriteRoute.formatDepartureTime = toHoursAndMinutes(favoriteRoute.routeSchedule.departureTime);
      return { ...favoriteRoute };
    }));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [favoriteRoutes]);

  const onDeleteFavoriteRoute = (oldData, resolve, reject) => {
    deleteFavoriteRoute(oldData.id).then(() => {
      const routesState = store.getState().routes;
      if (!routesState.error) {
        // console.log('success', routesState);
        resolve();
        const data = [...favoriteRoutesTableData].filter(route => route.id !== oldData.id);
        setFavoriteRoutesTableData(data);
        setSnackbar({ show: true, autoHideDuration: SNACKBAR_TIME.SUCCESS, severity: 'success', message: t('global.success.saved') });
      } else {
        reject();
        handleEndpointErrors(routesState, props, setSnackbar, t);
      }
    });
  };

  const onAddFavoriteRouteModalClose = () => {
    setAddFavoriteRouteModalOpen(false);
  };

  return (
    <React.Fragment>
      <Table
        className={ classes.table }
        title=""
        columns={ favoriteRoutesTableColumns }
        data={ favoriteRoutesTableData }
        paging={ false }
        deleteEvent={ onDeleteFavoriteRoute }
        addActions={[
          {
            icon: 'add',
            iconProps: {
              color: 'primary'
            },
            tooltip: t('user.favoriteRoutes.add.action'),
            isFreeAction: true,
            onClick: () => {
              setAddFavoriteRouteModalOpen(true);
            }
          }
        ]}
      />

      <CustomDialog open={ addFavoriteRouteModalOpen } onClose={ onAddFavoriteRouteModalClose } fullWidth={ true } maxWidth="md">
        <AddFavoriteRoute
          userId={ userId }
          clientId={ clientId }
          routeIds={ favoriteRoutesTableData.map(favoriteRoute => favoriteRoute.route.id) }
          onAddFavoriteRouteModalClose={ onAddFavoriteRouteModalClose }
        />
      </CustomDialog>
    </React.Fragment>
  );
}

FavoriteRoutes.propTypes = {
  userId: PropTypes.number.isRequired,
  clientId: PropTypes.number.isRequired,
};

export default withRouter(FavoriteRoutes);
