import React from 'react';
import { withRouter } from 'react-router-dom';

import { useStoreState, useStoreActions, useStore } from 'easy-peasy';

import { makeStyles } from '@material-ui/core/styles';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import DirectionsIcon from '@material-ui/icons/Directions';
import Divider from '@material-ui/core/Divider';
import EditIcon from '@material-ui/icons/Edit';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import HomeIcon from '@material-ui/icons/Home';
import Typography from '@material-ui/core/Typography';
import VisibilityIcon from '@material-ui/icons/Visibility';

import { handleEndpointErrors } from '../shared/utilities';

import Breadcrumbs from '../components/Breadcrumbs';
import LoadingCard from '../components/LoadingCard';
import BasicInformation from '../components/EditRoute/BasicInformation';
import CustomAccordion from '../components/CustomAccordion';
import Schedules from '../components/EditRoute/Schedules';
import Vehicles from '../components/EditRoute/Vehicles';
import Places from '../components/EditRoute/Places';
import Clients from '../components/EditRoute/Clients';
import Lines from '../components/EditRoute/Lines';

import { useTranslation } from 'react-multi-lang';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3, 2),
    '& .MuiDivider-root': {
      margin: theme.spacing(2, 0, 4),
    },
    '& .MuiAccordionDetails-root': {
      overflow: 'hidden',
      padding: 0,
      '& > div': {
        border: 'none',
        marginBottom: '-1px',
        width: '100%',
      },
      '& .MuiToolbar-root': {
        borderBottom: `1px solid ${ theme.palette.primary.light }`,
      },
    },
  },
  basicInformationTitle: {
    marginBottom: theme.spacing(2.5),
  },
  accordionHeading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

const EditRoute = (props) => {
  const classes = useStyles();

  const store = useStore();

  const permissions = useStoreState(state => state.global.permissions);
  const storeMenuTitle = useStoreActions(actions => actions.global.storeTitle);
  const setSnackbar = useStoreActions(actions => actions.global.setSnackbar);

  const route = useStoreState(state => state.routes.route);
  const getRoute = useStoreActions(actions => actions.routes.getRoute);
  const storeGetRoute = useStoreActions(actions => actions.routes.storeGetRoute);

  const clients = useStoreState(state => state.global.clients);

  const canEdit = permissions?.admin?.routes?.edit;

  const onGetRoute = () => {
    getRoute(props.match.params.id).then(() => {
      const routesState = store.getState().routes;
      if (!routesState.loading && !routesState.error) {
        // console.log(routesState);
      } else {
        handleEndpointErrors(routesState, props, setSnackbar, t);
      }
    });
  };

  React.useEffect(() => {
    onGetRoute();

    return () => {
      storeGetRoute({});
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const t = useTranslation();

  const pageTitle = canEdit ? t('routes.table.actionEdit') : t('route.route');

  storeMenuTitle(pageTitle);

  return (
    <div className={ classes.root }>
      <Breadcrumbs links={[
        { name: t('home.pageTitle'), path: '/', icon: <HomeIcon /> },
        { name: t('routes.pageTitle'), path: '/routes', icon: <DirectionsIcon /> },
        { 
          name: canEdit ? t('routes.table.actionEdit') : route?.name || pageTitle,
          icon: canEdit ? <EditIcon /> : <VisibilityIcon />
        },
      ]} />
      <Typography className={ classes.basicInformationTitle } variant="h5">{ pageTitle }</Typography>
      { (!route.id || !clients.length) ? <LoadingCard length={ 1 } height={ 196 } />  : <BasicInformation
        route={ route }
        onGetRoute={ onGetRoute }
      /> }
      <Divider />
      <CustomAccordion>
        <AccordionSummary expandIcon={ <ExpandMoreIcon /> }>
          <Typography className={ classes.accordionHeading }>{ t('route.schedules.title') }</Typography>
        </AccordionSummary>
        <AccordionDetails>
          { !route.id ? <LoadingCard width="100%" length={ 1 } height={ 50 } /> : <Schedules route={ route } /> }
        </AccordionDetails>
      </CustomAccordion>
      <CustomAccordion>
        <AccordionSummary expandIcon={ <ExpandMoreIcon /> }>
          <Typography className={ classes.accordionHeading }>{ t('route.vehicles.title') }</Typography>
        </AccordionSummary>
        <AccordionDetails>
          { !route.id ? <LoadingCard width="100%" length={ 1 } height={ 50 } /> : <Vehicles route={ route } /> }
        </AccordionDetails>
      </CustomAccordion>
      <CustomAccordion>
        <AccordionSummary expandIcon={ <ExpandMoreIcon /> }>
          <Typography className={ classes.accordionHeading }>{ t('route.places.title') }</Typography>
        </AccordionSummary>
        <AccordionDetails>
          { !route.id ? <LoadingCard width="100%" length={ 1 } height={ 50 } /> : <Places route={ route } /> }
        </AccordionDetails>
      </CustomAccordion>
      <CustomAccordion>
        <AccordionSummary expandIcon={ <ExpandMoreIcon /> }>
          <Typography className={ classes.accordionHeading }>{ t('route.lines.title') }</Typography>
        </AccordionSummary>
        <AccordionDetails>
          { !route.id ? <LoadingCard width="100%" length={ 1 } height={ 50 } /> : <Lines route={ route } /> }
        </AccordionDetails>
      </CustomAccordion>
      <CustomAccordion>
        <AccordionSummary expandIcon={ <ExpandMoreIcon /> }>
          <Typography className={ classes.accordionHeading }>{ t('route.clients.title') }</Typography>
        </AccordionSummary>
        <AccordionDetails>
          { !route.id ? <LoadingCard width="100%" length={ 1 } height={ 50 } /> : <Clients route={ route } /> }
        </AccordionDetails>
      </CustomAccordion>
    </div>
  );
}

export default withRouter(EditRoute);
