import React from 'react';
import { withRouter } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';

import { useStoreState, useStoreActions, useStore } from 'easy-peasy';

import { makeStyles } from '@material-ui/core/styles';
import BusinessIcon from '@material-ui/icons/Business';
import Button from '@material-ui/core/Button';
import EditIcon from '@material-ui/icons/Edit';
import Grid from '@material-ui/core/Grid';
import HomeIcon from '@material-ui/icons/Home';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import VisibilityIcon from '@material-ui/icons/Visibility';

import * as Yup from 'yup';

import { Formik, Field } from 'formik';
import { TextField } from 'formik-material-ui';

import { handleEndpointErrors, SNACKBAR_TIME } from '../shared/utilities';

import Breadcrumbs from '../components/Breadcrumbs';
import LoadingCard from '../components/LoadingCard';
import CustomFormikForm from '../components/CustomFormikForm';
import Table from '../components/Table';
import CustomDialog from '../components/CustomDialog';

import DeleteBusinessParkModal from '../components/EditBusinessPark/DeleteBusinessParkModal';
import AddClientModal from '../components/EditBusinessPark/AddClientModal';

import { useTranslation } from 'react-multi-lang';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3, 2),
  },
  pageTitle: {
    marginBottom: theme.spacing(2.5),
  },
  clients: {
    marginTop: theme.spacing(2.5),
    marginBottom: theme.spacing(2.5),
  },
  deleteBtn: {
    color: theme.palette.error.main,
  },
}));

const EditBusinessPark = (props) => {
  const classes = useStyles();
  const [clientsTableData, setClientsTableData] = React.useState([]);

  const store = useStore();

  const permissions = useStoreState(state => state.global.permissions);
  const storeMenuTitle = useStoreActions(actions => actions.global.storeTitle);
  const setSnackbar = useStoreActions(actions => actions.global.setSnackbar);

  const businessPark = useStoreState(state => state.businessParks.businessPark);
  const getBusinessPark = useStoreActions(actions => actions.businessParks.getBusinessPark);
  const storeGetBusinessPark = useStoreActions(actions => actions.businessParks.storeGetBusinessPark);
  const updateBusinessPark = useStoreActions(actions => actions.businessParks.updateBusinessPark);
  const deleteBusinessPark = useStoreActions(actions => actions.businessParks.deleteBusinessPark);

  const clients = useStoreState(state => state.clients.clients);
  const updateClient = useStoreActions(actions => actions.clients.updateClient);

  const [deleteBusinessParkModalOpen, setDeleteBusinessParkModalOpen] = React.useState(false);
  const [addClientModalOpen, setAddClientModalOpen] = React.useState(false);

  const canEdit = permissions?.admin?.businessParks?.edit;

  const onGetBusinessPark = () => {
    getBusinessPark(props.match.params.id).then(() => {
      const businessParksState = store.getState().businessParks;
      if (!businessParksState.loading && !businessParksState.error) {
        // console.log(businessParksState);
      } else {
        handleEndpointErrors(businessParksState, props, setSnackbar, t);
      }
    });
  };

  const t = useTranslation();

  const pageTitle = canEdit ? t('businessParks.table.actionEdit') : t('businessPark.businessPark');

  const clientsTableColumns = [
    { title: t('clients.id'), field: 'id', type: 'numeric', align: 'left', defaultSort: 'asc', editable: 'never' },
    { 
      title: t('clients.name'), field: 'name',
      render: rowData => <Link component={ RouterLink } to={ `/clients/${ rowData.id }` }>{ rowData.name }</Link>,
      editable: 'never'
    },
    { title: t('clients.active'), field: 'active', type: 'boolean', sorting: false, editable: 'never' },
    { title: t('clients.rsvAllowDelete'), field: 'rsvAllowDelete', type: 'boolean', sorting: false, editable: 'never' },
    { title: t('clients.rsvAutoCreate'), field: 'rsvAutoCreate', type: 'boolean', sorting: false, editable: 'never' },
  ];

  const onDeleteBusinessPark = () => {
    deleteBusinessPark(businessPark.id).then(() => {
      const businessParksState = store.getState().businessParks;
      if (!businessParksState.loading && !businessParksState.error) {
        // console.log('success', businessParksState);
        props.history.push({ pathname: '/business-parks' });
        setSnackbar({ show: true, autoHideDuration: SNACKBAR_TIME.SUCCESS, severity: 'success', message: t('global.success.saved') });
      } else {
        handleEndpointErrors(businessParksState, props, setSnackbar, t);
      }
    });
  };

  const onDeleteBusinessParkModalOpen = () => {
    setDeleteBusinessParkModalOpen(true);
  };

  const onDeleteBusinessParkModalClose = () => {
    setDeleteBusinessParkModalOpen(false);
  };

  const onAddClientModalClose = () => {
    setAddClientModalOpen(false);
  };

  const onDeleteClient = (oldData, resolve, reject) => {
    const client = clients.find(client => client.id === oldData.id);
    if ( client?.id ) {
      updateClient({ clientId: client.id, data: { ...client, parkId: null } }).then(() => {
        const clientsState = store.getState().clients;
        if (!clientsState.error) {
          // console.log('success', clientsState);
          resolve();
          const data = [...clientsTableData].filter(client => client.id !== oldData.id);
          setClientsTableData(data);
          setSnackbar({ show: true, autoHideDuration: SNACKBAR_TIME.SUCCESS, severity: 'success', message: t('global.success.saved') });
        } else {
          reject();
          handleEndpointErrors(clientsState, props, setSnackbar, t);
        }
      });
    }
  };

  React.useEffect(() => {
    return () => {
      storeGetBusinessPark({});
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    onGetBusinessPark();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (businessPark?.id) {
      setClientsTableData(businessPark.clients ?? []);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [businessPark]);

  const formRef = React.useRef();

  storeMenuTitle(pageTitle);

  return (
    <div className={ classes.root }>
      <Breadcrumbs links={[
        { name: t('home.pageTitle'), path: '/', icon: <HomeIcon /> },
        { name: t('businessParks.pageTitle'), path: '/business-parks', icon: <BusinessIcon /> },
        { 
          name: canEdit ? t('businessParks.table.actionEdit') : businessPark?.name || pageTitle,
          icon: canEdit ? <EditIcon /> : <VisibilityIcon />
        },
      ]} />
      <div>
        <Typography className={ classes.pageTitle } variant="h5">{ pageTitle }</Typography>
        { !businessPark?.id ? <LoadingCard length={ 1 } height={ 196 } /> : (
          <React.Fragment>
            <Formik
              innerRef={ formRef }
              initialValues={{ ...businessPark }}
              validationSchema={ Yup.object({
                name: Yup.string()
                  .max(50, t('global.errors.max', { field: t('businessParks.name'), length: 50 }))
                  .required(t('global.errors.required', { field: t('businessParks.name') })),
                description: Yup.string()
                  .max(300, t('global.errors.max', { field: t('businessParks.description'), length: 300 }))
                  .required(t('global.errors.required', { field: t('businessParks.description') })),
              }) }
              onSubmit={(values, { setSubmitting }) => {
                const data = { name: values.name };
                updateBusinessPark({ businessParkId: businessPark.id, data }).then(() => {
                  const businessParksState = store.getState().businessParks;
                  if (!businessParksState.loading && !businessParksState.error) {
                    // console.log('success', businessParksState);
                    setSnackbar({ show: true, autoHideDuration: SNACKBAR_TIME.SUCCESS, severity: 'success', message: t('global.success.saved') });
                  } else {
                    handleEndpointErrors(businessParksState, props, setSnackbar, t);
                  }
                  setSubmitting(false);
                });
              }}
            >
              {({ submitForm, isSubmitting }) => (
                <CustomFormikForm>
                  <Grid container spacing={ 3 }>
                    <Grid item xs={ 12 } md={ 6 }>
                      <Field
                        component={ TextField }
                        name="name"
                        type="text"
                        label={ t('businessParks.name') }
                        variant="outlined"
                        fullWidth
                        disabled={ isSubmitting || !canEdit }
                      />
                    </Grid>
                    <Grid item xs={ 12 }>
                      <Field
                        component={ TextField }
                        name="description"
                        type="text"
                        label={ t('businessParks.description') }
                        variant="outlined"
                        fullWidth
                        multiline
                        rows={3}
                        disabled={ isSubmitting || !canEdit }
                      />
                    </Grid>
                    { canEdit && <Grid item xs={ 12 }>
                      <Button disabled={ isSubmitting } onClick={ submitForm } color="primary">{ t('global.modal.btnUpdate') }</Button>
                      <Button disabled={ isSubmitting } onClick={ onDeleteBusinessParkModalOpen } className={classes.deleteBtn}>{ t('global.modal.btnDelete') }</Button>
                    </Grid> }
                  </Grid>
                </CustomFormikForm>
              )}
            </Formik>
            <Typography className={ classes.clients } variant="h5">{ t('clients.pageTitle') }</Typography>
            <Table
              title=""
              columns={ clientsTableColumns }
              data={ clientsTableData }
              exportButton
              exportFileName={ pageTitle }
              deleteEvent={ canEdit ? onDeleteClient : null }
              addActions={canEdit ? [{
                icon: 'add',
                iconProps: {
                  color: 'primary'
                },
                tooltip: t('businessPark.addClient.action'),
                isFreeAction: true,
                onClick: (_, rowData) => {
                  setAddClientModalOpen(true);
                }
              }] : []}
            />
            <CustomDialog open={ deleteBusinessParkModalOpen } onClose={ onDeleteBusinessParkModalClose } fullWidth={ true } maxWidth="sm">
              <DeleteBusinessParkModal
                businessPark={ businessPark }
                onDeleteBusinessPark={ onDeleteBusinessPark }
                onDeleteBusinessParkModalClose={ onDeleteBusinessParkModalClose }
              />
            </CustomDialog>

            <CustomDialog open={ addClientModalOpen } onClose={ onAddClientModalClose } fullWidth={ true } maxWidth="md">
              <AddClientModal
                businessParkId={ businessPark.id }
                onGetBusinessPark={ onGetBusinessPark }
                onAddClientModalClose={ onAddClientModalClose }
              />
            </CustomDialog>
          </React.Fragment>
        ) }
      </div>
    </div>
  );
}

export default withRouter(EditBusinessPark);
